import React, {useContext, useEffect, useState} from "react";
import {CircularProgress, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import {getCollectorDetailsBillList} from "../../api/accountResource";
import {useParams} from "react-router-dom";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import BillAccordion from "./BillAccordion";
import {CustomerContext} from "../../context/CustomerContext";
import {useAuth} from "oidc-react";

const RelatedBills = () => {
  const langContext = useContext(LanguageContext);
  const {showRequestError} = useRequestErrorMessage();
  const auth = useAuth();
  const [relatedBills, setRelatedBills] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const urlParams = useParams();
  const customerContext = useContext(CustomerContext);

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setDataLoading(true);
      getCollectorDetailsBillList(auth.userData.access_token, customerContext.selectedCustomer.id, urlParams.id)
        .then(res => setRelatedBills(res.data))
        .catch(error => showRequestError(error, langContext.dictionary.errorMessages.userDataLoadError))
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  return (
    <>
      {relatedBills.length > 0 ?
        (<>
          <Grid container item>
            <Typography variant="h4" marginBottom={3} marginTop={3}>{langContext.dictionary.relatedBills}</Typography>
          </Grid>
          <Grid container>
            {!dataLoading ? relatedBills?.map((item, index) =>
              <Grid item key={`relatedBill_${index}`} style={{marginTop: 20}}>
                <BillAccordion data={item} index={index} key={`billAccordion_${index}`}/>
              </Grid>
            ) : <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
              <CircularProgress color="secondary"/>
            </Grid>}
          </Grid>
        </>) : null}

    </>
  );
};

export default RelatedBills;