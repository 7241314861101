import React from "react";
import PropTypes from "prop-types";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const PortalAccessGrid = ({handleFieldChange, user, selectOptions, adminEditor}) => {
  return (
    <Grid item xs={12} container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography sx={{fontWeight: "bold"}}>{user.customerPortalStatus?.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={user.customerPortalStatus?.value}
          exclusive
          onChange={(event, newStatus) => handleFieldChange("customerPortalStatus", newStatus)}
          fullWidth
          disabled={!adminEditor}
        >
          {selectOptions.customer_portal_status?.map((status, index) => (
            <ToggleButton value={status.value} color={status.value === "N" ? "error" : "secondary"} key={`status_${index}`}>
              {status.title}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

PortalAccessGrid.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  adminEditor: PropTypes.bool
};

export default PortalAccessGrid;
