import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import {Paper} from "@mui/material";
import SectionWithDivider from "../common/SectionWithDivider";
import PropTypes from "prop-types";

const PrivilegeLevelsGrid = (props) => {
  const languageContext = useContext(LanguageContext);

  return (
    <Grid item container spacing={1} key={"privilegeLevels"} seleniumid={"privilegeLevelsGrid"}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {languageContext.dictionary.privilegeLevels}
        </Typography>
      </Grid>
      <Grid item xs={12} container>
        <Paper elevation={4} sx={{width: "100%", padding: 5}}>
          {props.privilegeLevels.map((privilegeLevel, index) => (
            <SectionWithDivider
              title={languageContext.dictionary[privilegeLevel.title] || privilegeLevel.title}
              value={languageContext.dictionary.privilegeLevelsLocalization[privilegeLevel.description] || privilegeLevel.description}
              key={`sectionWithDivider${index}`}
              reverseStyle
            />
          ))}
        </Paper>
      </Grid>
    </Grid>
  );
};

PrivilegeLevelsGrid.propTypes = {
  privilegeLevels: PropTypes.array
};

export default PrivilegeLevelsGrid;
