import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Checkbox, CircularProgress, ListItemButton} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {abbreviationCorrection} from "../../../utils/commonFunctions";
import {LanguageContext} from "../../../context/LanguageContext";
import Tooltip from "@mui/material/Tooltip";

const RightsGrid = ({handleFieldChange, user, rights, rightsLoading, adminEditor}) => {
  const languageContext = useContext(LanguageContext);

  const isReadOnly = (right) => {
    return right?.params?.find(item => item?.param_type === "READ-ONLY")?.param !== "0";
  };

  const hasRight = (right) => {
    return user.ssoRole?.value?.some(r => r === right.value);
  };

  const handleToggle = (newRight) => {
    if (!isReadOnly(newRight)) {
      let newRights = [];
      let ssoRole = user.ssoRole?.value || [];
      if (ssoRole.some(r => r === newRight.value)) {
        newRights = ssoRole.filter(v => v !== newRight.value);
      } else {
        newRights = [...ssoRole, newRight.value];
      }
      handleFieldChange("ssoRole", newRights);
    }
  };
  abbreviationCorrection(rights, null, languageContext, true);

  return (
    <Grid item xs={12} container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography sx={{fontWeight: "bold"}}>{user.ssoRole?.name}</Typography>
      </Grid>
      <Grid item xs={12} container>
        {!rightsLoading ? (
          <List sx={{width: '100%'}}>
            {rights?.map((right, index) => {
              const labelId = `checkbox-list-label-${index}`;
              return (
                <Tooltip title={isReadOnly(right) ? languageContext.dictionary.ssoRolesTooltip : ""} placement="left-start">
                  <ListItem
                    key={index}
                    disablePadding
                    onClick={() => handleToggle(right)}
                  >
                    <ListItemButton disabled={!adminEditor} dense>
                      <ListItemIcon>
                        <Checkbox
                          disabled={!adminEditor || isReadOnly(right)}
                          edge="start"
                          checked={hasRight(right)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{'aria-labelledby': labelId}}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={right.title}/>
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
        ) : (
          <Grid item xs={12} sx={{textAlign: "center"}}>
            <CircularProgress sx={{margin: 4}} color="secondary"/>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

RightsGrid.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  rights: PropTypes.array,
  rightsLoading: PropTypes.bool,
  adminEditor: PropTypes.bool
};

export default RightsGrid;
