import React, {useContext, useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {CustomerContext} from "../context/CustomerContext";
import {useAuth} from "oidc-react";

const useStyles = makeStyles(theme => ({
  circularProgress: {
    margin: theme.spacing(4)
  }
}));

const RootPage = () => {
  const classes = useStyles();
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.userData && !!customerContext?.selectedCustomer?.id) {
      setLoading(false);
    }
  }, [auth.userData, customerContext?.selectedCustomer?.id]);

  return (
    <div>
      {!loading ? (
        <Redirect to="/home"/>
      ) : (
        <div style={{textAlign: "center"}}>
          <CircularProgress
            className={classes.circularProgress}
            color="secondary"
          />
        </div>
      )}
    </div>
  );
};

export default RootPage;
