import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {UmamiContext} from "../../context/UmamiContext";

const ErrorTicketButton = ({size, hasOpenErrorTicket, serviceStatus}) => {
  const history = useHistory();
  const langContext = useContext(LanguageContext);
  const [buttonMessage, setButtonMessage] = useState("");
  const umamiContext = useContext(UmamiContext);

  useEffect(() => {
    if (hasOpenErrorTicket) {
      setButtonMessage(langContext.dictionary.errorButtonInProgress);
    } else if (serviceStatus === "Korlátozott") {
      setButtonMessage(langContext.dictionary.errorButtonLimited);
    } else if (serviceStatus === "Szüneteltetve") {
      setButtonMessage(langContext.dictionary.errorButtonSuspend);
    } else {
      setButtonMessage("");
    }
  }, [hasOpenErrorTicket, serviceStatus]);

  return (
    <>
      <Tooltip title={buttonMessage}>
        <span>
          <Button
            size={size}
            disabled={hasOpenErrorTicket || serviceStatus === "Korlátozott" || serviceStatus === "Szüneteltetve"}
            color="secondary"
            endIcon={<ChevronRightIcon/>}
            onClick={() => {
              umamiContext.track('from_service_details_click_new_error_report');
              history.push(`/technicalOverview/errorTicketRecording`);
            }}
          >
            {langContext.dictionary.errorTicketRecording}
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

ErrorTicketButton.propTypes = {
  size: PropTypes.string,
  hasOpenErrorTicket: PropTypes.bool,
  serviceStatus: PropTypes.string
};

ErrorTicketButton.defaultProps = {
  size: "large"
};

export default ErrorTicketButton;