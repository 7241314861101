import React, {useContext} from "react";
import {Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from "@mui/material/IconButton";
import {Link} from "react-router-dom";
import {formatDate} from "../../utils/dateFormat";
import {getStatusIcon} from "../../utils/commonFunctions";

const useStyles = makeStyles(theme => ({
  duration: {
    fontSize: 33,
    float: "left",
    marginRight: 5
  },
  rightGrid: {
    [theme.breakpoints.up('lg')]: {
      textAlign: "right"
    }
  },
  avatar: {
    backgroundColor: "white",
    color: theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: 2,
    width: 60,
    height: 60,
    fontSize: 25
  },
  boldRow: {
    fontWeight: "bold"
  },
  divider: {
    background: theme.palette.secondary.main
  },
  boldText: {
    fontWeight: "bold"
  }
}));

const ServicesGrid = ({data}) => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);

  return (
    <Grid item xs={12} style={{padding: 15}}>
      <Paper style={{padding: 15}}>
        <Grid container alignItems="center" justifyContent="space-around" spacing={1}>
          <Grid item container xs={10} sm={11} alignItems="center" justifyContent="space-around" spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="subtitle1" className={classes.boldText}>{data.type}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {data.siteList.map((site, index) => <Typography key={`site_${index}`}>{site}</Typography>)}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle1">{languageContext.dictionary.serviceId}</Typography>
              <Typography variant="subtitle1" className={classes.boldText}>{data.id}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="subtitle1">{languageContext.dictionary.contractNumber}</Typography>
              <Typography variant="subtitle1" className={classes.boldText}>{data.contractNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} style={{display: "flex", alignItems: "center"}}>
              {getStatusIcon(data.status)}
              <div style={{paddingLeft: 15}}>
                <Typography variant="subtitle1" className={classes.boldText}>{data.status}</Typography>
                <Typography variant="subtitle2">{formatDate(data.statusChangeDate)}</Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={1}>
            <Link to={`/serviceDetails/${data.poid}`} style={{textDecoration: 'none'}}>
              <IconButton color="secondary">
                <ChevronRightIcon/>
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ServicesGrid.propTypes = {
  data: PropTypes.object
};

export default ServicesGrid;