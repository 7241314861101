import React, {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Dialog from "@mui/material/Dialog";
import {DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

function DeleteDialog(props) {
  const languageContext = useContext(LanguageContext);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth={"sm"}
      disableScrollLock
    >
      <DialogTitle onClose={props.handleClose} color="secondary">
        {languageContext.dictionary.deleteConfirm}
      </DialogTitle>
      <DialogContent>
        <Typography variant={"body1"}>
          {props.confirmDeleteText || languageContext.dictionary.confirmDelete}
        </Typography>
      </DialogContent>
      <DialogActions sx={{padding: '24px'}}>
        <Button onClick={props.handleClose} color="primary">
          {languageContext.dictionary.close}
        </Button>
        <Button autoFocus variant="contained" onClick={props.handleDelete} color="mainButton">
          {languageContext.dictionary.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  confirmDeleteText: PropTypes.string
};

export default DeleteDialog;