import React, {useContext, useEffect, useState} from "react";
import {CircularProgress, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import {CustomerContext} from "../../context/CustomerContext";
import {useAuth} from "oidc-react";
import ErrorTicket from "./ErrorTicket";
import {getErrorTicketList} from "../../api/errorTicketResource";
import moment from "moment/moment";
import PropTypes from "prop-types";


const RelatedErrorTickets = ({serviceIds}) => {
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const [relatedErrorTickets, setRelatedErrorTickets] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const customerContext = useContext(CustomerContext);


  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      const fromDate = moment().subtract(1, 'years').valueOf();
      const toDate = moment().valueOf();
      let filters = {from: fromDate, to: toDate};
      if (serviceIds.length > 0) {
        setDataLoading(true);
        let promises = [];
        serviceIds.forEach((serviceId) => {
          promises.push(getErrorTicketList(auth.userData.access_token, customerContext.selectedCustomer.id, null, serviceId, null, filters, null));
        });
        Promise.all(promises)
          .then(responses => {
            let errorTickets = [];
            responses.forEach(response => {
              errorTickets = errorTickets.concat(response.data.items);
            });
            setRelatedErrorTickets(errorTickets);
          })
          .finally(() => setDataLoading(false));
      }

    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  return (
    <>
      {relatedErrorTickets.length > 0 ?
        (<Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" marginBottom={3} marginTop={3}>{langContext.dictionary.relatedErrorTickets}</Typography>
          </Grid>
          <Grid item xs={12}>
            {!dataLoading ? relatedErrorTickets?.map((item, index) =>
              <Grid item key={`relatedErrorTickets_${index}`} style={{marginTop: 20}}>
                <ErrorTicket data={item}/>
              </Grid>
            ) : <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
              <CircularProgress color="secondary"/>
            </Grid>}
          </Grid>
        </Grid>) : null}
    </>
  );
};

RelatedErrorTickets.propTypes = {
  serviceIds: PropTypes.array.isRequired,
};

export default RelatedErrorTickets;