import React, {useContext, useEffect, useState} from "react";
import {Container} from "@mui/material";
import ServerRoomEnterDetailsForm from "./ServerRoomEnterDetailsForm";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import ServerRoomPersonalDataForm from "./ServerRoomPersonalDataForm";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {useHistory} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import {CustomerContext} from "../../context/CustomerContext";
import {LoadingButton} from "@mui/lab";
import {useAuth} from "oidc-react";
import {useSnackbar} from "notistack";
import {createServerRoomAccessPermit} from "../../api/dcmaResource";
import {formatDateTimeSec} from "../../utils/dateFormat";
import {UmamiContext} from "../../context/UmamiContext";
import {UserContext} from "../../context/UserContext";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const defaultEnterDetails = {
  serverRoom: null,
  affectedServices: [],
  action: null,
  licensePlate: null,
  enterInterval: [null, null],
  comment: null
};

const defaultPersonalData = {
  contactId: "",
  firstName: "",
  lastName: "",
  company: "",
  documentType: "",
  documentNumber: ""
};

const ServerRoomAccessPermitPage = () => {
  const auth = useAuth();
  const {enqueueSnackbar} = useSnackbar();
  const languageContext = useContext(LanguageContext);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const history = useHistory();
  const [enterDetails, setEnterDetails] = useState(defaultEnterDetails);
  const [personalDataList, setPersonalDataList] = useState([defaultPersonalData]);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const umamiContext = useContext(UmamiContext);

  useEffect(() => {
    if (customerContext.selectedCustomer.name) {
      setPersonalDataList(curr => curr.map(c => ({...c, company: c.company || customerContext.selectedCustomer.name})));
    }
  }, [customerContext.selectedCustomer.id]);

  const handleSubmit = (e) => {
    umamiContext.track('server_room_access_permit_send');
    e.preventDefault();
    setSaveInProgress(true);

    const accessPermit = {
      customerId: customerContext.selectedCustomer.id,
      serverRoom: enterDetails.serverRoom,
      activity: enterDetails.action?.value,
      requiredTimeStamp: formatDateTimeSec(enterDetails.enterInterval[0]),
      requiredEndTimeStamp: formatDateTimeSec(enterDetails.enterInterval[1]),
      plateNumber: enterDetails.licensePlate,
      comment: enterDetails.comment,
      services: enterDetails.affectedServices?.map(service => service.id),
      contactList: personalDataList.map(data => ({
        contactFirstName: data.firstName,
        contactLastName: data.lastName,
        certificateType: data.documentType?.label,
        certificateId: data.documentNumber
      }))
    };

    createServerRoomAccessPermit(auth.userData.access_token, accessPermit)
      .then(() => {
        enqueueSnackbar(languageContext.dictionary.successCreate, {variant: "success", preventDuplicate: true});
        history.push("/service/serverRoomAccess"); // todo link to details
      })
      .finally(() => setSaveInProgress(false));
  };

  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Container maxWidth="lg" sx={{pb: 3, pt: 3}} seleniumid={"ServerRoomAccessPermitPage"}>
        <Button
          variant="text"
          onClick={() => history.push("/service/serverRoomAccess")}
          size="small"
          color="secondary"
          disableRipple
        >
          <ChevronLeftIcon color="secondary"/>
          {languageContext.dictionary.serverRoomAccesses}
        </Button>
        <Typography variant={"h5"} sx={{mt: 2}}>{languageContext.dictionary.serverRoomAccessPermit}</Typography>
        <form onSubmit={handleSubmit}>
          <ServerRoomEnterDetailsForm details={enterDetails} setDetails={setEnterDetails}/>
          <ServerRoomPersonalDataForm dataList={personalDataList} setDataList={setPersonalDataList} enterDetails={enterDetails}/>
          <Container sx={{textAlign: "center", mt: 2}}>
            <Tooltip arrow
                     title={userContext?.userInfo?.innerUser ? languageContext.dictionary.disabledImpersonate : "" || enterDetails?.affectedServices?.length === 0 ? languageContext.dictionary.noAffectedServiceSelected : ""}>
              <span>
                <LoadingButton
                  loading={saveInProgress}
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={userContext?.userInfo?.innerUser || enterDetails?.affectedServices?.length === 0}
                  seleniumid={"serverRoomAccessPermitPageSubmitButton"}
                >
                  {languageContext.dictionary.send}
                </LoadingButton>
              </span>
            </Tooltip>
          </Container>
        </form>
      </Container>
    </RenderedWithPermission>
  );

};

export default ServerRoomAccessPermitPage;
