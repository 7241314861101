import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {LanguageContext} from "../../context/LanguageContext";
import {useAuth} from "oidc-react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import {useHistory} from "react-router-dom";
import AdministrationButtons from "./AdministrationButtons";
import CustomSelect from "../formFields/CustomSelect";
import {CircularProgress, Paper} from "@mui/material";
import {dmsDynamicFieldDocs, dmsStartCase, getDmsCaseTemplate, getDmsCaseTypeList} from "../../api/dmsResource";
import {CustomerContext} from "../../context/CustomerContext";
import moment from "moment";
import {useSnackbar} from "notistack";
import {adminFinTechRight, dateFormat} from "../../utils/commonValues";
import DynamicFormField from "../common/DynamicFormField";
import {UmamiContext} from "../../context/UmamiContext";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import CustomTooltip from "../errorTicket/CustomTooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from '@mui/material/Switch';
import Tooltip from "@mui/material/Tooltip";
import CaseDocumentRow from "./CaseDocumentRow";
import {UserContext} from "../../context/UserContext";
import RenderedWithPermission from "../RenderedWithPermission";
import {LoadingButton} from "@mui/lab";

const AdministrationTab = () => {
  const defaultBusinessSelect = [{label: "", value: ""}];
  const {enqueueSnackbar} = useSnackbar();
  const {showRequestError} = useRequestErrorMessage();
  const auth = useAuth();
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const [business, setBusiness] = useState("");
  const [businessTemplate, setBusinessTemplate] = useState("");
  const [businessTemplateAnswers, setBusinessTemplateAnswers] = useState({});
  const [businessSelect, setBusinessSelect] = useState(defaultBusinessSelect);
  const [businessList, setBusinessList] = useState([]);
  const [downloadableDocumentList, setDownloadableDocumentList] = useState([]);
  const [disableFormSubmit, setDisableFormSubmit] = useState(false);
  const [question, setQuestion] = useState(null);
  const languageContext = useContext(LanguageContext);
  const history = useHistory();
  const umamiContext = useContext(UmamiContext);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [componentDisabler, setComponentDisabler] = useState(true);
  const allowedTypes = ["csv", "pdf", "png", "jpg", "jpeg", "svg", "doc", "docx", "txt", "webp", "xls", "xlsx", "xml"];

  const formSubmit = (e) => {
    setButtonLoading(true);
    e.preventDefault();
    const searchObject = businessList.find(item => item.id === business);
    const caseDescription = languageContext.language.id === "hu" ? `${searchObject.name}` : `${searchObject.nameEn}`;
    const now = moment().format(dateFormat);
    let dmsCaseAttributeList = [];
    Object.entries(businessTemplateAnswers).forEach(([key, value]) => {
      if (key === "attachment" || key === "attachment_name") {
        dmsCaseAttributeList.push({dmsValue: key.toString(), value: value?.toString()});
      } else {
        dmsCaseAttributeList.push({dmsValue: key.toString(), value: value?.value?.toString(), name: value?.name?.toString()});
      }
    });
    if (searchObject) {
      dmsStartCase(auth.userData.access_token, customerContext.selectedCustomer.id, searchObject.caseType, caseDescription, now, dmsCaseAttributeList)
        .then(() => enqueueSnackbar(languageContext.dictionary.successCaseStart, {variant: "success", preventDuplicate: true}))
        .catch(error => showRequestError(error, languageContext.dictionary.caseSendError))
        .finally(() => {
          setBusiness("");
          setBusinessTemplate("");
          setButtonLoading(false);
        });
    }
  };

  const handleChange = (event, value) => {
    setSelectedButton(value);
  };

  useEffect(() => {
      setQuestion(null);
      setComponentDisabler(true);
      setBusinessTemplate("");
      const searchObject = businessList.find(item => item.id === business);
      if (business !== "" && (auth.userData) && searchObject) {
        getDmsCaseTemplate(auth.userData.access_token, searchObject.caseTemplateId)
          .then(res => {
            let templateData = JSON.parse(res.data.templateContent);
            templateData = templateData.filter(object => {
              if (object.type.input === "switch") {
                setQuestion({...object, status: false});
              }
              return object.type.input !== "switch";
            });
            setBusinessTemplate({
              id: res.data.id,
              templateContent: templateData
            });
          })
          .finally(() => setComponentDisabler(false));
      } else {
        setComponentDisabler(false);
        setBusinessTemplate("");
      }
    },
    [business]);

  useEffect(() => {
    if (auth.userData && selectedButton) {
      setQuestion(null);
      setBusiness("");
      setComponentDisabler(true);
      getDmsCaseTypeList(auth.userData.access_token, selectedButton)
        .then(res => {
          setBusinessList(res.data);
          let DmsCaseTypeList = res.data.map((item) => {
            return {
              id: item.id,
              caseType: item.caseType,
              name: item.name,
              nameEn: item.nameEn,
              caseTemplateId: item.caseTemplate.id
            };
          });
          let DmsCaseTypeSelect = res.data.map((item) => {
            return {
              label: languageContext.language.id === "hu" ? item.name : item.nameEn,
              value: item.id
            };
          });
          setBusinessList(DmsCaseTypeList);
          setBusinessSelect(DmsCaseTypeSelect);
        })
        .finally(() => setComponentDisabler(false));
    }
  }, [auth.userData, languageContext.language.id, selectedButton]);

  useEffect(() => {
    setBusinessTemplateAnswers({});
    setDownloadableDocumentList([]);
    if (businessTemplate?.id && business) {
      dmsDynamicFieldDocs(auth.userData.access_token, business)
        .then(res => setDownloadableDocumentList(res.data));
    }
  }, [businessTemplate, business]);


  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Grid container sx={{paddingBottom: 3, paddingTop: 3}} spacing={3} seleniumid={"administrationTab"}>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item xs={"auto"}>
            <Typography variant={"h4"}>
              {languageContext.dictionary.choosePlease}
            </Typography>
          </Grid>
          <Grid item xs={"auto"}>
            <Button size={"large"}
                    color="secondary"
                    startIcon={<AddBoxOutlinedIcon/>}
                    onClick={() => {
                      umamiContext.track('from_administration_click_new_error_report');
                      history.push("/technicalOverview/errorTicketRecording");
                    }}>
              {languageContext.dictionary.newErrorReport}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AdministrationButtons handleChange={handleChange} value={selectedButton} disabled={componentDisabler}/>
        </Grid>
        {selectedButton ? (<Grid item xs={12}>
            <Paper elevation={4} square sx={{width: "97%", marginTop: 5, paddingTop: 2, paddingBottom: 2, margin: "auto", padding: 5}}>
              <form onSubmit={formSubmit}>
                <Grid container direction={"row"} rowSpacing={2}>
                  <Grid item xs={12} sm={7}>
                    <Typography>
                      {languageContext.dictionary.caseType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    {componentDisabler ?
                      <CircularProgress
                        fontSize="small"
                        color="secondary"/> :
                      <CustomSelect
                        disabled={componentDisabler}
                        defaultValue={""}
                        disableMinWidth
                        fullWidth
                        size="small"
                        value={business}
                        handleChange={e => setBusiness(e.target.value)}
                        parameters={{
                          name: "businessSelect",
                          label: languageContext.dictionary.caseType,
                          options: businessSelect,
                          canBeNull: true
                        }}
                      />}
                  </Grid>
                  {question ? (
                    <Grid item xs={12} sx={{marginTop: 2, marginBottom: 2}}>
                      <Typography>
                        {languageContext.language.id === "hu" ? question.label.hu : question.label.en}
                      </Typography>
                      <FormControlLabel
                        required={question.type.required}
                        control={<Switch color="secondary"/>}
                        onChange={event => setQuestion(curr => ({...curr, status: event.target.checked}))}
                        label={languageContext.dictionary.agree}/>
                    </Grid>
                  ) : null}
                  {downloadableDocumentList.length > 0 ?
                    (<Grid item xs={12}>
                      <CaseDocumentRow documentList={downloadableDocumentList}/>
                    </Grid>)
                    : null}
                  {business !== "" && businessTemplate && (question?.status || !question) ? (
                    businessTemplate.templateContent.map((data, index) => (
                      <Grid container item key={data?.id}>
                        <Grid item xs={12} sm={7}>
                          <Typography style={(data.type.tooltipHu && data.type.tooltipEn) || data.id === "attachment" ? {float: "left", paddingTop: 2} : null}>
                            {languageContext.language.id === "hu" ? data.label.hu : data.label.en}
                          </Typography>
                          {data.id === "attachment" ? (
                            <CustomTooltip title={languageContext.dictionary.fileUploadTooltip + allowedTypes.toString()}
                                           placement={"right-end"}
                                           style={{padding: 0, marginLeft: 2}}>
                              <IconButton>
                                <InfoIcon/>
                              </IconButton>
                            </CustomTooltip>) : null}
                          {data.type.tooltipHu && data.type.tooltipEn ?
                            (<CustomTooltip
                              title={languageContext.language.id === "hu" ? data.type.tooltipHu : data.type.tooltipEn}
                              placement={"bottom"}
                              style={{padding: 0, marginLeft: 2, maxWidth: 480}}>
                              <IconButton>
                                <InfoIcon/>
                              </IconButton>
                            </CustomTooltip>) : null}
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <DynamicFormField inputType={data.type}
                                            label={data.label}
                                            name={data?.id === "concat" ? `${data?.id}_${index}` : data?.id}
                                            value={businessTemplateAnswers.data?.id}
                                            setBusinessTemplateAnswers={setBusinessTemplateAnswers}
                                            data={businessTemplateAnswers}
                                            allowedTypes={allowedTypes}
                                            setDisableFormSubmit={setDisableFormSubmit}/>
                        </Grid>
                      </Grid>
                    ))) : null}
                  {business !== "" && businessTemplate ?
                    (< Grid item xs={12} textAlign="end">
                      {!userContext?.userInfo?.innerUser ?
                        (
                          <Tooltip title={userContext?.userInfo?.innerUser ? languageContext.dictionary.disabledImpersonate : "" || disableFormSubmit ? languageContext.dictionary.fileError : ""}>
                            <span>
                              <LoadingButton
                                disabled={userContext?.userInfo?.innerUser || disableFormSubmit}
                                type="submit"
                                loading={buttonLoading}
                                color="secondary"
                                variant="contained">
                                {languageContext.dictionary.next}
                              </LoadingButton>
                            </span>
                          </Tooltip>) : null}
                    </Grid>) : null}
                </Grid>
              </form>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    </RenderedWithPermission>
  );
};

export default AdministrationTab;
