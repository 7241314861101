import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import axios from "axios";
import {useSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";

const VersionChecker = () => {
  const languageContext = useContext(LanguageContext);
  const [serverVersion, setServerVersion] = useState(null);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const getVersionFromServer = async () => {
    return axios.get(`/version.txt`).then(res =>
      setServerVersion(res.data.trim()));
  };

  useEffect(() => {
    if (serverVersion && serverVersion !== process.env.REACT_APP_VERSION && window.location.hostname !== "localhost") {
      console.log(serverVersion);
      enqueueSnackbar(
        <div>
          {languageContext.dictionary.newVersionAvailable}
        </div>, {
          variant: "info",
          preventDuplicate: true,
          draggable: true,
          persist: true,
          action: key => (
            <>
              <IconButton onClick={() => window.location.reload()} style={{color: "white"}} size="large">
                <RefreshIcon/>
              </IconButton>
              <IconButton onClick={() => closeSnackbar(key)} style={{color: "white"}} size="large">
                <CloseIcon/>
              </IconButton>
            </>
          )
        });
    }
  }, [serverVersion]);

  useEffect(() => {
    const interval = setInterval(getVersionFromServer, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <></>
  );
};

VersionChecker.propTypes = {};

export default VersionChecker;
