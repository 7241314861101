import {makeStyles, useTheme} from "@mui/styles";
import React, {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import * as moment from "moment/moment";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MaintenanceGrid from "./MaintenanceGrid";
import AccordionDetails from "@mui/material/AccordionDetails";
import SectionWithDivider from "../common/SectionWithDivider";
import {formatDateTime} from "../../utils/dateFormat";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  details: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3)
    },
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

const MaintenanceAccordionGrid = ({data, index, active, handleLoadMaintenanceList, contactId}) => {
  const classes = useStyles();
  const theme = useTheme();
  const languageContext = useContext(LanguageContext);

  const getBorderStyle = (someDate, active) => {
    const isToday = someDate ? moment(someDate).diff(moment(), 'hours') < 25 : false;
    return {
      border: isToday && active ? 2 : 0,
      borderColor: isToday && active ? theme.palette.mainButton.main : theme.palette.primary.light
    };
  };

  return (
    <Grid item xs={12} style={{paddingTop: 25}}>
      <Accordion sx={getBorderStyle(data.startDate, active)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary"/>}>
          <MaintenanceGrid data={data} active={active}/>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className={classes.details} direction="row" justifyContent="center" alignItems="center">
            <SectionWithDivider title={languageContext.dictionary.technicalId} value={data.serviceId}/>
            <SectionWithDivider title={languageContext.dictionary.serviceId} value={data.technicalId}/>
            <SectionWithDivider title={languageContext.dictionary.affectedSites} value={data.siteList?.join(", ")}/>
            <SectionWithDivider title={languageContext.dictionary.maintenanceType} value={data.type}/>
            <SectionWithDivider title={languageContext.dictionary.feedbackDeadline} value={formatDateTime(data.feedbackDeadline)} lastItem/>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );

};

MaintenanceAccordionGrid.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  handleLoadMaintenanceList: PropTypes.func.isRequired,
  contactId: PropTypes.number
};

export default MaintenanceAccordionGrid;