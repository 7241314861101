import React, {useContext, useEffect} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import moment from "moment/moment";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import CustomDateRangePicker from "../date/CustomDateRangePicker";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import NumberFieldWithFormat from "../common/NumberFieldWithFormat";

const BillsFilter = ({filters, handleFilterChange, resetFilters, handleFilterSubmit}) => {
  const languageContext = useContext(LanguageContext);
  const twoYearsEarlier = moment(new Date()).subtract(2, "years");

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFilterSubmit(false);
    }
  };

  useEffect(() => {
    handleFilterSubmit(false);
  }, [filters.paymentDeadline, filters.billingDate]);

  return (
    <Grid item xs={12} seleniumid={`maintenanceFilter`} style={{paddingTop: 10}}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary"/>}>
          <Typography variant="h5">{languageContext.dictionary.filters}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} sx={{p: 2}} alignItems="center">
            <Grid item container xs={12} md={7} alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography>{languageContext.dictionary.paymentDeadline}</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomDateRangePicker
                  value={filters.paymentDeadline}
                  onChange={date => handleFilterChange(date, "paymentDeadline")}
                  helperText={false}
                  minDate={twoYearsEarlier}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={5} alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography>{languageContext.dictionary.billNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  label={languageContext.dictionary.billNumber}
                  name={"billNumber"}
                  onKeyDown={handleKeyDown}
                  value={filters.billNumber}
                  onChange={(e) => handleFilterChange(e.target.value, "billNumber")}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={7} alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography>{languageContext.dictionary.billingDate}</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomDateRangePicker
                  value={filters.billingDate}
                  onChange={date => handleFilterChange(date, "billingDate")}
                  helperText={false}
                  minDate={twoYearsEarlier}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={5} alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography>{languageContext.dictionary.amount}</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <div style={{display: "flex"}}>
                  <NumberFieldWithFormat
                    style={{maxWidth: 180}}
                    label={languageContext.dictionary.min}
                    onKeyDown={handleKeyDown}
                    name={"amountFrom"}
                    value={filters.amountFrom}
                    onChange={e => handleFilterChange(e.target.value, "amountFrom")}
                  />
                  <Typography style={{padding: 20, paddingBottom: 0}}>{" - "}</Typography>
                  <NumberFieldWithFormat
                    style={{maxWidth: 180}}
                    label={languageContext.dictionary.max}
                    onKeyDown={handleKeyDown}
                    name={"amountTo"}
                    value={filters.amountTo}
                    onChange={e => handleFilterChange(e.target.value, "amountTo")}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={12} md={2}>
                <Button color="secondary" variant="text" onClick={resetFilters}>
                  {languageContext.dictionary.deleteFilters}
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button color="secondary" variant="contained" onClick={() => handleFilterSubmit(false)}>
                  {languageContext.dictionary.filter}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default BillsFilter;