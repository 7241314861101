import React from 'react';
import {TableBody, TableBodyCell, TableBodyRow} from 'mui-datatables';
import {CircularProgress, TableBody as MuiTableBody, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    isLoading: {
      textAlign: 'center'
    }
  })
);

const LoadingTableBody = ({isLoading, options, columns, ...others}) => {
  const visibleColCnt = columns.filter(c => c.display === 'true').length;
  const classes = useStyles();
  return isLoading ? (
    <MuiTableBody>
      <TableBodyRow options={options}>
        <TableBodyCell
          colSpan={options.selectableRows !== 'none' || options.expandableRows ? visibleColCnt + 1 : visibleColCnt}
          options={options}
          colIndex={0}
          rowIndex={0}
        >
          <Typography variant="body1" className={classes.isLoading} component={'div'}>
            <CircularProgress/>
          </Typography>
        </TableBodyCell>
      </TableBodyRow>
    </MuiTableBody>
  ) : (
    <TableBody options={options} columns={columns} {...others} />
  );
};

export default LoadingTableBody;