import React, {useContext} from "react";
import PropTypes from "prop-types";
import {TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomAutocomplete from "../../formFields/CustomAutocomplete";
import {validEmail} from "../../../utils/commonFunctions";
import {LanguageContext} from "../../../context/LanguageContext";

const ComboInputGrid = ({data, handleChange, textFieldOptions, selectOptions, selectOptionsLoading, validationType, optionLabel}) => {
  const languageContext = useContext(LanguageContext);

  const isMobile = (options, valueMsr) => options?.find(option => option?.value === valueMsr)?.type === "mobile";

  const hasError = (data) => {
    switch (validationType) {
      case "phoneNumber":
        const phoneRegex = /^\+36[0-9]{8}$/;
        const mobileRegex = /^\+36[0-9]{9}$/;
        const match = String(data.value?.replaceAll(" ", "")).toLowerCase().match(isMobile(selectOptions.options, data?.valueMsr) ? mobileRegex : phoneRegex);
        return !!data.value && !match;
      case "email":
        return !validEmail(data.value);
      default:
        return false;
    }
  };

  const getHelperText = (data) => {
    switch (validationType) {
      case "phoneNumber":
        return hasError(data) ? languageContext.dictionary.invalidPhoneNumberMsg : "";
      case "email":
        return !validEmail(data.value) ? languageContext.dictionary.invalidEmailMsg : "";
      default:
        return "";
    }
  };

  const getPlaceHolder = (data) => {
    switch (validationType) {
      case "phoneNumber":
        return isMobile(selectOptions.options, data?.valueMsr) ? "+36701234567" : "+3611234567 / +3688123456";
      case "email":
        return "test@invitech.hu";
      default:
        return "";
    }
  };

  const changeTextField = (value) => {
    let validInput = true;
    if (validationType === "phoneNumber") {
      validInput = /^[0-9+\s]*$/.test(value);
    }
    if (validInput) {
      handleChange("value", value);
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label={textFieldOptions.label}
          variant={"outlined"}
          onChange={e => changeTextField(e.target.value)}
          inputProps={{maxLength: 255}}
          value={data.value || ""}
          error={hasError(data)}
          helperText={getHelperText(data)}
          required={textFieldOptions.required}
          placeholder={getPlaceHolder(data)}
        />
      </Grid>
      <Grid item xs={5}>
        <CustomAutocomplete
          options={selectOptions.options}
          value={data.valueMsr || ""}
          handleChange={(e, newValue) => handleChange("valueMsr", newValue?.value || "")}
          label={selectOptions.label}
          loading={selectOptionsLoading}
          required={selectOptions.required}
          placeholder={getPlaceHolder(data)}
          optionLabel={optionLabel}
        />
      </Grid>
    </>
  );
};

ComboInputGrid.propTypes = {
  data: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectOptions: PropTypes.object.isRequired,
  textFieldOptions: PropTypes.object.isRequired,
  selectOptionsLoading: PropTypes.bool,
  validationType: PropTypes.string,
  labelName: PropTypes.string
};

export default ComboInputGrid;
