import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Container, Tab, Tabs, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import SimpleTabPanel from "../components/common/SimpleTabPanel";
import {LanguageContext} from "../context/LanguageContext";
import {makeStyles, useTheme} from "@mui/styles";
import {useHistory, useLocation} from "react-router-dom";
import UsersAndPermissionsTab from "../components/user/UsersAndPermissionsTab";
import CompanyDetailsTab from "../components/user/CompanyDetailsTab";
import MyProfileTab from "../components/user/MyProfileTab";
import {contactHasRight} from "../utils/commonFunctions";
import {CustomerContext} from "../context/CustomerContext";
import {adminFinTechRight} from "../utils/commonValues";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const UserPage = () => {
  const customerContext = useContext(CustomerContext);
  const classes = useStyles();
  const history = useHistory();
  const languageContext = useContext(LanguageContext);
  const location = useLocation();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('lg'));
  const [actualTab, setActualTab] = useState(0);
  const [tabLoading, setTabLoading] = useState(true);
  const tabs = [
    {
      pathname: "/companyDetails",
      index: 0,
      label: "companyDetails",
      hidden: false
    },
    {
      pathname: "/profile",
      index: 1,
      label: "myProfile",
      hidden: false
    },
    {
      pathname: "/usersAndPermissions",
      index: 2,
      label: "usersAndPermissions",
      hidden: !contactHasRight(customerContext?.selectedContact?.roles, adminFinTechRight)
    }
  ];

  useEffect(() => {
    handleTabChange();
  }, [location.pathname]);

  return (
    <Container maxWidth="lg" seleniumid={"userPage"}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} style={{paddingBottom: 20}}>
          <Typography variant="h3" color="secondary" className={classes.header}>
            {languageContext.dictionary.profile}
          </Typography>
        </Grid>
        {!tabLoading ? (
          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs
                value={actualTab}
                onChange={(e, newValue) => handleTabAndPathChange(newValue)}
                variant={matchesSm ? "scrollable" : "fullWidth"}
                scrollButtons="auto"
                allowScrollButtonsMobile
                indicatorColor="primary"
              >
                {tabs.map(tab => !tab.hidden ? (
                  <Tab label={languageContext.dictionary[tab?.label]} key={`tab_${tab?.index}`} seleniumid={`${tab?.label}Tab`}/>
                ) : null)}
              </Tabs>
            </AppBar>
            <SimpleTabPanel value={actualTab} index={0}>
              <CompanyDetailsTab/>
            </SimpleTabPanel>
            <SimpleTabPanel value={actualTab} index={1}>
              <MyProfileTab/>
            </SimpleTabPanel>
            <SimpleTabPanel value={actualTab} index={2}>
              <UsersAndPermissionsTab/>
            </SimpleTabPanel>
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );

  function handleTabChange() {
    setTabLoading(true);
    const tabIndex = tabs.find(t => t.pathname === location.pathname)?.index;
    if (tabIndex !== actualTab) {
      setActualTab(tabIndex);
    }
    setTabLoading(false);
  }

  function handleTabAndPathChange(newValue) {
    setTabLoading(true);
    setActualTab(newValue);
    const toPage = tabs.find(t => t.index === newValue)?.pathname;
    if (toPage !== location.pathname) {
      history.push(toPage);
    }
    setTabLoading(false);
  }
};

export default UserPage;
