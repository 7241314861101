import {useEffect} from 'react';

const useScript = (url, attrs) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    if (attrs) {
      for (let key in attrs) {
        script.setAttribute(key, attrs[key]);
      }
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [url, attrs]);
};

export default useScript;