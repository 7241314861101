import React, {useContext} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Grid from "@mui/material/Grid";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import {NavLink} from "react-router-dom";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BusinessIcon from '@mui/icons-material/Business';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import LockResetIcon from '@mui/icons-material/LockReset';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import {contactHasRight} from "../../utils/commonFunctions";
import {CustomerContext} from "../../context/CustomerContext";
import {adminFinTechRight} from "../../utils/commonValues";

const useStyles = makeStyles(() => ({
  text: {
    maxWidth: 150,
    fontWeight: "bold"
  }
}));

const UserMenuMobileDialog = props => {
  const {handleClose, open,  changePassword, logout, firstName, lastName, configuretOtp} = props;
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);

  const formatFullName = () => {
    return languageContext.language.id === "hu" ?
      `${lastName} ${firstName}` :
      `${firstName} ${lastName}`;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle color="secondary">
        <Grid container spacing={1} alignContent="center" alignItems="center">
          <Grid item alignContent="center" alignItems="center" display="flex">
            <PersonIcon/>
          </Grid>
          <Grid item>
            {formatFullName()}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => handleClose()}
              fullWidth
              variant="contained"
              color="mainButton"
              startIcon={<BusinessIcon/>}
              size={"large"}
              component={NavLink}
              to="/companyDetails"
            >
              <Typography className={classes.text}>{languageContext.dictionary.companyDetails}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => handleClose()}
              fullWidth
              variant="contained"
              color="mainButton"
              startIcon={<AccountBoxIcon/>}
              size={"large"}
              component={NavLink}
              to="/profile"
            >
              <Typography className={classes.text}>{languageContext.dictionary.myProfile}</Typography>
            </Button>
          </Grid>
          {contactHasRight(customerContext.selectedContact.roles, adminFinTechRight) ?
            <Grid item xs={12} style={{textAlign: "center"}}>
              <Button
                onClick={() => handleClose()}
                fullWidth
                variant="contained"
                color="mainButton"
                startIcon={<LockOpenIcon/>}
                size={"large"}
                component={NavLink}
                to="/usersAndPermissions"
              >
                <Typography className={classes.text}>{languageContext.dictionary.usersAndPermissions}</Typography>
              </Button>
            </Grid> : null
          }
          <Grid item xs={12}>
            <Button onClick={changePassword}
                    fullWidth
                    variant="contained"
                    color="mainButton"
                    startIcon={<LockResetIcon/>}
                    size={"large"}>
              {languageContext.dictionary.changePassword}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={configuretOtp}
                    fullWidth
                    variant="contained"
                    color="mainButton"
                    startIcon={<SystemSecurityUpdateGoodIcon/>}
                    size={"large"}>
              {languageContext.dictionary.configureMfa}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={logout}
              fullWidth
              variant="contained"
              color="mainButton"
              startIcon={<LogoutIcon/>}
              size={"large"}
            >
              <Typography className={classes.text}>{languageContext.dictionary.logout}</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingTop: 2, paddingRight: 3}}>
        <Button onClick={handleClose} color="primary">
          {languageContext.dictionary.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserMenuMobileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  configuretOtp: PropTypes.bool
};

export default UserMenuMobileDialog;