import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

const resource = "/service";

export const getServiceDetails = async (token, poid) => {
  let url = `${env.apiRoot}${resource}/${poid}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const getServiceAll = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/all/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const getEstablishmentAll = async (token, serviceListType, cmId) => {
  let url = `${env.apiRoot}${resource}/${serviceListType}/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const getServiceStatusList = async (token, serviceListType) => {
  let url = `${env.apiRoot}${resource}/serviceStatusList`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {
    headers: {Authorization: authStr},
    params: {
      serviceListType: serviceListType
    }
  });
};

export const generateExcelForService = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/all/${cmId}/export`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, responseType: "blob"});
};