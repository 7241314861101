import React, {useContext} from "react";
import {makeStyles} from "@mui/styles";
import {Paper} from "@mui/material";
import {Divider, List, ListItem, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import invitechNewLight from "../../assets/images/ic_white_small.png";
import {useLocation} from "react-router-dom";
import {footerElementList, mainPageFooterElementList} from "../../utils/constants/footer";
import {LanguageContext} from "../../context/LanguageContext";
import {env} from "../../properties";
import {CustomerContext} from "../../context/CustomerContext";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    position: "absolute"
  },
  link: {
    padding: 20
  },
  image: {
    height: 20
  }
}));

const Footer = () => {
  const classes = useStyles();
  const location = useLocation();
  const langContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const tpRights = ["DCPOWER", "PERFMON", "SDWAN", "DDOS", "DNS", "K8SM"];
  const csspRights = ["CSSP"];
  const tpAndCsspRights = tpRights.concat(csspRights);


  const isMainPage = () => {
    return (location.pathname === "/" || location.pathname === "/home");
  };
  return (
    <Paper square elevation={0} sx={{backgroundColor: 'primary.main', zIndex: 1}}>
      {isMainPage() ?
        <>
          <Grid container style={{padding: "48px"}} spacing={5}>
            <Grid item xs={12} md={4}>
              <Typography variant="h4" color="primary.contrastText">
                {langContext.dictionary.footer.mainPage.mainPageText1}
              </Typography>
              <Button id="tp"
                      style={{marginTop: 30}}
                      className={classes.button}
                      href="https://www.invitech.hu/kapcsolat" target="&quot;_blank&quot;"
                      color="mainButton"
                      variant="contained"
                      size="large">
                {langContext.dictionary.footer.mainPage.askForOffer}
              </Button>
            </Grid>
            <Grid container item xs={12} md={8}>
              {mainPageFooterElementList?.map((footerElement, footerElementIndex) => (
                <Grid item xs={12} sm={4} key={`grid_${footerElementIndex}`}>
                  <List dense key={`list_${footerElementIndex}`}>
                    <ListItem key={`listItem_${footerElementIndex}`}>
                      <Link
                        href={footerElement.url || langContext.dictionary.urls[footerElement.mainTitle]}
                        target={footerElement?.target || ""} key={`link_list_${footerElement}`}
                      >
                        <Typography variant="h6" color="primary.contrastText" key={`typography_${footerElementIndex}`}>
                          {langContext.dictionary.footer.mainPage[footerElement.mainTitle] || footerElement.mainTitle}
                        </Typography>
                      </Link>
                    </ListItem>
                    {footerElement?.list?.map((listElement, listElementIndex) => (
                      <ListItem key={`listItem_list_${listElementIndex}`}>
                        <Link
                          href={listElement.url || langContext.dictionary.urls[listElement.title]}
                          target={listElement?.target || ""} key={`link_list_${listElementIndex}`}
                        >
                          <Typography variant="caption" color="primary.contrastText"
                                      key={`typography_list_${listElementIndex}`}>
                            {langContext.dictionary.footer.mainPage[listElement.title] || listElement.title}
                          </Typography>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {tpAndCsspRights.some(r => customerContext.selectedContact?.roles?.includes(r)) ?
            <>
              <Divider color="white" style={{marginLeft: "24px", marginRight: "24px"}}/>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                {csspRights.some(r => customerContext.selectedContact?.roles?.includes(r)) ?
                  <Grid item>
                    <Button id="cssp"
                            style={{margin: "24px"}}
                            className={classes.button}
                            href={env?.csspRoot}
                            color="mainButton"
                            variant="contained"
                            size="large">
                      <Icon className={classes.icon}>person_outline</Icon>
                      {langContext.dictionary.footer.mainPage.loginToCSSP}
                    </Button>
                  </Grid> : null
                }
                {tpRights.some(r => customerContext.selectedContact?.roles?.includes(r)) ?
                  <Grid item>
                    <Button id="tp"
                            style={{margin: "24px"}}
                            className={classes.button}
                            href={env?.tpRoot}
                            color="mainButton"
                            variant="contained"
                            size="large">
                      <Icon className={classes.icon}>person_outline</Icon>
                      {langContext.dictionary.footer.mainPage.loginToTechnicalPortal}
                    </Button>
                  </Grid> : null
                }
              </Grid>
            </> : null
          }
          <Divider color="white" style={{marginLeft: "24px", marginRight: "24px"}}/>
        </> : ""
      }
      <Grid container>
        <Grid item xs={4} sx={{padding: 2}}>
          <img style={{float: "left", marginTop: 3}} src={invitechNewLight} height="24px"/>
          <Typography style={{paddingLeft: 5}} variant="caption" color="primary.contrastText">
            COPYRIGHT©
          </Typography>
        </Grid>
        <Grid container item xs={8}>
          {footerElementList?.map((footerElement, index) => (
            <Grid item xs={12} sm={4} key={`link_${index}`}>
              <Link
                href={footerElement.url || langContext.dictionary.urls[footerElement.title]}
                target={footerElement?.target || ""}
              >
                <Typography style={{paddingLeft: "12px"}} variant="caption" color="primary.contrastText"
                            key={`typography_${index}`}>
                  {langContext.dictionary.footer[footerElement.title] || footerElement.title}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );

};

export default Footer;
