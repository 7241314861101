import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(4)
  },
  description: {
    marginBottom: theme.spacing(2)
  },
  childrenContainer: {
    border: "1px solid rgb(225,225,225)",
    borderRadius: "4px",
    padding: theme.spacing(2)
  }
}));

const Section = props => {
  const classes = useStyles();
  return (
    <div
      className={classes.section}
      style={{ display: props.inline ? "inline-block" : "block" }}
    >
      <Typography variant="h5" gutterBottom>
        {props.title}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {props.description}
      </Typography>
      <div
        className={classes.childrenContainer}
        style={{ border: props.hideBorder ? "none" : "" }}
      >
        {props.children}
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  hideBorder: PropTypes.bool,
  inline: PropTypes.bool
};

export default Section;
