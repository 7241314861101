import React from "react";
import BillDetails from "../components/billing/BillDetails";
import {Container} from "@mui/material";
import RelatedBills from "../components/billing/RelatedBills";
import RenderedWithPermission from "../components/RenderedWithPermission";
import {adminFinRight} from "../utils/commonValues";


const BillingDetailsPage = () => {
  return (
    <Container maxWidth="lg" sx={{padding: 2}}>
      <RenderedWithPermission roles={adminFinRight} displayNoRightsLabel={true}>
        <BillDetails/>
        <RelatedBills/>
      </RenderedWithPermission>
    </Container>
  );
};

export default BillingDetailsPage;
