import React, {useContext, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {Paper, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PropTypes from "prop-types";
import UserEditor from "../user/UserEditor";
import {CustomerContext} from "../../context/CustomerContext";
import Tooltip from "@mui/material/Tooltip";
import {contactHasRight} from "../../utils/commonFunctions";
import {UserContext} from "../../context/UserContext";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const MyProfileRow = ({formKey, value}) => {
  const langContext = useContext(LanguageContext);
  const [field, setField] = useState(value);
  const [edit, setEdit] = useState(false);

  return (
    <Grid sx={{marginTop: 1, marginBottom: 1}} container item direction="row" justifyContent="center" alignItems="center" key={`grid_${formKey}`}>
      <Grid item xs={5} sm={4}>
        <Typography variant={"subtitle1"}>{langContext.dictionary[formKey] || formKey}</Typography>
      </Grid>
      <Grid item xs={6} sm={7}>
        {edit ? (
          <TextField
            id="firstName_input" name="firstName_input" variant="outlined"
            onChange={event => setField(event.target.value)} value={field}
          />
        ) : (
          <Typography variant={"subtitle2"}>{langContext.dictionary.positions[field] || field}</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
    </Grid>
  );
};

MyProfileRow.propTypes = {
  formKey: PropTypes.string,
  value: PropTypes.string
};

const MyProfileCard = ({selectedUser, setUserIsSaved}) => {
  const langContext = useContext(LanguageContext);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editUserData, setEditUserData] = useState(selectedUser);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const [loading, setLoading] = useState(false);
  const displayableData = ["firstName", "lastName", "email", "position", "mobileNumber", "phoneNumber"];
  const avatarPhoto = "/static/media/avatar.96fcea05e5af9c0126ae.png";

  const editUser = (user) => {
    setEditUserData(user);
    setEditDialogOpen(true);
  };

  const clearEditor = () => {
    setEditDialogOpen(false);
    setEditUserData({});
  };

  const onItemEdited = () => {
    setLoading(true);
    clearEditor();
    reload();
    setLoading(false);
  };

  const reload = () => {
    new Promise(resolve => setTimeout(resolve, 500));
  };

  return (
    <Grid container marginTop={5} direction="row" justifyContent="center" alignItems="center">
      <Paper elevation={4} sx={{width: "100%", paddingTop: 3, paddingBottom: 3, paddingLeft: 1, paddingRight: 1}}>
        <Grid container item direction="row" justifyContent="center" alignItems="start">
          <Grid xs={12} sm={4} container item direction="row" justifyContent="center" alignItems="center">
            <div style={{width: "75%", height: "75%"}}>
              <img alt="companyLogo"
                   style={{maxWidth: "100%", maxHeight: "auto"}}
                   src={selectedUser.picture ? `data:text/plain;base64,${selectedUser.picture}` : avatarPhoto}/>
            </div>
            {contactHasRight(customerContext.selectedContact.roles, ['ADMIN', 'CP_FIN', 'CP_TECH']) ?
              (<Tooltip title={userContext?.userInfo?.innerUser ? langContext.dictionary.disabledImpersonate : ""}>
                <span>
                  <Button disabled={userContext?.userInfo?.innerUser}
                          component="label"
                          seleniumid="ProfilePhotoChangeButton"
                          color="secondary"
                          startIcon={<CameraAltIcon/>}>
                    {langContext.dictionary.changePhoto}
                    <input type="file" hidden/>
                  </Button>
                </span>
              </Tooltip>) : null}
          </Grid>
          <Grid item xs={12} sm={8}>
            {Object.entries(selectedUser).filter(([key]) => displayableData.includes(key)).map(([key, value]) => (
              <MyProfileRow formKey={key} value={value} key={key}/>
            ))}
            <Grid container sx={{marginTop: 2, marginBottom: 2}} direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={7} sm={8}>
                <Typography variant={"caption"}>{langContext.dictionary.mailText}</Typography>
              </Grid>
              <Grid item xs={5} sm={4}>
                <RenderedWithPermission roles={adminFinTechRight}>
                  <Button
                    component="label"
                    seleniumid="ProfileDataChangeButton"
                    color="secondary"
                    onClick={() => editUser(selectedUser)}
                    startIcon={<ManageAccountsIcon/>}
                  >
                    {langContext.dictionary.dataChangeUser}
                  </Button>
                </RenderedWithPermission>
              </Grid>
            </Grid>
            <Divider/>
          </Grid>
        </Grid>
      </Paper>
      <UserEditor
        setUserIsSaved={setUserIsSaved}
        loading={loading}
        user={editUserData}
        open={editDialogOpen}
        onEditCancel={clearEditor}
        onItemEdited={onItemEdited}/>
    </Grid>
  );
};

export default MyProfileCard;
