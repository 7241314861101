import React, {useContext, useEffect, useMemo, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import {CardHeader, Checkbox} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MaterialReactTable from "material-react-table";
import {MRT_Localization_EN} from "material-react-table/locales/en";
import {MRT_Localization_HU} from "material-react-table/locales/hu";
import {CustomerContext} from "../../context/CustomerContext";
import {useAuth} from "oidc-react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {AddCircle, Check, Close, Delete, Edit} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import DeleteDialog from "../dialog/DeleteDialog";
import {useSnackbar} from "notistack";
import {UmamiContext} from "../../context/UmamiContext";
import {getColositeRole, saveColositeService} from "../../api/colositeResource";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import VitrinNameTruncateSpan from "../serverRoom/VitrinNameTruncateSpan";
import {UserContext} from "../../context/UserContext";
import {fullNameByLanguage} from "../../utils/commonFunctions";
import {getUserRightsList} from "../../api/contactResource";

const emptyContact = {id: "", 'role.type': "", 'role.grant': "NO"};

const ServerRoomPermissionsServiceCard = ({service}) => {
  const auth = useAuth();
  const {enqueueSnackbar} = useSnackbar();
  const {showRequestError} = useRequestErrorMessage();
  const languageContext = useContext(LanguageContext);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const [tableData, setTableData] = useState([...service?.staff]);
  const [loading, setLoading] = useState(false);
  const [activateEdit, setActivateEdit] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState({open: false, row: {}});
  const [roleOptions, setRolesOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const sortedUserOptions = useMemo(() =>
      userOptions.filter(userOption => !tableData.some(tableDataElement => userOption.id === tableDataElement.id)),
    [tableData, userOptions]);

  const umamiContext = useContext(UmamiContext);

  const originalData = [...service?.staff];

  const columns = useMemo(() => [
    {
      accessorKey: 'id',
      header: languageContext.dictionary.name,
      enableEditing: row => !!row.original.new,
      editVariant: 'select',
      filterVariant: 'select',
      editSelectOptions: sortedUserOptions,
      filterSelectOptions: sortedUserOptions,
      Cell: ({cell}) => <span>{fullNameByLanguage(languageContext.language.id, cell?.row?.original?.firstName, cell?.row?.original?.lastName)}</span>,
    },
    {
      accessorKey: 'role.type',
      header: languageContext.dictionary.level,
      editVariant: 'select',
      filterVariant: 'select',
      editSelectOptions: roleOptions,
      filterSelectOptions: roleOptions,
      Cell: ({cell}) => <span>{cell?.row?.original?.role?.description}</span>,
    },
    {
      accessorKey: 'role.grant',
      header: languageContext.dictionary.permissionPrivilege,
      filterVariant: 'checkbox',
      Cell: ({cell}) => cell.getValue() === "YES" ? <Check color={"success"}/> : <Close color={"error"}/>,
      Edit: ({cell, row}) =>
        <Checkbox
          color="secondary"
          defaultChecked={cell.getValue() === "YES"}
          onChange={event => row._valuesCache['role.grant'] = event.target.checked ? "YES" : "NO"}  // todo not working
        />
    }
  ], [languageContext.language.id, roleOptions, userOptions, sortedUserOptions, activateEdit]);

  useEffect(() => {
    handleOptionsLoading();
  }, [activateEdit, service.id, auth.userData, customerContext.selectedCustomer.id]);

  const onEditingRowSave = async ({exitEditingMode, row, values, table}) => {
    umamiContext.track('editing_server_room_permission');
    exitEditingMode(); //required to exit editing mode and close modal
    setLoading(true);

    const updatedData = {
      id: values.id,
      role: {
        type: values["role.type"],
        grant: table.getRow(row.index)._valuesCache['role.grant'],
        description: roleOptions.find(option => option.value === values["role.type"])?.text
      }
    };

    //send/receive api updates here, then refetch or update local table data for re-render
    let updatedService = {...service};
    let updatedServiceStaff = [...service?.staff];
    let updatedUser = userOptions.find(u => u.id === updatedData.id);
    delete updatedUser.poid;
    try {
      let successMessage;
      if (values.new) {
        delete values.new;
        updatedData.role.grant = table.getRow(0)._valuesCache['role.grant'] || "NO";  // workaround
        updatedServiceStaff.push({
          ...updatedData,
          email: updatedUser.email,
          firstName: updatedUser.firstName,
          lastName: updatedUser.lastName,
          mobile: updatedUser.mobile
        });
        updatedService.staff = updatedServiceStaff;
        delete updatedService.vitrinIdValues;
        await saveColositeService(auth.userData.access_token, customerContext.selectedCustomer.id, updatedService);
        setTableData(curr => {
          let updated = [...curr];
          updated.shift();
          updated.unshift(updatedService?.staff[updatedService?.staff?.length - 1]);
          return updated;
        });
        successMessage = languageContext.dictionary.successCreate;
      } else {
        let index = updatedService.staff.findIndex(s => s.id === updatedData.id);
        updatedService.staff[index] = {
          ...updatedService.staff[index],
          role: {description: updatedData.role.description || "", grant: updatedData.role.grant, type: updatedData.role.type}
        };
        delete updatedService.vitrinIdValues;
        await saveColositeService(auth.userData.access_token, customerContext.selectedCustomer.id, updatedService);
        setTableData(curr => {
          let updated = [...curr];
          updated[row.index] = {...updated[row.index], ...updatedData};
          return updated;
        });
        successMessage = languageContext.dictionary.successEdit;
      }
      enqueueSnackbar(successMessage, {variant: "success", preventDuplicate: true});
    } catch (error) {
      showRequestError(error, languageContext.dictionary.errorMessages.saveError);
    } finally {
      setActivateEdit(false);
      setLoading(false);
    }

    return Promise.resolve();
  };

  const onEditingRowCancel = ({row, table}) => {
    umamiContext.track('editing_server_room_permission_cancel');
    setActivateEdit(false);
    if (row.original.new) {
      setTableData(curr => {
        let updated = [...curr];
        updated.shift();
        return updated;
      });
    } else {
      setTableData(originalData);
    }
    table.setEditingRow({});
    return Promise.resolve();
  };

  const handleAddNewContact = (service, table) => {
    umamiContext.track('editing_server_room_permission_add_new_contact');
    setActivateEdit(true);
    setTableData(curr => {
      let updated = [...curr];
      updated.unshift({...emptyContact, new: true});
      return updated;
    });

    let updatedRow = table.getRowModel().rows[0];
    updatedRow.original = {...emptyContact, new: true};
    updatedRow._valuesCache = {...emptyContact, new: true};
    table.setEditingRow(updatedRow);
  };

  const canAddOrDeleteContact = (contactList) => {
    return contactList.find(contact => customerContext.selectedContact.poid === (contact.masterId ? contact.masterId : contact.id))?.role?.grant === "YES";
  };

  return (
    <Card>
      <CardHeader
        title={`${service.name} (${service.serviceId})`}
        subheader={
          <Grid container spacing={1} sx={{pt: 1}}>
            <Grid item xs={12}>
              <Typography variant={"overline"}>
                {service.coloDescription}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <VitrinNameTruncateSpan shNameList={service.vitrinIdValues}/>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <MaterialReactTable
          columns={columns}
          data={tableData}
          enableEditing={!userContext?.userInfo?.innerUser && activateEdit}
          editingMode={"row"}
          positionActionsColumn="last"
          displayColumnDefOptions={{}}  // Workaround: https://github.com/KevinVandy/material-react-table/issues/189
          onEditingRowSave={onEditingRowSave}
          onEditingRowCancel={tableProps => onEditingRowCancel(tableProps, service)}
          state={{
            isLoading: loading
          }}
          localization={languageContext?.language?.id === 'hu' ? MRT_Localization_HU : MRT_Localization_EN}
          initialState={{
            pagination: {
              pageSize: 5
            }
          }}
          enableRowActions={!userContext?.userInfo?.innerUser}
          renderRowActionMenuItems={({row, closeMenu, table}) => [
            <>
              <MenuItem key={`edit_${row.id}`} onClick={() => {
                setActivateEdit(true);
                table.setEditingRow(row);
                closeMenu();
              }}>
                <Edit sx={{mr: 2}}/>
                {languageContext.dictionary.editSynon}
              </MenuItem>
              {canAddOrDeleteContact(service.staff) ? (
                <MenuItem key={`delete_${row.id}`} onClick={() => {
                  setDeleteConfirm({open: true, row: row});
                  closeMenu();
                }}>
                  <Delete color="error" sx={{mr: 2}}/>
                  {languageContext.dictionary.delete}
                </MenuItem>
              ) : null}
            </>
          ]}
          renderTopToolbarCustomActions={({table}) =>
            canAddOrDeleteContact(service.staff) ? (
              <Tooltip
                title={userContext?.userInfo?.innerUser ? languageContext.dictionary.disabledImpersonate : languageContext.dictionary.addAnotherContactPerson}
                arrow
              >
                <span>
                  <IconButton
                    color="secondary"
                    disabled={!!table.getState().editingRow || userContext?.userInfo?.innerUser}
                    onClick={() => handleAddNewContact(service, table)}
                  >
                    <AddCircle/>
                  </IconButton>
                </span>
              </Tooltip>
            ) : null
          }
        />
      </CardContent>
      <DeleteDialog
        open={deleteConfirm.open}
        handleDelete={handleDelete}
        handleClose={() => setDeleteConfirm({open: false, row: {}})}
      />
    </Card>
  );

  function handleDelete() {
    umamiContext.track('editing_server_room_permission_delete');
    let updatedService = {...service};
    let updatedUser = updatedService?.staff?.find(u => u.id === deleteConfirm.row.original.id);
    updatedUser.role.type = null;
    setLoading(true);
    saveColositeService(auth.userData.access_token, customerContext.selectedCustomer.id, updatedService)
      .then(() => {
        enqueueSnackbar(languageContext.dictionary.successDelete, {variant: "success", preventDuplicate: true});
        setTableData(curr => {
          let updated = [...curr];
          updated.splice(deleteConfirm.row.index, 1);
          return updated;
        });
      })
      .finally(() => setLoading(false));
    setDeleteConfirm({open: false, row: {}});
  }

  async function handleOptionsLoading() {
    if (!activateEdit || !service.id || !auth.userData || !customerContext.selectedCustomer.id) {
      return;
    }

    setLoading(true);
    setRolesOptions([]);
    setUserOptions([]);

    let allPromises = Promise.all([
      getColositeRole(auth.userData.access_token, service.id),
      getUserRightsList(auth.userData.access_token, customerContext.selectedCustomer.id)
    ]);

    try {
      const [
        colositeRoleRes,
        userRightsListRes
      ] = await allPromises;

      setRolesOptions(() => (colositeRoleRes.data.map(option => ({text: option.description, value: option.key}))));

      setUserOptions(userRightsListRes.data.map(option => ({
        email: option.email,
        firstName: option.firstName,
        lastName: option.lastName,
        mobile: option.mobileNumber,
        poid: option.contactId.toString(),
        id: option.contactId.toString(),
        text: fullNameByLanguage(languageContext.language.id, option.firstName, option.lastName),
        value: option.contactId.toString()
      })));

    } catch (error) {
      showRequestError(error, languageContext.dictionary.errorMessages.loadingError);
    } finally {
      setLoading(false);
    }

  }
};

export default ServerRoomPermissionsServiceCard;