import React, {useContext, useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles} from "@mui/styles";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {CircularProgress, Tooltip} from "@mui/material";
import CustomDateRangePicker from "../date/CustomDateRangePicker";
import {CustomerContext} from "../../context/CustomerContext";
import {getMaintenanceContactId, getMaintenanceList} from "../../api/maintenanceResource";
import Pagination from "@mui/material/Pagination";
import MaintenanceAccordionGrid from "../maintenance/MaintenanceAccordionGrid";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import {useAuth} from "oidc-react";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminTechRight} from "../../utils/commonValues";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const pageSize = 10;
const defaultSortOrder = {title: 'asc', serviceId: 'asc', endDate: 'asc'};

const MaintenancesTab = () => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  const active = useRef(true);
  const [loading, setLoading] = useState(true);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [period, setPeriod] = useState([null, null]);
  const [contactId, setContactId] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      handleLoadContactId();
      if (active.current) {
        handleLoadMaintenanceList();
      }
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  const handleActiveButtonClick = () => {
    setMaintenanceList([]);
    setSortOrder(defaultSortOrder);
    active.current = !active.current;
    setPeriod([null, null]);
    setPage(1);
    setCount(0);
    if (active.current) {
      handleLoadMaintenanceList();
    }
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const calculateTotalPages = (maintenanceList, pageSize) => {
    return Math.ceil(maintenanceList.length / pageSize);
  };

  const handleSort = () => {
    const dateSortOrder = sortOrder.endDate === 'asc' ? 'desc' : 'asc';
    setSortOrder(s => ({...s, endDate: dateSortOrder}));
    setLoading(true);
    setMaintenanceList(list => sortByDate(list, dateSortOrder));
    setLoading(false);
  };

  const sortByDate = (dataList, dateSortOrder) => {
    return dataList.sort((a, b) => {
      return dateSortOrder === 'asc' ? new Date(a.endDate) - new Date(b.endDate) : new Date(b.endDate) - new Date(a.endDate);
    });
  };

  return (
    <RenderedWithPermission roles={adminTechRight} displayNoRightsLabel={true}>
      <Grid container className={classes.grid} spacing={6} seleniumid={"maintenancesTab"}>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4">
              {active.current ? languageContext.dictionary.scheduled : languageContext.dictionary.finishedMaintenances}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{textAlign: "right"}}>
            <Button
              size="large"
              variant="text"
              startIcon={!active.current ? <NavigateBeforeIcon/> : null}
              endIcon={active.current ? <NavigateNextIcon/> : null}
              color="secondary"
              onClick={handleActiveButtonClick}
            >
              {active.current ? languageContext.dictionary.finishedMaintenances : languageContext.dictionary.currentMaintenances}
            </Button>
          </Grid>
        </Grid>
        {!active.current ? (
          <Grid item xs={12} container spacing={2} alignItems="center" seleniumid={"maintenanceFilter"} style={{paddingBottom: 20}}>
            <Grid item container xs={12} md={6} alignItems="center">
              <Grid item xs={12} sm={2}>
                <Typography>{languageContext.dictionary.period}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <CustomDateRangePicker
                  value={period}
                  onChange={date => setPeriod(date)}
                  helperText={false}
                  size={"small"}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Tooltip title={languageContext.dictionary.sortByDate} arrow>
                <IconButton variant="contained" size="large" onClick={handleSort}>
                  {sortOrder.endDate === 'asc' ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={1}>
              <Button disabled={!period[0] || !period[1]} onClick={handleLoadMaintenanceList} color="secondary">
                {languageContext.dictionary.filter}
              </Button>
            </Grid>
          </Grid>
        ) : null}
        {!loading ? (
          maintenanceList.slice(startIndex, endIndex).map((data, index) => (
            <MaintenanceAccordionGrid
              key={`maintenanceAccordionGrid_${index}`}
              seleniumid={`maintenanceAccordionGrid_${index}`}
              data={data}
              active={active.current}
              index={index}
              handleLoadMaintenanceList={handleLoadMaintenanceList}
              contactId={contactId}
            />
          ))) : (
          <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress sx={{margin: 4}} color="secondary"/>
          </Grid>
        )}
        <NoDataToDisplay loading={loading} list={maintenanceList}/>
        {maintenanceList.length !== 0 ?
          (<Grid item xs={12} style={{justifyContent: "center", display: 'flex'}}>
            <Pagination count={count} page={page} onChange={handlePaginationChange} color="secondary" size="large"/>
          </Grid>)
          : null}
      </Grid>
    </RenderedWithPermission>
  );

  function handleLoadMaintenanceList() {
    setLoading(true);
    const datePeriod = active.current ? [null, null] : period;
    getMaintenanceList(auth.userData.access_token, customerContext.selectedCustomer.id, active.current, datePeriod)
      .then(res => {
        let dataList = res.data.items.map(item => {
          item.type = item.type === "ÜFE ablak" ? "Tervezett hálózati karbantartás" : item.type;
          return item;
        });
        if (active.current) {
          dataList = sortByDate(dataList, 'asc');
        } else {
          dataList.sort((a, b) => {
            if (a.title !== b.title) {
              return sortOrder.title === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
            } else if (a.serviceId !== b.serviceId) {
              return sortOrder.serviceId === 'asc' ? a.serviceId - b.serviceId : b.serviceId - a.serviceId;
            } else {
              return sortOrder.endDate === 'asc' ? new Date(a.endDate) - new Date(b.endDate) : new Date(b.endDate) - new Date(a.endDate);
            }
          });
        }
        setMaintenanceList(dataList);
        setCount(calculateTotalPages(res.data.items, pageSize));
      })
      .finally(() => setLoading(false));
  }

  function handleLoadContactId() {
    getMaintenanceContactId(auth.userData.access_token, customerContext.selectedCustomer.id).then(res => setContactId(res.data));
  }
};

export default MaintenancesTab;