import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Container,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {CustomerContext} from "../../context/CustomerContext";
import {useAuth} from "oidc-react";
import UserDataGrid from "./userEditor/UserDataGrid";
import ContactDataGrid from "./userEditor/ContactDataGrid";
import RightsGrid from "./userEditor/RightsGrid";
import PortalAccessGrid from "./userEditor/PortalAccessGrid";
import MasterDataGrid from "./userEditor/MasterDataGrid";
import {getNewUserTemplate, getUser, getUserEditSelectOptions, saveUser} from "../../api/contactResource";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import Tooltip from "@mui/material/Tooltip";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {UserContext} from "../../context/UserContext";

const theme = createTheme();

const defaultDataForRepeater = {
  queryRecId: 0,
  colId: null,
  headId: 0,
  poId: 0,
  name: null,
  value: null,
  valueMsr: null
};

const deleteFlag = "delete";

const UserEditor = (props) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage(true);
  const {enqueueSnackbar} = useSnackbar();
  const languageContext = useContext(LanguageContext);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const [selectOptions, setSelectOptions] = useState({});
  const [selectOptionsLoading, setSelectOptionsLoading] = useState(true);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [existingUser, setExistingUser] = useState((!!props.user?.contactId && props.user?.contactId !== "new"));
  const [adminRole, setAdminRole] = useState(false);

  useEffect(() => {
    if (customerContext.selectedContact.poid && customerContext.selectedContact?.roles?.length > 0) {
      setAdminRole(customerContext.selectedContact.roles.includes("ADMIN"));
    }
  }, [customerContext.selectedContact?.poid, customerContext.selectedContact?.roles]);

  useEffect(() => {
    setUserLoading(true);
    if (props.open && auth.userData && customerContext.selectedCustomer.id) {
      loadUser();
    }
  }, [props.open, auth.userData, props.user, customerContext.selectedCustomer.id, languageContext.language.id]);

  useEffect(() => {
    setExistingUser((!!props.user?.contactId && props.user?.contactId !== "new"));
  }, [props.user?.contactId]);

  const handleCancel = () => {
    props.onEditCancel();
  };

  const handleSave = (e) => {
    e.preventDefault();
    setSavingInProgress(true);
    saveUser(auth.userData.access_token, user)
      .then(() => {
        enqueueSnackbar(languageContext.dictionary[existingUser ? "successEdit" : "successCreate"], {variant: "success", preventDuplicate: true});
        props.onItemEdited(user);
        props.setUserIsSaved?.(true);
      })
      .catch(error => {
        if (error.response.status === 406) {
          showResponseErrorList(error, error.response.data);
        } else {
          showRequestError(error, languageContext.dictionary.errorMessages.saveError);
        }
      })
      .finally(() => {
        setSavingInProgress(false);
      });
  };


  const handleFieldChange = (field, newValue, valueName = "value") => {
    setUser(curr => ({...curr, [field]: !valueName ? newValue : {...curr[field], [valueName]: newValue}}));
  };

  const showResponseErrorList = (error, responseErrorList) => {
    if (responseErrorList.length > 0) {
      responseErrorList.forEach((message) => {
        showRequestError(error, message, {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          },
          persist: true,
          style: {
            maxWidth: "450px"
          }
        });
      });
    }

  };

  return (
    <Dialog open={props.open} onClose={handleCancel} maxWidth="lg" fullScreen={fullScreen}>
      <form onSubmit={handleSave}>
        <DialogTitle>
          {!existingUser ? languageContext.dictionary.newUser : languageContext.dictionary.editUser}
        </DialogTitle>
        <DialogContent>
          {!userLoading && !selectOptionsLoading ? (
            <Grid container spacing={4} alignItems="flex-start">
              <Grid item container spacing={3} xs={12} lg={6} alignItems="center">
                <UserDataGrid
                  handleFieldChange={handleFieldChange}
                  user={user}
                  selectOptions={selectOptions}
                  selectOptionsLoading={selectOptionsLoading}
                  adminEditor={adminRole}
                  profilePhoto={props?.user?.picture ? `data:text/plain;base64,${props.user.picture}` : null}
                />
                <ContactDataGrid
                  handleFieldChange={handleFieldChange}
                  user={user}
                  selectOptions={selectOptions}
                  selectOptionsLoading={selectOptionsLoading}
                  deleteFlag={deleteFlag}
                  defaultNewObject={defaultDataForRepeater}
                  idName={"queryRecId"}
                />
              </Grid>
              <Grid item container xs={12} lg={6} spacing={2}>
                <RightsGrid
                  handleFieldChange={handleFieldChange}
                  user={user}
                  rights={selectOptions.sso_role}
                  rightsLoading={selectOptionsLoading}
                  adminEditor={adminRole}
                />
                <PortalAccessGrid
                  handleFieldChange={handleFieldChange}
                  user={user}
                  selectOptions={selectOptions}
                  adminEditor={adminRole}
                />
                {existingUser && (user?.mainPhoneNumbers?.length > 0 || user?.mainEmails?.length > 0) ? (
                  <MasterDataGrid
                    handleFieldChange={handleFieldChange}
                    user={user}
                    selectOptions={selectOptions}
                    selectOptionsLoading={selectOptionsLoading}
                    deleteFlag={deleteFlag}
                    defaultNewObject={defaultDataForRepeater}
                    idName={"queryRecId"}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <Container sx={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
              <CircularProgress sx={{margin: 4}} color="secondary"/>
            </Container>
          )}
        </DialogContent>
        <DialogActions sx={{pb: 2}}>
          <Tooltip title={userContext?.userInfo?.innerUser ? languageContext.dictionary.disabledImpersonate : ""}>
            <span>
              <LoadingButton
                disabled={userContext?.userInfo?.innerUser}
                loading={savingInProgress}
                color="secondary"
                variant="contained"
                type="submit"
              >
                {languageContext.dictionary.save}
              </LoadingButton>
            </span>
          </Tooltip>
          <Button onClick={handleCancel}>
            {languageContext.dictionary.cancel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  async function loadUser() {
    try {
      const contactId = props.user?.contactId || props.user?.userId;
      let res = (!!contactId && contactId !== "new") ?
        (await getUser(auth.userData.access_token, contactId)) :
        (await getNewUserTemplate(auth.userData.access_token, customerContext.selectedCustomer.id));
      setUser(res.data || {});
      handleOptionLoading(res.data.queryPoId, res.data.verification);
    } catch (error) {
      handleCancel();
    } finally {
      setUserLoading(false);
    }
  }

  function handleOptionLoading(queryPoId, verification) {
    setSelectOptionsLoading(true);
    getUserEditSelectOptions(auth.userData.access_token, queryPoId, verification)
      .then(res => {
        let updatedSelectOptions = {phoneTypes: [], emailTypes: [], masterPhoneTypes: [], masterEmailTypes: []};
        res.data.forEach(select => {
          if (select.type === "phone" || select.type === "mobile") {
            let phoneType = select.options.map(o => ({
              ...o,
              type: select.type,
              title: `${o.title} (${languageContext.dictionary[select.type]?.toLowerCase()})`
            }));
            if (select.mainData) {
              updatedSelectOptions.masterPhoneTypes = updatedSelectOptions.masterPhoneTypes.concat(phoneType);
            } else {
              updatedSelectOptions.phoneTypes = updatedSelectOptions.phoneTypes.concat(phoneType);
            }
          } else if (select.type === "email_address") {
            if (select.mainData) {
              updatedSelectOptions.masterEmailTypes = select.options;
            } else {
              updatedSelectOptions.emailTypes = select.options;
            }
          } else {
            updatedSelectOptions[select.type] = select.options;
          }
          return select;
        });
        setSelectOptions(updatedSelectOptions);
      })
      .finally(() => setSelectOptionsLoading(false));
  }

};
UserEditor.defaultProps = {
  setUserIsSaved: null
};

UserEditor.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  onItemEdited: PropTypes.func.isRequired,
  delete: PropTypes.bool,
  setUserIsSaved: PropTypes.func
};

export default UserEditor;
