import React, {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CustomAutocomplete from "../formFields/CustomAutocomplete";

const ServerRoomPermissionsFilter = ({filters, setFilters, handleFilter, defaultFilters, selectOptions, loading}) => {
  const languageContext = useContext(LanguageContext);

  const handleFilterChange = (newValue, filterName) => {
    setFilters(curr => ({...curr, [filterName]: newValue}));
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    if (handleFilter) {
      handleFilter(true);
    }
  };

  const filter = () => {
    if (handleFilter) {
      handleFilter(false);
    }
  };

  return (
    <Grid item xs={12} seleniumid={`serverRoomPermissionsFilter`} sx={{paddingTop: 3}}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary"/>}>
          <Typography variant="h5">{languageContext.dictionary.filters}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} sx={{padding: 2}} alignItems="center">
            <Grid item container xs={12} md={5} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography>{languageContext.dictionary.serverRoom}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <CustomAutocomplete
                  value={filters.serverRoom?.value}
                  handleChange={(event, newValue) => handleFilterChange(newValue, "serverRoom")}
                  options={selectOptions.serverRooms}
                  loading={loading}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={12} md={2}>
                <Button color="secondary" variant="text" onClick={resetFilters}>
                  {languageContext.dictionary.deleteFilters}
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button color="secondary" variant="contained" onClick={filter}>
                  {languageContext.dictionary.filter}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ServerRoomPermissionsFilter.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  handleFilter: PropTypes.func,
  defaultFilters: PropTypes.object
};

export default ServerRoomPermissionsFilter;