import React, {useContext} from "react";
import {Avatar, Grid} from "@mui/material";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {getIcon} from "material-file-icons";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  avatarSize: {
    height: 25,
    width: 25
  }
}));

const CaseDocumentRow = ({documentList}) => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);

  const avatarRender = (name) => {
    return (<Avatar className={classes.avatarSize} src={`data:image/svg+xml;utf8,${encodeURIComponent(getIcon(name).svg)}`}/>);
  };

  return (
    <Grid container xs={12}>
      <Grid item xs={7}>
        <Typography sx={{float: "left", marginTop: 0.5}}>
          {languageContext.dictionary.necessaryDocuments}
        </Typography>
        <Tooltip title={languageContext.dictionary.necessaryDocumentsTooltip}>
          <IconButton sx={{padding: 0, marginLeft: 1}}>
            <InfoIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={5}>
        {documentList.map(item =>
          (<Button
            sx={{marginRight: 2}}
            startIcon={avatarRender(item.url)}
            onClick={() => window.open(item.url, "_blank")}
          >
            <Typography fontSize={13} fontWeight={"bold"}>
              {languageContext.language.id === "hu" ? item.nameHu : item.nameEn}
            </Typography>
          </Button>)
        )}
      </Grid>
    </Grid>
  );
};
CaseDocumentRow.propTypes = {
  documentList: PropTypes.array.isRequired
};

export default CaseDocumentRow;