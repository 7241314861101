import React, {useContext} from "react";
import {LanguageContext} from "../context/LanguageContext";
import {Redirect, useLocation} from "react-router-dom";
import TabPageWithUrl from "../components/common/TabPageWithUrl";
import AdministrationTab from "../components/administration/AdministrationTab";
import CasesTab from "../components/administration/CasesTab";

const AdministrationPage = () => {
  const languageContext = useContext(LanguageContext);
  const location = useLocation();
  const tabs = [
    {pathname: "/administration", index: 0, label: "administrationSynonym", child: AdministrationTab},
    {pathname: "/administration/pendingCases", index: 1, label: "pendingCases", child: CasesTab},
    {pathname: "/administration/closedCases", index: 2, label: "closedCases", child: CasesTab, childProps: {isClosed: true}}
  ];

  return (
    <>
      {location.pathname === "/administration" ? (
        <Redirect to={tabs[0].pathname}/>
      ) : null}
      <TabPageWithUrl tabs={tabs} title={languageContext.dictionary.administration} seleniumid={"administrationPage"}/>
    </>
  );
};

export default AdministrationPage;
