import React, {useContext, useEffect, useState} from "react";
import {CircularProgress, Grid} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {useHistory} from "react-router-dom";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {getMaintenanceList} from "../../api/maintenanceResource";
import {CustomerContext} from "../../context/CustomerContext";
import {useAuth} from "oidc-react";
import {formatDate, formatDateTime, formatTime} from "../../utils/dateFormat";
import DayAndNightIconSwitcher from "./DayAndNightIconSwitcher";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RenderedWithPermission from "../RenderedWithPermission";
import {adminTechRight} from "../../utils/commonValues";

const MaintenanceBoard = () => {
  const langContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [maintenanceBoardData, setMaintenanceBoardData] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (auth.userData.access_token && customerContext.selectedCustomer.id) {
      setLoading(true);
      getMaintenanceList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => {
          if (res.data?.items?.length > 0) {
            const item = res.data.items[0];
            setMaintenanceBoardData({
              maintenanceType: item.title,
              maintenanceSites: item.siteList,
              maintenanceDate: formatDate(item.startDate),
              maintenanceDurationStart: formatDateTime(item.startDate),
              maintenanceDurationEnd: formatDateTime(item.endDate)
            });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);


  return !loading ? (
    <RenderedWithPermission roles={adminTechRight}>
      <Paper style={{marginTop: 15}}>
        {Object.keys(maintenanceBoardData).length > 0 ? (
          <Grid seleniumid="maintenanceBoardWrapper" style={{padding: 5}} container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6} sm={3} md={1} alignItems="start">
              <WarningRoundedIcon sx={{fontSize: 70, color: "warning.main"}}/>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Typography sx={{color: "warning.main"}} variant={"h5"}>{langContext.dictionary.maintenance}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant={"body1"} sx={{fontWeight: "bold"}}>{maintenanceBoardData.maintenanceType}</Typography>
              {maintenanceBoardData.maintenanceSites?.map((site, index) => (
                <Typography variant={"body1"} key={`site_${index}`}>{site}</Typography>
              ))}
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <CalendarMonthOutlinedIcon sx={{fontSize: 33, float: "left", marginRight: 1}}/>
              <Typography variant={"h6"}>{maintenanceBoardData.maintenanceDate}</Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <DayAndNightIconSwitcher
                fontSize={33}
                startHour={maintenanceBoardData.maintenanceDurationStart}
                endHour={maintenanceBoardData.maintenanceDurationEnd}
              />
              <Typography variant={"h6"}>
                {formatTime(maintenanceBoardData.maintenanceDurationStart)}-{formatTime(maintenanceBoardData.maintenanceDurationEnd)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={1}>
              <Button
                seleniumid="maintenanceBoardLink"
                color="secondary"
                onClick={() => history.push("/technicalOverview/maintenances")}
              >
                {langContext.dictionary.another}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid seleniumid="maintenanceBoardWrapper" style={{padding: 5}} container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3} sm={2} md={1} alignItems="start">
              <CheckCircleIcon color={"secondary"} sx={{fontSize: 70, padding: 1}}/>
            </Grid>
            <Grid item xs={9} sm={6} md={7}>
              <Typography variant={"h5"}>{langContext.dictionary.noMaintenance}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Button
                seleniumid="maintenanceBoardLink"
                color="secondary"
                size="large"
                onClick={() => history.push("/technicalOverview/maintenances")}
              >
                {langContext.dictionary.previousMaintenance}
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    </RenderedWithPermission>
  ) : (
    <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
      <CircularProgress sx={{margin: 4}} color="secondary"/>
    </Grid>
  );
};

export default MaintenanceBoard;