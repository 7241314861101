import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

const resource = "/maintenance";

let notificationListController = new AbortController();
export const getMaintenanceList = async (token, cmId, active = true, period = [null, null]) => {
  notificationListController.abort();
  notificationListController = new AbortController();
  let url = `${env.apiRoot}${resource}/maintenances/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {
    signal: notificationListController.signal,
    headers: {Authorization: authStr},
    params: {
      active: active,
      from: period[0] ? period[0]?.valueOf() : null,
      to: period[1] ? period[1]?.add(1, 'days')?.valueOf() : null
    }
  });
};

export const getMaintenanceTypeList = async (token) => {
  let url = `${env.apiRoot}${resource}/service_types`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const acceptMaintenance = async (token, contactId, maintenanceId) => {
  let url = `${env.apiRoot}${resource}/accept`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.post(url, {maintenanceId: maintenanceId, contactId: contactId}, {headers: {Authorization: authStr}});
};

export const rejectMaintenance = async (token, contactId, maintenanceId, rejectionCause) => {
  let url = `${env.apiRoot}${resource}/reject`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.post(url, {
    maintenanceId: maintenanceId,
    contactId: contactId,
    rejectionCause: rejectionCause
  }, {headers: {Authorization: authStr}});
};

export const getMaintenanceContactId = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/contactId/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};
