import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {CircularProgress} from "@mui/material";
import {CustomerContext} from "../../context/CustomerContext";
import Pagination from "@mui/material/Pagination";
import BillAccordion from "./BillAccordion";
import {getBillListPagination} from "../../api/accountResource";
import {useAuth} from "oidc-react";
import BillsFilter from "./BillsFilter";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinRight} from "../../utils/commonValues";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  details: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3)
    },
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  circularProgress: {
    margin: theme.spacing(4)
  }
}));

const defaultFilters = {
  billingDate: [null, null],
  paymentDeadline: [null, null],
  amountFrom: "",
  amountTo: "",
  billNumber: ""
};

const AllBillsTab = () => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const [billList, setBillList] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);


  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      handleBillDataLoad();
    }
  }, [auth.userData, customerContext.selectedCustomer.id, page]);

  const handleFilterChange = (newValue, filterName) => {
    setFilters(curr => ({...curr, [filterName]: newValue}));
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    handleFilterSubmit(true);
  };

  const handleFilterSubmit = (resetFilter = false) => {
    setPage(0);
    handleBillDataLoad(resetFilter);
  };

  return (
    <RenderedWithPermission roles={adminFinRight} displayNoRightsLabel={true}>
      <Grid container className={classes.grid} spacing={6} seleniumid={"allBillsTab"}>
        <Grid item xs={12}>
          <Typography color="secondary">{languageContext.dictionary.billsOlderThanTwoYears}</Typography>
        </Grid>
        <BillsFilter
          filters={filters}
          handleFilterChange={handleFilterChange}
          resetFilters={resetFilters}
          handleFilterSubmit={handleFilterSubmit}
        />
        {!dataLoading ? (
          billList.map((data, index) => (
            <Grid item xs={12} key={`billAccordionGrid_${index}`} seleniumid={`billAccordionGrid_${index}`} style={{paddingTop: 25}}>
              <BillAccordion data={data} index={index}/>
            </Grid>
          ))) : (
          <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress className={classes.circularProgress} color="secondary"/>
          </Grid>
        )}
        <NoDataToDisplay list={billList} loading={dataLoading}/>
        {billList.length > 0 ? (
            <Grid item xs={12} style={{justifyContent: "center", display: 'flex'}}>
              <Pagination count={count} page={page + 1} onChange={(e, value) => setPage(value - 1)} color="secondary" size="large"/>
            </Grid>)
          : null}
      </Grid>
    </RenderedWithPermission>
  );

  function handleBillDataLoad(resetFilters = false) {
    setDataLoading(true);
    const myFilters = resetFilters ? defaultFilters : filters;
    const query = {page: page, pageSize: 10};
    getBillListPagination(auth.userData.access_token, query, customerContext.selectedCustomer.id, myFilters)
      .then(res => {
        const myCount = Math.ceil(res.data.sum / res.data.pageSize) || 1;
        setCount(myCount);
        setBillList(res?.data?.items);
      })
      .catch(error => showRequestError(error, languageContext.dictionary.errorMessages.billListLoadError))
      .finally(() => setDataLoading(false));
  }
};

export default AllBillsTab;
