import React, {useContext} from "react";
import {Avatar} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: ({filled}) => filled ? theme.palette.secondary.main : "white",
    color: ({filled, colorSwitcher}) => filled ? "white" : colorSwitcher || theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: 2,
    width: 60,
    height: 60,
    fontSize: 25
  }
}));

const CurrencyAvatar = props => {
  const classes = useStyles({filled: props.filled, colorSwitcher: props.colorSwitcher});
  const languageContext = useContext(LanguageContext);

  const formatCurrencyAvatarContent = (currency) => {
    switch (currency) {
      case "HUF":
        return languageContext.dictionary.huf;
      case "USD":
        return "$";
      case "EUR":
        return "€";
      default:
        return currency;
    }
  };

  return (
    <Avatar className={classes.avatar} sx={props.sx}>
      {props.paid ? (<CheckIcon fontSize={"large"}/>) : formatCurrencyAvatarContent(props.currency)}
    </Avatar>
  );
};

CurrencyAvatar.propTypes = {
  currency: PropTypes.any,
  sx: PropTypes.object,
  filled: PropTypes.bool,
  paid: PropTypes.bool,
  colorSwitcher: PropTypes.string
};

export default CurrencyAvatar;