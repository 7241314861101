import React, {useContext, useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import {CircularProgress, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import {LanguageContext} from "../../context/LanguageContext";
import SectionWithDivider from "../common/SectionWithDivider";
import {useHistory, useParams} from "react-router-dom";
import {getCollectorDetails} from "../../api/accountResource";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {CustomerContext} from "../../context/CustomerContext";
import CurrencyAvatar from "../common/CurrencyAvatar";
import CurrencyTypography from "../common/CurrencyTypography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import {useAuth} from "oidc-react";
import OrderDialog from "../dialog/OrderDialog";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import {UserContext} from "../../context/UserContext";

const useStyles = makeStyles(() => ({
  headerText: {
    fontWeight: "bold",
    fontSize: 26
  }
}));

const BillDetails = () => {
  const langContext = useContext(LanguageContext);
  const classes = useStyles();
  const history = useHistory();
  const urlParams = useParams();
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const [dataLoading, setDataLoading] = useState(true);
  const [billDetails, setBillDetails] = useState({});
  const [errorTicketDialogOpen, setErrorTicketDialogOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const customerContext = useContext(CustomerContext);
  const userContext = useContext(UserContext);


  useEffect(() => {
    if (auth.userData.access_token && customerContext.selectedCustomer.id) {
      setRefreshData(false);
      setDataLoading(true);
      getCollectorDetails(auth.userData.access_token, customerContext.selectedCustomer.id, urlParams.id)
        .then(res => setBillDetails(res.data))
        .catch(error => {
          showRequestError(error, langContext.dictionary.errorMessages.userDataLoadError);
          history.push("/billing");
        })
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, refreshData]);

  const displayableData = [
    {title: langContext.dictionary.eBill, value: billDetails.einvoiceEmail, status: billDetails.einvoice},
    {title: langContext.dictionary.eNotice, value: billDetails.ewarningEmail, status: billDetails.ewarning},
    {title: langContext.dictionary.remoteAccount, value: billDetails.telcoInvoiceEmail, status: billDetails.telcoInvoice},
    {title: `${langContext.dictionary.bankAccount} (Invitech)`, value: billDetails.invitechBankAccount},
    {title: langContext.dictionary.clientCode, value: billDetails.customerId},
    {title: langContext.dictionary.billCollectorId, value: billDetails.collectorId},
    {title: langContext.dictionary.billingAddress, value: billDetails.invoiceAddress},
    {title: langContext.dictionary.customerNameAddress, value: `${billDetails.customerName}, ${billDetails.customerAddress}`},
    {title: `${langContext.dictionary.customer} ${langContext.dictionary.taxNumber.toLocaleLowerCase()}`, value: billDetails.customerTaxNumber},
    {title: langContext.dictionary.euCommunityTaxNumber, value: billDetails.customerEuTaxNumber},
  ];


  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs="auto">
          <Button
            variant="text"
            onClick={() => history.push("/billing")}
            size="small"
            color="secondary"
            disableRipple
          >
            <ChevronLeftIcon color="secondary"/>
            {langContext.dictionary.bills}
          </Button>
        </Grid>

        <Grid item xs="auto">
          <Button
            variant="text"
            color="secondary"
            size="large"
            onClick={() => setErrorTicketDialogOpen(true)}
            startIcon={<AddBoxOutlinedIcon color={userContext?.userInfo?.innerUser ? "" : "secondary"}/>}>
            {langContext.dictionary.eOrder}
          </Button>
        </Grid>
      </Grid>
      {!dataLoading ? (
        <>
          <Typography variant="h5" sx={{marginTop: 2, marginBottom: 2}}>
            {billDetails.giroId} {langContext.dictionary.classicAccount.toLocaleLowerCase()}
          </Typography>
          <Paper square>
            <Grid container direction="row" justifyContent="center" alignItems="center" padding={2} rowSpacing={1} style={{backgroundColor: "#f4f4f4"}}>
              <Grid item xs={6} sm={2}>
                <CurrencyAvatar currency={billDetails.currency} filled/>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="subtitle1">{langContext.dictionary.currency}</Typography>
                <Typography variant="subtitle1" color="secondary" className={classes.headerText}>{billDetails.currency}</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="subtitle1">{langContext.dictionary.balance}</Typography>
                <CurrencyTypography amount={billDetails.balance} currency={billDetails.currency} variant="subtitle1" className={classes.headerText}/>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="subtitle1">{langContext.dictionary.overdueDebt}</Typography>
                <CurrencyTypography amount={billDetails.overdueBalance} currency={billDetails.currency} variant="subtitle1" className={classes.headerText}/>
              </Grid>
            </Grid>
            <Grid sx={{paddingLeft: {xs: 2, sm: 10}, paddingRight: {xs: 2, sm: 10}, paddingBottom: {xs: 2, sm: 5}, paddingTop: {xs: 2, sm: 5}}}>
              {displayableData?.map((item, index) => (
                <SectionWithDivider key={`billDetailsRow_${index}`} title={item.title} value={item.value} status={item.status} longTitle dense/>
              ))}
            </Grid>
          </Paper>
        </>) : (
        <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
          <CircularProgress className={classes.circularProgress} color="secondary"/>
        </Grid>
      )}
      <OrderDialog
        title={langContext.dictionary.eOrder}
        handleClose={() => setErrorTicketDialogOpen(false)}
        open={errorTicketDialogOpen}
        setRefreshData={setRefreshData}
      />
    </>
  );
};

export default BillDetails;