import React, {useContext, useEffect, useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {StyledEngineProvider} from "@mui/material/styles";
import {Route, Switch, useHistory} from "react-router-dom";
import {env} from "../properties";
import NavigationBar from "./common/NavigationBar";
import "./App.css";
import {LanguageContext} from "../context/LanguageContext";
import moment from "moment";
import "moment/locale/hu";
import PermissionDenied from "../routes/PermissionDenied";
import {CustomerContext} from "../context/CustomerContext";
import {hasAccess} from "../utils/accessManager";
import Footer from "./common/Footer";
import {ConfirmProvider} from "material-ui-confirm";
import {AxiosInterceptorInitializer} from "./common/AxiosInterceptorInitializer";
import AppProviders from "./AppProviders";
import LoadingPage from "./common/LoadingPage";
import RootPage from "../routes/RootPage";
import HomePage from "../routes/HomePage";
import ReactGA from 'react-ga4';
import {hotjar} from "react-hotjar";
import CookieWrapper from "./cookies/CookieWrapper";
import {CookieContext} from "./cookies/CookieContext";
import * as Sentry from "@sentry/react";
import NotificationsPage from "../routes/NotificationsPage";
import {AdapterMoment} from "@mui/x-date-pickers-pro/AdapterMoment";
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import UserPage from "../routes/UserPage";
import ContractDetails from "../routes/ContractDetails";
import BillingDetailsPage from "../routes/BillingDetailsPage";
import ErrorTicketDetailsPage from "./errorTicket/ErrorTicketDetailsPage";
import ServiceDetails from "../routes/ServiceDetails";
import ServerRoomAccessPermitPage from "./serverRoom/ServerRoomAccessPermitPage";
import {LicenseInfo} from '@mui/x-license-pro';
import {useAuth} from "oidc-react";
import VersionChecker from "./common/VersionChecker";
import {Box} from "@mui/material";
import {contactHasRight} from "../utils/commonFunctions";

moment.locale("hu");

Sentry.init({dsn: "https://730fead7144e45ebb9547a28b4f9a696@sentry.invitech.hu/69", environment: window._env_.REACT_SENTRY_ENV});

LicenseInfo.setLicenseKey('e43aecf67a67215af78833b383bc67faTz04NjM3NixFPTE3NDE5NDM4MjEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
  const [navigation, setNavigation] = useState("horizontal");
  const customerContext = useContext(CustomerContext);
  const cookieContext = useContext(CookieContext);
  const languageContext = useContext(LanguageContext);
  const history = useHistory();
  const [locale, setLocale] = useState("horizontal");
  const auth = useAuth();

  useEffect(() => {
    moment.locale(languageContext.dictionary.datePickers.locale);
    setLocale(languageContext.language.id);
  }, [languageContext.language.id]);

  useEffect(() => {
    if (cookieContext.cookieInfo.thirdPartyCookie) {
      if (env.gaTrackingId) {
        ReactGA.initialize(env.gaTrackingId);
      } else {
        console.warn("GA Tracking ID not found");
      }
      hotjar.initialize(env.hjid, 6);

      history.listen((location, action) => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
        hotjar.stateChange(location.pathname);
      });
      customerContext.analyticsIdentify(cookieContext.cookieInfo.thirdPartyCookie);
    }
  }, [cookieContext.cookieInfo.thirdPartyCookie]);

  const hasApplicationAccess = () => {
    let access = env.webAccessGroups.some(r => auth?.userData?.profile?.realm_access?.roles.includes(r));
    return access;
  };

  const getRoute = (menuItem) => {

    if (!menuItem.children) {
      if (!contactHasRight(customerContext.selectedContact.roles, menuItem.access)) {
        return <Route
          key={"access_denied_" + menuItem.url}
          path={menuItem.url}
          component={PermissionDenied}
        />;
      }
      return <Route
        key={menuItem.url}
        path={menuItem.url}
        component={menuItem.component}
      />;
    }


    return menuItem.children.map(childItem => {
      if (!hasAccess(menuItem.access, auth) || !hasAccess(childItem.access, auth)) {
        return <Route
          key={"access_denied_" + childItem.url}
          path={childItem.url}
          component={PermissionDenied}
        />;
      }
      return <Route
        key={childItem.url}
        path={childItem.url}
        component={childItem.component}
      />;
    });
  };

  return (
    <AppProviders>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
        <StyledEngineProvider injectFirst>
          <ConfirmProvider>
            <CssBaseline/>
            <AxiosInterceptorInitializer/>
            <VersionChecker/>
            {auth.userData && <CookieWrapper/>}
            <Box sx={{display: "flex", flexDirection: "column", height: "100%", "> div:first-child": {flexGrow: 1}}}>
              {auth.userData ? (hasApplicationAccess() ? (
                <Switch>
                  <NavigationBar
                    variant={navigation}
                    content={
                      <Switch>
                        <Route exact path="/" component={RootPage}/>
                        {
                          env.menuItems.map(getRoute)
                        }
                        {
                          getRoute({
                            url: "/home",
                            component: HomePage
                          })
                        }
                        {
                          getRoute({
                            url: "/notifications",
                            component: NotificationsPage
                          })
                        }
                        {
                          getRoute({
                            url: "/companyDetails",
                            component: UserPage
                          })
                        }
                        {
                          getRoute({
                            url: "/profile",
                            component: UserPage
                          })
                        }
                        {
                          getRoute({
                            url: "/usersAndPermissions",
                            component: UserPage
                          })
                        }
                        {
                          getRoute({
                            url: "/contractDetails/:id",
                            component: ContractDetails
                          })
                        }
                        {
                          getRoute({
                            url: "/billingDetails/:id",
                            component: BillingDetailsPage
                          })
                        }
                        {
                          getRoute({
                            url: "/errorTicketDetails/:id",
                            component: ErrorTicketDetailsPage
                          })
                        }
                        {
                          getRoute({
                            url: "/serviceDetails/:id",
                            component: ServiceDetails
                          })
                        }
                        {
                          getRoute({
                            url: "/serverRoomAccessPermit",
                            component: ServerRoomAccessPermitPage
                          })
                        }
                      </Switch>
                    }
                  />
                </Switch>) : <Route component={PermissionDenied}/>) : <Route component={LoadingPage}/>}
              <Footer/>
            </Box>
          </ConfirmProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </AppProviders>
  );
}

export default App;
