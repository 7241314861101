import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import {LanguageContext} from "../../context/LanguageContext";
import {Dialog, DialogContent, DialogTitle, TextField, Tooltip, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {sendInvitation} from "../../api/invitationResource";
import {CustomerContext} from "../../context/CustomerContext";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import Grid from "@mui/material/Grid";
import MuiPhoneNumber from "material-ui-phone-number";
import {useAuth} from "oidc-react";

const emptyPhoneNumberLength = 3;
const validPhoneNumberLength = 12;

const useStyles = makeStyles(({
  buttonWrapper: {
    paddingTop: 10,
    padding: 2,
    textAlign: "right"
  },
  button: {
    margin: "8px"
  },
  textField: {
    width: "100%",
  }
}));

const InvitationSenderDialog = props => {
  const classes = useStyles();
  const customerContext = useContext(CustomerContext);
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const {enqueueSnackbar} = useSnackbar();
  const {showRequestError} = useRequestErrorMessage();
  const invitationObj = {firstName: "", lastName: "", email: "", phoneNumber: "+36"};
  const [invitationList, setInvitationList] = useState([invitationObj]);

  const handleChange = (fieldName, index, value) => {
    setInvitationList(curr => {
      let updated = [...curr];
      updated[index][fieldName] = value;
      return updated;
    });
  };

  const handleAddClick = () => {
    setInvitationList(curr => {
      let updated = [...curr];
      updated.push(invitationObj);
      return updated;
    });
  };

  const handleRemoveClick = (index) => {
    setInvitationList(curr => {
      let updated = [...curr];
      updated.splice(index, 1);
      return updated;
    });
  };

  const validEmail = (email) => {
    return !email || String(email).toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const showPhoneNumberError = (phoneNumber) => {
    return phoneNumber.length !== validPhoneNumberLength && phoneNumber.length !== emptyPhoneNumberLength;
  };

  const handleSend = async event => {
    event.preventDefault();

    if (invitationList.filter(invitation => !validEmail(invitation.email)).length > 0) {
      enqueueSnackbar(langContext.dictionary.invalidEmailMsg, {variant: "warning", preventDuplicate: true});
      return;
    }

    if (invitationList.some(invitation => invitation.phoneNumber.length !== validPhoneNumberLength)) {
      enqueueSnackbar(langContext.dictionary.invalidPhoneNumberMsg, {variant: "warning", preventDuplicate: true});
      return;
    }

    if (auth.userData) {
      try {
        await Promise.all(invitationList.map(invitation => {
          //TODO: Rendbe kell tenni, de most gyors megoldás kellett
          invitation.mobile = invitation.phoneNumber;
          return sendInvitation(auth.userData, invitation, customerContext.selectedCustomer.id);
        }));
        enqueueSnackbar(langContext.dictionary.invitationSuccess, {variant: "success", preventDuplicate: true});
      } catch (error) {
        showRequestError(error, langContext.dictionary.invitationError);
      } finally {
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setInvitationList([invitationObj]);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  return (
    <Dialog
      data-testid="invitationDialog"
      open={props.open}
      onClose={handleClose}
      fullWidth
      maxWidth={"xl"}
      disableScrollLock
    >
      <DialogTitle onClose={handleClose} color="secondary">
        {langContext.dictionary.sendInvitation}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSend}>
          <Grid container>
            <div style={{display: "table", width: "100%"}}>
              <Typography style={{display: "table-cell"}}>
                {langContext.dictionary.invitationSenderText}
              </Typography>
              <div style={{display: "table-cell", textAlign: "right"}}>
                <Tooltip title={langContext.dictionary.add} placement="left" arrow>
                  <IconButton data-testid="addButton" onClick={() => handleAddClick()}>
                    <AddIcon/>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {invitationList.map((invitation, index) => (
              <Grid container columnSpacing={2} rowSpacing={2} key={`div_${index}`}
                    style={{display: "flex", marginBottom: 10}}>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    data-testid="lastName"
                    className={classes.textField}
                    label={langContext.dictionary.lastName}
                    name={"lastName"}
                    autoComplete="family-name"
                    value={invitation.lastName}
                    onChange={event => handleChange("lastName", index, event.target.value)}
                    required
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    data-testid="firstName"
                    className={classes.textField}
                    label={langContext.dictionary.firstName}
                    name={"firstName"}
                    autoComplete="given-name"
                    value={invitation.firstName}
                    onChange={event => handleChange("firstName", index, event.target.value)}
                    required
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    data-testid="email"
                    className={classes.textField}
                    label={langContext.dictionary.email}
                    name={"email"}
                    value={invitation.email}
                    onChange={event => handleChange("email", index, event.target.value)}
                    required
                    multiline
                    error={!validEmail(invitation.email)}
                    helperText={!validEmail(invitation.email) ? langContext.dictionary.invalidEmailMsg : ""}
                  />
                </Grid>
                <Grid item xs={invitationList.length !== 1 ? 10 : 12} md={4} lg={invitationList.length !== 1 ? 2 : 3}>
                  <MuiPhoneNumber
                    defaultCountry={'hu'}
                    onlyCountries={['hu']}
                    variant="outlined"
                    sx={{svg: {height: "20px"}}}
                    className={classes.textField}
                    data-testid="phoneNumber"
                    label={langContext.dictionary.phoneNumber}
                    name={"phoneNumber"}
                    value={invitation.phoneNumber}
                    onChange={value => handleChange("phoneNumber", index, value)}
                    countryCodeEditable={false}
                    error={showPhoneNumberError(invitation.phoneNumber)}
                    helperText={showPhoneNumberError(invitation.phoneNumber) ? langContext.dictionary.invalidPhoneNumberMsg : ""}
                    required
                  />
                </Grid>
                {invitationList.length !== 1 &&
                  <Grid item xs={invitationList.length !== 1 ? 1 : 2} lg={1} style={{marginTop: 10}}>
                    <Tooltip
                      title={langContext.dictionary.delete} placement="right" arrow key={`delete_tooltip_${index}`}
                    >
                      <IconButton data-testid="deleteButton" onClick={() => handleRemoveClick(index)}
                                  key={`delete_btn_${index}`}>
                        <DeleteIcon key={`delete_icon_${index}`}/>
                      </IconButton>
                    </Tooltip>
                  </Grid>}
              </Grid>
            ))}
          </Grid>
          <div className={classes.buttonWrapper}>
            <Button onClick={handleClose} color="primary">
              {langContext.dictionary.close}
            </Button>
            <Button
              data-testid="submitButton"
              type={"submit"}
              className={classes.button}
              variant="contained"
              size="small"
              color="mainButton"
              disableRipple
            >
              {langContext.dictionary.send}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

InvitationSenderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default InvitationSenderDialog;