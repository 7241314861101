import axios from "axios";
import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

const resource = "/company";

export const getCompanyList = async (token) => {
  let url = `${env.apiRoot}${resource}/list`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const getCustomerRoles = async (token) => {
  let url = `${env.apiRoot}${resource}/customerRoles`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

let searchCompanyController = new AbortController();
export const searchCompany = async (token, pageIndex, pageSize, searchValue) => {
  searchCompanyController.abort();
  searchCompanyController = new AbortController();

  let url = `${env.apiRoot}${resource}/search`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {
    signal: searchCompanyController.signal,
    headers: {Authorization: authStr},
    params: {
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchValue: searchValue
    }
  });
};

export const saveCompany = async (token, companyData) => {
  let url = `${env.apiRoot}${resource}/active/save`;
  const authStr = "Bearer ".concat(token);
  return await axios.post(url, companyData, {headers: {Authorization: authStr}});
};

export const getActiveCompany = async (token, email) => {
  let url = `${env.apiRoot}${resource}/active`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}, params: {email: email}});
};

export const getContactPersons = async (token, companyId) => {
  let url = `${env.apiRoot}${resource}/getContactPersons/${companyId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const getCompanyInfo = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/company/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const getInternalContactList = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/internalContacts/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};