import React, {useEffect} from "react";
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  welcomeContainer: {
    height: "100%",
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 9999,
    backgroundColor: "#fff",
    textAlign: "center"
  },
  circularProgress: {
    margin: theme.spacing(4)
  },
  welcomeText: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  }
}));

const LoadingPage = () => {
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <div className={classes.welcomeContainer}>
      <CircularProgress
        className={classes.circularProgress}
        color="secondary"
      />
    </div>
  );
};

export default LoadingPage;
