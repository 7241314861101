import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";
import {CustomerContext} from "../../context/CustomerContext";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import {makeStyles, useTheme} from "@mui/styles";
import {LanguageContext} from "../../context/LanguageContext";
import CompanySelectorDialog from "../dialog/CompanySelectorDialog";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.userBar.background,
    placeContent: "flex-end"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  icon: {
    marginRight: theme.spacing(0.5)
  },
  button: {
    margin: `${theme.spacing(0.5)}`,
    "&$buttonDisabled": {
      color: theme.palette.grey[900]
    }
  },
  buttonDisabled: {},
  buttonNoLabel: {
    margin: `0`
  },
  buttonGroup: {
    margin: `0 ${theme.spacing(0.5)}`,
    [theme.breakpoints.down('md')]: {display: "none"}
  },
  hide: {
    display: "none"
  }
}));

const CompanySelectorButton = () => {
  const classes = useStyles();
  const theme = useTheme();

  const languageContext = useContext(LanguageContext);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const [multipleCompanies, setMultipleCompanies] = useState(true);
  const [companySelectorModalOpen, setCompanySelectorModalOpen] = useState(false);

  useEffect(() => {
    if (userContext?.userInfo?.email && userContext?.userInfo?.email !== "" && customerContext.selectedContact.loaded) {
      setMultipleCompanies(userContext.userInfo.innerUser || userContext.userInfo.companyList?.length > 1);
      setCompanySelectorModalOpen(!customerContext?.selectedCustomer?.id || !customerContext?.selectedContact?.id);
    }
  }, [userContext.userInfo, customerContext.selectedContact]);

  const showCompanySelector = () => {
    if (multipleCompanies) {
      setCompanySelectorModalOpen(true);
    }
  };

  const closeCompanySelector = () => setCompanySelectorModalOpen(false);

  return (
    <>
      <Tooltip title={languageContext.dictionary.companySelector}>
        <Button data-testid="companyModalOpenButton"
                id="openMenu"
                classes={{root: classes.button, disabled: classes.buttonDisabled}}
                onClick={showCompanySelector}
                disabled={!multipleCompanies}
                variant="text"
                size="small">
          <Icon className={classes.icon}>business</Icon>
          <span style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>{customerContext?.selectedCustomer?.name || ""}</span>
          {multipleCompanies ? (
            <Icon className={classes.icon} sx={{color: theme.palette.mainButton.main}}>expand_more</Icon>
          ) : null}
        </Button>
      </Tooltip>
      <CompanySelectorDialog open={companySelectorModalOpen} handleClose={closeCompanySelector}/>
    </>
  );

};


export default CompanySelectorButton;