import React, {useCallback, useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {makeStyles} from "@mui/styles";
import {LanguageContext} from "../context/LanguageContext";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import {Container, Paper, Tab, Tabs} from "@mui/material";
import SimpleTabPanel from "../components/common/SimpleTabPanel";
import DataTable from "../components/datatable/DataTable";
import {useRequestErrorMessage} from "../utils/useRequestErrorMessage";
import {ignoreCancellation} from "../api/apiUtils";
import {formatTime, humanizeDate} from "../utils/dateFormat";
import CustomDateRangePicker from "../components/date/CustomDateRangePicker";
import {fieldTypes} from "../utils/fieldTypes";
import CustomSelect from "../components/formFields/CustomSelect";
import {CustomerContext} from "../context/CustomerContext";
import {getNotificationListPagination, getNotificationTypeList} from "../api/notificationResource";
import {useAuth} from "oidc-react";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const defaultColumns = [
  {
    name: "title",
    label: "address",
    render: (value, rowData) => <span style={{fontWeight: rowData?.unread ? "bold" : undefined}}>{value}</span>
  },
  {
    name: "description",
    label: "description",
    render: (value, rowData) => <span style={{fontWeight: rowData?.unread ? "bold" : undefined}}>{value}</span>
  },
  {
    name: "date",
    label: "date",
    type: "dateTime",
    render: (value, rowData) =>
      <span style={{fontWeight: rowData?.unread ? "bold" : undefined}}>
        {humanizeDate(value)}
        <br/>
        {formatTime(value)}
      </span>
  }
];

const NotificationsPage = () => {
  const classes = useStyles();
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const [actualTab, setActualTab] = useState(0);
  const [columns] = useState(defaultColumns);
  const [isTableDataLoading, setIsTableDataLoading] = useState(0);
  const [period, setPeriod] = useState([null, null]);
  const [type, setType] = useState("");
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    if (actualTab === 1 && typeOptions.length === 0) {
      getNotificationTypeList(auth.userData.access_token).then(res => setTypeOptions(res.data));
    }
  }, [actualTab]);

  const getTableData = useCallback((query) => {
    setIsTableDataLoading(l => l + 1);
    if (!!customerContext.selectedCustomer.id) {
      return new Promise((resolve) => {
        const active = actualTab === 0;
        getNotificationListPagination(auth.userData.access_token, query, customerContext.selectedCustomer.id, active, type, period)
          .then(res => {
            resolve({
              data: res.data.items,
              page: query.page,
              totalCount: res.data.sum
            });
          })
          .catch(ignoreCancellation)
          .catch(error => showRequestError(error, languageContext.dictionary.errorMessages.loadNotificationListError))
          .finally(() => setIsTableDataLoading(l => l - 1));
      });
    }
  }, [auth.userData, actualTab, type, period, customerContext.selectedCustomer.id]);

  const handleRowClick = () => {
    // todo unread -> read & link to new page - notificationId + notificationType
  };

  const handleTabChange = (newValue) => {
    setActualTab(newValue);
    setType("");
    setPeriod([null, null]);
  };

  return (
    <Container maxWidth="lg" seleniumid={"notificationsPage"}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} style={{paddingBottom: 20}}>
          <Typography variant="h3" color="secondary" className={classes.header}>
            {languageContext.dictionary.notifications}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={actualTab}
              onChange={(e, newValue) => handleTabChange(newValue)}
              variant="fullWidth"
              scrollButtons="auto"
              indicatorColor="primary"
            >
              <Tab label={languageContext.dictionary.actualNotifications} key={"tab_0"} seleniumid={"actualNotificationsTab"}/>
              <Tab label={languageContext.dictionary.expiredNotifications} key={"tab_1"} seleniumid={"expiredNotificationsTab"}/>
            </Tabs>
          </AppBar>
          <SimpleTabPanel value={actualTab} index={0}>
            <DataTable
              seleniumid={"actualNotificationsTable"}
              data={getTableData}
              columns={columns}
              options={{
                filter: false,
                sort: false,
                download: false,
                onRowClick: handleRowClick
              }}
              isLoading={isTableDataLoading > 0}
            />
          </SimpleTabPanel>
          <SimpleTabPanel value={actualTab} index={1}>
            <Paper style={{paddingTop: 10}} elevation={4}>
              <Grid container spacing={2} alignItems="center" style={{padding: 10}} seleniumid={"expiredNotificationsFilters"}>
                <Grid item xs={12} sm={2}>
                  <Typography variant={"body2"}>{languageContext.dictionary.period}</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <CustomDateRangePicker
                    value={period}
                    onChange={date => setPeriod(date)}
                    helperText={false}
                    size={"small"}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant={"body2"}>{languageContext.dictionary.type}</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <CustomSelect
                    value={type}
                    handleChange={e => setType(e.target.value)}
                    parameters={{name: "type", label: languageContext.dictionary.type, type: fieldTypes.SELECT, options: typeOptions, canBeNull: true}}
                    notSelectedLabel={languageContext.dictionary.all}
                    size={"small"}
                  />
                </Grid>
              </Grid>
              <DataTable
                seleniumid={"expiredNotificationsTable"}
                data={getTableData}
                columns={columns}
                options={{
                  filter: false,
                  sort: false,
                  download: false,
                  onRowClick: handleRowClick
                }}
                isLoading={isTableDataLoading > 0}
              />
            </Paper>
          </SimpleTabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotificationsPage;
