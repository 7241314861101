export const formatDataSize = (dataSize, originalFormat, isBandwidth = true) => {
  const units = isBandwidth
    ? ["kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps", "Zbps", "Ybps"]
    : ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  if (!isBandwidth) {
    dataSize /= 8;
  }
  let i = units.indexOf(originalFormat);
  do {
    dataSize = dataSize / 1024;
    i++;
  } while (dataSize > 1024);

  return Math.max(dataSize, 0.1).toFixed(1) + " " + units[i];
};

export const formatDataSizeByUnit = (value, unit) => {
  let i = -1;
  switch (unit) {
    case "bps":
      const byteUnits = [" kbps", " Mbps", " Gbps", " Tbps", " Pbps", " Ebps", " Zbps", " Ybps"];
      do {
        value = value / 1024;
        i++;
      } while (value > 1024);
      return Math.max(value, 0.1).toFixed(1) + byteUnits[i];
    case "bit":
      const bitUnits = [" KB", " MB", " GB", " TB", " PB", " EB", " ZB", " YB"];
      value = value / 8;
      do {
        value = value / 1024;
        i++;
      } while (value > 1024);
      return Math.max(value, 0.1).toFixed(1) + bitUnits[i];
    default:
      return Math.max(value, 0.1).toFixed(1) + " " + unit;
  }
};

export const formatCurrency = (amount, currency, languageContext) => {
  if (currency === "HUF") {
    currency = languageContext?.dictionary?.huf || "HUF";
  }
  return `${Number(amount).toLocaleString(languageContext?.language?.id || 'hu')} ${currency}`;
};
