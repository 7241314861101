import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_HU} from "material-react-table/locales/hu";
import {MRT_Localization_EN} from "material-react-table/locales/en";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {formatDate} from "../../utils/dateFormat";
import {getServiceStatusList} from "../../api/serviceResource";
import {useAuth} from "oidc-react";
import {CustomerContext} from "../../context/CustomerContext";

const EstablishmentsTable = ({tableData}) => {
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const customerContext = useContext(CustomerContext);
  const [statusList, setStatusList] = useState([]);


  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      getServiceStatusList(auth.userData.id_token, "IMPLEMENT")
        .then(res => {
          setStatusList(res.data);
        });
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);


  useEffect(() => {
    let status = tableData.map(item => {
      return item.serviceStatusDescription;
    });
    status = [...new Set(status)];
    setStatusList(status);
  }, [tableData]);


  const columns = [
    {
      accessorKey: 'serviceCodeGroup',
      header: langContext.dictionary.nameSynonym
    },
    {
      accessorKey: 'contractId',
      header: langContext.dictionary.contractNumber,
      Cell: ({renderedCellValue, cell, row}) => <Link
        to={`/contractDetails/${row?.original.businessCase}`}
        style={{textDecoration: 'none'}}
        onClick={e => e.stopPropagation()}
      >
        <Button
          variant="text"
          size="small"
          color="secondary"
          disableRipple
          style={{fontSize: 14}}
        >
          {renderedCellValue}
        </Button>
      </Link>
    },
    {
      accessorKey: 'serviceId',
      header: langContext.dictionary.technicalId,
      Cell: ({renderedCellValue, cell, row}) => <Link
        to={`/serviceDetails/${row?.original.poId}`}
        style={{textDecoration: 'none'}}
        onClick={e => e.stopPropagation()}
      >
        <Button
          variant="text"
          size="small"
          color="secondary"
          disableRipple
          style={{fontSize: 14}}
        >
          {renderedCellValue}
        </Button>
      </Link>
    },
    {
      accessorKey: 'expectedConstructionDate',
      header: langContext.dictionary.expectedConstruction,
      Cell: ({renderedCellValue}) =>
        <span>
          {formatDate(renderedCellValue)}
        </span>
    },
    {
      accessorKey: 'serviceStatus',
      header: langContext.dictionary.status,
      filterVariant: 'select',
      filterFn: (row, columnId, filterValue) => row.original.serviceStatus === filterValue,
      filterSelectOptions: statusList,
      Cell: ({row}) => (
        <span>{row.original.serviceStatusDescription}</span>
      ),
    },
    {
      accessorKey: 'address',
      header: langContext.dictionary.endpointAddress,
      Cell: ({renderedCellValue, cell, row}) =>
        <span>
          {renderedCellValue}
        </span>
    },
  ];

  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      localization={langContext?.language?.id === 'hu' ? MRT_Localization_HU : MRT_Localization_EN}
      enableRowSelection={false}
      enableMultiRowSelection={false}
      positionToolbarAlertBanner={"none"}
      muiTableBodyProps={{
        sx: () => ({
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#EEEEEE'
          }
        })
      }} muiTableBodyCellProps={{
      sx: {border: 'none'}
    }}
    />
  );
};

EstablishmentsTable.propTypes = {
  tableData: PropTypes.array
};

export default EstablishmentsTable;
