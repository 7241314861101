import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {CircularProgress, FormControl, Paper, TextField, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import UserEditor from "../user/UserEditor";
import Button from "@mui/material/Button";
import ErrorTicketDialog from "../dialog/ErrorTicketDialog";
import CustomSelect from "../formFields/CustomSelect";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {useTheme} from "@mui/styles";
import {CustomerContext} from "../../context/CustomerContext";
import {getErrorTicketContactList, getErrorTicketMessage, getErrorTicketServiceList, getErrorTicketShortDescription} from "../../api/errorTicketResource";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useAuth} from "oidc-react";
import ErrorTicketTable from "./ErrorTicketTable";
import {fullNameByLanguage, handleBase64Formatting, validateFile, contactHasRight} from "../../utils/commonFunctions";
import CustomTooltip from "./CustomTooltip";
import CreateIcon from '@mui/icons-material/Create';
import Tooltip from "@mui/material/Tooltip";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {UserContext} from "../../context/UserContext";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const defaultContact = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  phoneNumber: "",
  position: ""
};

const ErrorTicketForm = () => {
  const langContext = useContext(LanguageContext);
  const newContactOption = {label: langContext.dictionary.newContact, value: "new"};
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedInnerUser, setSelectedInnerUser] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [serviceListDataGrid, setServiceListDataGrid] = useState([]);
  const [contactSelectOptions, setContactSelectOptions] = useState([]);
  const [contactData, setContactData] = useState(defaultContact);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const [errorDescription, setErrorDescription] = useState("");
  const [entryInfo, setEntryInfo] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorTicketDialogOpen, setErrorTicketDialogOpen] = useState(false);
  const [contactList, setContactList] = useState({});
  const [files, setFiles] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [serviceShortDescOption, setServiceShortDescOption] = useState([]);
  const [selectedServiceShortDesc, setSelectedServiceShortDesc] = useState("");
  const [serviceDataGridError, setServiceDataGridError] = useState(false);
  const [contactFieldReadOnly, setContactFieldReadOnly] = useState(false);
  const [hasAdminRole, setHasAdminRole] = useState(null);
  const [errorTicketSent, setErrorTicketSent] = useState(false);
  const [serviceShortDescOptionLoading, setServiceShortDescOptionLoading] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [disableFormSubmit, setDisableFormSubmit] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const allowedTypes = ["csv", "pdf", "png", "jpg", "jpeg", "svg", "doc", "docx", "txt", "webp", "xls", "xlsx", "xml", "msg"];

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tooltipStatus, setTooltipStatus] = useState(false);

  useEffect(() => {
    if (!tableLoading && customerContext.selectedContact.poid && customerContext.selectedContact?.roles?.length > 0) {
      setHasAdminRole(customerContext.selectedContact.roles.includes("ADMIN"));
    }
  }, [tableLoading, customerContext.selectedContact?.poid, customerContext.selectedContact?.roles, hasAdminRole]);


  useEffect(() => {
    if (selectedContact !== "new" && selectedContact !== "") {
      setContactData(contactList[selectedContact]);
      setContactFieldReadOnly(true);
    } else {
      setContactFieldReadOnly(false);
      setContactData(defaultContact);
    }
  }, [selectedContact]);

  useEffect(() => {
    if (selectedInnerUser) {
      setEditDialogOpen(true);
    }
  }, [selectedInnerUser]);

  useEffect(() => {
    setTableLoading(true);
    if (auth.userData && customerContext.selectedCustomer.id) {
      getErrorTicketContactList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => {
          //Erre azért van szükség, mert az apiból érkező null értékek bezavarnak az input mezők módosításába!!
          let contactListWithoutNull = [];
          res.data.forEach((item) => {
            Object.keys(item).forEach(key => {
              if (item[key] === null) {
                item[key] = "";
              }
            });
            contactListWithoutNull.push(item);
          });
          setContactList(contactListWithoutNull);
          let contactSelectOptions = contactListWithoutNull.map((contact, index) => {
            return {
              label: `${contact.lastName} ${contact.firstName}`,
              value: index.toString(),
            };
          });
          contactSelectOptions.unshift(newContactOption);
          setContactSelectOptions(contactSelectOptions);
        })
        .finally(() => setTableLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, reloadData]);

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setLoading(true);
      getErrorTicketServiceList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => {
          setServiceList(res.data);
          setServiceListDataGrid(res.data.map((service, index) => {
            return {
              id: index,
              poId: service.poId,
              serviceId: service.serviceId,
              contractId: service.contractId,
              serviceCodeGroup: service.serviceCodeGroup,
              address: service.address,
              status: service.serviceStatusDescription,
              businessCase: service.businessCase
            };
          }));
        })
        .finally(() => setLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  useEffect(() => {
    setSelectedServiceShortDesc("");
    setServiceShortDescOptionLoading(true);
    if (auth.userData && !!customerContext.selectedCustomer.id && selectedService != null) {
      getErrorTicketShortDescription(auth.userData.access_token, customerContext.selectedCustomer.id, selectedService.serviceId)
        .then(res => {
          let serviceSelectOptions = res.data.map((service, index) => {
            return {
              label: service === "x_Egyéb_x" ? langContext.dictionary.other : service,
              value: service?.toString()
            };
          });
          setServiceShortDescOption(serviceSelectOptions);
          setServiceShortDescOptionLoading(false);
        });
    }
  }, [selectedService]);

  useEffect(() => {
    setTooltipMessage(null);
    if (auth.userData && selectedService) {
      const regex = /\(([^)]+)\)/; //Szöveg kiszedése a zárójelek közül
      const serviceType = regex.exec(selectedService.category)[1];
      getErrorTicketMessage(auth.userData.access_token, serviceType.toLowerCase())
        .then(res => setTooltipMessage(res.data))
        .catch(error => showRequestError(error, langContext.dictionary.errorMessages.loadingError));
    }
  }, [selectedService]);

  useEffect(() => {
    if (selectedService !== null && serviceDataGridError === true) {
      setServiceDataGridError(false);
    }
  }, [selectedService]);

  useEffect(() => {
    if (errorTicketSent) {
      setSelectedService(null);
      setSelectedServiceShortDesc("");
      setSelectedContact("");
      setContactData(defaultContact);
      setErrorTicketSent(false);
      setErrorDescription("");
    }
  }, [errorTicketSent]);

  const onItemEdited = (item) => {
    setContactData({
      firstName: item.firstName?.value,
      lastName: item.lastName?.value,
      email: item.emails[0]?.value,
      phoneNumber: item.phoneNumbers[0]?.value,
      position: item.positionCode?.value
    });
    setReloadData(true);
    setEditDialogOpen(false);
  };

  const handleChangeSelect = (event, setData) => {
    event.preventDefault();
    setData(event.target.value);
  };

  const handleFieldChange = (field, newValue, setData) => {
    setData(curr => ({...curr, [field]: newValue}));
  };
  const handleFileChange = async (e) => {
    setFiles([]);
    setDisableFormSubmit(false);
    const fileArray = Object.values(e.target.files).map(item => item);
    let attachmentDataList = await handleBase64Formatting(fileArray);
    attachmentDataList = attachmentDataList.map(item => {
      const validationError = validateFile(item, langContext, allowedTypes);
      setDisableFormSubmit(!Object.is(validationError, null));
      return {name: item.title, data: item.data, error: validationError};
    });
    setFiles(attachmentDataList);
  };

  const collectDialogData = async () => {
    let attachmentList = files.filter(file => {
      if (!file.error) {
        delete file.error;
        return file;
      }
    });
    setDialogData({
      contractingCompany: customerContext.selectedCustomer.name,
      serviceId: selectedService.serviceId,
      contractId: selectedService.contractId,
      serviceAddress: selectedService.address,
      onSiteContactPhoneNumber: contactData.phoneNumber,
      onSiteContactEmail: contactData.email,
      errorTicketSender: fullNameByLanguage(langContext.language.id, customerContext.selectedContact.firstName, customerContext.selectedContact.lastName),
      shortDescription: selectedServiceShortDesc,
      longDescription: errorDescription,
      onSiteContactName: fullNameByLanguage(langContext.language.id, contactData.firstName, contactData.lastName),
      attachmentDataList: attachmentList,
      entryInfo: entryInfo
    });
  };

  const nextButtonOnClick = (e) => {
    e.preventDefault();
    setServiceDataGridError(!selectedService);
    collectDialogData();
    setErrorTicketDialogOpen(true);
  };

  useEffect(() => {
    if (selectedServiceId) {
      setSelectedService(serviceList.find(item => item.serviceId === selectedServiceId));
    }
  }, [selectedServiceId]);


  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <>
        {!loading ? (<Paper elevation={4} sx={{width: "100%", marginTop: 3, paddingTop: 2, paddingBottom: 2}}>
          <Grid item style={{paddingRight: 25, paddingLeft: 25}}>
            <Typography variant={"h4"}>{langContext.dictionary.errorDetails}</Typography>
            <Divider/>
            <Typography color="secondary" style={{paddingTop: 20}}>{langContext.dictionary.errorTicketListTitle}</Typography>
          </Grid>
          <form onSubmit={nextButtonOnClick}>
            <Grid container direction="row" justifyContent="center" alignItems="center"
                  style={{paddingRight: 50, paddingLeft: 50, marginTop: 15, marginBottom: 15}} rowSpacing={2}>
              <Grid item xs={12}>
                <ErrorTicketTable
                  tableData={serviceListDataGrid}
                  tableLoading={tableLoading}
                  getRowId={selectedServiceId}
                  setRowId={setSelectedServiceId}
                  errorTicketSent={errorTicketSent}
                />
              </Grid>

              <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                <Typography>{langContext.dictionary.serviceError}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} style={{textAlign: "end"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <CustomSelect
                  parameters={{name: "ServiceError", label: langContext.dictionary.serviceError, options: serviceShortDescOption}}
                  disabled={serviceShortDescOptionLoading}
                  required={serviceShortDescOption?.length > 0}
                  disableMinWidth
                  fullWidth
                  size="small"
                  value={selectedServiceShortDesc}
                  handleChange={e => handleChangeSelect(e, setSelectedServiceShortDesc)}
                />
              </Grid>

              <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                <Typography style={{float: "left", paddingTop: 7}}>{langContext.dictionary.onSiteContact}</Typography>
                {selectedContact !== "new" && hasAdminRole && selectedContact !== "" ?
                  (<Tooltip title={langContext.dictionary.dataChangeUser} arrow placement={"right"}>
                    <IconButton aria-label="delete" onClick={() => setEditDialogOpen(true)}>
                      <CreateIcon color="secondary" fontSize="inherit"/>
                    </IconButton>
                  </Tooltip>) : null}
              </Grid>
              <Grid item xs={12} sm={6} style={{textAlign: "end"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <CustomSelect
                  required
                  disableMinWidth
                  fullWidth
                  size="small"
                  value={selectedContact}
                  handleChange={e => handleChangeSelect(e, setSelectedContact)}
                  parameters={{name: "SelectContact", label: langContext.dictionary.onSiteContact, options: contactSelectOptions, canBeNull: true}}
                />
              </Grid>

              {selectedContact === "new" && hasAdminRole ? (<>
                <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                  <Typography>{langContext.dictionary.colleagueQuestion}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{textAlign: "center"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                  <FormControl style={{width: "100%"}}>
                    <RadioGroup
                      row
                      name="colleagueQuestion_radio"
                      value={selectedInnerUser}
                    >
                      <FormControlLabel
                        sx={{margin: "auto"}}
                        value={true}
                        onChange={() => setSelectedInnerUser(true)}
                        control={<Radio required color="secondary"/>}
                        label={langContext.dictionary.yes}/>
                      <FormControlLabel
                        sx={{margin: "auto"}}
                        value={false}
                        onChange={() => setSelectedInnerUser(false)}
                        control={<Radio required color="secondary"/>}
                        label={langContext.dictionary.no}/>
                    </RadioGroup>
                  </FormControl>
                </Grid></>) : null}


              <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                <Typography>{langContext.dictionary.onSiteContactName}</Typography>
              </Grid>
              <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} sm={6} style={{textAlign: "end"}} rowSpacing={2}
                    sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <Grid item xs={12} sm={6}>
                  <TextField id={`lastName_text`}
                             value={contactData.lastName}
                             required
                             disabled={contactFieldReadOnly}
                             fullWidth
                             label={langContext.dictionary.lastName}
                             size="small"
                             variant="outlined"
                             onChange={e => handleFieldChange("lastName", e.target.value, setContactData)}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id={`firstName_text`}
                             value={contactData.firstName}
                             required
                             disabled={contactFieldReadOnly}
                             fullWidth
                             label={langContext.dictionary.firstName}
                             size="small"
                             variant="outlined"
                             onChange={e => handleFieldChange("firstName", e.target.value, setContactData)}/>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                <Typography>{langContext.dictionary.onSiteContactPhoneNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} style={{textAlign: "end"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <TextField required
                           id={`phoneNumber`}
                           value={contactData.phoneNumber}
                           disabled={contactFieldReadOnly}
                           fullWidth
                           label={langContext.dictionary.onSiteContactPhoneNumber}
                           size="small"
                           variant="outlined"
                           onChange={e => handleFieldChange("phoneNumber", e.target.value, setContactData)}
                />
              </Grid>

              <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                <Typography>{langContext.dictionary.onSiteContactEmail}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} style={{textAlign: "end"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <TextField required
                           id={`email`}
                           value={contactData.email}
                           disabled={contactFieldReadOnly}
                           fullWidth
                           label={langContext.dictionary.onSiteContactEmail}
                           size="small"
                           variant="outlined"
                           onChange={e => handleFieldChange("email", e.target.value, setContactData)}/>
              </Grid>

              <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                <Typography>{langContext.dictionary.position}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} style={{textAlign: "end"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <TextField
                  required
                  id={`position`}
                  disabled={contactFieldReadOnly}
                  value={contactData.position}
                  fullWidth
                  label={langContext.dictionary.position}
                  size="small"
                  variant="outlined"
                  onChange={e => handleFieldChange("position", e.target.value, setContactData)}/>
              </Grid>

              <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                <Typography>{langContext.dictionary.entryInfo}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} style={{textAlign: "end"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <TextField
                  id={`entryInfo`}
                  value={entryInfo}
                  multiline
                  fullWidth
                  label={langContext.dictionary.entryInfo}
                  size="small"
                  variant="outlined"
                  onChange={e => handleChangeSelect(e, setEntryInfo)}/>
              </Grid>

              <Grid item xs={12} sm={6} sx={{height: {sm: `${8 * 25}px`, sx: "auto"}}} style={{textAlign: "start"}}>
                <Typography style={{float: "left", paddingTop: 2}}>{langContext.dictionary.errorTicketDetails}</Typography>
                <CustomTooltip title={tooltipMessage ? (langContext.language.id === "hu" ? tooltipMessage.valueHu : tooltipMessage.valueEn) : langContext.dictionary.errorTooltipMessage}
                               placement={"bottom"}
                               open={tooltipStatus}
                               style={{padding: 0, marginLeft: 2, maxWidth: 480}}>
                  <IconButton onClick={() => setTooltipStatus(!tooltipStatus)}>
                    <InfoIcon/>
                  </IconButton>
                </CustomTooltip>
              </Grid>
              <Grid item xs={12} sm={6} style={{textAlign: "end"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <TextField
                  required
                  id={`errorDescription_text`}
                  value={errorDescription}
                  fullWidth
                  label={langContext.dictionary.errorTicketDetails}
                  size="small"
                  variant="outlined"
                  multiline
                  rows={8}
                  onChange={e => handleChangeSelect(e, setErrorDescription)}
                  onClick={() => setTooltipStatus(!mobile)}
                  onBlur={() => setTooltipStatus(false)}
                />
              </Grid>

              <Grid item xs={12} sm={6} style={{textAlign: "start"}}>
                <Typography sx={{float: "left"}}>{langContext.dictionary.attachFiles}</Typography>
                <CustomTooltip title={langContext.dictionary.fileUploadTooltip + allowedTypes.toString()}
                               placement={"right-end"}
                               style={{padding: 0, marginLeft: 2}}>
                  <IconButton>
                    <InfoIcon/>
                  </IconButton>
                </CustomTooltip>
                <ul>
                  {files.map((file, i) => (
                    <li style={{color: file.error ? "red" : null, whiteSpace: "pre-wrap"}} key={i}>
                      {file.error ? file.name + `\n` + file.error : file.name}
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} style={{textAlign: "end"}} sx={{paddingLeft: {xs: "10px", sm: "100px"}}}>
                <Button component="label" color="secondary" startIcon={<UploadFileIcon/>}>
                  {langContext.dictionary.uploadFiles}
                  <input
                    type="file"
                    style={{zIndex: -10, height: 1, width: 1}}
                    multiple
                    accept="image/*,text/plain,.msg,.pdf,.csv,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>

              <Grid item xs={12} textAlign="end">
                {contactHasRight(customerContext.selectedContact.roles, adminFinTechRight) ?
                  (
                    <Tooltip title={userContext?.userInfo?.innerUser ? langContext.dictionary.disabledImpersonate : "" || disableFormSubmit ? langContext.dictionary.fileError : ""}>
                      <span>
                        <Button
                          disabled={userContext?.userInfo?.innerUser || disableFormSubmit}
                          type="submit"
                          color="secondary"
                          variant="contained">{langContext.dictionary.next}</Button>
                      </span>
                    </Tooltip>) : null}
              </Grid>
            </Grid>
          </form>
        </Paper>) : (<Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center", paddingTop: 15}}>
          <CircularProgress color="secondary"/>
        </Grid>)}
        <UserEditor
          loading={loading} user={contactData} open={editDialogOpen}
          onEditCancel={() => {
            setSelectedContact("");
            setSelectedInnerUser(null);
            setEditDialogOpen(false);
          }}
          onItemEdited={onItemEdited}
        />
        <ErrorTicketDialog
          data={dialogData}
          title={langContext.dictionary.summary}
          handleClose={() => {
            setErrorTicketDialogOpen(false);
          }}
          setErrorTicketSent={setErrorTicketSent}
          open={errorTicketDialogOpen}
        />
      </>
    </RenderedWithPermission>
  );
};

export default ErrorTicketForm;
