import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import {truncate} from "../../utils/commonFunctions";

const VitrinNameTruncateSpan = ({shNameList}) => {
  return (
    <Tooltip
      arrow
      title={shNameList?.length > 3 ? shNameList?.filter(Boolean).join(", ") : ""}
      placement="right"
      componentsProps={{tooltip: {sx: {maxWidth: 500}}}}
    >
      <span>
        {truncate(shNameList || [], 4)}
      </span>
    </Tooltip>
  )
};

VitrinNameTruncateSpan.propTypes = {
  shNameList: PropTypes.array.isRequired
};

export default VitrinNameTruncateSpan;
