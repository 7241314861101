import axios from "axios";
import {env} from "../properties";
import moment from "moment";

const resource = "/account";

export const getBillListPagination = async (token, query, cmId, filters) => {
  let url = `${env.apiRoot}${resource}/${cmId}/billList`;
  const authStr = "Bearer ".concat(token);
  return await axios.post(url, {
    pageIndex: query?.page || 0,
    pageSize: query?.pageSize || 10,
    invoiceNumber: filters.billNumber,
    dueDateFrom: filters.paymentDeadline[0] ? moment(new Date(filters.paymentDeadline[0])).valueOf() : null,
    dueDateTo: filters.paymentDeadline[1] ? moment(new Date(filters.paymentDeadline[1])).valueOf() : null,
    billingDateFrom: filters.billingDate[0] ? moment(new Date(filters.billingDate[0])).valueOf() : null,
    billingDateTo: filters.billingDate[1] ? moment(new Date(filters.billingDate[1])).valueOf() : null,
    totalAmountFrom: parseInt(filters.amountFrom),
    totalAmountTo: parseInt(filters.amountTo)
  }, {headers: {Authorization: authStr}});
};

export const getCollectorList = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/collectorList`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const getBalance = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/balance`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const getCollectorDetails = async (token, cmId, collectorId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/collectorDetails/${collectorId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const getCollectorDetailsBillList = async (token, cmId, collectorId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/collectorDetails/${collectorId}/billList`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const getInvoiceFile = async (token, bucket, fileName) => {
  let url = `${env.apiRoot}${resource}/invoice/${bucket}/${btoa(fileName)}`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}, responseType: 'blob'});
};

export const getEBill = async (token, collectorId) => {
  let url = `${env.apiRoot}${resource}/ebill/${collectorId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const saveEBill = async (token, collectorId, eBillData) => {
  let url = `${env.apiRoot}${resource}/ebill/${collectorId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.post(url, eBillData, {headers: {Authorization: authStr}});
};