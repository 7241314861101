import React, {useContext, useMemo} from "react";
import PropTypes from "prop-types";
import Rendered from "./Rendered";
import {CustomerContext} from "../context/CustomerContext";
import {contactHasRight} from "../utils/commonFunctions";

const RenderedWithPermission = (props) => {
  const customerContext = useContext(CustomerContext);

  const shouldRender = useMemo(() => {
    const roles = typeof props.roles === "string" ? [props.roles] : props.roles;
    return contactHasRight(customerContext.selectedContact.roles, roles);
  }, [customerContext, props.instance, props.roles]);

  return <Rendered rendered={shouldRender} displayNoRightsLabel={props.displayNoRightsLabel}>
    {props.children}
  </Rendered>;
};

RenderedWithPermission.defaultProps = {
  displayNoRightsLabel: false
};

RenderedWithPermission.propTypes = {
  roles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  instance: PropTypes.string,
  displayNoRightsLabel: PropTypes.bool
};

export default RenderedWithPermission;
