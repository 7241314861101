import * as React from 'react';
import PropTypes from "prop-types";

export default function CellBodyRender({ column, value, tableMeta, data }) {
  const currentIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;

  if (column.render) {
    let rendered = column.render(value, data[currentIndex], data);
    if (rendered === null || rendered === undefined) {
      return "";
    }
    return rendered;
  }

  return <span>{value}</span>;
}

CellBodyRender.propTypes = {
  column: PropTypes.object.isRequired,
  value: PropTypes.any, //can be null
  tableMeta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
};