import React, {useContext, useEffect, useState} from "react";
import {Grid, ToggleButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles} from "@mui/styles";
import PublicIcon from '@mui/icons-material/Public';
import StorageIcon from '@mui/icons-material/Storage';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import SecurityIcon from '@mui/icons-material/Security';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PropTypes from "prop-types";


const useStyles = makeStyles(() => ({
  buttonStyle: {
    maxWidth: "160px",
    width: "150px",
    height: "165px",
    maxHeight: "165px"
  },
}));

const ServiceFilterButton = ({data, serviceCount, handleDisplayableCategories}) => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const [selected, setSelected] = useState(false);
  const buttonData =
    {
      internet: {text: languageContext.dictionary.serviceCategories.internet, icon: <PublicIcon fontSize={"large"}/>},
      dataCenter: {text: languageContext.dictionary.serviceCategories.dataCenter, icon: <StorageIcon fontSize={"large"}/>},
      other: {text: languageContext.dictionary.serviceCategories.other, icon: <MiscellaneousServicesIcon fontSize={"large"}/>},
      cloud: {text: languageContext.dictionary.serviceCategories.cloud, icon: <CloudQueueIcon fontSize={"large"}/>},
      office: {text: languageContext.dictionary.serviceCategories.office, icon: <BusinessIcon fontSize={"large"}/>},
      security: {text: languageContext.dictionary.serviceCategories.security, icon: <SecurityIcon fontSize={"large"}/>},
      communication: {text: languageContext.dictionary.serviceCategories.communication, icon: <PhoneIcon fontSize={"large"}/>}
    };

  useEffect(() => {
    handleDisplayableCategories(data, selected);
  }, [selected]);

  return (
    <ToggleButton
      value=""
      color={"secondary"}
      className={classes.buttonStyle}
      variant={"outlined"}
      selected={selected}
      onChange={() => {
        setSelected(!selected);
      }}>
      <Grid container sx={{height: "100%"}} direction="row" alignContent="space-between" alignItems="center">
        <Grid container item xs={12}>
          <Grid item xs={12}>
            {buttonData[data].icon}
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} fontSize={13}>{buttonData[data].text}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"caption"}>
            {serviceCount === 0 ? languageContext.dictionary.noService : serviceCount + " " + languageContext.dictionary.serviceSynonym}
          </Typography>
        </Grid>
      </Grid>
    </ToggleButton>
  );
};

ServiceFilterButton.propTypes = {
  data: PropTypes.string,
  serviceCount: PropTypes.number,
  handleDisplayableCategories: PropTypes.func
};
export default ServiceFilterButton;