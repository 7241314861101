import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {Container, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {CustomerContext} from "../../context/CustomerContext";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import ContactForm from "./ContactForm";
import Button from "@mui/material/Button";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import PropTypes from "prop-types";
import {useAuth} from "oidc-react";
import {fullNameByLanguage, removeDuplicatesFromObjectArray} from "../../utils/commonFunctions";
import {getColositeService} from "../../api/colositeResource";

const ServerRoomPersonalDataForm = ({dataList, setDataList, ...props}) => {
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const defaultDocumentTypes = [
    {label: languageContext.dictionary.passport, value: 1, placeholder: "AB1234567"},
    {label: languageContext.dictionary.idCard, value: 2, placeholder: "123456AB"},
    {label: languageContext.dictionary.drivingLicense, value: 3, placeholder: "AB123456"}
  ];
  const [selectOptions, setSelectOptions] = useState({contacts: [], documentTypes: defaultDocumentTypes});
  const [selectOptionsLoading, setSelectOptionsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      getColositeService(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => {
          let allUser = [];
          res.data.forEach(item => {
            item.staff.forEach(user => {
              allUser.push({
                email: user.email,
                firstName: user.firstName,
                label: fullNameByLanguage(languageContext.language.id, user.firstName, user.lastName),
                lastName: user.lastName,
                mobile: user.mobile,
                poid: user.id,
                value: user.email,
                serviceId: item.serviceId
              });
            });
          });
          setAllUsers(allUser);
        })
        .catch(error => showRequestError(error, languageContext.dictionary.errorMessages.contactListLoadError))
        .finally(() => setSelectOptionsLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.language.id]);

  const handleAddNewPerson = () => {
    setDataList(c => c.concat([{}]));
  };

  useEffect(() => {
    checkMembers(props.enterDetails.affectedServices);
  }, [props.enterDetails]);

  const checkMembers = (affectedServices) => {
    const selectedUsers = allUsers.filter(u => affectedServices.some(a => a.serviceId === u.serviceId));
    let uniqueUsers = removeDuplicatesFromObjectArray(selectedUsers, "poid");
    setSelectOptions(curr => ({...curr, contacts: uniqueUsers}));
  };

  return (
    <Paper elevation={4} sx={{width: "100%", p: 3, mt: 2}} seleniumid={"ServerRoomPersonalDataForm"}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sx={{mb: 1}}>
          <Typography variant={"h5"}>{languageContext.dictionary.personalData}</Typography>
        </Grid>
        {dataList.map((data, index) => (
          <ContactForm
            data={data}
            index={index}
            setDataList={setDataList}
            selectOptions={selectOptions}
            selectOptionsLoading={selectOptionsLoading}
            key={index}
            disabled={!props.enterDetails.serverRoom}
            setSelectOptions={setSelectOptions}
          />
        ))}
        <Grid item xs={12}>
          <Container sx={{textAlign: "center"}}>
            <Button color="secondary" startIcon={<AddBoxOutlinedIcon/>} onClick={handleAddNewPerson} disabled={!props.enterDetails.serverRoom}>
              {languageContext.dictionary.addAnotherEntryPerson}
            </Button>
          </Container>
        </Grid>
      </Grid>
    </Paper>
  );
};

ServerRoomPersonalDataForm.propTypes = {
  dataList: PropTypes.array.isRequired,
  setDataList: PropTypes.func.isRequired,
  enterDetails: PropTypes.object.isRequired
};

export default ServerRoomPersonalDataForm;
