import React, {useContext} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {env} from "../../properties";
import Hidden from "@mui/material/Hidden";
import logo from "../../assets/images/invitech_logo.svg";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import VerticalMenuList from "./VerticalMenuList";
import HorizontalUserBar from "./HorizontalUserBar";
import GenericMenuItem from "../menu/GenericMenuItem";
import {CustomerContext} from "../../context/CustomerContext";
import {contactHasRight} from "../../utils/commonFunctions";

const drawerWidth = 240;
const minHeight = 64;

const useStyles = makeStyles(theme => ({
  root: {
    // display: "flex"
  },
  logo: {
    maxHeight: theme.spacing(5)
  },
  content: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  drawer: {
    [theme.breakpoints.down('lg')]: {
      flexShrink: 0
    },
    [theme.breakpoints.up("md")]: {
      flexGrow: 1
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  title: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  homeLink: {
    marginRight: theme.spacing(3)
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  list: {
    display: "flex",
    justifyContent: "flex-end"
  },
  listItem: {
    position: "relative",
    display: "inline-flex",
    width: "auto",
    color: theme.palette.primary.main,
    minHeight: minHeight,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& > div > span": {
      fontWeight: "bold",
      textTransform: "uppercase"
    },
    "&::after": {
      content: '""',
      display: "block",
      borderBottom: `solid 2px ${theme.palette.primary.contrastText}`,
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      transform: "scaleX(0)",
      transition: "transform 200ms ease-in-out"
    },
    "&:hover": {
      "&::after": {
        transform: "scaleX(1)"
      }
    }
  },
  listItemIcon: {
    color: "inherit",
    minWidth: 32
  },
  icon: {
    marginRight: theme.spacing(0.5)
  },
  button: {
    margin: `0 ${theme.spacing(0.5)}`
  },
  buttonGroup: {
    margin: `0 ${theme.spacing(0.5)}`,
    [theme.breakpoints.down('xl')]: {display: "none"}
  },
  collapse: {
    display: "inline-flex",
    position: "absolute",
    // top: 0,
    left: 0,
    // width: "100%",
    marginTop: minHeight + 1
  },
  collapseMenu: {
    backgroundColor: theme.palette.primary.light,
    width: "100%"
  }
}));

const HorizontalNavigation = ({content, withIcon, ...props}) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [subMenuOpen, setSubMenuOpen] = React.useState({});
  const customerContext = useContext(CustomerContext);

  const handleSubMenuOpen = name => event => {
    event.preventDefault();
    const newSubMenuOpen = {...subMenuOpen};
    if (newSubMenuOpen[name]) {
      newSubMenuOpen[name] = !newSubMenuOpen[name];
    } else {
      newSubMenuOpen[name] = true;
    }
    setSubMenuOpen(newSubMenuOpen);
  };

  const closeSubMenus = () => {
    setSubMenuOpen({});
  };

  const handleDrawerToggle = () => {
    setMobileOpen(open => !open);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img src={logo} alt="invitech" className={classes.logo}/>
      </div>
      <Divider/>
      <VerticalMenuList
        handleSubMenuOpen={handleSubMenuOpen}
        subMenuOpen={subMenuOpen}
      />
    </div>
  );
  return (
    <div className={classes.root}>
      <AppBar position="sticky" color={"primaryReverse"}>
        <HorizontalUserBar/>
        <Toolbar variant="dense">
          <IconButton
            data-testid="mobileMenuIcon"
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            size="large">
            <MenuIcon/>
          </IconButton>
          <img src={logo} alt="invitech" className={classes.logo}/>
          <nav className={classes.drawer}>
            {/* --- DESKTOP NAV --- */}
            <Hidden mdDown implementation="css">
              <div style={{display: "flex", justifyContent: "flex-end"}}>
                <List className={classes.list} dense disablePadding>
                  {env.menuItems.map(menuItem =>
                    contactHasRight(customerContext.selectedContact.roles, menuItem.access) ?
                      <GenericMenuItem menuItem={menuItem}
                                       handleSubMenuOpen={handleSubMenuOpen}
                                       subMenuOpen={subMenuOpen}
                                       closeSubMenus={closeSubMenus}
                                       withIcon={withIcon}
                                       orientation={"horizontal"}
                                       key={menuItem.key || menuItem.url}/>
                      : <></>
                  )}
                </List>
              </div>
            </Hidden>
            {/* --- MOBILE NAV --- */}
            <Hidden mdUp implementation="css">
              <Drawer
                variant="temporary"
                disableScrollLock
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>{content}</main>
    </div>
  );
};

HorizontalNavigation.propTypes = {
  content: PropTypes.any,
  withIcon: PropTypes.bool,
  changeOrientation: PropTypes.func
};

export default HorizontalNavigation;
