import React, {useContext, useState} from "react";
import moment from "moment";
import {TextField} from "@mui/material";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers-pro";
import PropTypes from "prop-types";
import {LanguageContext} from "../../context/LanguageContext";
import {dateFormat, dateFormatMask, dateTimeFormat, dateTimeFormatMask} from "../../utils/commonValues";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  inputSize: {
    maxWidth: ({fullWidth}) => fullWidth ? null : 180,
  },
  responsiveWidth: {
    width: ({fullWidth}) => fullWidth ? "100%" : 180
  }
}));

const CustomDatePicker = props => {
  const languageContext = useContext(LanguageContext);
  const [dateOpen, setDateOpen] = useState(false);
  const classes = useStyles({fullWidth: props.fullWidth});
  return (
    props.dateTime ? (
      <DateTimePicker
        className={classes.responsiveWidth}
        minDate={props.minDate}
        maxDate={props.maxDate}
        open={dateOpen}
        onOpen={() => setDateOpen(true)}
        onClose={() => setDateOpen(false)}
        clearable={props.clearable}
        allowSameDateSelection={props.allowSameDateSelection}
        name={props.name}
        value={props.value}
        placeholder={moment().format(dateTimeFormat)}
        onChange={props.onChange}
        inputFormat={dateTimeFormat}
        mask={dateTimeFormatMask}
        label={props.label}
        disabled={props.disabled}
        ampm={false}
        renderInput={textProps =>
          <TextField
            {...textProps}
            className={classes.inputSize}
            helperText={languageContext.dictionary.datePickers.timeHelperText}
            required={props.required}
            fullWidth={props.fullWidth}
            size={props.size}
            onClick={(e) => setDateOpen(true)}
          />
        }
      />
    ) : (
      <DatePicker
        className={classes.responsiveWidth}
        minDate={props.minDate}
        maxDate={props.maxDate}
        open={dateOpen}
        onOpen={() => setDateOpen(true)}
        onClose={() => setDateOpen(false)}
        clearable={props.clearable}
        allowSameDateSelection={props.allowSameDateSelection}
        name={props.name}
        value={props.value}
        placeholder={moment().format(dateFormat)}
        onChange={props.onChange}
        inputFormat={dateFormat}
        mask={dateFormatMask}
        label={props.label}
        disabled={props.disabled}
        renderInput={textProps =>
          <TextField
            {...textProps}
            className={classes.inputSize}
            helperText={props.disabledHelperText ? null : languageContext.dictionary.datePickers.helperText}
            required={props.required}
            fullWidth={props.fullWidth}
            size={props.size}
            onClick={(e) => setDateOpen(true)}
          />
        }
      />
    )
  );

};

CustomDatePicker.propTypes = {
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  clearable: PropTypes.bool,
  allowSameDateSelection: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  dateTime: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  disabledHelperText: PropTypes.bool
};

export default CustomDatePicker;
