import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ContractGrid from "./ContractGrid";
import {CircularProgress} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "oidc-react";
import {getContractList} from "../../api/contractResource";
import {CustomerContext} from "../../context/CustomerContext";
import {LanguageContext} from "../../context/LanguageContext";
import moment from "moment";
import PropTypes from "prop-types";
import {calculateTotalPages} from "../../utils/commonFunctions";

const pageSize = 5;

const ContractDataList = ({status, filters, setFilteredList}) => {
  const auth = useAuth();
  const customerContext = useContext(CustomerContext);
  const languageContext = useContext(LanguageContext);
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id && status) {
      setLoading(true);
      getContractList(auth.userData.access_token, customerContext.selectedCustomer.id, status)
        .then(res => {
          setContracts(res.data);
          setFilteredContracts(res.data);
          setCount(calculateTotalPages(res.data, pageSize));
        })
        .finally(() => setLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, status]);

  useEffect(() => {
    setFilteredList(curr => ({...curr, [status.toLowerCase()]: filteredContracts}));
  }, [filteredContracts]);

  useEffect(() => {
    handleFilterChange();
  }, [filters]);

  return filteredContracts.length > 0 ? (
    <Grid item xs={12} container>
      <Grid item xs={12} style={{paddingTop: 10}}>
        <Typography variant="h5">{languageContext.dictionary.contractTitle[status?.toLowerCase()]}</Typography>
      </Grid>
      {!loading ?
        filteredContracts.slice(startIndex, endIndex).map((data, index) => (
          <Grid item xs={12} key={index} style={{paddingTop: 25, paddingBottom: 0}}>
            <ContractGrid data={data} key={`contractGrid_${index}`}/>
          </Grid>
        )) : (
          <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress sx={{margin: 4}} color="secondary"/>
          </Grid>
        )}
      <Grid item xs={12} sx={{justifyContent: "center", display: 'flex', pt: 2}}>
        <Pagination count={count} page={page} onChange={(e, value) => setPage(value)} color="secondary"/>
      </Grid>
    </Grid>
  ) : null;

  function handleFilterChange() {
    setLoading(true);
    let filteredCopy = [...contracts];
    if (filters.period[0]) {
      filteredCopy = filteredCopy.filter(f => moment(f.subscriptionDate).isAfter(filters.period[0]));
    }
    if (filters.period[1]) {
      filteredCopy = filteredCopy.filter(f => moment(f.subscriptionDate).isBefore(filters.period[1]));
    }
    if (filters.contractNumber) {
      filteredCopy = filteredCopy.filter(f => f.contractNumber === filters.contractNumber);
    }
    if (filters.duration) {
      filteredCopy = filteredCopy.filter(f => f.commercialTermInMonths === filters.duration);
    }
    setCount(calculateTotalPages(filteredCopy, pageSize));
    setFilteredContracts(filteredCopy);
    setPage(1);
    setLoading(false);
  }

};

ContractDataList.propTypes = {
  status: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setFilteredList: PropTypes.func.isRequired
};

export default ContractDataList;