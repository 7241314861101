import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import Icon from "@mdi/react";
import { mdiShieldLockOutline } from "@mdi/js";
import {LanguageContext} from "../context/LanguageContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "50%",
    height: 360,
    width: 360,
    textAlign: "center",
    transition: "all ease 0.2s",
    "&:active": {
      boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px",
      transform: "translateY(5px)",
    },
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      height: "100%",
      width: "100%",
    },
  },
  button: {
    margin: theme.spacing(2),
    fontSize: "1.125rem",
  },
  message: {
    maxWidth: 200,
    margin: "0 auto",
  },
}));

const PermissionDenied = () => {
  const language = useContext(LanguageContext);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={8} component={Link} href="/">
        <Typography variant="h3" color="error">
          <Icon size={4} path={mdiShieldLockOutline} />
        </Typography>
        <Typography variant="h6" color="primary" gutterBottom>
          {language.dictionary.permissionDenied}
        </Typography>
        <Typography
          className={classes.message}
          variant="body2"
          color="textSecondary"
          gutterBottom
        >
          {language.dictionary.permissionDeniedMsg}
        </Typography>
      </Paper>
    </div>
  );
};

export default PermissionDenied;
