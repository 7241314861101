import React, {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";


const NoDataToDisplay = ({loading, list, right}) => {
  const langContext = useContext(LanguageContext);

  return (
    <Grid item xs={12}>
      {(!loading && list?.length === 0) || !right ?
        (<Grid container item xs={12} direction="row" justifyContent="center" alignItems="center" sx={{minHeight: 100}}>
          <Typography color={"secondary"} fontSize={20}>{!right ? langContext.dictionary.noPageView : langContext.dictionary.noDataToDisplay}</Typography>
        </Grid>)
        : null}
    </Grid>
  );
};

NoDataToDisplay.defaultProps = {
  right: true
};


NoDataToDisplay.propTypes = {
  loading: PropTypes.bool,
  list: PropTypes.array,
  right: PropTypes.bool
};

export default NoDataToDisplay;
