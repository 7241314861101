import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircleIcon from '@mui/icons-material/Circle';

const MyPermissions = ({selectedUser, companyInfo}) => {
  const langContext = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const adminRight = selectedUser.rights?.find(right => right.value === "ADMIN");
    if (adminRight) {
      companyInfo.rights.unshift(adminRight);
    }
    setLoading(false);
  }, [companyInfo]);

  return (
    <Grid container marginTop={5} seleniumid="MyPermissionsGrid">
      <Grid item marginBottom={1}>
        <Typography variant={"h4"}>{langContext.dictionary.myPermissions}</Typography>
      </Grid>
      <Grid container item direction="row" justifyContent="center" alignItems="center">
        <Paper elevation={4} sx={{width: "100%", paddingTop: 3, paddingBottom: 3, paddingLeft: 1, paddingRight: 1}}>
          {!loading && companyInfo.rights.map(right => (
            <Grid sx={{marginTop: 1, marginBottom: 1}} container item direction="row" justifyContent="center" alignItems="center" key={right.value}>
              <Grid item xs={5} sm={4}>
                <Typography variant={"subtitle2"}>{right.description}</Typography>
              </Grid>
              <Grid item xs={7} sm={7}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <CircleIcon style={{float: "left", height: 20}} color={selectedUser.rights?.some(r => r.value === right.value) ? "secondary" : "error"}/>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{marginLeft: 5, marginTop: 2, alignItems: "center"}}
                      variant={"subtitle1"}
                    >
                      {selectedUser.rights?.some(r => r.value === right.value) ? langContext.dictionary.active : langContext.dictionary.inactive}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
            </Grid>
          ))
          }
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MyPermissions;
