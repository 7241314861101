import React from "react";
import PropTypes from "prop-types";
import NoDataToDisplay from "./noDataTitle/NoDataToDisplay";

const Rendered = (props) => {
  return <>
    {!!props.rendered ? props.children : null}
    {!props.rendered && props.displayNoRightsLabel ? <NoDataToDisplay list={[]} right={props.rendered}/> : null}
  </>;
};

Rendered.propTypes = {
  rendered: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]).isRequired,
  displayNoRightsLabel: PropTypes.bool
};

export default Rendered;
