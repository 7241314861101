import en from "./en";
import hu from "./hu";

export const dictionaryList = {
  en,
  hu
};

export const languageOptions = [
  { id: "hu", text: "Magyar" },
  { id: "en", text: "English" }
];
