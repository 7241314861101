import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {UmamiContext} from "../../context/UmamiContext";
import {saveErrorTicket} from "../../api/errorTicketResource";
import {CustomerContext} from "../../context/CustomerContext";
import {useAuth} from "oidc-react";
import {useSnackbar} from "notistack";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {LoadingButton} from "@mui/lab";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  appBarSmall: {
    position: "relative",
    paddingRight: "0 !important",
    minHeight: 48
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  simpleText: {
    fontSize: 18
  },
  simpleBoldText: {
    fontSize: 18,
    fontWeight: "bold"
  },
  titleSmall: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  gridWrapper: {
    marginTop: 5,
    marginBottom: 5
  },
  actionWrapper: {
    marginBottom: 10,
    marginRight: 10
  }
}));

const ErrorTicketDialog = ({open, title, handleClose, fullScreen, data, setErrorTicketSent, ...props}) => {
  const classes = useStyles();
  const langContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  const umamiContext = useContext(UmamiContext);
  const {enqueueSnackbar} = useSnackbar();
  const {showRequestError} = useRequestErrorMessage();
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleDialogClose = (event, reason) => {
    umamiContext.track('error_ticket_recording_cancel');
    if (props.blocking && reason === "backdropClick") {
      return false;
    }

    if (props.blocking && reason === "escapeKeyDown") {
      return false;
    }

    if (typeof handleClose === "function") {
      handleClose();
    }
  };

  const handleErrorTicketSend = () => {
    setButtonLoading(true);
    umamiContext.track('error_ticket_recording_send');
    const errorTicketObj = {
      serviceId: data.serviceId,
      shortDescription: data.shortDescription,
      longDescription: data.longDescription,
      contactName: data.onSiteContactName,
      contactEmail: data.onSiteContactEmail,
      contactMobile: data.onSiteContactPhoneNumber,
      issuerName: data.errorTicketSender,
      issuerEmail: customerContext.selectedContact.email,
      issuerMobile: customerContext.selectedContact.mobile,
      attachmentDataList: data.attachmentDataList,
      entryInfo: data.entryInfo
    };

    saveErrorTicket(auth.userData.access_token, customerContext.selectedCustomer.id, errorTicketObj)
      .then(res => res.status === 200 ?
        enqueueSnackbar(langContext.dictionary.successErrorTicketSend, {variant: "success", preventDuplicate: true}) : null)
      .catch(error => {
        error.message = error.response.data !== "" ? error.response.data.details : error.message;
        showRequestError(error, langContext.dictionary.errorTicketSendError);
      })
      .finally(() => {
        setButtonLoading(false);
        setErrorTicketSent(true);
        handleClose();
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullScreen={fullScreen}
      maxWidth={"md"}
      disableEnforceFocus={true}
      fullWidth
    >
      <DialogTitle sx={{fontSize: 28}}>
        {title}
        <Divider/>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="center" alignItems="start">
          <Grid item xs={7} className={classes.gridWrapper}>
            <Typography className={classes.simpleText}>{langContext.dictionary.contractingCompany}</Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridWrapper}>
            <Typography className={classes.simpleBoldText}>{data.contractingCompany}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.gridWrapper}>
            <Typography className={classes.simpleText}>{langContext.dictionary.serviceId}</Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridWrapper}>
            <Typography className={classes.simpleBoldText}>{data.serviceId}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.gridWrapper}>
            <Typography className={classes.simpleText}>{langContext.dictionary.contractId}</Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridWrapper}>
            <Typography className={classes.simpleBoldText}>{data.contractId}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.gridWrapper}>
            <Typography className={classes.simpleText}>{langContext.dictionary.serviceAddress}</Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridWrapper}>
            <Typography className={classes.simpleBoldText}>{data.serviceAddress}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.gridWrapper}>
            <Typography className={classes.simpleText}>{langContext.dictionary.onSiteContactPhoneNumber}</Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridWrapper}>
            <Typography className={classes.simpleBoldText}>{data.onSiteContactPhoneNumber}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.gridWrapper}>
            <Typography className={classes.simpleText}>{langContext.dictionary.onSiteContactEmail}</Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridWrapper}>
            <Typography className={classes.simpleBoldText}>{data.onSiteContactEmail}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.gridWrapper}>
            <Typography className={classes.simpleText}>{langContext.dictionary.errorTicketSender}</Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridWrapper}>
            <Typography className={classes.simpleBoldText}>{data.errorTicketSender}</Typography>
          </Grid>
        </Grid>
        <Divider/>
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>
        <Button color="secondary" onClick={handleDialogClose}>{langContext.dictionary.close}</Button>
        <LoadingButton loading={buttonLoading} color="secondary" onClick={handleErrorTicketSend} variant="contained">
          {langContext.dictionary.errorTicketSend}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ErrorTicketDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  fullScreen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  setErrorTicketSent: PropTypes.func.isRequired
};

export default ErrorTicketDialog;
