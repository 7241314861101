import React from "react";
import {createTheme, StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import "./App.css";
import LanguageProvider from "../context/LanguageContext";
import moment from "moment";
import "moment/locale/hu";
import {ConfirmProvider} from "material-ui-confirm";

moment.locale("hu");

const themeSettings = {
  palette: {
    mode: "light",
    primary: {
      light: "#6D6E73",
      main: "#4a4f55",
      dark: "#33373B",
      contrastText: "#fff"
    },
    secondary: {
      light: "#81C746",
      main: "#6ba53a",
      dark: "#4E782A",
      contrastText: "#fff"
    },
    primaryReverse: {
      light: "#6D6E73",
      main: "#fff",
      dark: "#4a4f55",
      contrastText: "#33373B"
    },
    error: {
      main: "#e70033",
      contrastText: "#fff"
    },
    warning: {
      main: "#ffa200",
      contrastText: "#fff"
    },
    mainButton: {
      main: "#ffa200",
      contrastText: "#fff"
    },
    mainButtonReverse: {
      main: "#fff",
      contrastText: "#ffa200"
    },
    secondaryButton: {
      main: "#6ba53a",
      contrastText: "#fff"
    },
    slider: {
      main: "#ffa200",
      contrastText: "#fff"
    },
    userBar: {
      background: "#ebebeb"
    }
  }
};

const theme = createTheme(themeSettings);

export const AppProviders = (props) => {
  return (
    <LanguageProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            {props.children}
          </ConfirmProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LanguageProvider>
  );
};

export default AppProviders;