import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {CircularProgress, Container, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import {LanguageContext} from "../context/LanguageContext";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {formatDate} from "../utils/dateFormat";
import {useRequestErrorMessage} from "../utils/useRequestErrorMessage";
import ServiceGrid from "../components/services/ServiceGrid";
import Pagination from "@mui/material/Pagination";
import {downloadContractDocument, getContractDetails, getContractDocumentList, getContractServices} from "../api/contractResource";
import {CustomerContext} from "../context/CustomerContext";
import Documents from "../components/common/Documents";
import {calculateTotalPages, fileDownloader} from "../utils/commonFunctions";
import {useAuth} from "oidc-react";
import RenderedWithPermission from "../components/RenderedWithPermission";
import {adminFinRight} from "../utils/commonValues";

const pageSize = 5;

const ContractDetails = () => {
  const urlParams = useParams();
  const history = useHistory();
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const [contractDetails, setContractDetails] = useState({});
  const [relatedServices, setRelatedServices] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [documentLoading, setDocumentLoading] = useState(true);
  const [servicesLoading, setServicesLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;


  useEffect(() => {
    if (auth.userData && urlParams.id && customerContext.selectedCustomer.id) {
      setLoading(true);
      getContractDetails(auth.userData.access_token, urlParams.id)
        .then(res => {
          setContractDetails(res.data);
          setDocumentLoading(true);
          getContractDocumentList(auth.userData.access_token, customerContext.selectedCustomer.id, [res.data.contractNumber])
            .then(res => {
              let myList = [];
              res.data.forEach(data => myList = myList.concat([data].concat(data?.dmsDocumentAttachments)));
              setDocumentList(myList);
              setCount(calculateTotalPages(myList, pageSize));
            })
            .finally(() => setDocumentLoading(false));
        })
        .finally(() => setLoading(false));
    }
  }, [urlParams.id, auth.userData, customerContext.selectedCustomer.id, languageContext.languageHeader]);

  useEffect(() => {
    if (auth.userData) {
      setServicesLoading(true);
      getContractServices(auth.userData.access_token, urlParams.id)
        .then(res => {
          setRelatedServices(res.data);
          setCount(calculateTotalPages(res.data, pageSize));
        })
        .finally(() => setServicesLoading(false));
    }
  }, [auth.userData, page, languageContext.languageHeader]);

  return (
    <Container maxWidth="lg" sx={{paddingBottom: 3, paddingTop: 3}}>
      <Button
        variant="text"
        onClick={() => history.push("/services/contracts")}
        size="small"
        color="secondary"
        disableRipple
      >
        <ChevronLeftIcon color="secondary"/>
        {languageContext.dictionary.contracts}
      </Button>
      {!loading ? (
        <Grid container spacing={3} sx={{paddingTop: 3}}>
          <Grid item xs={12}>
            <Typography variant="h5">{contractDetails.customerPortalDescription}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{backgroundColor: "white", padding: 4}} elevation={3}>
              <Grid container spacing={2}>
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography>{languageContext.dictionary.contractId}</Typography>
                    <Typography variant="h6">{contractDetails.contractId}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography>{languageContext.dictionary.duration}</Typography>
                    <Typography variant="h6">
                      {contractDetails.commercialTermInMonths ? `${contractDetails.commercialTermInMonths} ${languageContext.dictionary.month.toLowerCase()}` : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography>{languageContext.dictionary.signatureDate}</Typography>
                    <Typography variant="h6">{formatDate(contractDetails.subscriptionDate)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography>{languageContext.dictionary.status}</Typography>
                    <Typography variant="h6">{contractDetails.statusDescription}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <RenderedWithPermission roles={adminFinRight}>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography sx={{fontWeight: "bold"}}>{languageContext.dictionary.downloadableDocuments}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {!documentLoading ?
                        <Documents documentList={documentList} handleDocumentDownLoad={handleDownload}/> :
                        <CircularProgress sx={{margin: 2}} color="secondary"/>
                      }
                    </Grid>
                  </Grid>
                </RenderedWithPermission>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{languageContext.dictionary.relatedServices}</Typography>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            {!servicesLoading ?
              relatedServices.slice(startIndex, endIndex).map((data, index) => (
                <Grid item xs={12} key={`serviceGrid_${index}`}>
                  <ServiceGrid data={data}/>
                </Grid>
              )) :
              <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
                <CircularProgress sx={{margin: 2}} color="secondary"/>
              </Grid>
            }
          </Grid>
          <Grid item xs={12} style={{justifyContent: "center", display: 'flex'}}>
            <Pagination count={count} page={page} onChange={(e, value) => setPage(value)} color="secondary"/>
          </Grid>
        </Grid>
      ) : (
        <div style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
          <CircularProgress sx={{margin: 2}} color="secondary"/>
        </div>
      )}
    </Container>
  );

  function handleDownload(documentFile) {
    downloadContractDocument(auth.userData.access_token, documentFile.documentId, documentFile.attachmentId, documentFile.fileName)
      .then(res => fileDownloader(res.data, documentFile.fileName))
      .catch(error => showRequestError(error, languageContext.dictionary.errorMessages.contractDocumentDownloadError));
  }
};

export default ContractDetails;
