import React, {useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import {LanguageContext} from "../../context/LanguageContext";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CellBodyRender from "./CellBodyRender";
import {debounce} from "lodash-es";
import Button from "@mui/material/Button";
import LoadingTableBody from "./LoadingTableBody";
import {formatDate, formatDateTime} from "../../utils/dateFormat";
import XLSX from "xlsx";

const useStyles = makeStyles(theme => ({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'right',
    }
  },
  circularProgress: {
    margin: theme.spacing(4)
  }
}));

// handleExcelExport - export all data, making the excel in frontend side
// source: https://github.com/vikas62081/material-table-YT/tree/excelExport
export function handleExcelExport(data, fileName = "ExportedData.xlsx", workSheetName = "exported") {
  const workSheet = XLSX.utils.json_to_sheet(data);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, workSheetName);

  let buf = XLSX.write(workBook, {bookType: "xlsx", type: "buffer"});   // Buffer
  XLSX.write(workBook, {bookType: "xlsx", type: "binary"}); // Binary string
  XLSX.writeFile(workBook, fileName);  // Download
}

// exportToExcel - export only the data in the table, filtering function, calls handleExcelExport
export function exportToExcel(columns, data, fileName, workSheetName, langContext) {
  // we need JSON stringify and parse because we don't want to change the original values
  let columnsStringify = JSON.stringify(columns);
  let tableDataStringify = JSON.stringify(data);
  let tableColumns = JSON.parse(columnsStringify);

  //tableColumns.splice(-1, 1);   // we don't need the last value of columns, it's only formatting
  let fields = {};
  tableColumns.forEach(column => fields[column.name] = langContext?.dictionary?.[column.label] || column.label);

  const newData = JSON.parse(tableDataStringify).map(row => {
    let arr = {};
    Object.keys(row).forEach(key => {
      if (fields[key]) {
        const details = tableColumns.find(c => c.name === key);
        arr[fields[key]] = details?.type === "date" ? formatDate(row[key]) : details?.type === "dateTime" ? formatDateTime(row[key]) : row[key];
      }
    });
    return arr;
  });

  handleExcelExport(newData, fileName, workSheetName);
}

const DataTable = props => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({page: 0, rowsPerPage: props.numOfRows || 5, sortOrder: undefined, filterList: [], searchText: ""});
  const [lastQueryObject, setLastQueryObject] = useState({});
  const [renderedColumns, setRenderedColumns] = useState([]);
  const [tableOptions, setTableOptions] = useState({
    serverSide: typeof props.data === "function",
    search: typeof props.data !== "function", // doesn't work on backend currently
    viewColumns: false,
    print: false,
    rowsPerPageOptions: props.numOfRowsOption || [5, 10, 20, 50],
    onChangeRowsPerPage: n => {
      setFilters(f => ({...f, rowsPerPage: n}));
    },
    onTableChange: (action, tableState) => {
      if (["changePage", "sort", "search"].includes(action)) {
        setFilters({
          page: tableState.page,
          sortOrder: tableState.sortOrder,
          filterList: tableState.filterList,
          searchText: tableState.searchText,
          rowsPerPage: tableState.rowsPerPage,
        });
      } else if (["filterChange", "resetFilters"].includes(action)) {
        setFilters({
          page: 0,
          sortOrder: tableState.sortOrder,
          filterList: tableState.filterList,
          searchText: tableState.searchText,
          rowsPerPage: tableState.rowsPerPage,
        });
      }
    },
    //when search is used with a button
    onFilterConfirm: (filterList) => {
      setFilters(curr => ({...curr, filterList: filterList}));
    },
    selectableRows: props.selection ? "multiple" : "none",
    filterType: "textField",
    setRowProps: (row, dataIndex, rowIndex) => {
      return {style: {backgroundColor: (rowIndex % 2 === 0) ? '#EEE' : '#FFF'}};
    },
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyFilters) =>
      <div style={{paddingTop: "16px"}}>
        <Button
          variant="contained"
          color="secondary"
          onClick={applyFilters}>
          {"OK"}
        </Button>
      </div>
  });

  useEffect(() => {
    let cols = props.columns.map((c, i) => ({
      ...c,
      label: languageContext.dictionary[c.label] || c.label,
      options: {
        ...c.options,
        sortThirdClickReset: true,
        filterList: filters.filterList[i],
        customFilterListOptions: {
          render: filterValue => `${languageContext.dictionary[c.label] || c.label}: ${filterValue}`
        },
        setCellProps: () => ({
          align: c.align
        }),
        setCellHeaderProps: () => ({
          className: c.align === "center" ? classes.centeredHeader : c.align === "right" ? classes.rightHeader : "",
        }),
        customBodyRender: (value, tableMeta) => (
          <CellBodyRender
            value={value}
            column={c}
            tableMeta={tableMeta}
            data={data}
          />
        )
      }
    }));
    setRenderedColumns(cols);
  }, [props.columns, data, languageContext.language.id]);

  useEffect(() => {
    if (props.columns?.length) {
      updateData(filters?.page, filters?.sortOrder, filters?.filterList, filters?.rowsPerPage);
    }
  }, [filters, props.data, props.columns]);

  const updateData = useMemo(() => debounce((page = 0, sortOrder = {}, filterList = [], rowsPerPage) => {
    if (typeof props.data === "function") {
      setIsLoading(true);
      (async () => {
        let filterQueryParams = {
          filters: filterList
            .map((f, i) => {
              return !f?.length ? undefined : {
                column: {
                  name: props.columns[i].name
                },
                value: f[0]
              };
            }).filter(f => f),
          page: page,
          pageSize: rowsPerPage,
          orderDirection: sortOrder.direction,
          orderBy: sortOrder.name
        };
        try {
          let newData = await props.data(filterQueryParams);
          setLastQueryObject(filterQueryParams);
          setData(newData.data);
          setTableOptions(curr => ({...curr, count: newData.totalCount}));
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setData(props.data);
    }
  }, 50), [props.data, props.columns]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  const BodyComponent = useMemo(() => (props) => (
    <LoadingTableBody isLoading={isLoading} {...props} />
  ), [isLoading]);

  return (
    <div style={{maxWidth: "100%"}}>
      <MUIDataTable
        title={<Typography variant="h6"> {props.title || ""} </Typography>}
        data={data}
        columns={renderedColumns}
        options={{
          download: props.exportButton,
          ...tableOptions,
          ...props.options,
          responsive: "simple",
          textLabels: {
            ...languageContext.dictionary.muiDataTableLocalization,
            body: {
              ...languageContext.dictionary.muiDataTableLocalization.body,
              noMatch: isLoading
                ? <CircularProgress className={classes.circularProgress} color="secondary"/>
                : languageContext.dictionary.muiDataTableLocalization.body.noMatch
            },
          },
          onDownload: () => {
            if (props.data === "function") {
              props.customExport(props.columns, data, lastQueryObject);
            } else {
              exportToExcel(props.columns, data, props.fileName, props.workSheetName, languageContext);
            }
            return false; // without this, it generates a csv file too
          }
        }}
        components={{TableBody: BodyComponent}}
      />
    </div>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  title: PropTypes.any,
  isLoading: PropTypes.bool,
  exportButton: PropTypes.bool,
  options: PropTypes.object,
  expandableRow: PropTypes.elementType,
  numOfRows: PropTypes.number,
  numOfRowsOption: PropTypes.array,
  customExport: PropTypes.func,
  fileName: PropTypes.string,
  workSheetName: PropTypes.string
};

export default DataTable;
