import React, {createContext, useContext, useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import {UserContext} from "./UserContext";
import {CustomerContext} from "./CustomerContext";
import useScript from "../utils/useScript";
import {env} from "../properties";

export const UmamiContext = createContext();

const UmamiProvider = props => {
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const location = useLocation();
  const lastLocation = useRef('');
  useScript(env.umamiUrl, {"data-website-id": env.umamiWebsiteDataId, "data-auto-track": "false"});

  useEffect(() => {
    let href = window.location.href;
    if (lastLocation.current !== href) {
      track()
      lastLocation.current = href;
    }
  }, [location]);

  const track = (...args) => {
    if (window.umami) {
      args[1] = args[1] || {};
      if (typeof args[1] === 'object') {
        args[1].user = userContext?.userInfo?.email;
        args[1].cmId = customerContext?.selectedCustomer?.id;
        if (customerContext?.selectedContact?.email) {
          args[1].contact = customerContext.selectedContact.email;
        }
      }
      window.umami.track(props => ({
        ...props,
        url: location.pathname + location.search,
        name: args[0],
        data: args[1]
      }));
    }
  };

  const provider = {
    track
  };

  return (
    <UmamiContext.Provider value={provider}>
      {props.children}
    </UmamiContext.Provider>
  );
};

export default UmamiProvider;
