import React from 'react';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import {NavLink} from 'react-router-dom';
import {LanguageContext} from '../../context/LanguageContext';
import {makeStyles, useTheme} from '@mui/styles';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

const minHeight = 64;
const useStyles = makeStyles(theme => ({
  collapse: {
    display: "inline-flex",
    position: "absolute",
    left: 0,
    marginTop: minHeight + 1
  },
  collapseMenu: {
    backgroundColor: theme.palette.primary.light,
    width: "100%"
  },
  listItem: {
    position: "relative",
    display: "inline-flex",
    width: "auto",
    color: theme.palette.primary.main,
    minHeight: minHeight,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    upperBold: {
      fontWeight: "bold",
      textTransform: "uppercase"
    },
    "& > div > span": {
      fontWeight: "bold",
      textTransform: "uppercase"
    },
    "&::after": {
      content: '""',
      display: "block",
      borderBottom: `solid 2px ${theme.palette.primary.contrastText}`,
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      transform: "scaleX(0)",
      transition: "transform 200ms ease-in-out"
    },
    "&:hover": {
      "&::after": {
        transform: "scaleX(1)"
      }
    }
  },
  listItemIcon: {
    color: "inherit",
    minWidth: 32
  },
}));

const GenericMenuItem = ({menuItem, subMenuOpen, handleSubMenuOpen, orientation, closeSubMenus, withIcon}) => {
  const theme = useTheme();
  const languageContext = React.useContext(LanguageContext);
  const classes = useStyles();
  if (orientation === 'vertical') {
    if (menuItem.children) {
      return <React.Fragment>
        <ListItem button onClick={handleSubMenuOpen(menuItem.label)}>
          <Tooltip
            title={
              languageContext.dictionary[menuItem.label] || menuItem.label
            }
          >
            <ListItemIcon>
              <Icon>{menuItem.icon}</Icon>
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={
              languageContext.dictionary[menuItem.label] || menuItem.label
            }
          />
          {subMenuOpen[menuItem.label] ? (
            <ExpandLess fontSize="small"/>
          ) : (
            <ExpandMore fontSize="small"/>
          )}
        </ListItem>
        <Collapse
          in={subMenuOpen[menuItem.label]}
          timeout="auto"
          unmountOnExit
        >
          <Divider/>
          <List dense>
            {menuItem.children.map(subMenuItem => (
              <ListItem
                button
                component={NavLink}
                activeStyle={{
                  backgroundColor: theme.palette.secondary.contrastText,
                  color: theme.palette.secondary.main
                }}
                to={{pathname: subMenuItem.url, state: {from: window.location.pathname}}}
                key={subMenuItem.url + '_submenu'}
              >
                <Tooltip
                  title={
                    languageContext.dictionary[subMenuItem.label] ||
                    subMenuItem.label
                  }
                >
                  <ListItemIcon>
                    <Icon>{subMenuItem.icon}</Icon>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={
                    languageContext.dictionary[subMenuItem.label] ||
                    subMenuItem.label
                  }
                />
              </ListItem>
            ))}
          </List>
          <Divider/>
        </Collapse>
      </React.Fragment>;
    }
    return <ListItem
      button
      component={NavLink}
      activeStyle={{
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
      }}
      to={{pathname: menuItem.url, state: {from: window.location.pathname}}}
    >
      <Tooltip
        title={
          languageContext.dictionary[menuItem.label] || menuItem.label
        }
      >
        <ListItemIcon>
          <Icon>{menuItem.icon}</Icon>
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        primary={
          languageContext.dictionary[menuItem.label] || menuItem.label
        }
      />
    </ListItem>;
  }

  if (menuItem.children) {
    return (
      <span style={{position: 'relative'}}>
        <ListItem
          button
          className={classes.listItem}
          onClick={handleSubMenuOpen(menuItem.label)}
          disableRipple
          component={NavLink}
          activeStyle={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
          }}
          to={{pathname: menuItem.url, state: {from: window.location.pathname}}}
        >
          {withIcon ? (
            <ListItemIcon className={classes.listItemIcon}>
              <Icon>{menuItem.icon}</Icon>
            </ListItemIcon>
          ) : null}
          <ListItemText
            primary={
              languageContext.dictionary[menuItem.label] ||
              menuItem.label
            }
          />
          {subMenuOpen[menuItem.label] ? (
            <ExpandLess fontSize="small"/>
          ) : (
            <ExpandMore fontSize="small"/>
          )}
        </ListItem>
        <Collapse
          className={classes.collapse}
          in={subMenuOpen[menuItem.label]}
          timeout="auto"
          unmountOnExit
        >
          <Paper
            className={classes.collapseMenu}
            square
            elevation={4}
          >
            <List className={classes.list} dense disablePadding>
              {menuItem.children.map(subMenuItem => (
                <ListItem
                  button
                  component={NavLink}
                  activeStyle={{
                    backgroundColor:
                    theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText
                  }}
                  to={{pathname: subMenuItem.url, state: {from: window.location.pathname}}}
                  key={subMenuItem.url}
                  className={classes.listItem}
                  style={{display: 'flex'}}
                  onClick={closeSubMenus}
                  disableRipple
                >
                  {withIcon ? (
                    <ListItemIcon
                      className={classes.listItemIcon}
                    >
                      <Icon>{subMenuItem.icon}</Icon>
                    </ListItemIcon>
                  ) : null}
                  <ListItemText
                    primary={
                      languageContext.dictionary[
                        subMenuItem.label
                        ] || subMenuItem.label
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Collapse>
      </span>
    );
  }

  return <ListItem
    button
    component={NavLink}
    activeStyle={{
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }}
    to={{pathname: menuItem.url, state: {from: window.location.pathname}}}
    className={classes.listItem}
    disableRipple
    onClick={closeSubMenus}
  >
    {withIcon ? (
      <ListItemIcon className={classes.listItemIcon}>
        <Icon>{menuItem.icon}</Icon>
      </ListItemIcon>
    ) : null}
    <ListItemText
      primary={
        languageContext.dictionary[menuItem.label] ||
        menuItem.label
      }
    />
  </ListItem>;
};

GenericMenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  closeSubMenus: PropTypes.func,
  subMenuOpen: PropTypes.object.isRequired,
  handleSubMenuOpen: PropTypes.func.isRequired,
  withIcon: PropTypes.bool,
};

export default GenericMenuItem;
