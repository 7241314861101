import React from "react";
import {styled} from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import {tooltipClasses} from "@mui/material";


const HtmlTooltip = styled(({className, ...props}) => (
  <Tooltip {...props}
           classes={{popper: className}}/>))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 480,
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #dadde9',
  },
}));

const CustomTooltip = ({...props}) => {
  return (<HtmlTooltip {...props}/>);
};

export default CustomTooltip;