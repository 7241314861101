const en = {
  accept: "Accept",
  accessPermissionRequest: "Access permission request",
  accountBalance: "Account balance",
  accountType: "Account type",
  action: "Action",
  actionOptions: {
    toServer: "To server",
    visit: "Visit",
    maintenance: "Maintenance",
    bringsOrTakesServer: "Brings / takes a server",
    troubleshooting: "Troubleshooting"
  },
  actions: "Actions",
  active: "Active",
  activeContracts: "Active contracts",
  activeErrorTickets: "Active error tickets",
  accountDetails: "Current account details",
  actual: "Actual",
  actualNotifications: "Actual notifications",
  add: "Add",
  addAnotherContactPerson: "Add another contact person",
  addAnotherEntryPerson: "Add another entry person",
  addedToDownloadList: "Added to download list.",
  addNewRow: "Add new row",
  addNewVm: "Add new VM",
  address: "Address",
  addToList: "Add to list",
  admin: "Admin",
  administration: "Administration",
  administrationSynonym: "Administration",
  affectedServices: "Affected services",
  affectedSite: "Affected site",
  affectedSites: "Affected sites",
  afType: "AF type",
  agree: "I agree",
  aggregateMode: "Aggregate mode",
  all: "All",
  allBills: "All bills",
  allErrorTickets: "All error ticket",
  allowService: "Allow service",
  allServices: "All services",
  amount: "Amount",
  another: "More",
  apiErrorMessages: {
    "maintenance/maintenances/*": "There was an error during the load of the maintenances!",
    "maintenance/service_types": "There was an error during the load of the maintenance types!",
    "maintenance/accept": "There was an error during the acceptance of the maintenance!",
    "maintenance/reject": "There was an error during the rejection of the maintenance!",
    "maintenance/contactId/*": "There was an error during the load of the contact id!",
    "service/*": "There was an error during the load of the service!",
    "company/internalContacts/*": "There was an error during the load of the contacts!",
    "ticket/*/contactList": "There was an error during the load of the contacts!",
    "service/all/*": "There was an error during the load of the services!",
    "colosite/colo/*": "There was an error during the download of the server rooms!",
    "colosite/role": "There was an error during the download of the roles!",
    "colosite/service/*": "There was an error during the download of the services!",
    "colosite/vitrinService/*": "There was an error during the download of the services!",
    "colosite/user/*": "There was an error during the download of the users!",
    "ticket/*/ticket/list": "There was an error during the load of the error tickets!",
    "dms/casetype/list/service": "There was an error during the load of the cases!",
    "dms/casetemplate/*": "There was an error during the load of the cases fields!",
    "dcma/access/*": "There was an error during the load of the server room accesses!",
    "dcma/access": "There was an error during the save of the server room access permit!",
    "contract/list/*": "There was an error during the load of the contracts!",
    "contract/contract/*": "There was an error during the load of the contract details!",
    "contract/services/*": "There was an error during the load of the contract related services!",
    "contract/documents/list/*": "There was an error during the load of the contract documents!",
    "contact/edit/selectorlist/*": "An error occurred while loading the selector list!",
    "contact/contactperson/new/*": "An error occurred while loading the new user's data!",
    "contact/contactperson/*": "An error occurred while loading the user's data!",
    "contact/contactperson": "An error occurred while saving the user's data!",
    "ticket/*/serviceId/openTicket/list": "There was an error during the load of the open error tickets!",
    "service/IMPLEMENT/*": "There was an error during the load of the establishments!",
    "ticket/errormessage/*": "There was an error during the loading!",
    "ticket/*/saveTicket/": "An error occurred while submitting the ticket!"
  },
  applicationGroups: "Application groups",
  applicationTraffic: "Application traffic",
  attachedMessage: "Attached message: ",
  attachFiles: "Attach files",
  authorizedPerson: "Authorized person",
  average: "Average",
  back: "Back",
  backToMainPage: "Back to the main page",
  balance: "Balance",
  balanceAndCurrentBills: "Balance and current bills",
  bandwidthDown: "Bandwidth (down)",
  bandwidthUp: "Bandwidth (up)",
  bankAccount: "Bank account",
  barchart: "Barchart",
  beginning: "Beginning",
  billing: "Billing",
  billingAddress: "Billing address",
  billingDate: "Billing date",
  billCollectorId: "Bill collector Id",
  billId: "Bill ID",
  billNumber: "Bill number",
  bills: "Bills",
  billsOlderThanTwoYears: "Regarding your account that is more than 2 years old, please contact our customer service at the toll-free number 06 80 82 00 82 or at the e-mail address vip@invitech.hu.",
  billTotal: "Bill total",
  billTypes: {
    bills: "Bill",
    adatkozlo: "Informant",
    calllog: "Call log",
    demandletter: "Letter of demand"
  },
  buildingVmBottomText: "The process may take several minutes, please be patient!",
  buildingVmCreateTitle: "Creating in progress",
  buildingVmList: [
    "Placing CPUs...",
    "RAM reservation...",
    "Insert storage...",
    "IP reservation...",
    "Domain registration...",
    "Operating system installation..."
  ],
  buildingVmModifyTitle: "Modification in progress",
  cancel: "Cancel",
  cannotBeEmpty: "Cannot be empty!",
  cardList: "Card list",
  cartCommentTxt: "Here you can let us know your comments and requests regarding your order.",
  cartContent: "Cart content",
  caseId: "Case Id",
  caseSendError: "Failed to send case",
  caseStartDate: "Case start date",
  caseType: "Case type",
  caseUnderRegistration: "Cases under registration",
  category: "Category",
  changeLanguage: "Váltás magyar nyelvre",
  changePassword: "Change Password",
  changePhoto: "Change photo",
  choose: "Choose",
  chooseCustomerFirst: "Choose a customer first",
  choosePreferredPayment: "Choose your preferred payment method",
  choosePlease: "Please select!",
  chooseRoutersFirst: "Choose routers first",
  chooseSitesFirst: "Choose sites first",
  classicAccount: "Classic account",
  clientCode: "Customer code",
  close: "Close",
  closedCases: "Closed case",
  cloud: "Cloud",
  comment: "Comment",
  currency: "Currency",
  currentMaintenances: "Current maintenances",
  customerInfo: "Customer information",
  customerRegistrationNumber: "Customer registration number",
  customerNameAddress: "Customer name, address",
  colleagueQuestion: "A colleague of their company or institution?",
  companyDetails: "Company details",
  companyInformation: "Company information",
  companyInformationSectionTxt: "By entering your tax number, we identify additional parameters of your company",
  companyName: "Company name",
  companySelectionError: "An error occurred while selecting the company!",
  companySelector: "Company Selector",
  companySelectorTitle: "Please choose from the list below which company you would like to act on behalf of!",
  completeSolutions: "Complete solutions",
  consolePrivilege: "Console privilege",
  configurator: "Configurator",
  configureMfa: "Configure Two-step authentication",
  configureMfaDialog: "If you activate two-factor authentication on our website, please note that this setting can only be deactivated later by contacting our customer service (06 80 82-00-82).",
  configureMfaDialogTitle: "Important Security Information",
  configureMfaExperimentalFeature: "Experimental Feature",
  confirm: "Confirm",
  confirmDelete: "Are you sure you want to delete?",
  confirmDeleteVm: "Are you sure you want to delete the virtual machine?",
  confirmVmName: "Please confirm the name of the virtual machine by typing the name below: ",
  contact: "Contact",
  contactAdditionForVm: "A request has been made for $_{name} service id. ",
  contactData: "Contact data",
  contactHeader: "Please select the subject of the contact and write the message. We will contact you after processing your report!",
  contactHeaderVm: "Please write your needs for the selected virtual server. We will contact you after processing your request!",
  contactListLoadError: "There was an error during the load of the contacts!",
  contactMessageError: "There is an error during the process! Please, try again later!",
  contactMsgPlaceholder: "Please write your message here (max. 2000 characters) ...",
  contactPerson: "Contact person",
  contactSuccess: "Thank you for your contact!",
  contractDetails: "Contract details",
  contractId: "Contract ID",
  contractNumber: "Contract number",
  contractualCurrentAccountNumber: "Contractual current account number",
  contracts: "Contracts",
  contractTitle: {
    proposal: "Suspended establishment contracts",
    implement: "Contracts under construction",
    operating: "Active contracts"
  },
  contractingCompany: "Name of contracting company",
  contractsUnderConstruction: "Contracts under construction",
  cookie: {
    headerMessage: "This website uses cookies",
    mainContentPart1: "This site uses cookies that are essential for its operation and to provide a better browsing experience, personalize content and analyze traffic. Cookies are primarily used by the website operator, and cookies necessary for traffic analysis are used by a third party (e.g. Google). ",
    mainContentPart2: "By accepting cookies, you consent to the use of required cookies.",
    cookieURL: "Learn more about cookies in our information sheet. ",
    allCookies: "Accept all cookies",
    requiredCookies: "I only accept required cookies"
  },
  copyErrorMessage: "Copy error message",
  couldNotDownloadFile: "Could not download file",
  createNewVm: "Create new VM",
  tp: "Technical Portal",
  cp: "Customer Portal",
  cssp: "Cloud Self Service Portal",
  csspDesc: "Cloud Self Service Portal",
  creditCard: "Credit card",
  custom: "Custom",
  customer: "Customer",
  dashboard: "Dashboard",
  datatable: "Datatable",
  day: "Day",
  dataCenter: "Data center",
  dataTransfer: "Data transfer",
  dataChange: "Data change report",
  dataChangeUser: "Change my data",
  date: "Date",
  datePickers: {
    locale: "en",
    helperText: "yyyy.mm.dd",
    timeHelperText: "yyyy.mm.dd hh:mm"
  },
  dcArduinoMeter: "DC Arduino Meters",
  dcArduinoMeterDesc: "You can track the consumption of your devices located in our data center",
  ddosSecurityService: "DDOS security",
  ddosSecurityServiceDesc: "View DDOS Services and Alerts",
  debtDueAfter8Days: "Debt due after 8 days",
  debtDueWithin8Days: "Debt due within 8 days",
  default: "Default",
  delete: "Delete",
  deleteContactMsg: "If you would like to delete a contact, please contact our customer service at the toll-free number 06 80 82 00 82 or at the e-mail address vip@invitech.hu.",
  deleteUserMsg: "If you want to delete a user, please contact our customer service at the toll-free number 06 80 82 00 82 or at the e-mail address vip@invitech.hu.",
  deleteContactCustomerService: "To delete, please contact our customer service",
  deleteConfirm: "Confirm delete",
  deleteFilters: "Delete filters",
  description: "Description",
  details: "Details",
  dialog: "Dialog",
  disabledImpersonate: "Unable to use due to impersonation",
  dnsManagement: "DNS management",
  dnsManagementDesc: "DNS Zone management",
  documentNumber: "Document number",
  documentType: "Document type",
  domain: "Domain",
  domainCounterLoadError: "There was an error during the load of the domain counter!",
  domainExistsError: "An error occurred while checking the domain!",
  domainIncorrectEntry: "Incorrect entry.",
  domainListLoadError: "There was an error during the load of the domain list!",
  domainName: "Domain name",
  domainReserved: "Already reserved domain.",
  dontJudge: "Don't judge, it's a work in progress",
  downloadableDocuments: "Downloadable documents",
  duration: "Duration",
  drivingLicense: "Driving license",
  eBill: "E-bill",
  edit: "Edit",
  editSynon: "Edit",
  editUser: "Edit user",
  email: "E-mail",
  emailAddress: "E-mail address",
  emailAddressType: "E-mail address type",
  emptyCartHint: "Cart is empty!",
  emptyVmEdit: "No virtual machine selected to modify!",
  endpointAddress: "Endpoint address",
  endDate: "End date",
  enterDate: "Enter date",
  enterInterval: "Enter interval",
  enterIntervalTooltip: "Single entry: each entry must be requested separately!",
  enterDetails: "Enter details",
  enterCompanyTaxNumber: "Enter your company tax number",
  entityName: "Entity Name",
  entryInfo: "Entry info",
  eNotice: "E-notice",
  eOrder: "E-order",
  eOrderSend: "E-order send",
  error: "Error",
  errorButtonSuspend: "The error ticket cannot be started for a suspended service.",
  errorButtonLimited: "The error ticket cannot be started for a limited service.",
  errorButtonInProgress: "There is an ongoing error ticket on the service. No new errors can be submitted until the current ticket is resolved.",
  errorDetails: "Error details",
  errorMessage: "Error message",
  errorMessages: {
    companyListError: "There was an error during the load of the companies!",
    loadNotificationListError: "The notification list could not be loaded",
    notificationCounterLoadError: "There was an error during the load of the notification counter!",
    selectedCompanyLoadError: "There was an error during the load of the selected company!",
    establishmentCounterLoadError: "There was an error during the load of the establishment counter!",
    establishmentDataLoadError: "There was an error during the load of the establishments!",
    userDataLoadError: "There was an error during the load of the users!",
    billListLoadError: "There was an error during the load of the bills!",
    billTypesLoadError: "There was an error during the load of the bill types!",
    contractsListLoadError: "There was an error during the load of the contracts!",
    typesLoadError: "There was an error during the load of the types!",
    optionsLoadError: "There was an error during the load of the options!",
    serviceListLoadError: "There was an error during the load of the services!",
    contractDocumentDownloadError: "There was an error during the download of the contract document!",
    loadingError: "An error occurred while loading the data!",
    saveError: "There was an error during the save!"
  },
  errorTicket: "Error ticket",
  errorTicketDetails: "Detailed description of error ticket",
  errorTicketListTitle: "In the list below, we list the service types for which a error ticket can be submitted",
  errorTicketId: "Error ticket id",
  errorTicketRecording: "Error ticket recording",
  errorTicketSend: "Error ticket send",
  eOrderSendError: "Failed to submit an e-order",
  errorTicketSendError: "Failed to submit an error ticket",
  errorTicketSender: "Sender of error ticket",
  errorTicketOpenService: "There is an error ticket on the service",
  errorTooltipMessage: "Please provide all the necessary data regarding the service and the error phenomenon in order to accurately define and eliminate the error! If you do not provide enough information, our colleagues will contact you via one of the contact channels provided, so that we can start investigating the error as soon as possible. Ex: Data connection/internet service: What exactly are you experiencing? Totally out of service? Are the devices powered on, have they been restarted, do they seem operational? From where and what exactly are you unable to reach (in case of L3 service, IP address)? If there is a backup line, does it work? Voice/telephone service: What exactly do you experience? Is the line completely silent? Which number is affected by the error? Example call: number, called number, time of call, error phenomenon. In the case of an IP/internet-based telephone service, is the carrier service operating?",
  errorType: "Error type",
  establishments: "Establishments",
  euCommunityTaxNumber: "Eu community tax number",
  exhibitionMethod: "Exhibition method",
  expirationDate: "Date of expiration",
  expirationDateError: "An error occurred while loading the expiration date!",
  expiredNotifications: "Expired notifications",
  expectedConstruction: "Expected construction",
  exportToExcel: "Export to Excel file",
  fastlane: "Fastlane",
  fastlaneDesc: "Kubernetes Fastlane",
  favouriteFood: "Favourite food",
  fees: "Fees",
  fileError: "Bad file",
  fileErrorType: "Invalid file type. Suitable types: ",
  fileErrorSize: "Maximum file size is 20 MB",
  fileNotFound: "File not found",
  fileUploadTooltip: "Maximum size: 20 MB. Supported file types: ",
  feedbackDeadline: "Feedback deadline",
  filterChanged: "The filters have changed, click to update the data",
  financialContact: "Customer Portal - Financial Contact",
  financialPartner: "Financial partner",
  finishedMaintenances: "Finished maintenances",
  firmwareVersion: "Firmware version",
  firstName: "First name",
  footer: {
    mainPage: {
      mainPageText1: "PERSONALIZED SOLUTIONS. EXACTLY THE WAY YOU WANT.",
      askForOffer: "Ask for offer now!",
      aboutUs: "About us",
      solutions: "Solutions",
      socialMedia: "Social media",
      aboutInvitech: "About Invitech",
      references: "References",
      career: "Career",
      socialResponsibility: "Social responsibility",
      centralizedProcurement: "Centralized procurement",
      smartVillage: "Smart village",
      forLargeCompanies: "For large companies",
      forSmallAndMediumCompanies: "For small and medium companies",
      forInstitutions: "For institutions",
      forWholesalers: "For wholesalers",
      businessServices: "Business services",
      infrastructureServices: "Infrastructure services",
      news: "News",
      press: "Press",
      blog: "Blog",
      loginToCustomerPortal: "Login to the customer portal",
      loginToTechnicalPortal: "Login to the technical portal",
      loginToCSSP: "Login to the Cloud Self Service Portal"
    },
    dataProtection: "Data protection",
    dataProcessingCode: "Data processing code",
    termsAndConditions: "Terms and Conditions",
    gtc: "GTC",
    certificates: "Certificates",
    impress: "Impress",
    providerInformation: "Provider information",
    filteringSoftware: "Filtering Software",
    coServiceOffers: "Co-service offers",
    premiumServices: "Premium services",
    socialResponsibility: "Social responsibility (InnoMax)",
    iivrApplication: "IIVR Application"
  },
  fulfillmentDate: "Fulfillment date",
  filter: "Filter",
  filters: "Filters",
  forms: "Forms",
  from: "from",
  fromDate: "From date",
  generalSearch: "General Search",
  gift: "gift",
  hasDuplicateDomainHint: "The specified domain name is listed more than once!",
  hardwareDeliveryPrivilege: "Hardware delivery privilege",
  hardwareExchangePrivilege: "Hardware exchange privilege",
  headquarter: "Headquarter",
  help: "Help",
  home: "Home",
  homePage: {
    mainTitle: "Build your own scalable dedicated server or choose from the configurations below",
    mainList: [
      "Guaranteed troubleshooting within 4 hours",
      "The compiled configuration can be flexibly modified at any time",
      "Can be put into operation immediately",
      "Continuous backup and monitoring",
      "You can run your own operating system"
    ],
    additionalBenefits: "Additional benefits",
    additionalBenefitsCards: {
      card1Title: "Professional operation",
      card1Text: "Redundant infrastructure, Internet access provided by multiple core network data connections.",
      card2Title: "Cost-efficient",
      card2Text: "You can achieve a cost reduction of up to 15-30% compared to running your own physical server. Monthly subscription based service.",
      card3Title: "Safe",
      card3Text: "Our machine rooms meet the highest security standards, and the data stored on our servers will always be secure.",
      card4Title: "Customizable",
      card4Text: "You don't need to scale your server for years to come, as you can increase it at any time to suit your operational and hardware needs."
    },
    selfCareTutorial: "Self Care tutorial",
    theyChoseUs: "They chose us",
    theyChoseUsDescription: "As an expert and experienced partner, we offer answers to a unique and complex business challenge based on your IT needs. Develop your IT system consciously and predictably, avoid one-time large investments, rent server services on a monthly fee basis."
  },
  hostname: "Hostname",
  hour: "Hour",
  huf: "HUF",
  ictSkill: 'ICT skill',
  id: "ID",
  idCard: "ID card",
  identificationNumber: "Identification number",
  ifName: "IF Name",
  inactive: "Inactive",
  inactiveErrorTickets: "Inactive error tickets",
  interfaceData: "Interface data",
  internet: "Internet",
  invalidEmailMsg: "Email address is invalid!",
  invalidPhoneNumberMsg: "Phone number is invalid!",
  invitation: "Invitation",
  invitationError: "An error occurred while sending the email(s)!",
  invitationSenderText: "Please specify who you want to send an invitation to!",
  invitationSuccess: "Successfully sent email(s)!",
  invitech: "Invitech ICT Services Kft.",
  invitechContacts: "Invitech contacts",
  invoicingAdministration: "Invoicing administration",
  invoiceOutputTypes: {
    REMOTE: "remote",
    PAPER: "paper",
    ELECTRO: "electronic"
  },
  ipAddress: "IP Address",
  itPartner: "IT partner",
  itSolutions: "IT solutions",
  jitter: "Jitter",
  lastData: "Last data",
  lastName: "Last name",
  lastUpdate: "Last update",
  latency: "Latency",
  legalText: {
    prices: "Prices are in HUF and do not include VAT.",
    checkBoxPrimary: "Please check the box to confirm that you have read understood and accepted our ",
    checkBoxFirstConjunction: " and ",
    checkBoxSecondConjunction: ".*"
  },
  level: "Level",
  licensePlate: "License plate number",
  licenseType: "License type",
  linechart: "Linechart",
  loadingCompanyData: "Loading company data",
  loadingList: "Loading list",
  loggedInContact: "Logged in contact",
  loggingIn: "Logging in...",
  login: "Login",
  loginAndLogout: "Login / logout",
  logout: "Log Out",
  macAddress: "MAC address",
  mailText: "The email address is the same as the username",
  maintenance: "Maintenance",
  maintenanceRejectionConfirm: "Are you sure you want to reject the maintenance below?",
  maintenances: "Maintenances",
  maintenanceType: "Maintenance type",
  managerContact: "Manager contact",
  manual: "Manual",
  map: "Map",
  masterData: "Master data",
  masterDataChangeWarning: "Master data is provided for the following customers:\n$_{companyList}.\nIn the event of a change, it will also change for the customers listed here.",
  max: "Max",
  message: "Message",
  messageSubject: "Message subject",
  min: "Min",
  minimum3CharactersRequired: "At least three characters are required to begin searching",
  missingInput: "Missing input field",
  mobile: "Mobile",
  mobileContactType: "Mobile contact type",
  mobileNumber: "Mobile number",
  month: "Month",
  muiDataTableLocalization: {
    body: {noMatch: "No matching records founds", toolTip: "Sort"},
    pagination: {
      next: "Next page",
      previous: "Previous page",
      rowsPerPage: "Rows per page:",
      displayRows: "of"
    },
    toolbar: {
      search: "Search",
      downloadCsv: "Export",
      print: "Print",
      viewColumns: "View columns",
      filterTable: "Filter table"
    },
    filter: {all: "All", title: "Filters", reset: "Reset"},
    viewColumns: {title: "Show columns", titleAria: "Show/Hide table Ccolumns"},
    selectedRows: {
      text: "row(s) selected",
      delete: "Delete",
      deleteAria: "Delete selected rows"
    }
  },
  myPermissions: "My permissions",
  myProfile: "My profile",
  name: "Name",
  nameSynonym: "Name",
  necessaryDocuments: "Necessary documents!",
  necessaryDocumentsTooltip: "The following document(s) are required to start a case!",
  needVmForConfigurator: "At least one virtual machine for the configurator to function",
  newContact: "New contact",
  newContactToward: "To add a new contact, go to the Server room permissions page!",
  newErrorReport: "New error report",
  newVersionAvailable: "New version available! Please, refresh!",
  newUser: "New user",
  next: "Next",
  no: "No",
  noAffectedServiceSelected: "No affected service is selected!",
  noDataToDisplay: "No data to display!",
  noPageView: "You do not have the appropriate permissions to view this page!",
  noPayableBill: "There is no bill to pay!",
  noChangesInVm: "There are no modifications present for the virtual machine",
  noMaintenance: "No maintenance is currently in progress",
  noPermission: "You do not have the appropriate permissions!",
  noDataWithGivenFilters: "There is no available data with the given filters",
  noMessageSubjectSelected: "No message subject selected!",
  noTaxNumber: "No tax number specified",
  noService: "No service",
  notifications: "Notifications",
  notSelected: "Not selected",
  object: "Object",
  onSiteContact: "On-site contact",
  onSiteContactName: "On-site contact name",
  onSiteContactPhoneNumber: "On-site contact phone number",
  onSiteContactEmail: "On-site contact e-mail",
  offers: "Offers",
  operatingSystem: "Operating system",
  operatingSystemShort: "Op. system",
  operationCannotBeUndone: "The operation cannot be undone!",
  operativePartner: "Operative partner",
  optionalOpSystem: "Optional op. system",
  order: "Order",
  orderLoadError: "There was an error during the load of the order!",
  orderSaveError: "There was an error during the save of the order!",
  osListLoadError: "There was an error during the load of the operating systems!",
  other: "Other",
  otherServiceParameters: "Other service parameters",
  overdueDebt: "Overdue debt",
  overviewOrderProducts: "Overview of products to order",
  overviewOfEstablishments: "Overview of establishments",
  packetLoss: "Packet loss",
  paid: "Paid",
  pageNotFound: "Page not found",
  pageNotFoundLong: "Sorry, we couldn't found the page you are looking for.",
  paperBase: "Paper base",
  passport: "Passport",
  payable: "Payable",
  paymentDate: "Payment date",
  paymentDeadline: "Payment deadline",
  paymentDetails: "Payment details",
  paymentMethod: "Payment method",
  pendingCases: "Pending cases",
  percentage: "Percentage",
  performanceMonitor: "Performance monitor",
  performanceMonitorDesc: "A tool that displays performance graphs of network and other devices",
  period: "Period",
  periodEnd: "End of period:",
  permissionPrivilege: "Permission privilege",
  personalData: "Personal data",
  phone: "Phone",
  phoneNumber: "Phone number",
  phoneNumberHelperTxt: "+<country code> <area> <phone number>",
  phoneNumberType: "Phone number type",
  pleaseWait: "Please wait...",
  plotlyLocale: {locale: "en"},
  portalAccess: "Portal access",
  portId: "Port id",
  position: "Position",
  positions: {
    ceo: "CEO",
    cio: "CIO",
    cto: "CTO",
    ciso: "CISO",
    cfo: "CFO",
    staff: "Staff"
  },
  post: "Post",
  previousMaintenance: "Previous maintenance",
  privacyPolicy: "Privacy policy",
  privilegeLevels: "Privilege levels",
  privilegeLevelsLocalization: {
    admin: "Has full authority.",
    all: "All users have access to the menu items \"Support\", \"Notifications\", \"Company data\", their \"User profile\" and our news.",
    consolePrivilege: "You can take over the Customer's server by remote access, but you cannot access it physically.",
    financialPartner: "Can manage their error reports, customer service cases, and view their services.",
    hardwareExchangePrivilege: "With console privilege, you have the right to request the server to a designated room and perform repair work. (This does not apply to a rented server owned by the Service Provider!)",
    hardwareDeliveryPrivilege: "You are entitled to take the device away, and you also have Console and Hardware exchange privilege. (This does not apply to a rented server owned by the Service Provider!)",
    itPartner: "Has access to all functions except user registration.",
    financialContact: "Can view billing and contract information, manage your customer service matters.",
    technicalContact: "Can manage their error reports, customer service cases, and view their services."
  },
  profile: "Profile",
  pushMe: "Push Me!",
  range: "Range",
  registeringCourt: "Registering court",
  registrationNumber: "Company registration number",
  registrationForFreeIceCream: "Registration for Free Ice Cream",
  reject: "Reject",
  rejectMaintenance: "Maintenance rejection",
  rejectReason: "Reject reason",
  refresh: "Refresh",
  relatedBills: "Related bills",
  relatedErrorTickets: "Related error tickets",
  relatedServices: "Related services",
  relatedServicesChange: "Linked service ID",
  relatedServicesID: "Related service ID",
  relatedSite: "Equipment address",
  remoteAccount: "Remote account",
  reportDate: "Report date",
  reportedAsErrorTicket: "Reported as an error ticket",
  reportEndDate: "Closing date",
  reporterName: "Reporter's name",
  requestForOffer: "Request for offer",
  requestForOfferInProgress: "Request for offer in progress",
  requiredPaymentDetails: "The company and payment details are also required to place an order",
  reservedDomainHint: "Already reserved or incorrect domain(s) specified!",
  rights: "Rights",
  role: "Role",
  roles: {
    admin: "Admin",
    finance: "Financial Contact",
    technical: "Technical Contact"
  },
  routerName: "Router name",
  routerSerialNumber: "Router serial number (S/N)",
  routerType: "Router type",
  save: "Save",
  scheduled: "Scheduled",
  scheduledLoginLogout: "Scheduled login and logout",
  sdWanDesc: "SD-WAN service overview interface",
  sdWanId: "SD-WAN id",
  sdwanSites: "SD-WAN Sites",
  selectObjects: "Select objects",
  send: "Send",
  sendInvitation: "Send Invitation",
  sendRequest: "Send request",
  sendRequestForOffer: "Send request for offer",
  senderDetails: "Sender details",
  serverRoomAccess: "Server room access",
  service: "Service",
  serviceCategories: {
    internet: "Data and internet services",
    dataCenter: "Data center services",
    other: "Other services",
    cloud: "Cloud services",
    office: "Office IT systems",
    security: "IT security services",
    communication: "Telephone and communication services"
  },
  serviceAddress: "Service address",
  serviceAdministration: "Service administration",
  serviceError: "Service error",
  serviceSynonym: "Service",
  services: "Services",
  serviceId: "Service id",
  serviceParameters: "Service parameters",
  serviceRelatedNotification: "Service related notification",
  serviceStatus: "Service status",
  serviceType: "Service type",
  serverName: "Server name",
  serverRoom: "Server room",
  serverRoomAccesses: "Server room accesses",
  serverRoomAccessPermit: "Server room access permit",
  serverRoomPermissions: "Server room permissions",
  setting: "Setting",
  settings: "Settings",
  showAllVms: "Show all VMs",
  showApplicationTraffic: "Show application traffic data",
  showCode: "Show me the code",
  showInterfaceData: "Show interface data",
  showLineBreaks: "Show line breaks",
  showSiteDetails: "Show site details",
  showTrafficData: "Show traffic data",
  shId: "SH identifier",
  signatureDate: "Signature date",
  site: "Site",
  siteAddress: "Site address",
  siteOrder: "Site order",
  sites: "Sites",
  solution: {
    forGeneralBusinessUse: "for general business use",
    forHighComputingNeeds: "for high computing needs",
    forMediumComputingNeeds: "for medium computing needs",
    toHostLargeContent: "to host large content",
    customVmDescription: "custom configuration for individual needs"
  },
  solutionListLoadError: "There was an error during the load of the solutions!",
  solutions: "Solutions",
  sound: "Sound",
  sortByDate: "Sort by date",
  speedMbps: "Speed (MBPS)",
  ssoRolesTooltip: "Please contact our Customer Service regarding the registration of additional users.",
  startDate: "Start date",
  startDateSynonym: "Start date",
  starWarsMovies: "Star Wars movies",
  status: "Status",
  statusChangeDate: "Date of status change",
  statusError: "Status: Error",
  statusOk: "Status: OK",
  statusSynonym: "Status",
  statusUnknown: "Status: Unknown",
  statusWarning: "Status: Warning",
  storage: "Storage",
  storageType: "Storage type",
  storageTypeListLoadError: "There was an error during the load of the storage types!",
  successCaseStart: "Successful case starts",
  successCreate: "Successful creation",
  successEdit: "Successful modification",
  successEOrderSend: "Successful e-order",
  successErrorTicketSend: "Successful error ticket recording",
  successDelete: "Successful delete",
  successVmCreateTitle: "Successful VM creation",
  successVmDeleteTitle: "Successful VM delete",
  successVmEditTitle: "Successful VM modification",
  successfulAcceptance: "Successful acceptance",
  successfulModification: "Successful modification",
  successfulEntry: "Successful entry",
  successfulRejection: "Successful rejection",
  summary: "Summary",
  support: "Support",
  suspendedEstablishmentContracts: "Suspended establishment contracts",
  taxNumber: "Tax number",
  technicalBackground: "Technical background",
  technicalContact: "Customer Portal - Technical Contact",
  technicalId: "Technical ID",
  technicalOverview: "Technical overview",
  technical_support: "Support",
  testPeriod: "Test period",
  timeSelectorOptions: {s: "s", m: "m"},
  to: "to",
  toDate: "To date",
  tooLongName: "The name you entered is too long!",
  tooLongNote: "The note is too long!",
  tooManyVmAndReservedDomainHint: "You have reached the max. VM number ($_{count} pcs) and reserved or incorrect domain(s) specified!",
  tooManyVmHint: "You have reached the max. VM number ($_{count} pcs)!",
  total: "Total",
  totalDebt: "Total debt",
  traffic: "Traffic",
  trafficData: "Traffic data",
  transfer: "Transfer",
  trialTimeExpire: "Test period end: ",
  tryIt: "Try it",
  type: "Type",
  underAssignment: "Under assignment",
  underConstruction: "Under construction",
  uniqueOffer: "unique offer",
  unknownAddress: "Unknown address",
  unpaidBills: "Unpaid bills",
  uploadFiles: "Upload files",
  urls: {
    aboutInvitech: "https://www.invitech.hu/about-invitech",
    references: "https://www.invitech.hu/corporations",
    socialResponsibility: "https://www.invitech.hu/corporate-social-responsibility",
    centralizedProcurement: "https://www.invitech.hu/centralised-procurement",
    forLargeCompanies: "https://www.invitech.hu/services",
    forSmallAndMediumCompanies: "https://www.invitech.hu/small-and-medium-sized-enterprises",
    forInstitutions: "https://www.invitech.hu/centralised-procurement",
    businessServices: "https://www.invitech.hu/services",
    infrastructureServices: "https://www.invitech.hu/infrastructure",
    news: "https://www.invitech.hu/news",
    dataProcessingCode: "https://www.invitech.hu/storage/attachments/documents/5f7b2f7a01f4a/DATA%20PROCESSING%20CODE_Invitech_EN.pdf",
    gtc: "https://www.invitech.hu/general-terms-and-conditions",
    certificates: "https://www.invitech.hu/our-certificates",
    impress: "https://www.invitech.hu/invitech-impress",
    providerInformation: "https://www.invitech.hu/provider-information"
  },
  user: "User",
  userData: "User data",
  users: "Users",
  usersAndPermissions: "Users and permissions",
  version: "Version",
  whichCompanyComeFromQuestion: "Which company do you come from?",
  validity: "Validity",
  virtualMachine: "Virtual machine",
  virtualMachineChange: "Virtual machine changing",
  virtualMachineName: "Virtual machine's name",
  virtualMachineOrder: "Virtual machine ordering",
  virtualServer: "Virtual server",
  virtualServerDashboard: "Virtual server dashboard",
  virtualServerVm: "Virtual server (VM)",
  vmConfigurator: "VM Configurator",
  vmCounterLoadError: "There was an error during the load of the vm counter!",
  vmCreateInProgressText: "The requested operation is taking longer than average, so please be patient. We don't want to wait any longer, so feel free to close this window and we'll notify you via email as soon as we're done.",
  vmCreateInProgressTitle: "Creating VM in progress",
  vmDeleteError: "There was an error during the delete of the vm!",
  vmEditInProgressTitle: "Modify VM in progress",
  vmInProgressError: "Operation in progress!",
  vmListLoadError: "There was an error during the load of the vm list!",
  vmLoadError: "There was an error during the load of the vm!",
  vmNameChangeError: "An error occurred while changing the VM name!",
  vmNameChangeSuccess: "Successful VM name change",
  vmTemplateLoadError: "There was an error during the load of the vm details!",
  vmUpdateError: "There was an error during the update of the vm!",
  voice: "Voice",
  week: "Week",
  workLog: "Work log",
  yes: "Yes"
};

export default en;
