import React, {createContext, useState} from "react";
import {useLocalStorage} from "../../utils/useLocalStorage";

const initialCookieInfo = {
  requiredCookie: false,
  thirdPartyCookie: false
};

export const CookieContext = createContext({
  cookieInfo: initialCookieInfo
});

const CookieProvider = props => {
  const [cookieInfo, setCookieInfo] = useLocalStorage("gdpr", initialCookieInfo);

  const provider = {
    cookieInfo,
    setCookie: (cookie) => {
      setCookieInfo({
        requiredCookie: cookie.requiredCookie,
        thirdPartyCookie: cookie.thirdPartyCookie
      });
    }
  };

  return (
    <CookieContext.Provider value={provider}>
      {props.children}
    </CookieContext.Provider>
  );

};

export default CookieProvider;
