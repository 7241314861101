import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {CircularProgress, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {CustomerContext} from "../../context/CustomerContext";
import {getCompanyInfo} from "../../api/companyResource";
import {useAuth} from "oidc-react";
import Tooltip from "@mui/material/Tooltip";
import company from "../../assets/images/company.png";
import {UserContext} from "../../context/UserContext";

const hiddenItems = ["cmId", "picture", "rights"];

const CustomerInformation = () => {
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const [dataLoading, setDataLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState({});
  const notYet = true; // egyelőre ne jelenjen meg a gomb !

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setDataLoading(true);
      getCompanyInfo(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => setCompanyInfo(res.data))
        .catch(error => showRequestError(error, langContext.dictionary.errorMessages.userDataLoadError))
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  return (
    <Grid container marginTop={5}>
      <Grid container item direction="row" justifyContent="start" alignItems="center">
        <Grid item xs={12} sm={6} marginBottom={1}>
          <Typography variant={"h4"}>{langContext.dictionary.customerInfo}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="end">
          {!notYet ?
            (<Tooltip title={userContext?.userInfo?.innerUser ? langContext.dictionary.disabledImpersonate : ""}>
              <span>
                <Button disabled={userContext?.userInfo?.innerUser}
                        seleniumid="CustomerInformationChangeButton"
                        color="secondary"
                        endIcon={<KeyboardArrowRightIcon/>}>
                  {langContext.dictionary.dataChange}
                </Button>
              </span>
            </Tooltip>) : null}
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Paper elevation={4} sx={{width: "100%", paddingTop: 3, paddingBottom: 3, paddingLeft: 1, paddingRight: 1}}>
          {!dataLoading ?
            <Grid container direction="row" justifyContent="center" alignItems="start">
              <Grid container item xs={12} sm={4} direction="row" justifyContent="center" alignItems="center">
                <div style={{width: "80%", height: "80%"}}>
                  <img alt="companyLogo" style={{maxWidth: "100%", maxHeight: "auto"}} src={company}/>
                </div>
                <Tooltip title={userContext?.userInfo?.innerUser ? langContext.dictionary.disabledImpersonate : ""}>
                  <span>
                    <Button disabled={userContext?.userInfo?.innerUser}
                            component="label"
                            seleniumid="CustomerLogoChangeButton"
                            color="secondary"
                            startIcon={<CameraAltIcon/>}>
                      {langContext.dictionary.changePhoto}
                      <input type="file" hidden/>
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid container item xs={12} sm={8}>
                {Object.entries(companyInfo).filter(([key]) => !hiddenItems.includes(key)).map(([key, value]) => (
                  <Grid
                    container item key={`customerInfo_${key}`} sx={{marginTop: 1, marginBottom: 1}}
                    direction="row" justifyContent="center" alignItems="center"
                  >
                    <Grid item xs={5} sm={4}>
                      <Typography variant={"subtitle1"}>{langContext.dictionary[key]}</Typography>
                    </Grid>
                    <Grid item xs={7} sm={7}>
                      <Typography variant={"subtitle2"}>{value}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider/>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid> :
            <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
              <CircularProgress color="secondary"/>
            </Grid>
          }
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CustomerInformation;
