import React, {useContext} from "react";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {formatDate, formatTime} from "../../utils/dateFormat";
import moment from "moment";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DayAndNightIconSwitcher from "./DayAndNightIconSwitcher";

const useStyles = makeStyles(theme => ({
  duration: {
    fontSize: 23,
    float: "left",
  },
  icon: {
    color: ({active}) => active ? theme.palette.mainButton.main : theme.palette.primary.light,
    fontSize: 45
  },
  rightGrid: {
    [theme.breakpoints.up('lg')]: {
      textAlign: "right"
    }
  }
}));

const MaintenanceGrid = ({data, active}) => {
  const classes = useStyles({active: active});
  const languageContext = useContext(LanguageContext);

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2}>
      <Grid item container spacing={2} alignItems="center" justifyContent="center" xs={12} sm={4} md={3}>
        <Grid item xs={12} md={5} className={classes.rightGrid}>
          <WarningRoundedIcon fontSize="large" className={classes.icon}/>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography className={classes.text}>
            {languageContext.dictionary.maintenance}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Typography style={{fontWeight: "bold"}}>{data.title}</Typography>
        {data.siteList.map((site, index) => <Typography key={`site_${index}`}>{site}</Typography>)}
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <CalendarMonthOutlinedIcon className={classes.duration}/>
        <Typography fontWeight={"bold"}>{formatDate(data.startDate)}</Typography>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <DayAndNightIconSwitcher fontSize={25} startHour={moment(data.startDate).hour()} endHour={moment(data.endDate).hour()}/>
        <Typography>{`${formatTime(data.startDate)}-${formatTime(data.endDate)}`}</Typography>
      </Grid>
    </Grid>
  );
};

MaintenanceGrid.propTypes = {
  data: PropTypes.object,
  active: PropTypes.bool,
};

export default MaintenanceGrid;