import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Tooltip from "@mui/material/Tooltip";
import {LanguageContext} from "../../../context/LanguageContext";

const RepeaterGrid = ({dataList, defaultNewObject, deleteFlag, idName, ...props}) => {
  const languageContext = useContext(LanguageContext);
  const [list, setList] = useState(dataList);

  useEffect(() => {
    setList(dataList);
  }, [dataList]);

  const handleAddNew = () => {
    let newList = [...list];
    newList.push(defaultNewObject || {});
    setList(newList);
    if (props.handleAddNew) {
      props.handleAddNew(newList);
    }
  };

  const handleDelete = (index) => {
    let newList = [...list];
    if (newList[index][idName] && deleteFlag) {
      newList[index][deleteFlag] = true;
    } else {
      newList.splice(index, 1);
    }
    setList(newList);
    if (props.handleDelete) {
      props.handleDelete(newList);
    }
  };

  return list?.map((data, index) => !data[deleteFlag] ? (
      <Grid container spacing={2} item xs={12} key={`repeaterGrid_${index}`} justifyContent="center">
        {props.children(data, index)}
        <Grid item xs={1}>
          <Tooltip title={languageContext.dictionary[index === 0 ? "add" : "delete"]} arrow>
            <IconButton onClick={() => index === 0 ? handleAddNew() : handleDelete(index)} sx={{marginTop: 1}}>
              {index === 0 ? <AddCircleOutlineOutlinedIcon color="secondary"/> : <DeleteIcon color="error"/>}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    ) : null
  );
};

RepeaterGrid.propTypes = {
  dataList: PropTypes.array,
  handleAddNew: PropTypes.func,
  handleDelete: PropTypes.func,
  deleteFlag: PropTypes.string,
  defaultNewObject: PropTypes.object,
  idName: PropTypes.string
};

export default RepeaterGrid;
