import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomAutocomplete from "../formFields/CustomAutocomplete";
import {TextField} from "@mui/material";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import UserEditor from "../user/UserEditor";
import {CustomerContext} from "../../context/CustomerContext";
import {fullNameByLanguage} from "../../utils/commonFunctions";

const ContactForm = (props) => {
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const [contact, setContact] = useState(props.data);
  const [dialogOpen, setDialogOpen] = useState(false);
  const defaultContact = {
    contactId: "",
    firstName: "",
    lastName: "",
    company: customerContext.selectedCustomer.name,
    documentType: {},
    documentNumber: ""
  };

  useEffect(() => {
    props.setDataList(curr => {
      let updated = [...curr];
      updated[props.index] = contact;
      return updated;
    });
  }, [contact]);

  const handleFormChange = (field, newValue) => {
    setContact(curr => ({...curr, [field]: newValue}));
  };

  const handleContactChange = (event, newContact) => {
    event.preventDefault();
    if (newContact) {
      if (newContact.value === "new") {
        setDialogOpen(true);
      }
      setContact({
        ...defaultContact,
        contactId: newContact.value,
        firstName: newContact.firstName,
        lastName: newContact.lastName,
        documentType: newContact.documentType,
        documentNumber: newContact.documentNumber
      });
    } else {
      setContact(defaultContact);
    }
  };

  const handleNewContact = (newData) => {
    setDialogOpen(false);
    props.setSelectOptions(curr => ({
      ...curr, contacts: [...curr.contacts, {
        label: fullNameByLanguage(languageContext.language.id, newData.firstName?.value, newData.lastName?.value),
        value: newData.poId,
        contactId: newData.poId,
        firstName: newData.firstName?.value,
        lastName: newData.lastName?.value,
        documentType: {value: mergeDocumentType(newData.personalId?.valueMsr)},
        documentNumber: newData.personalId?.value
      }]
    }));

    setContact({
      ...defaultContact,
      contactId: newData.poId,
      firstName: newData.firstName?.value,
      lastName: newData.lastName?.value,
      documentType: {value: mergeDocumentType(newData.personalId?.valueMsr)},
      documentNumber: newData.personalId?.value
    });
  };

  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
        <Typography>{props.index === 0 ? languageContext.dictionary.authorizedPerson : languageContext.dictionary.contactPerson}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
        <CustomAutocomplete
          value={contact.contactId}
          handleChange={(event, newValue) => handleContactChange(event, newValue)}
          options={props.selectOptions.contacts}
          size="small"
          selectOptionsLoading={props.selectOptionsLoading}
          required
          label={props.index === 0 ? languageContext.dictionary.authorizedPerson : languageContext.dictionary.contactPerson}
          disabled={props.disabled}
        />
        {props.index === 0 ? (
          <Typography color={"secondary"} style={{textAlign: "start", paddingLeft: 5}}>{languageContext.dictionary.newContactToward}</Typography>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
        <Typography>{languageContext.dictionary.lastName}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
        <TextField
          value={contact.lastName || ""}
          onChange={event => handleFormChange("lastName", event.target.value)}
          size="small"
          required
          label={languageContext.dictionary.lastName}
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
        <Typography>{languageContext.dictionary.firstName}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
        <TextField
          value={contact.firstName || ""}
          onChange={event => handleFormChange("firstName", event.target.value)}
          size="small"
          required
          label={languageContext.dictionary.firstName}
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
        <Typography>{languageContext.dictionary.whichCompanyComeFromQuestion}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
        <TextField
          multiline
          value={contact.company || ""}
          onChange={event => handleFormChange("company", event.target.value)}
          size="small"
          label={languageContext.dictionary.whichCompanyComeFromQuestion}
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
        <Typography>{languageContext.dictionary.documentType}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
        <CustomAutocomplete
          value={contact.documentType?.value}
          handleChange={(event, newValue) => handleFormChange("documentType", newValue || {})}
          options={props.selectOptions.documentTypes}
          size="small"
          selectOptionsLoading={props.selectOptionsLoading}
          required
          label={languageContext.dictionary.documentType}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
        <Typography>{languageContext.dictionary.documentNumber}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
        <TextField
          value={contact.documentNumber || ""}
          onChange={event => handleFormChange("documentNumber", event.target.value)}
          size="small"
          required
          label={languageContext.dictionary.documentNumber}
          fullWidth
          disabled={props.disabled}
          placeholder={contact.documentType?.placeholder}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <UserEditor
        user={contact}
        open={dialogOpen}
        onEditCancel={() => {
          setContact(defaultContact);
          setDialogOpen(false);
        }}
        onItemEdited={handleNewContact}
      />
    </Grid>
  );

  function mergeDocumentType(personalIdValueMsr) {
    switch (personalIdValueMsr) {
      case "PASSPORTID":
        return 1;
      case "IDCARDID":
        return 2;
      case "DRIVLIC":
        return 3;
      default:
        return "";
    }
  }
};

ContactForm.propTypes = {
  data: PropTypes.object.isRequired,
  setDataList: PropTypes.func.isRequired,
  selectOptions: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  setSelectOptions: PropTypes.func
};

export default ContactForm;