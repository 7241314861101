// you can find available icons names here: https://mui.com/material-ui/material-icons/ and https://material.io/resources/icons/?style=baseline
// icon names must be lowercase and used with underscore
import HomePage from "./routes/HomePage";
import TechnicalOverviewPage from "./routes/TechnicalOverviewPage";
import BillingPage from "./routes/BillingPage";
import ServicesPage from "./routes/ServicesPage";
import ServicePage from "./routes/ServicePage";
import AdministrationPage from "./routes/AdministrationPage";

const menuItems = [
  {
    label: "home",
    url: "/home",
    component: HomePage,
    icon: "home",
  },
  {
    label: "technicalOverview",
    url: "/technicalOverview",
    component: TechnicalOverviewPage,
    icon: "table_view",
    access: ['ADMIN', 'CP_FIN','CP_TECH']
  },
  {
    label: "service",
    url: "/service",
    component: ServicePage,
    icon: "table_view",
    access: ['ADMIN', 'CP_FIN','CP_TECH']
  },
  {
    label: "services",
    url: "/services",
    component: ServicesPage,
    icon: "electrical_services",
    access: ['ADMIN', 'CP_FIN','CP_TECH']
  },
  {
    label: "administration",
    url: "/administration",
    component: AdministrationPage,
    icon: "electrical_services",
    authRequired: true,
    access: ['ADMIN', 'CP_FIN','CP_TECH']
  },
  {
    label: "billing",
    url: "/billing",
    component: BillingPage,
    icon: "payments",
    access: ['ADMIN', 'CP_FIN','CP_TECH']
  }
];

const webAccessGroups = [
  "ALL_CUSTOMER",
  "CP_USER",
  "SALES"
];

const environment = {
  TEST: {
    title: "Invitech Customer Portal Dev",
    name: "test",
    apiRoot: window._env_.REACT_APP_API_URL,
    csspApiRoot: window._env_.REACT_APP_CSSP_API_URL,
    csspRoot: window._env_.REACT_APP_CSSP_URL,
    tpRoot: window._env_.REACT_APP_TP_URL,
    menuItems: menuItems,
    oidcRealm: window._env_.REACT_APP_OIDC_REALM,
    oidcUrl: window._env_.REACT_APP_OIDC_URL,
    oidcClientId: window._env_.REACT_APP_CLIENT_ID,
    gaTrackingId: window._env_.REACT_GA_TRACKING_ID,
    hjid: window._env_.REACT_HJID,
    umamiUrl: window._env_.REACT_UMAMI_URL,
    umamiWebsiteDataId: window._env_.REACT_UMAMI_DATA_WEBSITE_ID,
    webAccessGroups
  },
  PROD: {
    title: "Invitech Customer Portal",
    name: "production",
    apiRoot: window._env_.REACT_APP_API_URL,
    csspApiRoot: window._env_.REACT_APP_CSSP_API_URL,
    menuItems: menuItems,
    oidcRealm: window._env_.REACT_APP_OIDC_REALM,
    oidcUrl: window._env_.REACT_APP_OIDC_URL,
    oidcClientId: window._env_.REACT_APP_CLIENT_ID,
    gaTrackingId: window._env_.REACT_GA_TRACKING_ID,
    hjid: window._env_.REACT_HJID,
    umamiUrl: window._env_.REACT_UMAMI_URL,
    umamiWebsiteDataId: window._env_.REACT_UMAMI_DATA_WEBSITE_ID,
    webAccessGroups
  }
};

let envVar = {};
switch (window._env_.REACT_APP_STAGE) {
  case "TEST":
    envVar = environment.TEST;
    break;
  case "PROD":
    envVar = environment.PROD;
    break;
  default:
    envVar = environment.TEST;
}

let instanceVar = "";
switch (window._env_.REACT_APP_OIDC_REALM) {
  case "TP":
    instanceVar = "TP";
    break;
  case "Invitech":
    instanceVar = "INV";
    break;
  default:
    instanceVar = "TP";
    break;
}

export const env = envVar;
export const instance = instanceVar;
