import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Tooltip, Typography} from "@mui/material";

const SimpleConfirmDialog = props => {
  const langContext = useContext(LanguageContext);

  return (
    <Dialog
      data-testid="invitationDialog"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle onClose={props.handleClose} color="secondary">
        {props.dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>{langContext.dictionary.cancel}</Button>
        <Button onClick={props.handleAction} variant="contained" color="secondary" autoFocus>
          {props.actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


SimpleConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogContentText: PropTypes.any
};

export default SimpleConfirmDialog;