import * as moment from "moment";
import {dateFormat, dateTimeFormat, dateTimeSecFormat, timeFormat} from "./commonValues";

export function formatDate(date) {
  return date ? moment(date).format(dateFormat) : null;
}

export function formatDateTime(date) {
  return date ? moment(date).format(dateTimeFormat) : null;
}

export function formatDateTimeSec(date) {
  return date ? moment(date).format(dateTimeSecFormat) : null;
}

export function formatTime(date) {
  return date ? moment(date).format(timeFormat) : null;
}

export function humanizeDate(date) {
  const diff = moment().diff(date, 'hours');

  if (diff > 25) {
    return formatDate(date);
  }

  return date ? moment(date).calendar().split(' ')[0] : null;
}

export function humanizeDateTime(date, locale = 'hu') {
  const diff = moment().diff(date, 'hours');

  if (!date) {
    return null;
  }

  if (diff > 25) {
    return formatDateTime(date);
  }

  const day = moment(date).calendar().split(' ')[0];
  const time = moment(date).format(timeFormat);
  return `${day} ${time}`;
}
