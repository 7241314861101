import React from "react";
import ErrorTicketForm from "../errorTicket/ErrorTicketForm";

const ErrorTicketRecordingTab = () => {
  return (
    <>
    <ErrorTicketForm/>
    </>
  );
};

export default ErrorTicketRecordingTab;