import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";
import axios from "axios";

const resource = "/ticket";

export const getErrorTicketServiceList = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/serviceId/list`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const getErrorTicketShortDescription = async (token, cmId, serviceId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/shortDesc`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, params: {serviceId: serviceId}});
};

export const getErrorTicketList = async (token, cmId, active, serviceId, query, filters, sourceSystemTicketId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/ticket/list`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {
    headers: {Authorization: authStr},
    params: {
      active: active,
      serviceId: serviceId,
      from: filters.from,
      to: filters.to,
      limit: query?.pageSize || 10,
      offset: query?.page || 0,
      sourceSystemTicketId: sourceSystemTicketId
    }
  });
};

export const getErrorTicketDetails = async (token, cmId, ticketId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/${ticketId}/ticket`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};
export const getErrorTicketContactList = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/contactList`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};
export const getOpenErrorTicketList = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/${cmId}/serviceId/openTicket/list`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const saveErrorTicket = async (token, cmId, errorTicketObj) => {
  let url = `${env.apiRoot}${resource}/${cmId}/saveTicket`;
  const authStr = "Bearer ".concat(token);
  return await axios.post(url, errorTicketObj, {headers: {Authorization: authStr}});
};

export const getErrorTicketMessage = async (token, serviceType) => {
  let url = `${env.apiRoot}${resource}/errormessage/${serviceType}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};