import React, {useContext} from "react";
import {LanguageContext} from "../context/LanguageContext";
import {Redirect, useLocation} from "react-router-dom";
import TabPageWithUrl from "../components/common/TabPageWithUrl";
import AllServicesTab from "../components/services/AllServicesTab";
import ContractsTab from "../components/services/ContractsTab";

const ServicesPage = () => {
  const languageContext = useContext(LanguageContext);
  const location = useLocation();
  const tabs = [
    {pathname: "/services/all", index: 0, label: "allServices", child: AllServicesTab},
    {pathname: "/services/contracts", index: 1, label: "contracts", child: ContractsTab},
  ];

  return (
    <>
      {location.pathname === "/services" ? (
        <Redirect to={tabs[0].pathname}/>
      ) : null}
      <TabPageWithUrl tabs={tabs} title={languageContext.dictionary.services} seleniumid={"ServicesPage"}/>
    </>
  );
};

export default ServicesPage;
