import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import DataTable from "../datatable/DataTable";
import {saveCompany, searchCompany} from "../../api/companyResource";
import {debounce, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {UserContext} from "../../context/UserContext";
import {CustomerContext} from "../../context/CustomerContext";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import CompanySelectorExpandedRow from "./CompanySelectorExpandedRow";
import {useAuth} from "oidc-react";
import {useHistory} from "react-router-dom";

const defaultTableOptions = {
  rowsPerPage: 5,
  print: false,
  filter: false,
  viewColumns: false,
  searchText: ""
};

const CompanySelectorDialog = props => {
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [tableOptions, setTableOptions] = useState({...defaultTableOptions});
  const expandOptions = {
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => <CompanySelectorExpandedRow customer={data[rowMeta.dataIndex]} closeDialog={props.handleClose}/>,
  };

  useEffect(() => {
    if (props.open && userContext.userInfo.innerUser) {
      setTableOptions(curr => {
        return ({...curr, ...expandOptions});
      });
    }
  }, [data, props.open]);

  const loadCompaniesLazy = debounce((action, tableState) => {
    if (["search", "changeRowsPerPage", "changePage"].includes(action) && tableState.searchText?.length >= 3) {
      setLoadingData(true);
      searchCompany(auth.userData.access_token, tableState.page + 1, tableState.rowsPerPage, tableState.searchText)
        .then(res => {
          const updatedCustomerList = res.data.customerList?.map(customer => {
            customer.name = customer.customerName;
            customer.id = customer.cmId;
            customer.vat = customer.taxNumber;
            delete customer.customerName;
            delete customer.cmId;
            delete customer.taxNumber;
            return customer;
          });
          setData(updatedCustomerList);
          setTableOptions(curr => ({
            ...curr,
            page: res.data.pageIndex - 1,
            rowsPerPage: res.data.pageSize,
            count: res.data.rowCount,
          }));
          setLoadingData(false);
        });
    }
  }, 300);

  const onTableChange = (action, tableState) => {
    if (action !== "propsUpdate") {
      setTableOptions(curr => ({
        ...curr,
        page: tableState.page,
        rowsPerPage: tableState.rowsPerPage,
        count: tableState.count,
        searchText: tableState.searchText,
      }));
      if (userContext?.userInfo?.innerUser) {
        loadCompaniesLazy(action, tableState);
      }
    }
  };

  useEffect(() => {
    if (props.open && auth.userData) {
      setColumns(getColumns());
      if (userContext.userInfo.allCustomer) {
        setTableOptions(curr => ({
          ...curr,
          onTableChange: onTableChange,
          serverSide: true,
          searchAlwaysOpen: true,
          searchPlaceholder: langContext.dictionary.minimum3CharactersRequired,
        }));
      } else {
        setTableOptions(curr => ({
          ...curr,
          onTableChange: onTableChange,
        }));
        setLoadingData(true);
        setData(userContext.userInfo.companyList);
        setLoadingData(false);
      }
    }
  }, [props.open, auth.userData, userContext.userInfo]);

  useEffect(() => {
    setColumns(getColumns());
  }, [customerContext.selectedCustomer]);

  const selectCompany = (companyId, rowData) => {
    const contact = {
      ...customerContext.selectedContact,
      id: companyId,
      name: rowData?.name,
      vat: rowData?.vat,
      roles: rowData?.roles
    };

    if (companyId !== customerContext.selectedCustomer.id) {
      customerContext.setCustomer(rowData);
      customerContext.setContact(contact);
      saveCompany(auth.userData.access_token, {
        cmId: companyId,
        username: userContext.userInfo.email,
        contactEmail: userContext.userInfo.email,
        contactMobile: userContext.userInfo.mobile,
        contactFirstName: userContext.userInfo.firstName,
        contactLastName: userContext.userInfo.lastName,
        poId: userContext.userInfo.poid
      })
        .then(() => {
          history.push("/home");
        })
        .catch(error => showRequestError(error, langContext.dictionary.companySelectionError));
      props.handleClose();
    }
  };

  const handleDialogClose = (event, reason) => {
    // “disableBackdropClick of ForwardRef(Dialog) is deprecated”
    if (reason === "backdropClick") {
      return false;
    }

    if (typeof props.handleClose === "function") {
      props.handleClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth={"lg"}
      disableEscapeKeyDown
      disableScrollLock
    >
      <DialogTitle onClose={props.handleClose} color="secondary">
        {langContext.dictionary.companySelector}
      </DialogTitle>
      <DialogContent>
        <div data-testid="companySelectorTable">
          <Typography style={{marginBottom: 10}}>
            {langContext.dictionary.companySelectorTitle}
          </Typography>
          <DataTable
            columns={columns}
            data={data}
            isLoading={loadingData}
            options={tableOptions}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{padding: '24px'}}>
        <Button onClick={props.handleClose} color="primary" disabled={!customerContext?.selectedCustomer?.name}>
          {langContext.dictionary.close}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function getColumns() {
    let columns = [
      {
        name: "name",
        label: langContext.dictionary.companyName
      },
      {
        name: "vat",
        label: langContext.dictionary.taxNumber
      },
    ];
    if (!userContext.userInfo.innerUser) {
      columns.push({
        name: "id",
        label: " ",
        align: "center",
        render: (value, rowData) =>
          <Button
            data-testid="selectCompanyButton"
            type="submit"
            variant="contained"
            size="small"
            color={customerContext?.selectedCustomer?.id && value === customerContext.selectedCustomer.id ? "mainButton" : "mainButtonReverse"}
            style={{width: 120}}
            onClick={() => selectCompany(value, rowData)}
          >
            {customerContext?.selectedCustomer?.id && value === customerContext.selectedCustomer.id ? langContext.dictionary.actual : langContext.dictionary.choose}
          </Button>
      });
    }
    return columns;
  }

};

CompanySelectorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CompanySelectorDialog;