import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {hotjar} from "react-hotjar";
import ReactGA from "react-ga4";
import {CookieContext} from "../components/cookies/CookieContext";
import {useAuth} from "oidc-react";
import axios from "axios";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";
import {getUserInfo} from "../api/contactResource";

export const initialCustomerInfo = {
  id: "",
  name: "",
  vat: "",
  roles: [],
  analyticsIdentify: () => {
  }
};

export const initialContactInfo = {
  // customer
  id: "",
  name: "",
  vat: "",
  // contact
  poid: "",
  email: "",
  mobile: "",
  firstName: "",
  lastName: "",
  roles: [],
  loaded: false
};

export const CustomerContext = createContext({
  selectedCustomer: initialCustomerInfo,
  selectedContact: initialContactInfo
});

const CustomerProvider = props => {
  const auth = useAuth();
  const cookieContext = useContext(CookieContext);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedContact, setSelectedContact] = useState({});

  const analyticsIdentify = useCallback((allowed) => {
    if (allowed) {
      ReactGA.set({user_id: selectedCustomer?.id});
      hotjar.identify(selectedCustomer?.id, {userProperty: 'value'});
    }
  }, [selectedCustomer?.id]);

  useEffect(() => {
    analyticsIdentify(cookieContext.cookieInfo.thirdPartyCookie);
  }, [analyticsIdentify, cookieContext.cookieInfo.thirdPartyCookie]);

  const provider = {
    selectedCustomer: selectedCustomer,
    selectedContact: selectedContact,
    setCustomer: (customer) => setSelectedCustomer(customer),
    setContact: (contact) => {
      setContactInfo(contact);
    },
    analyticsIdentify: analyticsIdentify
  };

  return (
    <CustomerContext.Provider value={provider}>
      {props.children}
    </CustomerContext.Provider>
  );

  async function setContactInfo(contact) {
    // axios Selected-Contact, this is not that contact, wrong name choice
    axios.defaults.headers.common['Selected-Contact'] = auth.userData.profile.preferred_username;
    axios.defaults.headers.common['Customer-Id'] = contact.id;
    axiosWithInterceptors.defaults.headers.common['Selected-Contact'] = auth.userData.profile.preferred_username;
    axiosWithInterceptors.defaults.headers.common['Customer-Id'] = contact.id;
    if (!contact.roles || contact.roles?.length === 0) {
      const promise = await getUserInfo(auth.userData.access_token, contact.poid);
      contact.roles = promise.data.rights.map(r => r.value);
    }
    contact.loaded = true;
    setSelectedContact(contact);
  }

};

export default CustomerProvider;
