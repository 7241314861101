import React, {useContext} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {CardActions, CardHeader, Divider} from "@mui/material";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {useHistory} from "react-router-dom";
import CurrencyAvatar from "../common/CurrencyAvatar";
import CurrencyTypography from "../common/CurrencyTypography";

const useStyles = makeStyles(theme => ({
  rowValue: {
    fontWeight: "bold",
    fontSize: 14,
  },
  overDueBalance: {
    fontSize: 14,
    textAlign: "end",
    color: theme.palette.error.main
  }
}));

const AccountBalancesCard = ({data, index, homePage}) => {
  const history = useHistory();
  const langContext = useContext(LanguageContext);
  const classes = useStyles();

  const handleGoBillingPage = () => {
    history.push(`/billing`);
  };

  return (
    <>
      <Grid container item xs={12} md={4}>
        <Card elevation={4} sx={{maxWidth: 350, width: 350, margin: 2}}>
          <CardHeader
            avatar={<CurrencyAvatar currency={data.currency}/>}
            title={<Typography color="primary" variant={"h5"} style={{fontSize: 20}}> {langContext.dictionary.totalDebt} </Typography>}
            subheader={<CurrencyTypography amount={data.accountBalance} currency={data.currency} sx={{fontWeight: "bold", color: "black"}} variant={"h5"}/>}
          />
          <CardContent>
            <Divider/>
            <Grid container item direction="row" justifyContent="end" alignItems="center">
              <Grid item xs={6} sx={{marginTop: 1, marginBottom: 1}}>
                <Typography color="error" className={classes.rowValue} variant={"h6"}>
                  {langContext.dictionary.overdueDebt}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CurrencyTypography amount={data.overDueBalance} currency={data.currency} className={classes.overDueBalance} variant={"h6"}/>
              </Grid>
            </Grid>
            <Divider/>
            <Grid container item direction="row" justifyContent="end" alignItems="center">
              <Grid item xs={6} sx={{marginTop: 1, marginBottom: 1}}>
                <Typography className={classes.rowValue} variant={"subtitle1"}>
                  {langContext.dictionary.debtDueWithin8Days}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CurrencyTypography amount={data.overDueBalanceIn8d} currency={data.currency} className={classes.rowValue} sx={{textAlign: "end"}}/>
              </Grid>
            </Grid>
            <Divider/>
            <Grid container item direction="row" justifyContent="end" alignItems="center">
              <Grid item xs={6} sx={{marginTop: 1, marginBottom: 1}}>
                <Typography color="primary" variant={"subtitle1"} style={{fontSize: 14}}>
                  {langContext.dictionary.debtDueAfter8Days}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CurrencyTypography amount={data.overDueBalanceOver8d} currency={data.currency} className={classes.rowValue} sx={{textAlign: "end"}}/>
              </Grid>
            </Grid>
            <Divider/>
          </CardContent>
          {homePage ? <CardActions>
            <Button style={{marginLeft: "auto"}} onClick={O => handleGoBillingPage()} color="secondary" seleniumid={"BalanceCardButton_" + index} href="#">
              {langContext.dictionary.details}
            </Button>
          </CardActions> : null}
        </Card>
      </Grid>
    </>
  );
};

AccountBalancesCard.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  homePage: PropTypes.bool
};
export default AccountBalancesCard;
