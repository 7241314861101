import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

const resource = "/worklog";

export const getErrorTicketWorkLogList = async (token, cmId, ticketId ) => {
  let url = `${env.apiRoot}${resource}/${cmId}/${ticketId}/worklog/list`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {
    headers: {Authorization: authStr}});
};