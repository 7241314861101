import axios from "axios";

export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

//for intentional cancellations
export const ignoreCancellation = (e) => {
  if (!(e instanceof axios.Cancel.prototype.constructor)) {
    return Promise.reject(e);
  }
};