import React from "react";
import Typography from "@mui/material/Typography";
import MaintenanceBoard from "../components/maintenance/MaintenanceBoard";
import {Container} from "@mui/material";
import AccountBalances from "../components/accountbalances/AccountBalances";
import HomeMenuCards from "../components/menu/HomeMenuCards";

const HomePage = () => {
  return (
    <Container maxWidth="lg">
      <MaintenanceBoard/>
      <AccountBalances hasTitle homePage/>
      <HomeMenuCards/>
      <div>
        <Typography
          style={{
            position: "relative",
            bottom: 0,
            fontSize: "12px",
            paddingTop: "6px",
            paddingLeft: "6px",
            paddingBottom: "6px"
          }}
        >
          version: {process.env.REACT_APP_VERSION}
        </Typography>
      </div>
    </Container>
  );
};

export default HomePage;
