import React, {createContext, useContext, useEffect, useState} from "react";
import {dictionaryList, languageOptions} from "../languages";
import axios from "axios";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

export const LanguageContext = createContext({
  language: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")) : languageOptions[0],
  dictionary: dictionaryList[localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")).id : languageOptions[0].id]
});

const LanguageProvider = props => {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);
  const [languageHeader, setLanguageHeader] = useState(language.id);

  const get = (key, params) => {
    let res =
      dictionary[key] ||
      dictionary[key.toLowerCase()] ||
      dictionary[key.charAt(0).toLowerCase() + key.slice(1)];
    if (res && params) {
      Object.keys(params).forEach(pKey => {
        res = res.replace(new RegExp(`\\$_\\{${pKey}\\}`, "g"), params[pKey]);
      });
    }
    return res;
  };

  useEffect(() => {
    if (language) {
      localStorage.setItem("language", JSON.stringify(language));
      axios.defaults.headers.common['X-Selected-Language'] = language.id;
      axiosWithInterceptors.defaults.headers.common['X-Selected-Language'] = language.id;
      setLanguageHeader(language.id); // for useEffects
    }
  }, [language.id]);

  const provider = {
    language,
    dictionary,
    languageHeader,
    get,
    setLanguage: selectedLanguage => {
      setLanguage(selectedLanguage);
      setDictionary(dictionaryList[selectedLanguage.id]);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
