import axios from "axios";
import React, {useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {LanguageContext} from "../../context/LanguageContext";
import {debounce} from "lodash-es";

export const axiosWithInterceptors = axios.create(); // export this and use it in all your components

export const AxiosInterceptorInitializer = () => {
  const languageContext = useContext(LanguageContext);
  const {showGroupedRequestError} = useRequestErrorMessage();

  const groupedMessages = useRef({});

  const showGroupedMessages = useMemo(() => debounce(() => {
    for (const pathName in groupedMessages.current) {
      showGroupedRequestError(groupedMessages.current[pathName], "firstPerGroup");
    }
    groupedMessages.current = {};
  }, 500), [showGroupedRequestError]);

  const getByRegex = (obj, toMatch) => {
    for (const key in obj) {
      const matches = toMatch.matchAll(regexKey(key)).next();
      if (matches.value) {
        return {
          errorMessage: obj[key],
          pathParams: matches.value.slice(1),
          pathName: key
        };
      }
    }
  };

  const createInterceptor = useCallback(() => {
    return axiosWithInterceptors.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      let pathName = (new URL(error.config.url)).pathname.replace(/^(\/)+/, "");

      // customer portal specific, needs to modify the indexOf searchElement ('api/cp/') and the substring indexStart (7)
      if (pathName.indexOf('api/cp/') === 0) {
        pathName = pathName.substring(7);
      }

      let errorMessage = languageContext.dictionary.apiErrorMessages?.[pathName]?.[error.request.status];
      let pathParams = [];

      if (!errorMessage) {
        const byRegex = getByRegex(languageContext.dictionary.apiErrorMessages, pathName);
        if (byRegex) {
          pathParams = byRegex.pathParams;
          errorMessage = byRegex.errorMessage?.[error.request.status] || byRegex.errorMessage;
          pathName = byRegex.pathName;
        }
      }

      if (typeof errorMessage === "function") {
        errorMessage = errorMessage({
          queryParams: error.config.params,
          pathParams: pathParams,
          body: error.config.data
        });
      } else if (typeof errorMessage !== "string") {
        pathName = "UNKNOWN_ERROR";
        errorMessage = error.config.hideUnknownErrors ? "" : languageContext.dictionary.unknownError;
      }

      groupedMessages.current[pathName] = groupedMessages.current[pathName] || [];
      if (errorMessage) {
        groupedMessages.current[pathName].push({errorMessage: errorMessage, error: error});
      }

      showGroupedMessages();

      return Promise.reject(error);
    });
  }, [languageContext.dictionary.apiErrorMessages, languageContext.dictionary.unknownError, showGroupedMessages]);

  const regexKey = (input) => new RegExp(`^${input.replaceAll("*", "(.+?)").replace(/\/$/, "")}$`, "g");

  useEffect(() => {
    const resInterceptor = createInterceptor();
    return () => {
      axiosWithInterceptors.interceptors.response.eject(resInterceptor);
    };
  }, [createInterceptor]);

  return <></>;
};