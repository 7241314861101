import React, {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const companyData = {
  headquarter: "1013 Budapest, Krisztina krt. 39.",
  companyRegistrationNumber: "01-09-414291",
  registeringCourt: "Budapest Környéki Törvényszék Cégbírósága",
  taxNumber: "25836965-2-44",
  bankAccountHUF: "10950009-00000005-03841526",
  bankAccountEUR: "10918001-00000005-03840020",
  bankAccountUSD: "10918001-00000005-03840006",
};

const InvitechCompanyData = () => {
  const langContext = useContext(LanguageContext);

  return (
    <Grid container marginTop={5} seleniumid="InvitechCompanyDataGrid">
      <Grid item marginBottom={1}>
        <Typography variant={"h4"}>{langContext.dictionary.invitech}</Typography>
      </Grid>
      <Grid container item direction="row" justifyContent="center" alignItems="center">
        <Paper elevation={4} sx={{width: "100%", paddingTop: 3, paddingBottom: 3, paddingLeft: 1, paddingRight: 1}}>
          <Grid container item sx={{marginTop: 1, marginBottom: 1}} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant={"subtitle1"}>{langContext.dictionary.headquarter}</Typography>
            </Grid>
            <Grid item xs={7} sm={7}>
              <Typography variant={"subtitle2"}>{companyData.headquarter}</Typography>
            </Grid>
          </Grid>
          <Divider/>
          <Grid container item sx={{marginTop: 1, marginBottom: 1}} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant={"subtitle1"}>{langContext.dictionary.registrationNumber}</Typography>
            </Grid>
            <Grid item xs={7} sm={7}>
              <Typography variant={"subtitle2"}>{companyData.companyRegistrationNumber}</Typography>
            </Grid>
          </Grid>
          <Divider/>
          <Grid container item sx={{marginTop: 1, marginBottom: 1}} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant={"subtitle1"}>{langContext.dictionary.registeringCourt}</Typography>
            </Grid>
            <Grid item xs={7} sm={7}>
              <Typography variant={"subtitle2"}>{companyData.registeringCourt}</Typography>
            </Grid>
          </Grid>
          <Divider/>
          <Grid container item sx={{marginTop: 1, marginBottom: 1}} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant={"subtitle1"}>{langContext.dictionary.taxNumber}</Typography>
            </Grid>
            <Grid item xs={7} sm={7}>
              <Typography variant={"subtitle2"}>{companyData.taxNumber}</Typography>
            </Grid>
          </Grid>
          <Divider/>
          <Grid container item sx={{marginTop: 1, marginBottom: 1}} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant={"subtitle1"}>{langContext.dictionary.bankAccount + " (HUF)"}</Typography>
            </Grid>
            <Grid item xs={7} sm={7}>
              <Typography variant={"subtitle2"}>{companyData.bankAccountHUF}</Typography>
            </Grid>
          </Grid>
          <Divider/>
          <Grid container item sx={{marginTop: 1, marginBottom: 1}} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant={"subtitle1"}>{langContext.dictionary.bankAccount + " (EUR)"}</Typography>
            </Grid>
            <Grid item xs={7} sm={7}>
              <Typography variant={"subtitle2"}>{companyData.bankAccountEUR}</Typography>
            </Grid>
          </Grid>
          <Divider/>
          <Grid container item sx={{marginTop: 1, marginBottom: 1}} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant={"subtitle1"}>{langContext.dictionary.bankAccount + " (USD)"}</Typography>
            </Grid>
            <Grid item xs={7} sm={7}>
              <Typography variant={"subtitle2"}>{companyData.bankAccountUSD}</Typography>
            </Grid>
          </Grid>
          <Divider/>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InvitechCompanyData;
