import React, {useContext} from "react";
import {Grid} from "@mui/material";
import {LanguageContext} from "../../context/LanguageContext";
import CurrencyAvatar from "../common/CurrencyAvatar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const BillAccordionPaid = () => {
  const langContext = useContext(LanguageContext);

  return (
    <Paper sx={{border: 1, borderColor: "userBar.background"}}>
      <Grid container direction="row" justifyContent="start" alignItems="center" style={{marginTop: 12, marginBottom: 12}}>
        <Grid item xs={1}>
          <CurrencyAvatar paid sx={{margin: "auto"}}/>
        </Grid>
        <Grid item xs={11}>
          <Typography sx={{color: "primary.light"}} style={{fontSize: 20, fontWeight: "bold"}}>
            {langContext.dictionary.noPayableBill}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BillAccordionPaid;