import axios from "axios";
import {env} from "../properties";

const resource = "/invitation";

export const sendInvitation = async (token, invitationObj, companyId) => {
  let url = `${env.csspApiRoot}${resource}/send/${companyId}`; // TODO cp apiRoot
  const authStr = "Bearer ".concat(token);
  return await axios.post(url, invitationObj, { headers: { Authorization: authStr } });
};
