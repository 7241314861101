import React, {useContext, useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import SectionWithDivider from "../common/SectionWithDivider";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import {capitalizeFirstLetter} from "../../utils/commonFunctions";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import Documents from "../common/Documents";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinRight} from "../../utils/commonValues";

const useStyles = makeStyles(theme => ({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  details: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3)
    },
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  icon: {
    color: theme.palette.secondary.main,
    width: 50,
    height: 50,
  },
  circleActive: {
    color: theme.palette.mainButton.main,
  },
  texActive: {
    color: theme.palette.mainButton.main,
  },
  circleInactive: {
    color: theme.palette.secondary.main,
  },
  textInactive: {
    color: theme.palette.secondary.main,
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    paddingRight: 30
  },
}));

const CaseAccordion = ({data, closedKeyword}) => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const randomNumber = Math.floor((Math.random() * 3) + 1);
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    let docList = [];
    if (data?.document && data.document.fileContent !== null) {
      docList.push({
        fileName: data.document.name,
        size: data.document.size,
        fileContent: data.document.fileContent
      });
    }
    if (data?.attachments?.length > 0) {
      data.attachments.forEach(item => {
        docList.push({
          fileName: item.name,
          size: item.size,
          fileContent: item.fileContent
        });
      });
    }
    setDocumentList(docList);
  }, [data]);


  const randomIcon = () => {
    switch (randomNumber) {
      case 1:
        return (<TuneOutlinedIcon className={classes.icon} color="secondary"/>);
      case 2:
        return (<PaymentsOutlinedIcon className={classes.icon} color="secondary"/>);
      case 3:
        return (<LocalMallOutlinedIcon className={classes.icon} color="secondary"/>);
      default:
        return null;
    }
  };

  function downloadDocument(base64Data, fileName) {
    const linkSource = `data:application;base64,${base64Data}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      <Accordion elevation={3}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary"/>}>
          <Grid container alignItems="center" justifyContent="start">
            <Grid item xs={12} sm={2} md={1}>
              {randomIcon()}
            </Grid>
            <Grid item xs={12} sm={4} md={4} style={{paddingRight: 5}}>
              <Typography className={classes.text} fontSize={20} fontWeight="bold">{data.type}</Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Typography>{data.registrationDate}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Typography>{`${languageContext.dictionary.caseId}:`}</Typography>
              <Typography>{data.registrationNumber || languageContext.dictionary.underAssignment}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className={classes.details} direction="row" justifyContent="center" alignItems="start" rowSpacing={2}>
            <Grid item xs={12} sm={5}>
              <Typography>
                {languageContext.dictionary.caseId}
              </Typography>
              <Typography color={"secondary"} fontSize={24} fontWeight={"bold"}>
                {data.registrationNumber || languageContext.dictionary.underAssignment}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>
                {languageContext.dictionary.caseType}
              </Typography>
              <Typography color={"secondary"} fontSize={24} fontWeight={"bold"}>
                {data.type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box className={classes.statusWrapper}>
                <CircleIcon style={{float: "left", height: 20}}
                            className={data.status.toLowerCase() === closedKeyword ? classes.circleInactive : classes.circleActive}/>
                <Typography className={data.status.toLowerCase() === closedKeyword ? classes.textInactive : classes.texActive} fontWeight="bold"
                            textAlign="center">{capitalizeFirstLetter(data.status)}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <SectionWithDivider longTitle title={languageContext.dictionary.caseStartDate} value={data.registrationDate}/>
            <SectionWithDivider longTitle title={languageContext.dictionary.reportEndDate} value={data.closeDate}/>
            {/*<SectionWithDivider longTitle title={languageContext.dictionary.loggedInContact} value={data.operator}/>*/}
            {documentList?.length > 0 ?
              (
                <RenderedWithPermission roles={adminFinRight}>
                  <Grid item container xs={12} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography sx={{fontWeight: "bold"}}>{languageContext.dictionary.downloadableDocuments}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Documents documentList={documentList} handleDocumentDownLoad={(document) => {
                        downloadDocument(document.fileContent, document.fileName);
                      }}/>
                    </Grid>
                  </Grid>
                </RenderedWithPermission>
              ) : null
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

CaseAccordion.propTypes = {
  data: PropTypes.object.isRequired,
  closedKeyword: PropTypes.string.isRequired
};

export default CaseAccordion;