import React, {useCallback, useContext} from "react";
import {Autocomplete, Checkbox, CircularProgress, TextField} from "@mui/material";
import PropTypes from "prop-types";
import {LanguageContext} from "../../context/LanguageContext";

const CustomAutocomplete = (props) => {
  const langContext = useContext(LanguageContext);

  const getValue = useCallback(() => {
    if (props.multiple) {
      return props.value?.map(id => props.options.find(o => o.value === id)) || [];
    } else {
      return props.options?.find(o => o.value === props.value) || null;
    }
  }, [props.value, props.multiple]);

  return (
    <Autocomplete
      {...props.autocompleteOptions}
      multiple={props.multiple}
      value={getValue()}
      options={props.options || []}
      getOptionLabel={(option) => option?.[props.optionLabel] || ""}
      isOptionEqualToValue={(option, value) => option?.[props.optionLabel] === value?.[props.optionLabel]}
      onChange={props.handleChange}
      required={props.required}
      fullWidth={props.fullWidth}
      disableClearable={props.required}
      autoHighlight
      disabled={props.disabled}
      renderInput={(inputParams) =>
        <TextField
          {...inputParams}
          required={props.required}
          label={langContext.dictionary[props.label] || props.label}
          inputProps={{
            ...inputParams.inputProps,
            required: props.multiple && props.required ? getValue()?.length === 0 : props.required
          }}
          InputProps={{
            ...inputParams.InputProps,
            endAdornment: (
              <React.Fragment>
                {props.loading ? <CircularProgress color="secondary" size={20}/> : null}
                {inputParams.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      }
      renderOption={(renderOptionProps, option, {selected}) =>
        <li {...renderOptionProps}>
          {props.multiple && <Checkbox checked={selected} color="secondary"/>}
          {option?.[props.optionLabel]}
        </li>
      }
      defaultValue={props.multiple ? [] : ""}
      size={props.size}
      autoComplete={false}
    />
  );
};

CustomAutocomplete.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),  // can be null
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  size: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  autocompleteOptions: PropTypes.object,
  optionLabel: PropTypes.string
};

CustomAutocomplete.defaultProps = {
  optionLabel: "label"
};

export default CustomAutocomplete;
