import React, {useContext} from "react";
import {CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import PropTypes from "prop-types";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  circularProgress: {
    margin: theme.spacing(4)
  }
}));

const CustomSelect = ({parameters, value, handleChange, ...props}) => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);

  return !props.loading ? (
    <FormControl
      required={props.required}
      style={props.disableMinWidth ? null : {minWidth: props.minWidth}}
      size={props.size} fullWidth={props.fullWidth}
      error={!!props.error} disabled={props.disabled}
    >
      <InputLabel id={`${parameters.name}_label`}>
        {parameters.label}
      </InputLabel>
      <Select
        labelId={`${parameters.name}_label`}
        id={`${parameters.name}_id`}
        name={parameters.name}
        value={value}
        onChange={handleChange}
        label={parameters.label}
        style={{textAlign: "start"}}
      >
        {parameters.canBeNull && (
          <MenuItem value="" key={`select_notSelectedLabel`}>
            <em>{props.notSelectedLabel || languageContext.dictionary.notSelected}</em>
          </MenuItem>
        )}
        {parameters.options.map((option, idx) => (
          <MenuItem
            key={!option.label ? `${parameters.name}_none` : `${parameters.name}_${option.label}_${option.value}_${idx}`}
            value={option.value}
          >
            {languageContext.dictionary[option.label] || option.label}
          </MenuItem>
        ))}
      </Select>
      {props.error ? (<FormHelperText>{languageContext.dictionary.cannotBeEmpty}</FormHelperText>) : null}
    </FormControl>
  ) : (
    <CircularProgress className={classes.circularProgress} color="secondary" size={props.size === "small" ? 25 : 35}/>
  );
};

CustomSelect.propTypes = {
  parameters: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleChange: PropTypes.func.isRequired,
  notSelectedLabel: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  disableMinWidth: PropTypes.bool,
  error: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  minWidth: PropTypes.number
};

CustomSelect.defaultProps = {
  helperText: true,
  loading: false,
  minWidth: 180
};

export default CustomSelect;
