import React from "react";
import PropTypes from "prop-types";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import moment from "moment";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: ({fontSize}) => fontSize,
    float: "left",
    marginRight: theme.spacing(1)
  }
}));

const DayAndNightIconSwitcher = ({startHour, endHour, fontSize}) => {
  const classes = useStyles({fontSize: fontSize});

  startHour = startHour ? moment(startHour).hours() : null;
  endHour = endHour ? moment(endHour).hours() : null;

  return startHour && endHour ? startHour < 20 && endHour > 5 ?
      <WbSunnyOutlinedIcon className={classes.icon}/> :
      <DarkModeOutlinedIcon className={classes.icon}/>
    : null;
};

DayAndNightIconSwitcher.propTypes = {
  startHour: PropTypes.any.isRequired,
  endHour: PropTypes.any.isRequired,
  fontSize: PropTypes.number
};

export default DayAndNightIconSwitcher;
