export const hasAccess = (access, auth) => {

  let roles = auth?.userData?.profile?.realm_access?.roles;

  if (access === undefined || access === null) {
    return true;
  }

  return (Array.isArray(access) && access.some(r => roles.includes(r))) ||
    roles.includes(access);
};

export const hasRealmRole = (auth, roleName) => {

  let roles = auth?.userData?.profile?.realm_access?.roles;
  return roles.includes(roleName);
};
