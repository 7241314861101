import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import VerticalIcon from "@mui/icons-material/MoreVert";
import HorizontalIcon from "@mui/icons-material/MoreHoriz";
import ButtonGroup from "@mui/material/ButtonGroup";
import VerticalMenuList from "./VerticalMenuList";
import {env} from "../../properties";
import logo from "../../assets/images/invitech_logo.svg";
import {LanguageContext} from "../../context/LanguageContext";
import {languageOptions} from "../../languages";
import {UserContext} from "../../context/UserContext";
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import {Badge, ButtonBase, MenuItem} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {Menu} from "@mui/material";
import CompanySelectorDialog from "../dialog/CompanySelectorDialog";
import InvitationSenderDialog from "../dialog/InvitationSenderDialog";
import {CustomerContext} from "../../context/CustomerContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {useAuth} from "oidc-react";
import {InfoOutlined} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  logo: {
    maxHeight: theme.spacing(5),
    flexGrow: 1,
    "& > img": {
      maxHeight: theme.spacing(5),
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "white",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  appBarShift: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: props => props.drawerWidth,
      width: props => `calc(100% - ${props.drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: props => props.drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      display: props => props.drawerWidth > 0 ? "" : "none"
    }
  },
  drawerOpen: {
    width: props => props.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: `${parseInt(theme.spacing(7)) + 3}px !important`
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    width: props => props.drawerWidth
  },
  content: {
    flexGrow: 1
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  icon: {
    marginRight: theme.spacing(0.5)
  },
  button: {
    margin: `0 ${theme.spacing(0.5)}`
  },
  buttonNoLabel: {
    margin: `0`
  },
  buttonGroup: {
    margin: `0 ${theme.spacing(0.5)}`,
    [theme.breakpoints.down('md')]: {display: "none"}
  },
  hide: {
    display: "none"
  },
  badge: {
    '& .MuiBadge-badge': {
      right: -3,
      top: 5
    }
  }
}));

const VerticalNavigation = props => {
  const location = useLocation();
  const history = useHistory();
  const isMainPage = () => {
    return (location.pathname === "/" || location.pathname === "/home");
  };
  const classes = useStyles({
    drawerWidth: isMainPage() ? 0 : 240
  });
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [companySelectorModalOpen, setCompanySelectorModalOpen] = useState(false);
  const [multipleCompanies, setMultipleCompanies] = useState(true);
  const [invitationSenderDialogOpen, setInvitationSenderDialogOpen] = useState(false);

  const userMenuOpen = Boolean(anchorEl);

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const languageContext = useContext(LanguageContext);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();

  useEffect(() => {
    const authSuccess = (
      auth.userData &&
      Object.keys(userContext?.userInfo || {}).length > 0 &&
      Object.keys(customerContext?.selectedCustomer || {}).length > 0
    );

    if (authSuccess && !customerContext?.selectedCustomer?.id) {
      if (userContext.userInfo.companyList?.length === 1) {
        customerContext.setCustomer(userContext.userInfo.companyList[0], auth.userData.access_token);
        setMultipleCompanies(false);
      } else {
        setCompanySelectorModalOpen(true);
      }
    }
  }, [auth.userData, userContext?.userInfo, customerContext?.selectedCustomer]);

  const logout = () => {
    auth.userManager.signoutRedirect();
  };

  const changePassword = () => {
    let url = new URL(window._env_.REACT_APP_OIDC_URL + "/realms/" + window._env_.REACT_APP_OIDC_REALM + "/protocol/openid-connect/auth");
    url.searchParams.append("client_id", window._env_.REACT_APP_CLIENT_ID);
    url.searchParams.append("kc_action", "UPDATE_PASSWORD");
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", window.location.href);
    window.open(url, "_self");
  };

  const configureTotp = () => {
    let url = new URL(window._env_.REACT_APP_OIDC_URL + "/realms/" + window._env_.REACT_APP_OIDC_REALM + "/protocol/openid-connect/auth");
    url.searchParams.append("client_id", window._env_.REACT_APP_CLIENT_ID);
    url.searchParams.append("kc_action", "CONFIGURE_TOTP");
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", window.location.href);
    window.open(url, "_self");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSubMenuOpen = name => event => {
    const newSubMenuOpen = {...subMenuOpen};
    if (newSubMenuOpen[name]) {
      newSubMenuOpen[name] = !newSubMenuOpen[name];
    } else {
      newSubMenuOpen[name] = true;
    }
    setSubMenuOpen(newSubMenuOpen);
  };

  const handleLanguageChange = event => {
    let selectedLanguage;
    const lang = languageContext.language.id;
    if (lang === "hu") {
      selectedLanguage = languageOptions.find(item => item.id === "en");
    } else {
      selectedLanguage = languageOptions.find(item => item.id === "hu");
    }
    languageContext.setLanguage(selectedLanguage);
  };

  const showCompanySelector = () => {
    if (multipleCompanies) {
      setCompanySelectorModalOpen(true);
    }
  };

  const closeCompanySelector = () => {
    setCompanySelectorModalOpen(false);
  };

  const showInvitationSender = () => {
    handleUserMenuClose();
    setInvitationSenderDialogOpen(true);
  };

  const closeInvitationSender = () => {
    setInvitationSenderDialogOpen(false);
  };

  const drawer = (
    <nav>
      <div className={classes.toolbar}>
        <img src={logo} alt="invitech" className={classes.logo}/>
        <Hidden smUp implementation="css">
          <IconButton onClick={handleDrawerToggle} size="large">
            <ChevronLeftIcon/>
          </IconButton>
        </Hidden>
        <Hidden mdDown implementation="css">
          <IconButton onClick={handleDrawerClose} size="large">
            <ChevronLeftIcon/>
          </IconButton>
        </Hidden>
      </div>
      <Divider/>
      <VerticalMenuList
        handleSubMenuOpen={handleSubMenuOpen}
        subMenuOpen={subMenuOpen}
      />
    </nav>
  );

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setMobileOpen(true)}
              edge="start"
              className={classes.menuButton}
              size="large">
              <Icon>menu</Icon>
            </IconButton>
          </Hidden>
          <Hidden mdDown implementation="css">
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: (open || isMainPage())
              })}
              size="large">
              <Icon>menu</Icon>
            </IconButton>
          </Hidden>
          {!isMainPage() ?
            <Typography variant="h6" className={classes.title} noWrap color="secondary">
              {env.title}
            </Typography> :
            <div className={classes.logo}>
              <img src={logo} alt="invitech"/>
            </div>
          }
          <Tooltip title={languageContext.dictionary.companySelector}>
            <ButtonBase onClick={showCompanySelector} style={{marginRight: 15}} data-testid="companyModalOpenButton">
              <Typography variant="h7" fontWeight="bold" color="text.primary">
                {customerContext?.selectedCustomer?.name || ""}
              </Typography>
              {multipleCompanies ? (
                <Icon className={classes.icon} color="primary">expand_more</Icon>
              ) : null}
            </ButtonBase>
          </Tooltip>
          <FormControl>
            <Button data-testid="userButton"
                    id="openMenu"
                    className={classes.button}
                    onClick={handleUserMenuOpen}
                    color="mainButton"
                    variant="contained"
                    size="small">
              <Icon className={classes.icon}>account_circle</Icon>
              {userContext.userInfo.email || languageContext.dictionary.login}
              {userMenuOpen ? (
                <Icon className={classes.icon}>expand_less</Icon>
              ) : (
                <Icon className={classes.icon}>expand_more</Icon>
              )}
            </Button>
            <Menu
              data-testid="loggedInUserMenu"
              id="basic-menu"
              open={userMenuOpen}
              anchorEl={anchorEl}
              onClose={handleUserMenuClose}
              disableScrollLock
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={showInvitationSender}>{languageContext.dictionary.sendInvitation}</MenuItem>
              <MenuItem onClick={handleUserMenuClose} component={NavLink} to="/profile">
                {languageContext.dictionary.profile}
              </MenuItem>
              <MenuItem onClick={changePassword}>{languageContext.dictionary.changePassword}</MenuItem>
              <Tooltip title={languageContext.dictionary.configureMfaExperimentalFeature}>
                <MenuItem onClick={configureTotp}>{languageContext.dictionary.configureMfa}<InfoOutlined fontSize={"small"} color={"disabled"}/></MenuItem>
              </Tooltip>
              <MenuItem onClick={logout}>{languageContext.dictionary.logout}</MenuItem>
            </Menu>
          </FormControl>
          <Tooltip title={languageContext.dictionary.changeLanguage}>
            <Button
              className={classes.button}
              color="primary"
              onClick={handleLanguageChange}
              size="small"
            >
              <Icon className={classes.icon}>language</Icon>
              {languageContext.language.id}
            </Button>
          </Tooltip>
          <Tooltip title={languageContext.dictionary.notifications}>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => history.push("/notifications")}
              size="small"
            >
              <Badge badgeContent={userContext.notificationCounter} color="secondary" className={classes.badge}>
                <NotificationsIcon color="action"/>
              </Badge>
            </Button>
          </Tooltip>
          {props.changeOrientation && (
            <ButtonGroup
              color="secondary"
              variant="contained"
              size="small"
              className={classes.buttonGroup}
            >
              <Tooltip title="Vertikális menü">
                <Button onClick={() => props.changeOrientation("vertical")}>
                  <VerticalIcon/>
                </Button>
              </Tooltip>
              <Tooltip title="Váltás horizontális menüre">
                <Button
                  color="primary"
                  onClick={() => props.changeOrientation("horizontal")}
                >
                  <HorizontalIcon/>
                </Button>
              </Tooltip>
            </ButtonGroup>
          )}
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar}/>
        {props.content}
      </main>
      <CompanySelectorDialog open={companySelectorModalOpen} handleClose={closeCompanySelector}/>
      <InvitationSenderDialog open={invitationSenderDialogOpen} handleClose={closeInvitationSender}/>
    </div>
  );
};

VerticalNavigation.propTypes = {
  content: PropTypes.any,
  changeOrientation: PropTypes.func
};

export default VerticalNavigation;
