import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {TextField} from "@mui/material";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers-pro";
import PropTypes from "prop-types";
import {LanguageContext} from "../../context/LanguageContext";
import Typography from "@mui/material/Typography";
import {dateFormat, dateFormatMask, dateTimeFormat, dateTimeFormatMask} from "../../utils/commonValues";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  inputSize: {
    maxWidth: ({fullWidth}) => fullWidth ? null : 180,
  },
  responsiveWidth: {
    width: ({fullWidth}) => fullWidth ? "50%" : 180
  }
}));

const CustomDateRangePicker = props => {
  const classes = useStyles({fullWidth: props.fullWidth});
  const languageContext = useContext(LanguageContext);
  const [dateFrom, setDateFrom] = useState(props.value[0]);
  const [dateTo, setDateTo] = useState(props.value[1]);
  const [dateFromOpen, setDateFromOpen] = useState(false);
  const [dateToOpen, setDateToOpen] = useState(false);

  useEffect(() => {
    setDateFrom(props.value[0]);
    setDateTo(props.value[1]);
  }, [props.value]);

  useEffect(() => {
    props.onChange([dateFrom, dateTo]);
  }, [dateFrom, dateTo]);

  const minDate = () => dateFrom || props.minDate;

  return (
    props.dateTime ? (
      <div style={{display: "flex"}}>
        <DateTimePicker
          className={classes.responsiveWidth}
          disableFuture={props.disableFuture}
          minDate={props.minDate}
          maxDate={dateTo}
          open={dateFromOpen}
          onOpen={() => setDateFromOpen(true)}
          onClose={() => setDateFromOpen(false)}
          allowSameDateSelection={true}
          value={dateFrom}
          placeholder={moment().format(dateTimeFormat)}
          onChange={date => setDateFrom(date)}
          inputFormat={dateTimeFormat}
          mask={dateTimeFormatMask}
          label={props.startLabel ? props.startLabel : languageContext.dictionary.startDate}
          ampm={false}
          renderInput={textProps =>
            <TextField
              {...textProps}
              error={false}
              className={classes.inputSize}
              fullWidth={props.fullWidth}
              size={props.size}
              helperText={languageContext.dictionary.datePickers.timeHelperText}
              required={props.required}
              onClick={(e) => setDateFromOpen(true)}
            />
          }
        />
        <Typography style={{padding: 20, paddingBottom: 0}}>{" - "}</Typography>
        <DateTimePicker
          className={classes.responsiveWidth}
          disableFuture={props.disableFuture}
          minDate={minDate()}
          maxDate={props.maxDate}
          open={dateToOpen}
          onOpen={() => setDateToOpen(true)}
          onClose={() => setDateToOpen(false)}
          allowSameDateSelection={props.allowSameDateSelection}
          value={dateTo}
          placeholder={moment().format(dateTimeFormat)}
          onChange={date => setDateTo(date)}
          inputFormat={dateTimeFormat}
          mask={dateTimeFormatMask}
          label={props.endLabel || languageContext.dictionary.endDate}
          ampm={false}
          renderInput={textProps =>
            <TextField
              {...textProps}
              error={false}
              className={classes.inputSize}
              fullWidth={props.fullWidth}
              size={props.size}
              helperText={languageContext.dictionary.datePickers.timeHelperText}
              required={props.required}
              onClick={(e) => setDateToOpen(true)}
            />
          }
        />
      </div>
    ) : (
      <div style={{display: "flex"}}>
        <DatePicker
          className={classes.responsiveWidth}
          disableFuture={props.disableFuture}
          minDate={props.minDate}
          maxDate={dateTo}
          open={dateFromOpen}
          onOpen={() => setDateFromOpen(true)}
          onClose={() => setDateFromOpen(false)}
          allowSameDateSelection={true}
          value={dateFrom}
          placeholder={moment().format(dateFormat)}
          onChange={date => setDateFrom(date)}
          inputFormat={dateFormat}
          mask={dateFormatMask}
          label={props.startLabel ? props.startLabel : languageContext.dictionary.startDate}
          renderInput={textProps =>
            <TextField
              {...textProps}
              error={false}
              className={classes.inputSize}
              fullWidth={props.fullWidth}
              size={props.size}
              helperText={props.helperText ? languageContext.dictionary.datePickers.helperText : ""}
              required={props.required}
              onClick={(e) => setDateFromOpen(true)}
            />
          }
        />
        <Typography style={{padding: props.size === "small" ? 10 : 20, paddingBottom: 0}}>{" - "}</Typography>
        <DatePicker
          className={classes.responsiveWidth}
          disableFuture={props.disableFuture}
          minDate={minDate()}
          maxDate={props.maxDate}
          open={dateToOpen}
          onOpen={() => setDateToOpen(true)}
          onClose={() => setDateToOpen(false)}
          allowSameDateSelection={props.allowSameDateSelection}
          value={dateTo}
          placeholder={moment().format(dateFormat)}
          onChange={date => setDateTo(date)}
          inputFormat={dateFormat}
          mask={dateFormatMask}
          label={props.endLabel || languageContext.dictionary.endDate}
          renderInput={textProps =>
            <TextField
              {...textProps}
              error={false}
              className={classes.inputSize}
              fullWidth={props.fullWidth}
              size={props.size}
              helperText={props.helperText ? languageContext.dictionary.datePickers.helperText : ""}
              required={props.required}
              onClick={(e) => setDateToOpen(true)}
            />
          }
        />
      </div>
    )
  );

};

CustomDateRangePicker.propTypes = {
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  allowSameDateSelection: PropTypes.bool,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  dateTime: PropTypes.bool,
  disableFuture: PropTypes.bool,
  helperText: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string
};

CustomDateRangePicker.defaultProps = {
  helperText: true,
  fullWidth: false
};

export default CustomDateRangePicker;
