import React, {useContext} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../../context/LanguageContext";
import RepeaterComboInput from "./RepeaterComboInput";
import {UserContext} from "../../../context/UserContext";

const MasterDataGrid = ({handleFieldChange, user, selectOptions, selectOptionsLoading, deleteFlag, defaultNewObject, idName}) => {
  const userContext = useContext(UserContext);
  const languageContext = useContext(LanguageContext);

  const companyListVisible = !userContext?.userInfo?.innerUser && userContext?.userInfo?.companyList?.length > 0;

  return (
    <Grid item xs={12} container spacing={2} alignItems="center">
      <Grid item xs={12} >
          <Typography sx={{fontWeight: "bold"}}>{languageContext.dictionary.masterData}</Typography>
          {companyListVisible ? (
            <Typography style={{whiteSpace: "pre-wrap"}}>
              {languageContext.get("masterDataChangeWarning", {companyList: userContext.userInfo.companyList?.map(c => c?.name)?.join(", ")})}
            </Typography>
          ) : null}
      </Grid>
      <RepeaterComboInput
        handleFieldChange={handleFieldChange}
        dataList={user.mainPhoneNumbers}
        selectOptions={{
          options: selectOptions.masterPhoneTypes,
          label: languageContext.dictionary.phoneNumberType,
          required: !!user.mainPhoneNumbers[0]?.value || (!user.phoneNumbers[0]?.valueMsr && !user.mainPhoneNumbers[0]?.valueMsr)
        }}
        textFieldOptions={{
          label: languageContext.dictionary.phoneNumber,
          required: !!user.mainPhoneNumbers[0]?.valueMsr || (!user.phoneNumbers[0]?.value && !user.mainPhoneNumbers[0]?.value)
        }}
        selectOptionsLoading={selectOptionsLoading}
        listName={"mainPhoneNumbers"}
        validationType={"phoneNumber"}
        optionLabel={"title"}
        deleteFlag={deleteFlag}
        defaultNewObject={defaultNewObject}
        idName={idName}
      />
      <RepeaterComboInput
        handleFieldChange={handleFieldChange}
        dataList={user.mainEmails}
        selectOptions={{
          options: selectOptions.masterEmailTypes,
          label: languageContext.dictionary.emailAddressType,
          required: !!user.mainEmails[0]?.value || (!user.emails[0]?.valueMsr && !user.mainEmails[0]?.valueMsr)
        }}
        textFieldOptions={{
          label: languageContext.dictionary.emailAddress,
          required: !!user.mainEmails[0]?.valueMsr || (!user.emails[0]?.value && !user.mainEmails[0]?.value)
        }}
        selectOptionsLoading={selectOptionsLoading}
        listName={"mainEmails"}
        validationType={"email"}
        optionLabel={"title"}
        deleteFlag={deleteFlag}
        defaultNewObject={defaultNewObject}
        idName={idName}
      />
    </Grid>
  );
};

MasterDataGrid.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  selectOptions: PropTypes.object.isRequired,
  selectOptionsLoading: PropTypes.bool,
  deleteFlag: PropTypes.string,
  defaultNewObject: PropTypes.object
};

export default MasterDataGrid;
