import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Container, Tab, Tabs, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import SimpleTabPanel from "./SimpleTabPanel";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles, useTheme} from "@mui/styles";
import {useHistory, useLocation} from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const TabPageWithUrl = props => {
  const classes = useStyles();
  const history = useHistory();
  const languageContext = useContext(LanguageContext);
  const location = useLocation();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('lg'));
  const [actualTab, setActualTab] = useState(0);
  const [tabLoading, setTabLoading] = useState(true);

  useEffect(() => {
    handleTabChange();
  }, [location.pathname]);

  return (
    <Container maxWidth="lg">
      <Grid container className={classes.grid}>
        <Grid item xs={12} style={{paddingBottom: 20}}>
          <Typography variant="h3" color="secondary" className={classes.header}>
            {props.title || ""}
          </Typography>
        </Grid>
        {!tabLoading ? (
          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs
                value={actualTab}
                onChange={(e, newValue) => handleTabAndPathChange(newValue)}
                variant={matchesSm ? "scrollable" : "fullWidth"}
                scrollButtons="auto"
                allowScrollButtonsMobile
                indicatorColor="primary"
              >
                {props.tabs.map(tab => (
                  <Tab label={languageContext.dictionary[tab.label]} key={`tab_${tab.index}`} seleniumid={`${tab.label}Tab`}/>
                ))}
              </Tabs>
            </AppBar>
            {props.tabs.map(tab => (
              <SimpleTabPanel value={actualTab} index={tab.index} key={`tabPanel_${tab.index}`}>
                <tab.child {...tab.childProps}/>
              </SimpleTabPanel>
            ))}
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );

  function handleTabChange() {
    setTabLoading(true);
    const tabIndex = props.tabs.find(t => t.pathname === location.pathname)?.index || 0;
    if (tabIndex !== actualTab) {
      setActualTab(tabIndex);
    }
    setTabLoading(false);
  }

  function handleTabAndPathChange(newValue) {
    setTabLoading(true);
    setActualTab(newValue);
    const toPage = props.tabs.find(t => t.index === newValue)?.pathname;
    if (toPage !== location.pathname) {
      history.push(toPage);
    }
    setTabLoading(false);
  }
};

TabPageWithUrl.propTypes = {
  tabs: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default TabPageWithUrl;
