import React from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {makeStyles} from "@mui/styles";
import Paper from "@mui/material/Paper";
import {useHistory} from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import {formatDateTime} from "../../utils/dateFormat";
import {capitalizeFirstLetter} from "../../utils/commonFunctions";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.mainButton.main,
    fontSize: 60
  },
  circleActive: {
    color: theme.palette.mainButton.main,
  },
  texActive: {
    color: theme.palette.mainButton.main,
  },
  circleInactive: {
    color: theme.palette.secondary.main,
  },
  textInactive: {
    color: theme.palette.secondary.main,
  },
  wrapper: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 20,
    paddingRight: 20
  },
  characterHandler: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    paddingRight: 10
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    paddingRight: 30
  },
}));

const ErrorTicket = ({data}) => {
  const history = useHistory();
  const classes = useStyles();


  return (
    <Paper style={{marginTop: 10, marginBottom: 10}} square elevation={3}>
      <Grid className={classes.wrapper} container direction="row" justifyContent="end" alignItems="center">
        <Grid item xs={12} md={1}>
          <WarningRoundedIcon className={classes.icon}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className={classes.characterHandler}>{capitalizeFirstLetter(data.description)}</Typography>
          <Typography color={"#78909c"} fontSize={"1rem"} variant="caption">{data.sourceSystemTicketId}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography fontWeight={"bold"}>{formatDateTime(new Date(data.dateOfEntry))}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography>{data.site}</Typography>
        </Grid>
        <Grid item xs={11} md={2}>
          <Box className={classes.statusWrapper}>
            <CircleIcon style={{float: "left", height: 20}} className={data.status === "Folyamatban" ? classes.circleActive : classes.circleInactive}/>
            <Typography className={data.status === "Folyamatban" ? classes.texActive : classes.textInactive} fontWeight="bold"
                        textAlign="center">{data.status}</Typography>
          </Box>
        </Grid>
        <Grid item xs={1} md={1} textAlign="end">
          <IconButton onClick={() => history.push(`/errorTicketDetails/${data.ticketId}`)}>
            <ArrowForwardIosIcon style={{fontSize: "1.5rem"}} color="secondary"/>
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

ErrorTicket.propTypes = {
  data: PropTypes.object.isRequired,
};
export default ErrorTicket;