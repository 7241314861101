import axios from "axios";
import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

const resource = "/contract";

export const getContractDocumentList = async (token, cmId, contractNumberList) => {
  let params = new URLSearchParams();
  contractNumberList.forEach(c => params.append("contractId", c));

  let url = `${env.apiRoot}${resource}/documents/list/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, params: params});
};

export const downloadContractDocument = async (token, documentId, attachmentId, fileName) => {
  let url = `${env.apiRoot}${resource}/documents/${documentId}/${attachmentId}/${fileName}`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {responseType: "blob", headers: {Authorization: authStr}});
};

export const getContractList = async (token, cmId, status) => {
  let url = `${env.apiRoot}${resource}/list/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, params: {status: status}});
};

export const getContractDetails = async (token, poId) => {
  let url = `${env.apiRoot}${resource}/contract/${poId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const getContractServices = async (token, poId) => {
  let url = `${env.apiRoot}${resource}/contract/services/${poId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};
