import React, {useContext} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

const CookieDialog = ({handleClose, open}) => {
  const languageContext = useContext(LanguageContext);

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown" || reason === "clickaway") {
      return false;
    }

    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth={"sm"}
      disableEscapeKeyDown
      disableScrollLock
      style={{zIndex: 10000}}
    >
      <DialogTitle color="secondary">
        <Grid item xs={12} alignContent="center" alignItems="center" display="flex">
          <Typography color="#6ba53a" variant="h6">{languageContext.dictionary.cookie.headerMessage}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Typography>
            {languageContext.dictionary.cookie.mainContentPart1}
            <Link
              href={"https://www.invitech.hu/storage/attachments/documents/60dedae22015c/Invitech-adatkezelesi-tajekoztato-2021-3.pdf"}
              target="_blank" color="#ffa200" underline="none">
              {languageContext.dictionary.cookie.cookieURL}
            </Link>
            {languageContext.dictionary.cookie.mainContentPart2}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)}>
          {languageContext.dictionary.cookie.requiredCookies}
        </Button>
        <Button variant="contained" size="small" color="mainButton" onClick={() => handleClose(false)}>
          {languageContext.dictionary.cookie.allCookies}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CookieDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CookieDialog;