import * as plotlyHuLocale from "plotly.js/lib/locales/hu";

const hu = {
  accept: "Elfogad",
  accessPermissionRequest: "Belépési engedély kérés",
  accountBalance: "Számla egyenleg",
  accountType: "Számla típusa",
  action: "Tevékenység",
  actionOptions: {
    toServer: "Szerverhez",
    visit: "Látogatás",
    maintenance: "Karbantartás",
    bringsOrTakesServer: "Szervert hoz / visz",
    troubleshooting: "Hibaelhárítás"
  },
  actions: "Műveletek",
  active: "Aktív",
  activeContracts: "Aktív szerződések",
  activeErrorTickets: "Aktív hibajegyek",
  accountDetails: "Folyószámla részletek",
  actual: "Aktuális",
  actualNotifications: "Aktuális értesítések",
  add: "Hozzáadás",
  addAnotherContactPerson: "Másik kapcsolattartó hozzáadása",
  addAnotherEntryPerson: "Másik belépő hozzáadása",
  addedToDownloadList: "Hozzáadva a letöltés listához.",
  addNewRow: "Új sor hozzáadása",
  addNewVm: "Új VM hozzáadása",
  address: "Cím",
  addToList: "Listához adás",
  admin: "Admin",
  administration: "Ügyintézés",
  administrationSynonym: "Ügyindítás",
  affectedServices: "Érintett szolgáltatások",
  affectedSite: "Érintett telephely",
  affectedSites: "Érintett telephelyek",
  afType: "AF típus",
  agree: "Megértettem",
  aggregateMode: "Csoportosítási mód",
  all: "Összes",
  allBills: "Összes számla",
  allErrorTickets: "Összes hibajegy",
  allowService: "Szolgáltatás engedélyezve",
  allServices: "Összes szolgáltatás",
  amount: "Összeg",
  another: "További",
  apiErrorMessages: {
    "maintenance/maintenances/*": "Hiba történt a karbantartások betöltése során!",
    "maintenance/service_types": "Hiba történt a karbantartás típusok betöltése során!",
    "maintenance/accept": "Hiba történt a karbantartás elfogadása során!",
    "maintenance/reject": "Hiba történt a karbantartás elutasítása során!",
    "maintenance/contactId/*": "Hiba történt a kapcsolat azonosító betöltése során!",
    "service/*": "Hiba történt a szolgáltatás betöltése során!",
    "company/internalContacts/*": "Hiba történt a kapcsolattartók betöltése során!",
    "ticket/*/contactList": "Hiba történt a kapcsolattartók betöltése során!",
    "service/all/*": "Hiba történt a szolgáltatások betöltése során!",
    "colosite/colo/*": "Hiba történt a szervertermek betöltése során!",
    "colosite/role": "Hiba történt a szintek betöltése során!",
    "colosite/service/*": "Hiba történt a szolgáltatások betöltése során!",
    "colosite/vitrinService/*": "Hiba történt a szolgáltatások betöltése során!",
    "colosite/user/*": "Hiba történt a felhasználók betöltése során!",
    "ticket/*/ticket/list": "Hiba történt a hibajegyek betöltése során!",
    "dms/casetype/list/service": "Hiba történt a ügyek betöltése során!",
    "dms/casetemplate/*": "Hiba történt a ügyintézés mezőinek betöltése során!",
    "dcma/access/*": "Hiba történt a szerverterem belépések betöltése során!",
    "dcma/access": "Hiba történt a szerverterem belépési engedély mentése során!",
    "contract/list/*": "Hiba történt a szerződések betöltése során!",
    "contract/contract/*": "Hiba történt a szerződés részletek betöltése során!",
    "contract/services/*": "Hiba történt a szerződéshez kapcsolódó szerződések betöltése során!",
    "contract/documents/list/*": "Hiba történt a szerződés dokumentumok betöltése során!",
    "contact/edit/selectorlist/*": "Hiba történt a kiválasztó lista betöltése során!",
    "contact/contactperson/new/*": "Hiba történt az új felhasználó adatainak betöltése során!",
    "contact/contactperson/*": "Hiba történt a felhasználó adatainak betöltése során!",
    "contact/contactperson": "Hiba történt a felhasználó adatainak mentése során!",
    "ticket/*/serviceId/openTicket/list": "Hiba történt a nyitott hibajegyek betöltése során!",
    "service/IMPLEMENT/*": "Hiba történt a létesítések betöltése során!",
    "ticket/errormessage/*": "Hiba történt a betöltése során!",
    "ticket/*/saveTicket/": "Hiba történt a jegy beküldése során!"
  },
  applicationGroups: "Alkalmazás csoportok",
  applicationTraffic: "Alkalmazás forgalom",
  attachedMessage: "Csatolt üzenet: ",
  attachFiles: "Fájl csatolása",
  authorizedPerson: "Bejutásra jogosult személy",
  average: "Átlag",
  back: "Vissza",
  backToMainPage: "Vissza a főoldalra",
  balance: "Egyenleg",
  balanceAndCurrentBills: "Egyenleg és folyószámlák",
  bandwidthDown: "Sávszélesség (le)",
  bandwidthUp: "Sávszélesség (fel)",
  bankAccount: "Bankszámlaszám",
  barchart: "Oszlopdiagram",
  beginning: "Kezdete",
  billCollectorId: "Számlagyüjtő azonosító",
  billing: "Számlázás",
  billingAddress: "Számlaküldés címe",
  billingDate: "Számla kelte",
  billId: "Számla azonosító",
  billNumber: "Számlaszám",
  bills: "Számlák",
  billsOlderThanTwoYears: "2 éven túli számlájával kapcsolatban kérjük, hogy keresse ügyfélszolgálatunkat a 06 80 82 00 82-es díjmentesen hívható telefonszámon, vagy a vip@invitech.hu e-mail címen.",
  billTotal: "Számla végösszege",
  billTypes: {
    bills: "Számla",
    adatkozlo: "Adatközlő",
    calllog: "Hívásrészletező",
    demandletter: "Felszólító levél"
  },
  buildingVmBottomText: "A folyamat akár több percig is eltarthat, kérjük szíves türelmét!",
  buildingVmCreateTitle: "Létrehozás folyamatban",
  buildingVmList: [
    "CPU-k elhelyezése...",
    "RAM lefoglalása...",
    "Storage beillesztése...",
    "IP lefoglalása...",
    "Domain regisztrálása...",
    "Operációs rendszer telepítése..."
  ],
  buildingVmModifyTitle: "Módosítás folyamatban",
  cancel: "Mégse",
  cannotBeEmpty: "Nem lehet üres!",
  cardList: "Kártyák",
  cartCommentTxt: "Itt jelezheti számunkra a megrendelésével kapcsolatos megjegyzéseit, kéréseit.",
  cartContent: "Kosár tartalma",
  caseId: "Ügy azonosító",
  caseSendError: "ügy beküldése sikertelen",
  caseStartDate: "Ügyindítás dátuma",
  caseType: "Ügy típusa",
  caseUnderRegistration: "Iktatás alatti ügyek",
  category: "Kategória",
  changeLanguage: "Change to English",
  changePassword: "Jelszócsere",
  changePhoto: "Kép cseréje",
  choose: "Kiválasztom",
  chooseCustomerFirst: "Először válasszon ki egy ügyfelet",
  choosePreferredPayment: "Válasszon a preferált fizetési módok között",
  choosePlease: "Kérjük válasszon!",
  chooseRoutersFirst: "Először válassza ki a routereket",
  chooseSitesFirst: "Először válassza ki a telephelyeket",
  classicAccount: "Folyószámla",
  clientCode: "Ügyfélkód",
  close: "Bezárás",
  closedCases: "Lezárt ügyek",
  cloud: "Felhő",
  comment: "Megjegyzés",
  currency: "Devizanem",
  currentMaintenances: "Aktuális karbantartások",
  customerInfo: "Ügyféladatok",
  customerRegistrationNumber: "Cégjegyzékszám",
  customerNameAddress: "Vevő neve, címe",
  colleagueQuestion: "A kapcsolattartó cégük, intézményük kollégája?",
  companyDetails: "Cégadatok",
  companyInformation: "Cégadatok",
  companyInformationSectionTxt: "Adószámának megadásával beazonosítjuk cége további paramétereit",
  companyName: "Cégnév",
  companySelectionError: "Hiba történt a cég kiválasztása során!",
  companySelector: "Cégválasztó",
  companySelectorTitle: "Kérem válasszon az alábbi listából, hogy melyik cég nevében szeretne eljárni!",
  completeSolutions: "Kész megoldások",
  consolePrivilege: "Konzol jogosultság",
  configurator: "Konfigurátor",
  configureMfa: "Kétlépcsős hitelesítés beállítása",
  configureMfaDialog: "Amennyiben aktiválja a kétfaktoros hitelesítést weboldalunkon, kérjük, vegye figyelembe, hogy ezt a beállítást később csak ügyfélszolgálatunk (06 80 82-00-82) segítségével lehet kikapcsoltatni.",
  configureMfaDialogTitle: "Fontos Biztonsági Információ",
  configureMfaExperimentalFeature: "Kísérleti funkció",
  confirm: "Megerősítés",
  confirmDelete: "Biztosan törölni szeretné?",
  confirmDeleteVm: "Biztosan törölni szeretné a virtuális számítógépet?",
  confirmVmName: "Kérem, erősítse meg a virtuális számítógép nevét az alábbi név begépelésével: ",
  contact: "Kapcsolatfelvétel",
  contactAdditionForVm: "Megkeresés érkezett a(z) $_{name} jelzésű szolgáltatásazonosító kapcsán. ",
  contactData: "Elérhetőségi adatok",
  contactHeader: "Kérem válassza ki a kapcsolatfelvétel tárgyát és írja meg az üzenetet. A bejelentése feldolgozását követően felvesszük Önnel a kapcsolatot!",
  contactHeaderVm: "Kérem a kiválasztott virtuális szerver kapcsán írja meg az igényeit. Az igény feldolgozását követően felvesszük Önnel a kapcsolatot!",
  contactListLoadError: "Hiba történt a kapcsolat lista betöltése során!",
  contactMessageError: "Hiba történt a kapcsolatfelvételi űrlap küldése közben! Kérjük próbálja meg később!",
  contactMsgPlaceholder: "Kérjük ide írja üzenetét (max 2000 karakter)...",
  contactPerson: "Kapcsolattartó",
  contactSuccess: "Köszönjük a megkeresését! Kollégánk hamarosan jelentkezni fog!",
  contractDetails: "Szerződés részletek",
  contractId: "Szerződés azonosító",
  contractNumber: "Szerződésszám",
  contractualCurrentAccountNumber: "Szerződéses folyószámla száma",
  contracts: "Szerződések",
  contractTitle: {
    proposal: "Felfüggesztett létesítés szerződések",
    implement: "Létesítés alatti szerződések",
    operating: "Aktív szerződések"
  },
  contractsUnderConstruction: "Létesítés alatti szerződések",
  contractingCompany: "Szerződő cég neve",
  cookie: {
    headerMessage: "Ez a weboldal sütiket használ",
    mainContentPart1: "Ez az oldal sütiket használ, amelyek elengedhetetlenek a működéséhez, illetve annak érdekében, hogy jobb böngészési élményt nyújtson, személyre szabja a tartalmat, és elemezze a forgalmat. A sütiket elsősorban az oldal üzemeltetője, a forgalom elemzéséhez szükséges sütiket harmadik személy (pl. Google) alkalmazza.",
    mainContentPart2: "A sütik elfogadásával Ön hozzájárul a nem elengedhetetlen sütik alkalmazásához.",
    cookieURL: "Tudjon meg többet a sütikről tájékoztatónkból. ",
    allCookies: "Minden sütit engedélyezek",
    requiredCookies: "Csak a szükséges sütiket engedélyezem"
  },
  copyErrorMessage: "Hibaüzenet másolása",
  couldNotDownloadFile: "Hiba a fájl letöltése közben",
  createNewVm: "Új VM létrehozása",
  tp: "Technical Portal",
  cp: "Ügyfél Portál",
  cssp: "Felhő Szolgáltatások Önkiszolgáló Portál",
  csspDesc: "Felhő Szolgáltatások Önkiszolgáló Portál",
  creditCard: "Bankkártya",
  custom: "Egyedi",
  customer: "Ügyfél",
  dashboard: "Dashboard",
  datatable: "Datatable",
  day: "Nap",
  dataCenter: "Adatközpont",
  dataTransfer: "Adatátvitel",
  dataChange: "Adatváltozás bejelentése",
  dataChangeUser: "Adatok szerkesztése",
  date: "Dátum",
  datePickers: {
    locale: "hu",
    helperText: "éééé.hh.nn",
    timeHelperText: "éééé.hh.nn óó:pp"
  },
  dcArduinoMeter: "DC Arduino Árammérők",
  dcArduinoMeterDesc: "Adatközpontunkban elhelyezett eszközei fogyasztását követheti",
  ddosSecurityService: "DDOS védelem",
  ddosSecurityServiceDesc: "DDOS Szolgáltatások és riasztások megtekintése",
  debtDueAfter8Days: "8 napon túl lejár",
  debtDueWithin8Days: "8 napon belül lejár",
  default: "Default",
  delete: "Törlés",
  deleteContactMsg: "Ha kapcsolattartót szeretne törölni, kérjük, hogy keresse ügyfélszolgálatunkat a 06 80 82 00 82-es díjmentesen hívható telefonszámon, vagy a vip@invitech.hu e-mail címen.",
  deleteUserMsg: "Ha felhasználót szeretne törölni, kérjük, hogy keresse ügyfélszolgálatunkat a 06 80 82 00 82-es díjmentesen hívható telefonszámon, vagy a vip@invitech.hu e-mail címen.",
  deleteContactCustomerService: "Törléshez kérem keresse fel ügyfélszolgálatunkat",
  deleteConfirm: "Törlés megerősítése",
  deleteFilters: "Szűrők törlése",
  description: "Leírás",
  details: "Részletek",
  dialog: "Dialog",
  disabledImpersonate: "Megszemélyesítés miatt nem használható",
  dnsManagement: "DNS kezelés",
  dnsManagementDesc: "DNS Zóna kezelés",
  documentNumber: "Okmány száma",
  documentType: "Okmány típusa",
  domain: "Domain",
  domainCounterLoadError: "Hiba történt a domain darabszámának betöltése során!",
  domainExistsError: "Hiba történt a domain létezésének vizsgálata során!",
  domainIncorrectEntry: "Helytelen név.",
  domainListLoadError: "Hiba történt a domain lista betöltése során!",
  domainName: "Domain név",
  domainReserved: "Foglalt név.",
  dontJudge: "Még ne ítélkezz, ez egy work in progress...",
  downloadableDocuments: "Letölthető dokumentumok",
  duration: "Futamidő",
  drivingLicense: "Jogosítvány",
  eBill: "E-számla",
  edit: "Módosítás",
  editSynon: "Szerkesztés",
  editUser: "Felhasználó módosítása",
  email: "E-mail",
  emailAddress: "E-mail cím",
  emailAddressType: "E-mail cím típusa",
  emptyCartHint: "A kosár üres!",
  emptyVmEdit: "Nincs kiválasztva módosítandó virtuális gép!",
  endpointAddress: "Végponti cím",
  endDate: "Vég dátum",
  enterCompanyTaxNumber: "Írja be a cége adószámát",
  enterDate: "Belépés időpontja",
  enterInterval: "Belépés intervalluma",
  enterIntervalTooltip: "Egyszeri belépés: minden belépést külön meg kell kérni!",
  enterDetails: "Belépés részletei",
  entityName: "Entitás név",
  entryInfo: "Bejutási infó",
  eNotice: "E-felszólítás",
  eOrder: "E-Megrendelő",
  eOrderSend: "E-Megrendelő beküldése",
  error: "Hiba",
  errorButtonSuspend: "Szüneteltetett szolgáltatásra nem indíható hibajegy",
  errorButtonLimited: "Korlátozott szolgáltatásra nem indíható hibajegy",
  errorButtonInProgress: "A szolgáltatáson folyamatban lévő hibajegy van. A hibajegy lezárásáig nem adható le új hiba",
  errorDetails: "Hiba részletei",
  errorMessage: "Hibaüzenet",
  errorMessages: {
    companyListError: "Hiba történt a cégek betöltése során!",
    loadNotificationListError: "Nem sikerült betölteni az értesítés listát",
    notificationCounterLoadError: "Hiba történt az értesítések darabszámának betöltése során!",
    selectedCompanyLoadError: "Hiba történt a kiválasztott cég betöltése során!",
    establishmentCounterLoadError: "Hiba történt a létesítések darabszámának betöltése során!",
    establishmentDataLoadError: "Hiba történt a létesítések betöltése során!",
    userDataLoadError: "Hiba történt a felhasználók betöltése során!",
    billListLoadError: "Hiba történt a számlák betöltése során!",
    billTypesLoadError: "Hiba történt a számla típusok betöltése során!",
    contractsListLoadError: "Hiba történt a szerződések betöltése során!",
    typesLoadError: "Hiba történt a típusok betöltése során!",
    optionsLoadError: "Hiba történt az opciók betöltése során!",
    serviceListLoadError: "Hiba történt a szolgáltatások betöltése során!",
    contractDocumentDownloadError: "Hiba történt a szerződés dokumentum letöltése során!",
    loadingError: "Hiba történt az adatok betöltése során!",
    saveError: "Hiba történt a mentés során!"
  },
  errorTicket: "Hibajegy",
  errorTicketDetails: "Hibajegy részletes leírása",
  errorTicketListTitle: "A lenti listában azokat a szolgáltatás típusokat listázzuk, amikre hibajegy adható le",
  errorTicketId: "Hibajegy azonosító",
  errorTicketRecording: "Hibajegy felvétel",
  errorTicketSend: "Hibajegy beküldése",
  eOrderSendError: "E-megrendelő beküldése sikertelen",
  errorTicketSendError: "Hibajegy beküldése sikertelen",
  errorTicketSender: "Hibajegy feladója",
  errorTicketOpenService: "A szolgáltatáson van nyitott hibajegy",
  errorTooltipMessage: "Kérjük, hogy a hiba pontos behatárolása és elhárítása érdekében adjon meg minden szükséges adatot a szolgálatással és a hibajelenséggel kapcsolatban! Amennyiben nem ad meg elégséges információt, úgy kollégáink keresni fogják Önt a megadott kapcsolattartási csatornák egyikén, hogy a hiba kivizsgálását mihamarabb megkezdhessük. Pl: Adatkapcsolati/internet szolgáltatás: Mit tapasztal pontosan? Teljesen szakadt a szolgáltatás? Az eszközök áram alatt vannak, voltak újraindítva, üzemképesnek tűnnek? Honnan és mit nem ér el pontosan(L3-as szolgáltatás esetén IP-cím)? Ha van tartalék vonal, akkor az működik? Hang/telefon szolgáltatás: Mit tapasztal pontosan? Teljesen néma a vonal? Melyik számot érinti a hiba? Példa hívás: hívószám, hívott szám, hívás időpontja, hibajelenség. IP/internet alapú telefonszolgáltatás esetén a hordozó szolgáltatás üzemel?",
  errorType: "Hiba típusa",
  establishments: "Létesítések",
  euCommunityTaxNumber: "Eu közösségi adószám",
  exhibitionMethod: "Kiállítás módja",
  expirationDate: "Lejárat dátuma",
  expirationDateError: "Hiba történt a lejárati dátum betöltése során!",
  expiredNotifications: "Lejárt értesítések",
  expectedConstruction: "Várható kiépítés",
  exportToExcel: "Exportálás Excel fájlba",
  fastlane: "Fastlane",
  fastlaneDesc: "Kubernetes Fastlane",
  favouriteFood: "Kedvenc étel",
  fees: "Díjak",
  fileError: "Hibás fájl",
  fileErrorType: "Nem megfelelő fájl. Megfelelő típusok: ",
  fileErrorSize: "Maximum fájl méret 20 MB",
  fileNotFound: "Nem található a fájl",
  fileUploadTooltip: "Maximális méret: 20 MB. Támogatott fájltípusok: ",
  feedbackDeadline: "Visszajelzés határideje",
  filterChanged: "A szűrők megváltoztak, kattints az adatok frissítéséhez",
  financialPartner: "Pénzügyi partner",
  financialContact: "Ügyfél Portál - Pénzügyi kapcsolattartó",
  finishedMaintenances: "Elvégzett karbantartások",
  firmwareVersion: "Firmware verzió",
  firstName: "Keresztnév",
  footer: {
    mainPage: {
      mainPageText1: "SZEMÉLYRE SZABOTT MEGOLDÁSOK. PONT ÚGY, AHOGY ÖN SZERETNÉ.",
      askForOffer: "Kérjen ajánlatot most!",
      aboutUs: "Rólunk",
      solutions: "Megoldások",
      socialMedia: "Social-Media",
      aboutInvitech: "Az Invitechről",
      references: "Referenciák",
      career: "Karrier",
      socialResponsibility: "Társadalmi felelősségvállalás",
      centralizedProcurement: "Központosított beszerzés",
      smartVillage: "Okosfalu",
      forLargeCompanies: "Nagyvállalatoknak",
      forSmallAndMediumCompanies: "Kis és középvállalkozásoknak",
      forInstitutions: "Intézményeknek",
      forWholesalers: "Nagykereskedőknek",
      businessServices: "Vállalati szolgáltatások",
      infrastructureServices: "Infrastruktúra szolgáltatások",
      news: "Hírek",
      press: "Sajtó",
      blog: "Blog",
      loginToCustomerPortal: "Belépés az ügyfélportálra",
      loginToTechnicalPortal: "Belépés a technikai portálra",
      loginToCSSP: "Belépés a Felhő Szolgáltatások Önkiszolgáló Portálra"
    },
    dataProtection: "Adatvédelem",
    dataProcessingCode: "Adatfeldolgozói kódex",
    termsAndConditions: "ÁSZF",
    gtc: "ÁSZF",
    certificates: "Tanúsítványaink",
    impress: "Impresszum",
    providerInformation: "Szolgáltató adatai",
    filteringSoftware: "Szűrőszoftver",
    coServiceOffers: "Társszolgáltatói ajánlatok",
    premiumServices: "Emelt díjas szolgáltatások",
    socialResponsibility: "Társadalmi felelősségvállalás (InnoMax)",
    iivrApplication: "IIVR Pályázat"
  },

  filter: "Szűrés",
  filters: "Szűrők",
  fulfillmentDate: "Teljesítés dátuma",
  forms: "Űrlapok",
  from: "-tól",
  fromDate: "Dátumtól",
  generalSearch: "Általános keresés",
  gift: "ajándék",
  hasDuplicateDomainHint: "A megadott domain név többször is szerepel!",
  hardwareDeliveryPrivilege: "Hardverelszállítási jogosultság",
  hardwareExchangePrivilege: "Hardvercsere jogosultság",
  headquarter: "Székhely",
  help: "Segítség",
  home: "Főoldal",
  homePage: {
    mainTitle: "Állítsa össze saját, bővíthető dedikált szerverét, vagy válasszon a lenti konfigurációkból",
    mainList: [
      "Garantált hibaelhárítás 4 órán belül",
      "Az összeállított konfiguráció bármikor rugalmasan módosítható",
      "Azonnal üzembe helyezhető",
      "Folyamatos biztonsági mentés és monitoring",
      "Saját operációs rendszert futtathat"
    ],
    additionalBenefits: "További előnyök",
    additionalBenefitsCards: {
      card1Title: "Professzionális üzemeltetés",
      card1Text: "Redundáns infrastruktúra, többszörös gerinchálózati adatkapcsolatokkal biztosított internetelérés.",
      card2Title: "Költséghatékony",
      card2Text: "Akár 15-30%-os költségcsökkenést érhet el saját fizikai szerver üzemeltetésével szemben. Havidíjas előfizetés alapú szolgáltatás.",
      card3Title: "Biztonságos",
      card3Text: "Géptermeink a legmagasabb biztonsági szabványoknak is megfelelnek, szerverein tárolt adatai mindig biztonságban lesznek.",
      card4Title: "Egyedi igényre szabható",
      card4Text: "Nem szükséges több évre előre méreteznie szerverét, hiszen üzemeltetési és hardverigényeihez igazodva bármikor növelheti azt."
    },
    selfCareTutorial: "Self Care bemutató",
    theyChoseUs: "Minket választottak",
    theyChoseUsDescription: "Szakértő és tapasztalt partnereként az Ön IT-igényei alapján kínálunk választ egyedi és összetett üzleti kihívásra. Fejlessze IT-rendszerét tudatosan és tervezhetően, kerülje el az egyszeri nagy beruházásokat, béreljen szerver szolgáltatást havidíj alapon."
  },
  hostname: "Hoszt név",
  hour: "Óra",
  huf: "Ft",
  ictSkill: 'ICT jártasság',
  id: "Azonosító",
  idCard: "Személyi igazolvány",
  identificationNumber: "Azonosító száma",
  ifName: "IF Neve",
  inactive: "Inaktív",
  inactiveErrorTickets: "Befejezett hibajegyek",
  interfaceData: "Interface adatok",
  internet: "Internet",
  invalidEmailMsg: "Az email cím nem megfelelő!",
  invalidPhoneNumberMsg: "A telefonszám nem megfelelő!",
  invitation: "Meghívó",
  invitationError: "Hiba történt az email(ek) küldése során!",
  invitationSenderText: "Kérem adja meg, kinek szeretne meghívót küldeni!",
  invitationSuccess: "Sikeres email küldés!",
  invitech: "Invitech ICT Services Kft.",
  invitechContacts: "Invitech kapcsolattartók",
  invoicingAdministration: "Számlázás ügyintézés",
  invoiceOutputTypes: {
    REMOTE: "távszámla",
    PAPER: "papír alapú",
    ELECTRO: "elektronikus"
  },
  ipAddress: "IP Cím",
  itPartner: "Informatikai partner",
  itSolutions: "IT megoldások",
  jitter: "Késleltetés ingadozás",
  lastData: "Utolsó adat",
  lastName: "Vezetéknév",
  lastUpdate: "Utolsó frissítés",
  latency: "Késleltetés",
  legalText: {
    prices: "Az árak forintban értendők és az ÁFÁ-t nem tartalmazzák.",
    checkBoxPrimary: "Kérjük, a jelölőnégyzetbe helyezett pipával igazolja, hogy elolvasta, megértette, és elfogadta ",
    checkBoxFirstConjunction: "-ünket és ",
    checkBoxSecondConjunction: "unkat.*"
  },
  level: "Szint",
  licensePlate: "Rendszám",
  licenseType: "Igazolvány típusa",
  linechart: "Vonaldiagram",
  loadingCompanyData: "Cégadatok betöltése",
  loadingList: "Lista betöltés alatt",
  loggedInContact: "Bejelentkezett kapcsolattartó",
  loggingIn: "Bejelentkezés...",
  login: "Belépés",
  loginAndLogout: "Belépés / kilépés",
  logout: "Kijelentkezés",
  macAddress: "MAC cím",
  mailText: "Az e-mail cím megegyezik a felhasználónévvel",
  maintenance: "Karbantartás",
  maintenanceRejectionConfirm: "Biztos, hogy elutasítja az alábbi karbantartást?",
  maintenances: "Karbantartások",
  maintenanceType: "Karbantartás jellege",
  managerContact: "Ügyfélmenedzser kapcsolatfelvétel",
  manual: "Manuális",
  map: "Térkép",
  materialTable: {
    labelDisplayedRows: "{from}-{to} összesen {count}",
    labelRowsSelect: "sor",
    nRowsSelected: "{0} sor kiválasztva",
    actions: "Műveletek",
    emptyDataSourceMessage: "Nincs megjeleníthető adat",
    filterTooltip: "Szűrő",
    groupingPlaceholder: "Húzd ide a fejlécet...",
    groupedBy: "Csoportosítás: ",
    search: "Keresés"
  },
  masterData: "Törzsadatok",
  masterDataChangeWarning: "A törzsadatok az alábbi ügyfeleknél vannak megadva:\n$_{companyList}.\nMódosítás esetén az itt felsorolt ügyfeleknél is változni fog.",
  max: "Max",
  message: "Üzenet",
  messageSubject: "Bejelentés tárgya",
  min: "Min",
  minimum3CharactersRequired: "Legalább három karakterre szükség van a keresés elkezdéséhez",
  missingInput: "Hiányzó input mező",
  mobile: "Mobil",
  mobileContactType: "Mobil elérhetőség típusa",
  mobileNumber: "Mobilszám",
  month: "Hónap",
  muiDataTableLocalization: {
    body: {noMatch: "Nincs megjeleníthető adat", toolTip: "Sorbarendezés"},
    pagination: {
      next: "Következő oldal",
      previous: "Előző oldal",
      rowsPerPage: "Sorok oldalanként:",
      displayRows: "/"
    },
    toolbar: {
      search: "Keresés",
      downloadCsv: "Export",
      print: "Nyomtatás",
      viewColumns: "Oszlopok megtekintése",
      filterTable: "Szűrő tábla"
    },
    filter: {all: "Összes", title: "Szűrők", reset: "Alaphelyzetbe állítás"},
    viewColumns: {
      title: "Oszlopok megjelenítése",
      titleAria: "Oszlopok megjelenítése/elrejtése"
    },
    selectedRows: {
      text: "sor kiválasztva",
      delete: "Törlés",
      deleteAria: "Kiválasztott sorok törlése"
    }
  },
  myPermissions: "Jogosultságaim",
  myProfile: "Saját profil",
  name: "Név",
  nameSynonym: "Megnevezés",
  necessaryDocuments: "Szükséges dokumentumok!",
  necessaryDocumentsTooltip: "A következő dokumentum(ok) kitöltése szükséges az ügy indításhoz!",
  needVmForConfigurator: "A konfigurátor működéséhez legalább egy virtuális gépre szükség van",
  newContact: "Új kapcsolattartó",
  newContactToward: "Új kapcsolattartót felvételéhez, lépjen át a Szerverterem jogosultságok oldalra!",
  newErrorReport: "Új hibabejelentés",
  newVersionAvailable: "Új verzió érhető el! Kérlek, frissítsd az oldalt!",
  newUser: "Új felhasználó",
  next: "Tovább",
  no: "Nem",
  noAffectedServiceSelected: "Nincs érintett szolgáltatás kiválasztva!",
  noDataToDisplay: "Nincs megjeleníthető adat!",
  noPageView: "Nincs megfelelő jogosultság az oldal megtekintéséhez!",
  noPayableBill: "Nincs befizetendő számla!",
  noChangesInVm: "Nem történt változtatás a virtuális számítógép beállításaiban",
  noDataWithGivenFilters: "A megadott szűrőkkel nincs elérhető adat",
  noMessageSubjectSelected: "Nincs kiválasztva a bejelentés tárgya!",
  noMaintenance: "Jelenleg nincs folyamatban lévő karbantartás",
  noPermission: "Nincs megfelelő jogosultságod!",
  noService: "Nincs szolgáltatás",
  noTaxNumber: "Nincs megadva adószám",
  notifications: "Értesítések",
  notSelected: "Nincs kiválasztva",
  object: "Objektum",
  onSiteContact: "Helyszíni kapcsolattartó",
  onSiteContactName: "Helyszíni kapcsolattartó neve",
  onSiteContactPhoneNumber: "Helyszíni kapcsolattartó telefonszáma",
  onSiteContactEmail: "Helyszíni kapcsolattartó e-mail",
  offers: "Ajánlatok",
  operatingSystem: "Operációs rendszer",
  operatingSystemShort: "Op. rendszer",
  operationCannotBeUndone: "A művelet nem visszavonható!",
  optionalOpSystem: "Szabadon választható op. rendszer",
  operativePartner: "Operatív partner",
  order: "Megrendelés",
  orderLoadError: "Hiba történt a rendelés betöltése során!",
  orderSaveError: "Hiba történt a rendelés mentése során!",
  osListLoadError: "Hiba történt az operációs rendszerek betöltése során!",
  other: "Egyéb",
  otherServiceParameters: "Szolgáltatás egyéb paraméterei",
  overdueDebt: "Lejárt tartozás",
  overviewOrderProducts: "Megrendelendő termékek áttekintése",
  overviewOfEstablishments: "Létesítések áttekintése",
  packetLoss: "Csomagvesztés",
  paid: "Fizetett",
  pageNotFound: "Az oldal nem található",
  pageNotFoundLong: "Sajnáljuk, a keresett oldalt sajnos nem találjuk",
  paperBase: "Papír alapú",
  passport: "Útlevél",
  payable: "Fizetendő",
  paymentDate: "Befizetés dátuma",
  paymentDeadline: "Fizetési határidő",
  paymentDetails: "Fizetési adatok",
  paymentMethod: "Fizetés módja",
  pendingCases: "Folyamatban lévő ügyek",
  percentage: "Százalék",
  performanceMonitor: "Performancia monitor",
  performanceMonitorDesc: "Hálózati és más eszközök teljesítménygrafikonjait megjelenítő eszköz",
  period: "Időszak",
  periodEnd: "Időszak vége:",
  permissionPrivilege: "Engedélyezési jogosultság",
  personalData: "Személyes adatok",
  phone: "Telefon",
  phoneNumber: "Telefonszám",
  phoneNumberHelperTxt: "+<országkód> <körzet> <telefonszám>",
  phoneNumberType: "Telefonszám típusa",
  pleaseWait: "Kérem várjon...",
  plotlyLocale: {
    locales: {"hu": plotlyHuLocale},
    locale: "hu"
  },
  portalAccess: "Portál hozzáférés",
  portId: "Port azonosító",
  position: "Pozíció",
  positions: {
    ceo: "CEO",
    cio: "CIO",
    cto: "CTO",
    ciso: "CISO",
    cfo: "CFO",
    staff: "Staff"
  },
  post: "Beosztás",
  previousMaintenance: "Korábbi karbantartások",
  privacyPolicy: "Adatvédelmi Szabályzat",
  privilegeLevels: "Jogosultsági szintek",
  privilegeLevelsLocalization: {
    admin: "Teljes jogkörrel rendelkezik.",
    all: "Minden felhasználó eléri a \"Támogatás\", \"Értesítések\" \"Cégadatok\" menüpontokat, a \"Felhasználói profil\"-ját és a híreinket.",
    consolePrivilege: "Távoli eléréssel veheti át a Megrendelő szerverét, fizikailag azonban nem férhet hozzá.",
    financialPartner: "Intézheti hibabejelentéseiket, ügyfélszolgálati ügyeiket, megtekintheti szolgáltatásaikat.",
    hardwareDeliveryPrivilege: "Jogosult az eszköz elvitelére, emellett Konzol és Hardvercsere hozzáférési joggal is rendelkezik. (A Szolgáltató tulajdonát képező bérelt szerverre ez nem vonatkozik!)",
    hardwareExchangePrivilege: "Konzol jogosultság mellett jogosult a szervert egy kijelölt helyiségbe kikérni, javítási munkákat végezhet. (A Szolgáltató tulajdonát képező bérelt szerverre ez nem vonatkozik!)",
    itPartner: "Felhasználói regisztráción kívül minden funkcióhoz van hozzáférése.",
    financialContact: "Megtekintheti a számlázási és szerződéses információkat, intézheti ügyfélszolgálati ügyeiket.",
    technicalContact: "Intézheti hibabejelentéseiket, ügyfélszolgálati ügyeiket, megtekintheti szolgáltatásaikat."
  },
  profile: "Profil",
  pushMe: "Nyomj meg!",
  range: "Intervallum",
  registeringCourt: "Nyilvántartásba bejegyző bíróság",
  registrationNumber: "Cégjegyzék szám",
  registrationForFreeIceCream: "Regisztráció ingyen fagyira",
  reject: "Elutasít",
  rejectMaintenance: "Karbantartás elutasítása",
  rejectReason: "Elutasítás indoka",
  refresh: "Frissítés",
  relatedBills: "Kapcsolódó számlák",
  relatedErrorTickets: "Kapcsolódó hibajegyek",
  relatedServices: "Kapcsolódó szolgáltatások",
  relatedServicesChange: "Összekapcsolt szolgáltatás ID",
  relatedServicesID: "Kapcsolodó szolgáltatás azonosító",
  relatedSite: "Felszerelési cím",
  remoteAccount: "Távszámla",
  reportDate: "Bejelentés dátuma",
  reportedAsErrorTicket: "Hibajegyre jelentve",
  reportEndDate: "Lezárás dátuma",
  reporterName: "Bejelentő neve",
  requestForOffer: "Ajánlatkérés",
  requestForOfferInProgress: "Ajánlatkérés folyamatban",
  requiredPaymentDetails: "A megrendeléshez a cég és a fizetési adatok is szükségesek",
  reservedDomainHint: "Foglalt vagy hibás domain(ek) lett(ek) megadva!",
  rights: "Jogosultságok",
  role: "Szerepkör",
  roles: {
    admin: "Admin",
    finance: "Pénzügyi kapcsolattartó",
    technical: "Technikai kapcsolattartó"
  },
  routerName: "Router megnevezés",
  routerSerialNumber: "Router sorozatszám (S/N)",
  routerType: "Router típus",
  save: "Mentés",
  scheduled: "Ütemezett",
  scheduledLoginLogout: "Igényelt belépés és kilépés",
  sdWanDesc: "SD-WAN szolgáltatás áttekintésére szolgáló felület",
  sdWanId: "SD-WAN azonosító",
  sdwanSites: "SD-WAN Telephelyek",
  selectObjects: "Válasz ki az objektumokat",
  send: "Küldés",
  sendInvitation: "Meghívó küldés",
  sendRequest: "Bejelentés elküldése",
  sendRequestForOffer: "Ajánlatkérés elküldése",
  senderDetails: "Feladó adatai",
  serverRoomAccess: "Szerverterem belépések",
  service: "Szerviz",
  serviceAddress: "Szolgáltatási cím",
  serviceAdministration: "Szolgáltatás ügyintézés",
  serviceError: "Szolgáltatás hibája",
  serviceCategories: {
    internet: "Adat és internet szolgáltatások",
    dataCenter: "Adatközponti szolgáltatások",
    other: "Egyéb szolgáltatások",
    cloud: "Felhő szolgáltatások",
    office: "Irodai informatikai rendszerek",
    security: "IT biztonsági szolgáltatások",
    communication: "Telefon- és kommunikációs szolgáltatások"
  },
  serviceSynonym: "Szolgáltatás",
  services: "Szolgáltatások",
  serviceId: "Szolgáltatás azonosító",
  serviceParameters: "A szolgáltatás paraméterei",
  serviceRelatedNotification: "Szolgáltatással kapcsolatos értesítés",
  serviceStatus: "Szolgáltatás státusz",
  serviceType: "Szolgáltatástípus",
  serverName: "Szerver neve",
  serverRoom: "Szerverterem",
  serverRoomAccesses: "Szerverterem belépések",
  serverRoomAccessPermit: "Szerverterem belépési engedély",
  serverRoomPermissions: "Szerverterem jogosultságok",
  setting: "Beállítás",
  settings: "Beállítások",
  showAllVms: "Összes VM megtekintése",
  showApplicationTraffic: "Alkalmazás forgalom megjelenítése",
  showCode: "Mutasd a kódot",
  showInterfaceData: "Interface adatok megjelenítése",
  showLineBreaks: "Sortörések mutatása",
  showSiteDetails: "Telephely részletek megjelenítése",
  showTrafficData: "Forgalmi adatok megjelenítése",
  shId: "SH azonosító",
  signatureDate: "Aláírás dátuma",
  site: "Telephely",
  siteAddress: "Telephely cím",
  siteOrder: "Telephely sorszám",
  sites: "Telephelyek",
  solution: {
    forGeneralBusinessUse: "általános üzleti használathoz",
    forHighComputingNeeds: "nagy számítási igényekhez",
    forMediumComputingNeeds: "közepes számítási igényekhez",
    toHostLargeContent: "nagy méretű tartalmak hosztolásához",
    customVmDescription: "egyedi igényekhez egyedi konfiguráció"
  },
  solutionListLoadError: "Hiba történt az megoldások betöltése során!",
  solutions: "Megoldások",
  sound: "Hang",
  sortByDate: "Sorbarendezés dátum szerint",
  speedMbps: "Sebesség (MBPS)",
  ssoRolesTooltip: "További felhasználók regisztrálásával kapcsolatban kérjük keresse Ügyfélszolgálatunkat.",
  startDate: "Kezdeti dátum",
  startDateSynonym: "Indulás dátuma",
  starWarsMovies: "Star Wars filmek",
  status: "Státusz",
  statusChangeDate: "Státuszváltás dátuma",
  statusError: "Státusz: Hiba",
  statusOk: "Státusz: OK",
  statusSynonym: "Állapot",
  statusUnknown: "Státusz: Ismeretlen",
  statusWarning: "Státusz: Figyelmeztetés",
  storage: "Tárhely",
  storageType: "Tárhely típus",
  storageTypeListLoadError: "Hiba történt a tárhely típusok betöltése során!",
  successCaseStart: "Sikeres ügyindítás",
  successCreate: "Sikeres létrehozás",
  successEdit: "Sikeres módosítás",
  successErrorTicketSend: "Sikeres hibajegy felvétel",
  successEOrderSend: "Sikeres e-megrendelés",
  successDelete: "Sikeres törlés",
  successVmCreateTitle: "Sikeres VM létrehozás",
  successVmDeleteTitle: "Sikeres VM törlés",
  successVmEditTitle: "Sikeres VM módosítás",
  successfulAcceptance: "Sikeres elfogadás",
  successfulModification: "Sikeres módosítás",
  successfulEntry: "Sikeres bejegyzés",
  successfulRejection: "Sikeres elutasítás",
  summary: "Összegzés",
  support: "Támogatás",
  suspendedEstablishmentContracts: "Felfüggesztett létesítés szerződések",
  taxNumber: "Adószám",
  technicalBackground: "Műszaki háttér",
  technicalId: "Műszaki azonosító",
  technicalOverview: "Műszaki áttekintés",
  technical_support: "Támogatás",
  technicalContact: "Ügyfél Portál - Technikai kapcsolattartó",
  testPeriod: "Teszt időszak",
  timeSelectorOptions: {s: "mp", m: "p"},
  to: "-ig",
  toDate: "Dátumig",
  tooLongName: "A megadott név túl hosszú!",
  tooLongNote: "A megjegyzés túl hosszú!",
  tooManyVmAndReservedDomainHint: "Elérte a max. VM számot ($_{count} db) és foglalt vagy hibás domain(ek) lett(ek) megadva!",
  tooManyVmHint: "Elérte a max. VM számot ($_{count} db)!",
  total: "Végösszeg",
  totalDebt: "Összesen fizetendő",
  traffic: "Forgalom",
  trafficData: "Forgalmi adatok",
  transfer: "Átutalás",
  trialTimeExpire: "Teszt időszak vége: ",
  tryIt: "Kipróbálom",
  type: "Típus",
  underAssignment: "Kiosztás alatt",
  underConstruction: "Létesítés alatt",
  uniqueOffer: "egyedi ajánlat",
  unknownAddress: "Ismeretlen cím",
  unpaidBills: "Fizetendő számlák",
  uploadFiles: "Fájlok feltöltése",
  urls: {
    aboutInvitech: "https://www.invitech.hu/invitechrol",
    references: "https://www.invitech.hu/ugyfeleink",
    socialResponsibility: "https://www.invitech.hu/innomax-fooldal", centralizedProcurement: "https://www.invitech.hu/kozpontositott-beszerzes",
    forLargeCompanies: "https://www.invitech.hu/vallalati-szolgaltatasok-gyujto",
    forSmallAndMediumCompanies: "https://www.invitech.hu/kis-es-kozepvallalkozasok-gyujto",
    forInstitutions: "https://www.invitech.hu/kozpontositott-beszerzes",
    businessServices: "https://www.invitech.hu/vallalati-szolgaltatasok-gyujto",
    infrastructureServices: "https://www.invitech.hu/infrastruktura",
    news: "https://www.invitech.hu/hirek",
    dataProcessingCode: "https://www.invitech.hu/storage/pbB0key1ENoZhpMpb0LVtlWc9sgyyEU6eIrQ98by.pdf",
    gtc: "https://www.invitech.hu/altalanos-szerzodesi-feltetelek-invitech",
    certificates: "https://www.invitech.hu/tanusitvanyaink",
    impress: "https://www.invitech.hu/impresszum",
    providerInformation: "https://www.invitech.hu/szolgaltato-adatai"
  },
  user: "Felhasználó",
  userData: "Felhasználói adatok",
  users: "Felhasználók",
  usersAndPermissions: "Felhasználók és jogosultságok",
  version: "Verzió",
  whichCompanyComeFromQuestion: "Melyik cégtől érkezik?",
  validity: "Érvényesség",
  virtualMachine: "Virtuális számítógép",
  virtualMachineChange: "Virtuális számítógép módosítás",
  virtualMachineName: "Virtuális számítógép neve",
  virtualMachineOrder: "Virtuális számítógép megrendelés",
  virtualServer: "Virtuális szerver",
  virtualServerDashboard: "Virtuális szerver dashboard",
  virtualServerVm: "Virtuális szerver (VM)",
  vmConfigurator: "VM Konfigurátor",
  vmCounterLoadError: "Hiba történt a vm darabszámának betöltése során!",
  vmCreateInProgressText: "A kért művelet az átlagosnál több időt vesz igénybe, ezért még egy kis türelmét kérjük. Nem szeretnénk tovább várakoztatni, ezért ezt az ablakot nyugodtan bezárhatja, e-mailben azonnal értesítjük, amint elkészülünk.",
  vmCreateInProgressTitle: "VM létrehozása folyamatban",
  vmDeleteError: "Hiba történt a vm törlése során!",
  vmEditInProgressTitle: "VM módosítása folyamatban",
  vmInProgressError: "Folyamatban lévő művelet!",
  vmListLoadError: "Hiba történt a vm lista betöltése során!",
  vmLoadError: "Hiba történt a vm betöltése során!",
  vmNameChangeError: "Hiba történt a VM név módosítása során!",
  vmNameChangeSuccess: "Sikeres VM név módosítás",
  vmTemplateLoadError: "Hiba történt a vm részleteinek betöltése során!",
  vmUpdateError: "Hiba történt a vm módosítása során!",
  voice: "Hang",
  week: "Hét",
  workLog: "Munkanapló",
  yes: "Igen"
};

export default hu;
