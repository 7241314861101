import React from "react";
import {Avatar, Grid, ListItemAvatar, ListItemButton} from "@mui/material";
import PropTypes from "prop-types";
import {getIcon} from 'material-file-icons';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

const Documents = props => {
  const handleDocumentDownload = (e, document) => {
    if (props.handleDocumentDownLoad) {
      props.handleDocumentDownLoad(document);
    }
  };

  return (
    <Grid container spacing={2}>
      {props.documentList.map(document => (
        <Grid item xs={"auto"} key={document.fileName}>
          <Paper>
            <ListItem sx={{padding: 0}}>
              <ListItemButton onClick={e => handleDocumentDownload(e, document)}>
                <ListItemAvatar>
                  <Avatar src={`data:image/svg+xml;utf8,${encodeURIComponent(getIcon(document.fileName).svg)}`}/>
                </ListItemAvatar>
                <ListItemText primary={document.name || document.fileName}
                              secondary={document.fileName}
                              secondaryTypographyProps={{style: {whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}}/>
              </ListItemButton>
            </ListItem>
          </Paper>
        </Grid>
      ))
      }
    </Grid>
  );
};

Documents.propTypes = {
  documentList: PropTypes.array,
  handleDocumentDownLoad: PropTypes.func
};

export default Documents;