import React, {useContext, useState} from "react";
import {Avatar, CircularProgress, Grid, ListItemAvatar, ListItemButton} from "@mui/material";
import PropTypes from "prop-types";
import {getIcon} from 'material-file-icons';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import {LanguageContext} from "../../context/LanguageContext";
import {getInvoiceFile} from "../../api/accountResource";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import fileDownload from "js-file-download";
import {useAuth} from "oidc-react";

const BillDocuments = (props) => {
  const auth = useAuth();
  const languageContext = useContext(LanguageContext);
  const {showRequestError} = useRequestErrorMessage();
  const [loading, setLoading] = useState("");

  const downloadFile = (bucketName, objectName, fileName) => {
    setLoading(fileName);
    getInvoiceFile(auth.userData.access_token, bucketName, objectName).then(data => {
      fileDownload(data.data, fileName);
    }).catch(error => {
      switch (error.response?.status) {
        case 500:
          //Ezt azért kell ilyen undorítóra, mert blob-ként jön vissza a válasz
          let reader = new FileReader();
          reader.onload = function () {
            if (reader.result?.includes("details")) {
              error.message = JSON.parse(reader.result).details;
            } else {
              error.message = reader.result;
            }
            showRequestError(error, languageContext.dictionary.couldNotDownloadFile);
          };
          reader.readAsText(error.response.data);
          break;
        case 404:
          error.message = languageContext.dictionary.fileNotFound;
          showRequestError(error, languageContext.dictionary.couldNotDownloadFile);
          break;
        default:
          showRequestError(error, languageContext.dictionary.couldNotDownloadFile);
      }
    }).finally(data => {
      setLoading("");
    });
  };

  return (
    <Grid container spacing={2}>
      {props.billingData?.fileSourceList?.map(document => (
        <Grid item xs={12} sm={6} md={4} key={document.fileName}>
          <Paper>
            <ListItem sx={{padding: 0}}>
              <ListItemButton disabled={loading === document.fileName} onClick={() => {
                downloadFile(document.bucketName, document.objectName, document.fileName);
              }}>
                <ListItemAvatar>
                  {loading === document.fileName ?
                    <CircularProgress color="secondary"/>
                    :
                    <Avatar src={`data:image/svg+xml;utf8,${encodeURIComponent(getIcon(document.fileName).svg)}`}/>
                  }
                </ListItemAvatar>
                <ListItemText primary={languageContext.dictionary.billTypes[document.fileType.toLowerCase()] || document.fileType}
                              secondary={document.fileName}
                              secondaryTypographyProps={{style: {fontSize: 12, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}}/>
              </ListItemButton>
            </ListItem>
          </Paper>
        </Grid>
      ))
      }
    </Grid>
  );
};

BillDocuments.propTypes = {
  billingData: PropTypes.object
};

export default BillDocuments;