import React, {createContext, useContext, useEffect, useState} from "react";
import {parseJwt} from "../utils/parseToken";
import {CustomerContext, initialCustomerInfo} from "./CustomerContext";
import {getActiveCompany, getCustomerRoles} from "../api/companyResource";
import {LanguageContext} from "./LanguageContext";
import {useRequestErrorMessage} from "../utils/useRequestErrorMessage";
import {omit} from "lodash-es";
import {getNotificationCounter} from "../api/notificationResource";
import {useAuth} from "oidc-react";
import {hasAccess} from "../utils/accessManager";

const initialUserInfo = {
  email: "",
  fullName: "",
  firstName: "",
  lastName: "",
  username: "",
  title: "",
  company: "",
  companyList: [],
  innerUser: false,
  notificationCounter: 0
};

export const UserContext = createContext({
  userInfo: initialUserInfo
});

const UserProvider = props => {
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const {showRequestError} = useRequestErrorMessage();
  const [userInfo, setUserInfo] = useState({});
  const auth = useAuth();

  const provider = {
    userInfo
  };

  useEffect(() => {
    if (auth.userData && !auth.isLoading) {
      setUser(auth);
    }
  }, [auth]);

  async function setUser(auth) {
    const parsedToken = parseJwt(auth.userData.access_token);

    const allCustomer = (hasAccess("CSSP_ALL_CUSTOMER", auth) || hasAccess("ALL_CUSTOMER", auth));
    const innerUser = (allCustomer || hasAccess("SALES", auth));

    let companyList = [];
    if (!allCustomer) {
      // if allCustomer, then search api in company selector
      const tokenEmpty = !parsedToken["customer_roles"] || parsedToken["customer_roles"]?.length === 0;
      companyList = !tokenEmpty ? parsedToken["customer_roles"] : (await customerRoles(auth.userData.access_token));
    }

    if (customerContext.selectedCustomer && !customerContext.selectedCustomer.id) {
      let customer = companyList[0];
      let contact = {
        email: parsedToken.email || "",
        mobile: parsedToken.mobile || "",
        firstName: parsedToken["given_name"] || "",
        lastName: parsedToken["family_name"] || "",
        poid: parsedToken["poid"] || "",
        ...customer
      };

      if (innerUser || companyList?.length > 1) {
        let activeCustomer = await activeCompany(auth.userData.access_token, parsedToken.email, companyList, innerUser);

        if (activeCustomer?.id !== "") {
          const contactMapping = {
            contactEmail: "email",
            contactMobile: "mobile",
            contactFirstName: "firstName",
            contactLastName: "lastName",
            poId: "poid"
          };

          for (let contactMappingKey in contactMapping) {
            contact[contactMapping[contactMappingKey]] = activeCustomer[contactMappingKey];
          }

          customer = omit(activeCustomer, Object.keys(contactMapping));
          contact = {...contact, ...customer};
        }

      }

      customerContext.setContact(contact);
      customerContext.setCustomer(customer);
    }

    const notificationCounter = (await getNotificationCount(auth.userData.access_token));

    setUserInfo({
      email: parsedToken.email || "",
      fullName: parsedToken.name || "",
      firstName: parsedToken["given_name"] || "",
      lastName: parsedToken["family_name"] || "",
      poid: parsedToken["poid"] || "",
      title: parsedToken["title"] || "",
      companyList: companyList,
      allCustomer: allCustomer,
      innerUser: innerUser,
      notificationCounter: notificationCounter
    });
  }

  async function customerRoles(token) {
    let companyList = [];

    try {
      const companyPromise = await getCustomerRoles(token);
      companyList = companyPromise.data;
    } catch (error) {
      showRequestError(error, languageContext.dictionary.errorMessages.companyListError);
    }

    return companyList;
  }

  async function activeCompany(token, email, companyList, innerUser) {
    let selectedCompany = initialCustomerInfo;

    try {
      const selectedCompanyPromise = await getActiveCompany(token, email);
      let validCompany = companyList?.find(c => c.id === selectedCompanyPromise.data.id);

      if (innerUser && !validCompany) {
        validCompany = selectedCompanyPromise.data;
      }

      if (validCompany) {
        selectedCompany = {...selectedCompanyPromise.data, roles: validCompany.roles || []};
      }
    } catch (error) {
      showRequestError(error, languageContext.dictionary.errorMessages.selectedCompanyLoadError);
    }

    if (selectedCompany.taxNumber) {
      selectedCompany.vat = selectedCompany.taxNumber;
      delete selectedCompany.taxNumber;
    }

    return selectedCompany;
  }

  async function getNotificationCount(token) {
    let vmCounter = 0;

    try {
      vmCounter = (await getNotificationCounter(token)).data;
    } catch (error) {
      showRequestError(error, languageContext.dictionary.errorMessages.notificationCounterLoadError);
    }

    return vmCounter;
  }

  return (
    <UserContext.Provider value={provider}>
      {props.children}
    </UserContext.Provider>
  );

};

export default UserProvider;
