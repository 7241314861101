import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {LanguageContext} from "../../context/LanguageContext";
import {useAuth} from "oidc-react";
import Typography from "@mui/material/Typography";
import CaseAccordion from "./CaseAccordion";
import {CustomerContext} from "../../context/CustomerContext";
import {CircularProgress} from "@mui/material";
import PropTypes from "prop-types";
import {getDmsCaseList} from "../../api/dmsResource";
import {formatDateTimeSec} from "../../utils/dateFormat";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const CasesTab = ({isClosed}) => {
  const auth = useAuth();
  const languageContext = useContext(LanguageContext);
  const [caseList, setCaseList] = useState([]);
  const [caseListUnderRegistration, setCaseListUnderRegistration] = useState([]);
  const customerContext = useContext(CustomerContext);
  const [loading, setLoading] = useState(true);
  const closedKeyword = "lezárva";

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setLoading(true);
      getDmsCaseList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => {
          let list = res.data.filter(item => {
            item.registrationDate = formatDateTimeSec(item.registrationDate) || "";
            item.closeDate = formatDateTimeSec(item.closeDate) || "";
            item.operator = item.operator || "";
            item.subject = item.subject || item.type;
            return !isClosed ? item.status !== closedKeyword : item.status === closedKeyword;
          });
          setCaseList(list.filter(item => item.registrationNumber));
          setCaseListUnderRegistration(list.filter(item => !item.registrationNumber));
        })
        .finally(() => setLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, isClosed]);

  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Grid container sx={{paddingBottom: 3, paddingTop: 3}} spacing={3} seleniumid={isClosed ? "closedCasesTab" : "pendingCasesTab"}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {languageContext.dictionary[isClosed ? "closedCases" : "pendingCases"]}
          </Typography>
        </Grid>
        {!loading ? (
          <Grid item xs={12} container>
            <Grid item xs={12} container>
              {caseList.toReversed().map((item, index) => (
                <Grid item xs={12} key={index} style={{marginBottom: 20}}>
                  <CaseAccordion data={item} closedKeyword={closedKeyword}/>
                </Grid>
              ))}
            </Grid>
            <NoDataToDisplay loading={loading} list={caseList}/>
            {caseListUnderRegistration.length > 0 && !isClosed ? (
              <Grid item xs={12} container spacing={3} sx={{paddingTop: 2}}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {languageContext.dictionary.caseUnderRegistration}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {caseListUnderRegistration.toReversed().map((item, index) => (
                    <Grid item xs={12} key={index} style={{marginBottom: 20}}>
                      <CaseAccordion data={item} closedKeyword={closedKeyword}/>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}
          </Grid>) : (
          <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress sx={{margin: 4}} color="secondary"/>
          </Grid>
        )}
      </Grid>
    </RenderedWithPermission>
  );
};

CasesTab.propTypes = {
  isClosed: PropTypes.bool
};

export default CasesTab;
