import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {makeStyles} from "@mui/styles";
import {useAuth} from "oidc-react";
import ContractsFilter from "./ContractsFilter";
import ContractDataList from "./ContractDataList";
import {getContractList} from "../../api/contractResource";
import {CustomerContext} from "../../context/CustomerContext";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  details: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3)
    },
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

const defaultFilters = {
  period: [null, null],
  contractNumber: "",
  duration: ""
};

const ContractsTab = () => {
  const classes = useStyles();
  const customerContext = useContext(CustomerContext);
  const languageContext = useContext(LanguageContext);
  const auth = useAuth();
  const [filters, setFilters] = useState(defaultFilters);
  const [selectOptions, setSelectOptions] = useState({duration: []});
  const [contracts, setContracts] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [filteredList, setFilteredList] = useState({operating: [], implement: [], proposal: []});

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setOptionsLoading(true);
      getContractList(auth.userData.access_token, customerContext.selectedCustomer.id, "ALL")
        .then(res => {
          getDurationList(res.data);
          setContracts(res.data);
        })
        .finally(() => setOptionsLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  const handleFilterSubmit = (filter) => {
    setFilters(filter);
  };

  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Grid container className={classes.grid} spacing={6} seleniumid={"ContractsTab"}>
        <ContractsFilter
          handleFilterSubmit={handleFilterSubmit}
          optionsLoading={optionsLoading}
          options={selectOptions}
        />
        <ContractDataList
          status={"OPERATING"}
          filters={filters}
          setFilteredList={setFilteredList}
        />
        <ContractDataList
          status={"IMPLEMENT"}
          filters={filters}
          setFilteredList={setFilteredList}
        />
        <ContractDataList
          status={"PROPOSAL"}
          filters={filters}
          setFilteredList={setFilteredList}
        />
        {(filteredList.operating.length + filteredList.implement.length + filteredList.proposal.length) === 0 ?
          <NoDataToDisplay loading={optionsLoading} list={[]}/> : null}
      </Grid>
    </RenderedWithPermission>
  );

  function getDurationList(contractList) {
    let durationList = contractList.map(contract => ({
      label: contract.commercialTermInMonths ? `${contract.commercialTermInMonths} ${languageContext.dictionary.month.toLowerCase()}` : null,
      value: contract.commercialTermInMonths
    })).sort((a, b) => a.value - b.value);
    let filteredList = durationList.filter((object, index) => object.value && index === durationList.findIndex(obj => obj.value === object.value));
    setSelectOptions(o => ({...o, duration: filteredList}));
  }

};

export default ContractsTab;
