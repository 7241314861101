import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DataTable from "../datatable/DataTable";
import {Avatar} from "@mui/material";
import {CustomerContext} from "../../context/CustomerContext";
import {getInternalContactList} from "../../api/companyResource";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {fullNameByLanguage} from "../../utils/commonFunctions";
import {useAuth} from "oidc-react";

const InvitechContacts = () => {
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const customerContext = useContext(CustomerContext);
  const [dataLoading, setDataLoading] = useState(true);
  const {showRequestError} = useRequestErrorMessage();
  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      name: "name",
      label: "name",
      render: (value, rowData) => (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Avatar src={`data:text/plain;base64,${rowData?.picture}`} style={{float: "left"}}/>
          </Grid>
          <Grid item>
            <Typography sx={{ml: 3, fontWeight: "bold"}}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      )
    },
    {name: "position", label: "position"},
    {name: "phoneNumber", label: "phoneNumber"},
    {name: "email", label: "email"}
  ];

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setDataLoading(true);
      getInternalContactList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => {
          const formattedList = res.data.map(item => {
            return {...item, name: fullNameByLanguage(langContext.language.id, item?.firstName, item?.lastName)};
          });
          setTableData(formattedList);
        })
        .catch(error => showRequestError(error, langContext.dictionary.errorMessages.userDataLoadError))
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  return (
    <Grid container marginBottom={1} marginTop={5}>
      <Grid item xs={12}>
        <Typography variant="h4">{langContext.dictionary.invitechContacts}</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          data={tableData}
          columns={columns}
          isLoading={dataLoading}
        />
      </Grid>
    </Grid>
  );
};

export default InvitechContacts;
