import { useState } from "react";
export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      if (item) {
        if (typeof initialValue === "function") {
          return initialValue(JSON.parse(item));
        }
        return JSON.parse(item);
      } else if (typeof initialValue === "function") {
        return initialValue();
      }
      return initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });
  const store = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, store];
};