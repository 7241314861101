import React from "react";
import {NumericFormat} from 'react-number-format';
import {TextField} from "@mui/material";

// https://stackoverflow.com/questions/63150232/react-js-material-ui-how-to-format-textfield-as-an-amount
// https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
// https://github.com/s-yadav/react-number-format/issues/191

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      valueIsNumericString
      allowNegative={false}
      decimalSeparator={","}
      thousandSeparator={" "}
    />
  );
});

const NumberFieldWithFormat = props => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumericFormatCustom
      }}
    />
  );
};

export default NumberFieldWithFormat;