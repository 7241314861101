import {useSnackbar} from "notistack";
import RequestErrorCopyButton from "../components/common/RequestErrorCopyButton";
import React, {useCallback, useContext} from "react";
import {LanguageContext} from "../context/LanguageContext";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import * as Sentry from "@sentry/react";

const getMessageFromErrorObject = (error, additionalMessage) => {
  let message = "";
  if (error instanceof Error) {
    if (error.response?.data) {
      try {
        message = JSON.stringify(error.response.data);
      } catch (cyclicObjectError) {
        message = error.response.data;
      }
    } else if (error.message) {
      message = error.message;
    }
  } else {
    try {
      message = JSON.stringify(error);
    } catch (cyclicObjectError) {
      message = error;
    }
  }

  return moment().format("YYYY-MM-DD HH:mm:ss (G[M]TZ)") + " - " + (additionalMessage ? message + "\n" + additionalMessage : message);
};

export const useRequestErrorMessage = (simpleMessage = false) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const languageContext = useContext(LanguageContext);

  const getDisplayedError = useCallback((messageObject) => <div key={messageObject.errorMessage}>
    {messageObject.errorMessage ? <>{messageObject.errorMessage}<br/></> : <></>}
    {messageObject.error instanceof Error ? <>{languageContext.dictionary.errorMessage}: {messageObject.error.message}</> : messageObject.error}
  </div>, [languageContext.dictionary.errorMessage]);

  const uniqBy = (arr, predicate) => {
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

    return [...arr.reduce((map, item) => {
      const key = (item === null || item === undefined) ?
        item : cb(item);

      map.has(key) || map.set(key, item);

      return map;
    }, new Map()).values()];
  };

  /**
   * @param {Object[]} messageGroup
   * @param {('filterDuplicates'|'firstPerGroup')} [filterStrictness] - Optional. How strictly to filter the displayed error message. Does not affect the values copied by the copy button.
   */
  const showGroupedRequestError = (messageGroup, filterStrictness) => {
    if (!messageGroup?.length) {
      return;
    }
    let displayedMessage = "";
    if (!filterStrictness) {
      displayedMessage = messageGroup.map(getDisplayedError);
    } else if (filterStrictness === "filterDuplicates") {
      displayedMessage = uniqBy(messageGroup.map(getDisplayedError), m => JSON.stringify(m));
    } else if (filterStrictness === "firstPerGroup") {
      displayedMessage = getDisplayedError(messageGroup[0]);
    }

    const errorMessage = messageGroup.map(m => getMessageFromErrorObject(m.error, m.errorMessage)).join('\n\n');
    enqueueSnackbar(
      <div>
        {displayedMessage}
      </div>, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 10000,
        action: key => (
          <>
            <RequestErrorCopyButton errorMessage={errorMessage}
                                    closeSnackbar={() => closeSnackbar(key)}/>
            <IconButton onClick={() => closeSnackbar(key)} style={{color: "white"}} size="large">
              <CloseIcon/>
            </IconButton>
          </>
        )
      });
  };

  const showRequestError = (error, message, options) => {
    console.error(error);
    Sentry.captureException(error);
    Sentry.captureMessage(message);
    enqueueSnackbar(
      <div>
        {message ? <>{message}<br/></> : <></>}
        {!simpleMessage ? error instanceof Error ?
          <>{languageContext.dictionary.errorMessage}: {error.message}</> :
          {error} : null
        }
      </div>, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 10000,
        action: key => (
          <>
            <RequestErrorCopyButton errorMessage={getMessageFromErrorObject(error, message)} closeSnackbar={() => closeSnackbar(key)}/>
            <IconButton onClick={() => closeSnackbar(key)} style={{color: "white"}} size="large">
              <CloseIcon/>
            </IconButton>
          </>
        ),
        ...options
      });
  };
  return {
    showRequestError: showRequestError,
    showGroupedRequestError: showGroupedRequestError,
    hideRequestError: closeSnackbar,
  };
};