import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {capitalizeFirstLetter, filterServicesOrEstablishments} from "../../utils/commonFunctions";
import Typography from "@mui/material/Typography";
import {Chip, CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {CustomerContext} from "../../context/CustomerContext";
import {useAuth} from "oidc-react";
import {getEstablishmentAll} from "../../api/serviceResource";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import EstablishmentsTable from "./EstablishmentsTable";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminTechRight} from "../../utils/commonValues";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    marginRight: theme.spacing(1)
  },
  circularProgress: {
    margin: theme.spacing(4)
  }
}));

const EstablishmentsTab = () => {
  const classes = useStyles();
  const auth = useAuth();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const [tableData, setTableData] = useState({cloud: [], voice: [], internet: [], dataTransfer: [], dataCenter: [], itSolutions: [], other: []});
  const [establishments, setEstablishments] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(true);
    if (auth.userData && !!customerContext.selectedCustomer.id) {
      getEstablishmentAll(auth.userData.id_token, "IMPLEMENT", customerContext.selectedCustomer.id)
        .then(res => {
          setTableData(filterServicesOrEstablishments(res.data));
          setEstablishments(res.data);
        })
        .finally(() => setLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.languageHeader]);

  return (
    <RenderedWithPermission roles={adminTechRight} displayNoRightsLabel={true}>
      {!loading ? (
        <Grid container className={classes.grid} spacing={6} seleniumid={"establishmentsGridContainer"}>
          {Object.entries(tableData).map(([key, value]) => (
            value?.length > 0 ? (
              <Grid item container spacing={1} key={`establishments_grid_${key}`} seleniumid={`establishmentsGrid${capitalizeFirstLetter(key)}`}>
                <Grid item xs={12}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Typography variant="h5" className={classes.title}>
                      {languageContext.dictionary.serviceCategories[key]}
                    </Typography>
                    <Chip label={value.length} color="secondary" size="small"/>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <EstablishmentsTable
                    tableData={value}
                    tableLoading={loading}
                  />
                </Grid>
              </Grid>
            ) : null
          ))}
        </Grid>
      ) : (
        <div style={{textAlign: "center"}}>
          <CircularProgress
            className={classes.circularProgress}
            color="secondary"
          />
        </div>
      )}
      <NoDataToDisplay loading={loading} list={establishments}/>
    </RenderedWithPermission>
  );
};

export default EstablishmentsTab;