import React, {useContext} from "react";
import {LanguageContext} from "../context/LanguageContext";
import MaintenancesTab from "../components/technicalOverview/MaintenancesTab";
import EstablishmentsTab from "../components/technicalOverview/EstablishmentsTab";
import ErrorTicketRecordingTab from "../components/technicalOverview/ErrorTicketRecordingTab";
import TabPageWithUrl from "../components/common/TabPageWithUrl";
import {Redirect, useLocation} from "react-router-dom";

const TechnicalOverviewPage = () => {
  const languageContext = useContext(LanguageContext);
  const location = useLocation();
  const tabs = [
    {pathname: "/technicalOverview/maintenances", index: 0, label: "maintenances", child: MaintenancesTab},
    {pathname: "/technicalOverview/establishments", index: 1, label: "establishments", child: EstablishmentsTab},
    {pathname: "/technicalOverview/errorTicketRecording", index: 2, label: "errorTicketRecording", child: ErrorTicketRecordingTab}
  ];

  return (
    <>
      {location.pathname === "/technicalOverview" ? (
        <Redirect to={tabs[0].pathname}/>
      ) : null}
      <TabPageWithUrl tabs={tabs} title={languageContext.dictionary.technicalOverview} seleniumid={"TechnicalOverviewPage"}/>
    </>
  );
};

export default TechnicalOverviewPage;
