import React, {useContext} from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {LanguageContext} from "../../context/LanguageContext";

const SectionWithDivider = ({title, value, status, lastItem, reverseStyle, dense, longTitle}) => {
  const langContext = useContext(LanguageContext);
  return (
    <Grid
      item xs={12} container
      style={{paddingTop: 8, paddingBottom: 8}}
      direction="row" justifyContent="center" alignItems="center"
    >
      <Grid item xs={12} sm={longTitle ? 5 : 3} sx={{paddingRight: 1}}>
        <Typography style={{fontWeight: reverseStyle ? "bold" : ""}}>{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={longTitle ? 7 : 9}>
        {status !== undefined ?
          <Typography style={{fontWeight: reverseStyle ? "" : "bold"}}>{status ? langContext.dictionary.yes : langContext.dictionary.no}</Typography> : null}
        <Typography style={{fontWeight: reverseStyle ? "" : "bold"}}>{value}</Typography>
      </Grid>
      {!lastItem ? (
        <Grid item xs={12}>
          <Divider sx={dense ? {} : {paddingTop: "16px"}}/>
        </Grid>
      ) : null}
    </Grid>
  );
};

SectionWithDivider.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  status: PropTypes.bool,
  lastItem: PropTypes.bool,
  index: PropTypes.number,
  reverseStyle: PropTypes.bool,
  dense: PropTypes.bool,
  longTitle: PropTypes.bool
};

export default SectionWithDivider;
