import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

const resource = "/dms";

export const getDmsCaseTypeList = async (token, categoryName) => {
  let url = `${env.apiRoot}${resource}/casetype/list/${categoryName}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const getDmsCaseTemplate = async (token, templateId) => {
  let url = `${env.apiRoot}${resource}/casetemplate/${templateId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const getDmsCaseList = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/cases/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const dmsStartCase = async (token, cmId, caseType, caseDescription, startOfCase, dmsCaseAttributeList) => {
  let url = `${env.apiRoot}${resource}/startCase`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.post(url, {
    cmId: cmId,
    caseType: caseType,
    caseDescription: caseDescription,
    startOfCase: startOfCase,
    dmsCaseAttributeList: dmsCaseAttributeList
  }, {headers: {Authorization: authStr}});
};

export const universalDmsDynamicFieldSelectApi = async (token, cmId, url) => {
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const dmsDynamicFieldDocs = async (token, caseId) => {
  let url = `${env.apiRoot}${resource}/caseattachment/${caseId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};
