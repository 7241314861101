import React, {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const CurrencyTypography = ({amount, currency, ...props}) => {
  const languageContext = useContext(LanguageContext);

  return (
    <Typography variant={props.variant} sx={props.sx} className={props.className}>
      {`${Number(amount).toLocaleString(languageContext?.language?.id || 'hu')} 
      ${currency === "HUF" ? languageContext?.dictionary?.huf || "HUF" : currency}`}
    </Typography>
  );
};

CurrencyTypography.propTypes = {
  amount: PropTypes.any,
  currency: PropTypes.any,
  variant: PropTypes.string,
  sx: PropTypes.object,
  className: PropTypes.any
};

export default CurrencyTypography;