import React, {useContext} from "react";
import Typography from "@mui/material/Typography";
import {CardActionArea, CardMedia} from "@mui/material";
import Grid from "@mui/material/Grid";
import Section from "../common/Section";
import {LanguageContext} from "../../context/LanguageContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {makeStyles} from "@mui/styles";
import {CustomerContext} from "../../context/CustomerContext";
import {env, instance} from "../../properties";
import dcImage from "../../assets/images/dc.jpeg";
import perfmonImage from "../../assets/images/perfmon.jpeg";
import sdwanImage from "../../assets/images/sdwan.jpeg";
import ddosImage from "../../assets/images/ddos.jpeg";
import dnsImage from "../../assets/images/dns.jpeg";
import cssp from "../../assets/images/cssp.jpg";
import kubernetesImage from "../../assets/images/kubernetes.jpeg";
import Link from '@mui/material/Link';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  media: {
    height: 140,
  },
});

const HomeMenuCards = () => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);

  // homePageCards needs to be here because of env and instance
  const homePageCards = [
    {
      label: "dcArduinoMeter",
      url: instance === 'INV' ? `${env?.tpRoot}/dcArduinoMeter` : `${env?.tpRoot}/dcService`,
      src: dcImage,
      access: ["DCPOWER"],
      description: "dcArduinoMeterDesc"
    },
    {
      label: "performanceMonitor",
      url: instance === 'INV' ? `${env?.tpRoot}/perfmon` : `${env?.tpRoot}/perfmonData`,
      src: perfmonImage,
      access: ["PERFMON"],
      description: "performanceMonitorDesc"
    },
    {
      label: "SD-WAN",
      url: `${env?.tpRoot}/site`,
      src: sdwanImage,
      access: ["SDWAN"],
      description: "sdWanDesc"
    },
    {
      label: "ddosSecurityService",
      url: `${env?.tpRoot}/ddosSecurityService`,
      src: ddosImage,
      access: ["DDOS"],
      description: "ddosSecurityServiceDesc"
    },
    {
      label: "dnsManagement",
      url: `${env?.tpRoot}/dnsMgmt`,
      src: dnsImage,
      access: ["DNS"],
      description: "dnsManagementDesc"
    },
    {
      label: "cssp",
      url: `${env?.csspRoot}`,
      src: cssp,
      access: ["CSSP"],
      description: "csspDesc"
    },
    {
      label: "fastlane",
      url: `${env?.tpRoot}/k8sUsage`,
      src: kubernetesImage,
      access: ["K8SM"],
      description: "fastlaneDesc"
    }
  ];

  return (
    <Section hideBorder>
      <Grid container spacing={4}>
        {homePageCards.map(page => page.access?.some(r => customerContext.selectedContact?.roles?.includes(r)) ? (
          <Grid item xs={12} sm={6} lg={3} key={page.label + "_grid"}>
            <Link href={page?.url} target={page?.url || ""} style={{textDecoration: 'none'}}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia className={classes.media} image={page.src} title={page.componentName}/>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {languageContext.dictionary[page.label] || page.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {languageContext.dictionary[page.description] || page.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        ) : null)}
      </Grid>
    </Section>
  );
};

export default HomeMenuCards;
