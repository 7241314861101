import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {CircularProgress, Container, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import {LanguageContext} from "../context/LanguageContext";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {formatDate} from "../utils/dateFormat";
import ServiceDetailsRow from "../components/services/ServiceDetailsRow";
import {getServiceDetails} from "../api/serviceResource";
import {formatCurrency} from "../utils/formatters";
import {useAuth} from "oidc-react";
import RelatedErrorTickets from "../components/errorTicket/RelatedErrorTickets";
import {getOpenErrorTicketList} from "../api/errorTicketResource";
import {CustomerContext} from "../context/CustomerContext";
import ErrorTicketButton from "../components/errorTicket/ErrorTicketButton";
import {contactHasRight} from "../utils/commonFunctions";
import RenderedWithPermission from "../components/RenderedWithPermission";
import {adminFinRight, adminFinTechRight} from "../utils/commonValues";

const ServiceDetails = () => {
  const urlParams = useParams();
  const history = useHistory();
  const auth = useAuth();
  const customerContext = useContext(CustomerContext);
  const languageContext = useContext(LanguageContext);
  const [serviceDetails, setServiceDetails] = useState({});
  const [openErrorTicketList, setOpenErrorTicketList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ticketListLoading, setTicketListLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (auth.userData && urlParams.id) {
      getServiceDetails(auth.userData.access_token, urlParams.id)
        .then(res => {
          let rawData = res.data;
          rawData.commonDataList.map(item => {
            if (item.attributeName !== languageContext.dictionary.relatedServicesChange) {
              return item;
            } else {
              item.attributeName = languageContext.dictionary.relatedServicesID;
              return item;
            }
          });
          setServiceDetails(rawData);
        })
        .catch(() => history.push("/services/all"))
        .finally(() => setLoading(false));
    }
  }, [urlParams.id, auth.userData, languageContext.language.id]);

  useEffect(() => {
    setTicketListLoading(true);
    if (auth.userData && customerContext.selectedCustomer.id) {
      getOpenErrorTicketList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => setOpenErrorTicketList(res.data))
        .finally(() => setTicketListLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  const getCommonValue = (param) => {
    switch (param.dataType) {
      case "N":
        return `${Number(param.value).toLocaleString(languageContext?.language?.id || 'hu') || ""} ${param.dmsr || ""}`;
      case "D":
        return formatDate(param.value);
      default:
        return param.description;
    }
  };

  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Container maxWidth="lg" sx={{paddingBottom: 3, paddingTop: 3}}>
        <Button
          variant="text"
          onClick={() => history.push("/services/all")}
          size="small"
          color="secondary"
          disableRipple
        >
          <ChevronLeftIcon color="secondary"/>
          {languageContext.dictionary.allServices}
        </Button>
        {!loading ? (
          <>
            <Grid container spacing={3} sx={{paddingTop: 3}}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">{serviceDetails.serviceName}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                {!ticketListLoading ? (
                  <Container sx={{textAlign: "right"}}>
                    <ErrorTicketButton
                      hasOpenErrorTicket={serviceDetails?.serviceIds?.some(id => openErrorTicketList?.includes(id))}
                      serviceStatus={serviceDetails.status}
                    />
                  </Container>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{backgroundColor: "white", padding: 4}} elevation={3}>
                  <Grid container spacing={2}>
                    <Grid item container xs={12}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography>{languageContext.dictionary.serviceType}</Typography>
                        <Typography variant="h6" color="secondary">{serviceDetails.serviceType}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={8}>
                        <Typography>{languageContext.dictionary.nameSynonym}</Typography>
                        <Typography variant="h6" color="secondary">{serviceDetails.serviceName}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 2}}>
                      <Typography style={{fontWeight: "bold"}}>{languageContext.dictionary.serviceParameters}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider/>
                    </Grid>
                    <ServiceDetailsRow
                      key={"contractNumber"}
                      data={serviceDetails.contractNumber}
                      title={"contractNumber"}
                      linkTo={`/contractDetails/${serviceDetails.contractPoid}`}
                      linkText={languageContext.dictionary.contractDetails}
                    />
                    <ServiceDetailsRow
                      key={"collectorId"}
                      data={serviceDetails.giroId}
                      title={"contractualCurrentAccountNumber"}
                      linkTo={`/billingDetails/${serviceDetails.billCollectorId}`}
                      linkText={languageContext.dictionary.accountDetails}
                      displayButton={contactHasRight(customerContext.selectedContact.roles, adminFinRight)}
                    />
                    <ServiceDetailsRow data={`${serviceDetails.linkCode} ${serviceDetails.serviceIds.length > 0 ? ("(" + serviceDetails.serviceIds?.join(", ") + ")") : ""} `}
                                       title={"serviceId"}/>
                    {serviceDetails.endpointAddresses.map((endpoint, index) => (
                      <ServiceDetailsRow key={`site_${index}`} data={endpoint} title={"endpointAddress"}/>
                    ))}
                    <RenderedWithPermission roles={adminFinRight}>
                      <Grid item xs={12} sx={{mt: 2}}>
                        <Typography style={{fontWeight: "bold"}}>{languageContext.dictionary.fees}</Typography>
                      </Grid>
                      {serviceDetails.feeDataList.map((fee, index) => (
                        <ServiceDetailsRow
                          key={`fee_${index}`}
                          data={formatCurrency(fee.fee, fee.msr, languageContext)}
                          title={fee.attributeName}
                        />
                      ))}
                    </RenderedWithPermission>
                    <Grid item xs={12} sx={{mt: 2}}>
                      <Typography style={{fontWeight: "bold"}}>{languageContext.dictionary.otherServiceParameters}</Typography>
                    </Grid>
                    {serviceDetails.commonDataList.map((param, index) => (
                      <ServiceDetailsRow
                        key={`serviceParameter_${index}`}
                        data={getCommonValue(param)}
                        title={param.attributeName}
                        relatedService={param?.poid}
                        poId={param.poid}
                      />
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <RelatedErrorTickets serviceIds={serviceDetails.serviceIds}/>
          </>
        ) : (
          <div style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress sx={{margin: 2}} color="secondary"/>
          </div>
        )}
      </Container>
    </RenderedWithPermission>
  );

};

export default ServiceDetails;
