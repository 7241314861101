import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

const resource = "/dcma";

export const getDcmaAccessList = async (token, cmId, from, to) => {
  let url = `${env.apiRoot}${resource}/access/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, params: {from: from, to: to}});
};

export const createServerRoomAccessPermit = async (token, obj) => {
  let url = `${env.apiRoot}${resource}/access`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.post(url, obj, {headers: {Authorization: authStr, 'Content-Type': 'application/json'}});
};
