import React, {useContext} from "react";
import PropTypes from "prop-types";
import ComboInputGrid from "./ComboInputGrid";
import RepeaterGrid from "./RepeaterGrid";
import {LanguageContext} from "../../../context/LanguageContext";

const RepeaterComboInput = (props) => {
  const {
    handleFieldChange,
    dataList,
    listName,
    textFieldOptions,
    selectOptions,
    selectOptionsLoading,
    validationType,
    optionLabel,
    deleteFlag,
    defaultNewObject,
    idName
  } = props;

  const languageContext = useContext(LanguageContext);

  const handleListChange = (fieldName, value, idx) => {
    let updatedList = [...dataList];
    updatedList[idx][fieldName] = value;
    handleFieldChange(listName, updatedList, null);
  };

  return dataList ? (
    <RepeaterGrid
      dataList={dataList}
      handleAddNew={(updatedList) => handleFieldChange(listName, updatedList, null)}
      handleDelete={(updatedList) => handleFieldChange(listName, updatedList, null)}
      deleteFlag={deleteFlag}
      defaultNewObject={defaultNewObject}
      idName={idName}
    >
      {(data, index) =>
        <ComboInputGrid
          data={data}
          handleChange={(fieldName, value) => handleListChange(fieldName, value, index)}
          textFieldOptions={index > 0 && textFieldOptions.label === languageContext.dictionary.phoneNumber ? {
            label: languageContext.dictionary.mobile,
            required: textFieldOptions.required
          } : textFieldOptions}
          selectOptions={selectOptions}
          selectOptionsLoading={selectOptionsLoading}
          validationType={validationType}
          optionLabel={optionLabel}
        />
      }
    </RepeaterGrid>
  ) : null;
};

RepeaterComboInput.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  listName: PropTypes.string.isRequired,
  textFieldOptions: PropTypes.object.isRequired,
  selectOptions: PropTypes.object.isRequired,
  selectOptionsLoading: PropTypes.bool,
  validationType: PropTypes.string,
  labelName: PropTypes.string,
  deleteFlag: PropTypes.string,
  defaultNewObject: PropTypes.object,
  idName: PropTypes.string,
};

export default RepeaterComboInput;
