import {Link, useHistory} from "react-router-dom";
import React, {useContext} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Container} from "@mui/material";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";

const ServiceDetailsRow = ({data, title, linkTo, linkText, relatedService, poId, displayButton}) => {
  const history = useHistory();
  const languageContext = useContext(LanguageContext);

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} sm={4} md={4} sx={{paddingRight: 2}}>
        <Typography>{languageContext.dictionary[title] || title}</Typography>
      </Grid>
      {relatedService ? (
        <Grid item xs={12} sm={linkTo ? 4 : 6} md={linkTo ? 5 : 8}>
          <Link
            to={`/serviceDetails/${poId}`}
            style={{textDecoration: 'none'}}
            onClick={e => e.stopPropagation()}
          >
            <Button
              variant="text"
              size="small"
              color="secondary"
              disableRipple
              style={{fontSize: 14}}
            >
              <Typography style={{fontWeight: "bold"}}>{data}</Typography>
            </Button>
          </Link>
        </Grid>
      ) : (
        <Grid item xs={12} sm={linkTo ? 4 : 6} md={linkTo ? 5 : 8}>
          <Typography style={{fontWeight: "bold"}}>{data}</Typography>
        </Grid>
      )}
      {linkTo && displayButton ? (
        <Grid item xs={12} sm={4} md={3}>
          <Container sx={{textAlign: "right"}}>
            <Button
              variant="text"
              onClick={() => history.push(linkTo)}
              size="small"
              color="secondary"
              disableRipple
            >
              {linkText}
              <ChevronRightIcon color="secondary"/>
            </Button>
          </Container>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Divider light/>
      </Grid>
    </Grid>
  );
};

ServiceDetailsRow.propTypes = {
  data: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
  poId: PropTypes.string,
  relatedService: PropTypes.bool,
  displayButton: PropTypes.bool
};
ServiceDetailsRow.defaultProps = {
  displayButton: true
};

export default ServiceDetailsRow;