import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_HU} from "material-react-table/locales/hu";
import {MRT_Localization_EN} from "material-react-table/locales/en";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {getOpenErrorTicketList} from "../../api/errorTicketResource";
import {useAuth} from "oidc-react";
import {CustomerContext} from "../../context/CustomerContext";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Tooltip from "@mui/material/Tooltip";

const ErrorTicketTable = ({tableData, tableLoading, setRowId, errorTicketSent}) => {
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const customerContext = useContext(CustomerContext);
  const [openErrorTicketList, setOpenErrorTicketList] = useState([]);
  const [ticketListLoading, setTicketListLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});


  const columns = [
    {
      accessorKey: 'serviceId',
      header: langContext.dictionary.technicalId,
      Cell: ({renderedCellValue, cell, row}) => <Link
        to={`/serviceDetails/${row?.original?.poId}`}
        style={{textDecoration: 'none'}}
        onClick={e => e.stopPropagation()}
      >
        <Tooltip title={openErrorTicketList.includes(renderedCellValue) ? langContext.dictionary.errorTicketOpenService : ""}>
          <Button
            variant="text"
            endIcon={openErrorTicketList.includes(renderedCellValue) ? <WarningAmberIcon color="warning"/> : null}
            size="small"
            color="secondary"
            disableRipple
            style={{fontSize: 15}}
          >
            {renderedCellValue}
          </Button>
        </Tooltip>
      </Link>,
    },
    {
      accessorKey: 'contractId',
      header: langContext.dictionary.contractNumber,
      Cell: ({renderedCellValue, cell, row}) => <Link
        to={`/contractDetails/${row?.original?.businessCase}`}
        style={{textDecoration: 'none'}}
        onClick={e => e.stopPropagation()}
      >
        <Button
          variant="text"
          size="small"
          color="secondary"
          disableRipple
          style={{fontSize: 15}}
        >
          {renderedCellValue}
        </Button>
      </Link>
    },
    {
      accessorKey: 'serviceCodeGroup',
      header: langContext.dictionary.services,
    },
    {
      accessorKey: 'address',
      header: langContext.dictionary.address,
    },
  ];

  useEffect(() => {
    if (errorTicketSent) {
      setRowSelection({});
    }
  }, [errorTicketSent]);

  useEffect(() => {
    setTicketListLoading(true);
    if (auth.userData && customerContext.selectedCustomer.id) {
      getOpenErrorTicketList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => setOpenErrorTicketList(res.data))
        .finally(() => setTicketListLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);


  useEffect(() => {
    if (Object.keys(rowSelection)?.length > 0) {
      //erre azért van szükség hogy egyedi id-val rendelkezzik minden sor és abból kell kiszűrni a serviceId-t. Sor id:{serviceId_contractId}
      setRowId(Object.keys(rowSelection)[0].split("_")[0]);
    }
  }, [rowSelection]);


  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      localization={langContext?.language?.id === 'hu' ? MRT_Localization_HU : MRT_Localization_EN}
      enableRowSelection
      enableMultiRowSelection={false}
      getRowId={(row) => `${row.serviceId}_${row.contractId}`}
      onRowSelectionChange={setRowSelection}
      positionToolbarAlertBanner={"none"}
      state={{
        isLoading: tableLoading && ticketListLoading,
        rowSelection: rowSelection
      }}
    />
  );
};

ErrorTicketTable.propTypes = {
  tableData: PropTypes.array,
  tableLoading: PropTypes.bool,
  setRowId: PropTypes.any
};

export default ErrorTicketTable;
