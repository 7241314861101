import React, {useContext} from "react";
import {Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {formatDate} from "../../utils/dateFormat";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  duration: {
    fontSize: 33,
    float: "left",
    marginRight: 5
  },
  rightGrid: {
    [theme.breakpoints.up('lg')]: {
      textAlign: "right"
    }
  },
  avatar: {
    backgroundColor: "white",
    color: theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: 2,
    width: 60,
    height: 60,
    fontSize: 25
  },
  boldRow: {
    fontWeight: "bold"
  },
  divider: {
    background: theme.palette.secondary.main
  },
  gridItem: {
    display: "flex"
  },
  boldText: {
    marginLeft: 10,
    fontWeight: "bold"
  }
}));

const ContractGrid = ({data, active}) => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);

  return (
    <Paper style={{padding: 15}}>
      <Grid container alignItems="center" justifyContent="space-around" spacing={2}>
        <Grid item container xs={10} md={11} alignItems="center" justifyContent="space-around">
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            {active ? <CheckCircleOutlinedIcon color="secondary"/> : <DownloadForOfflineOutlinedIcon/>}
            <Typography variant="subtitle1" className={classes.boldText}>{data.customerPortalDescription}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
            <Typography variant="subtitle1">{`${languageContext.dictionary.beginning}:`}</Typography>
            <Typography variant="subtitle1" className={classes.boldText}>{formatDate(data.subscriptionDate)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
            <Typography variant="subtitle1">{`${languageContext.dictionary.duration}:`}</Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              {data.commercialTermInMonths ? `${data.commercialTermInMonths} ${languageContext.dictionary.month.toLowerCase()}` : ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="subtitle1">{`${languageContext.dictionary.id}:`}</Typography>
            <Typography variant="subtitle1" className={classes.boldText}>{data.contractId}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={2} md={1} className={classes.gridItem}>
          <Link to={`/contractDetails/${data.poid}`} style={{textDecoration: 'none'}}>
            <IconButton color="secondary">
              <ChevronRightIcon/>
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </Paper>
  );
};

ContractGrid.propTypes = {
  data: PropTypes.object,
  active: PropTypes.bool
};

export default ContractGrid;