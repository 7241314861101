export const mainPageFooterElementList = [
  {
    mainTitle: "aboutUs",
    list: [
      {title: "aboutInvitech"},
      {title: "references"},
      {title: "career", url: "https://karrier.invitech.hu/allasok", target: "&quot;_blank&quot;"},
      {title: "socialResponsibility"},
      {title: "centralizedProcurement"},
      {title: "smartVillage", url: "https://www.okosfalu.hu/", onlyHU: true}
    ]
  },
  {
    mainTitle: "solutions",
    list: [
      {title: "forLargeCompanies"},
      {title: "forSmallAndMediumCompanies"},
      {title: "forInstitutions"},
      {title: "forWholesalers", url: "https://www.invitech.hu/nagykereskedelem-gyujto"},
      {title: "businessServices"},
      {title: "infrastructureServices"}
    ]
  },
  {
    mainTitle: "socialMedia",
    list: [
      {title: "news"},
      {title: "press", url: "https://www.invitech.hu/sajtoszoba", onlyHU: true},
      {title: "blog", url: "https://www.invitech.hu/techpercek", onlyHU: true},
      {title: "Linkedin", url: "https://www.linkedin.com/company/invitech-solutions/", target: "&quot;_blank&quot;"},
      {
        title: "Facebook",
        url: "https://www.facebook.com/pages/category/Telecommunication-Company/Invitech-1351873854829925/",
        target: "&quot;_blank&quot;"
      },
      {
        title: "Youtube",
        url: "https://www.youtube.com/channel/UC8nRKAHSwnIo8OH7kJHNr2A",
        target: "&quot;_blank&quot;"
      }
    ]
  }
];

export const footerElementList = [
  {title: "dataProtection", url: "https://www.invitech.hu/file/invitech-adatkezelesi-tajekoztato-2021-3/"},
  {title: "dataProcessingCode"},
  {title: "gtc"},
  {title: "certificates"},
  {title: "impress"},
  {title: "providerInformation"},
  {
    title: "filteringSoftware",
    url: "http://nmhh.hu/cikk/187272/Szuroszoftverek_a_karos_internetes_tartalmak_ellen_gyerekzar",
    target: "&quot;_blank&quot;"
  },
  {title: "coServiceOffers", url: "https://www.invitech.hu/tarsszolgaltatoi-ajanlatok"},
  {title: "premiumServices", url: "https://www.invitech.hu/emelt-dijas-szolgaltatasok"},
  {title: "socialResponsibility"},
  {title: "iivrApplication", url: "https://www.invitech.hu/iivr-palyazat"},
];