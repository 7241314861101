import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {LanguageContext} from "../../context/LanguageContext";
import AccountBalancesCard from "./AccountBalancesCard";
import PropTypes from "prop-types";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {getBalance} from "../../api/accountResource";
import {CircularProgress} from "@mui/material";
import {CustomerContext} from "../../context/CustomerContext";
import {useAuth} from "oidc-react";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinRight} from "../../utils/commonValues";

const AccountBalances = ({hasTitle, homePage}) => {
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const [dataLoading, setDataLoading] = useState(true);
  const [balanceList, setBalanceList] = useState([]);
  const customerContext = useContext(CustomerContext);


  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setDataLoading(true);
      getBalance(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => setBalanceList(res.data))
        .catch(error => showRequestError(error, langContext.dictionary.errorMessages.userDataLoadError))
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  return (
    <RenderedWithPermission roles={adminFinRight} displayNoRightsLabel={false}>
      <Grid marginTop={5} container direction="row" justifyContent={balanceList.length % 3 === 0 ? "center" : "start"} alignItems="center">
        {hasTitle ? <Grid item xs={12}>
          <Typography color="primary" variant={"h4"}>{langContext.dictionary.accountBalance}</Typography>
        </Grid> : null}
        {!dataLoading ? balanceList.map((item, index) =>
            <AccountBalancesCard key={`balancesCard_${index}`} data={item} index={index} homePage={homePage}/>
          ) :
          (<Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress color="secondary"/>
          </Grid>)}
        <NoDataToDisplay list={balanceList} loading={dataLoading}/>
      </Grid>
    </RenderedWithPermission>
  );
};

AccountBalances.propTypes = {
  hasTitle: PropTypes.bool,
  homePage: PropTypes.bool
};
export default AccountBalances;
