import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Avatar, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../../context/LanguageContext";
import IconButton from "@mui/material/IconButton";
import avatar from "../../../assets/images/avatar.png";
import CustomAutocomplete from "../../formFields/CustomAutocomplete";
import {handleBase64Formatting} from "../../../utils/commonFunctions";

const UserDataGrid = ({handleFieldChange, user, selectOptions, selectOptionsLoading, adminEditor, profilePhoto}) => {
  const languageContext = useContext(LanguageContext);

  const handlePhotoUpload = (event) => {
    let fileArray = Object.values(event.target.files).map(item => item);
    handleBase64Formatting(fileArray).then(res => handleFieldChange("photo", res[0].url));
  };

  const getPlaceHolder = () => {
    switch (user.personalId?.valueMsr) {
      case "IDCARDID":
        return "123456AB";
      case "PERSONALID":
        return "12345678912";
      case "DRIVLIC":
        return "AB123456";
      case "PASSPORTID":
        return "AB1234567";
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography sx={{fontWeight: "bold"}}>{languageContext.dictionary.userData}</Typography>
      </Grid>
      <Grid item xs={12} md={4} sx={{textAlign: "center"}}>
        <IconButton component="label" disabled>
          <Avatar
            sx={{maxWidth: {xs: "256px", lg: "100%"}, maxHeight: "100%", width: "100%", height: "auto"}}
            alt={user.firstName?.value + " " + user.lastName?.value}
            src={profilePhoto || avatar}
          >
          </Avatar>
          <input type="file" accept=".png,.jpeg,.jpg" hidden onChange={handlePhotoUpload}/>
        </IconButton>
      </Grid>
      <Grid item container xs={12} md={8} spacing={2} style={{paddingTop: 25}}>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label={user.lastName?.name}
            variant={"outlined"}
            onChange={e => handleFieldChange("lastName", e.target.value)}
            inputProps={{maxLength: 255}}
            value={user.lastName?.value || ""}
            required
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="outlined-basic"
            fullWidth
            label={user.firstName?.name}
            variant={"outlined"}
            onChange={e => handleFieldChange("firstName", e.target.value)}
            inputProps={{maxLength: 255}}
            value={user.firstName?.value || ""}
            required
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomAutocomplete
          options={selectOptions.position_code || []}
          value={user.positionCode?.value || ""}
          handleChange={(e, newValue) => handleFieldChange("positionCode", newValue?.value || "")}
          label={user.positionCode?.name}
          optionLabel={"title"}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <CustomAutocomplete
          options={selectOptions.role || []}
          value={user.role?.value || ""}
          handleChange={(e, newValue) => handleFieldChange("role", newValue?.value || "")}
          label={user.role?.name}
          disabled={!adminEditor}
          optionLabel={"title"}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <CustomAutocomplete
          options={selectOptions.personal_id || []}
          value={user.personalId?.valueMsr || ""}
          handleChange={(e, newValue) => handleFieldChange("personalId", newValue?.value, "valueMsr")}
          label={languageContext.dictionary.licenseType}
          optionLabel={"title"}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label={languageContext.dictionary.identificationNumber}
          variant={"outlined"}
          onChange={e => handleFieldChange("personalId", e.target.value)}
          inputProps={{maxLength: 255}}
          value={user.personalId?.value || ""}
          placeholder={getPlaceHolder()}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomAutocomplete
          options={selectOptions.colleague_who_authorized || []}
          value={user.colleagueWhoAuthorized?.value || ""}
          handleChange={(e, newValue) => handleFieldChange("colleagueWhoAuthorized", newValue?.value || "")}
          label={user.colleagueWhoAuthorized?.name}
          optionLabel={"title"}
        />
      </Grid>
    </Grid>
  );
};

UserDataGrid.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  selectOptions: PropTypes.object.isRequired,
  selectOptionsLoading: PropTypes.bool,
  adminEditor: PropTypes.bool,
  profilePhoto: PropTypes.string
};

export default UserDataGrid;
