import React, {useContext, useEffect, useMemo, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles} from "@mui/styles";
import {CustomerContext} from "../../context/CustomerContext";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CustomDateRangePicker from "../date/CustomDateRangePicker";
import {useAuth} from "oidc-react";
import MaterialReactTable from "material-react-table";
import {getDcmaAccessList} from "../../api/dcmaResource";
import moment from "moment/moment";
import {MRT_Localization_HU} from 'material-react-table/locales/hu';
import {MRT_Localization_EN} from 'material-react-table/locales/en';
import {formatDate, formatDateTime, formatTime} from "../../utils/dateFormat";
import {fullNameByLanguage} from "../../utils/commonFunctions";
import {useHistory} from "react-router-dom";
import {UmamiContext} from "../../context/UmamiContext";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminTechRight} from "../../utils/commonValues";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const ServerRoomAccessTab = () => {
  const classes = useStyles();
  const defaultFromDate = moment().subtract(1, 'month');
  const defaultToDate = moment();
  const [period, setPeriod] = useState([defaultFromDate, defaultToDate]);
  const [accessList, setAccessList] = useState([]);
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const history = useHistory();
  const umamiContext = useContext(UmamiContext);


  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      handleLoadServerRoomAccess();
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.language.id]);

  const dateFormatter = (starDate, endDate, key) => {
    if (moment(starDate).isValid() && moment(endDate).isValid()) {
      if (formatDate(starDate) !== formatDate(endDate)) {
        switch (key) {
          case "entranceDate":
            return `${formatDate(starDate)}\n${formatDate(endDate)}`;
          case "scheduledLogInLogOut":
          case "logInLogOut":
            return `${formatDateTime(starDate)}\n${formatDateTime(endDate)}`;
          default:
            return;
        }
      } else {
        switch (key) {
          case "entranceDate":
            return `${formatDate(starDate)}`;
          case "scheduledLogInLogOut":
          case "logInLogOut":
            return `${formatTime(starDate)} - ${formatTime(endDate)}`;
          default:
            return;
        }
      }
    } else {
      return "-";
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "entranceDate",
        header: languageContext.dictionary.date,
        size: 1,
        Cell: ({cell}) => (
          <span style={{whiteSpace: "pre"}}>
            <b>
              {cell.getValue()}
            </b>
          </span>),
      },
      {
        accessorKey: "scheduledLogInLogOut",
        header: languageContext.dictionary.scheduledLoginLogout,
        Cell: ({cell}) => (
          <span style={{whiteSpace: "pre"}}>
            {cell.getValue()}
          </span>),
      },
      {
        accessorKey: "serverRoom",
        header: languageContext.dictionary.serverRoom,
        size: 1
      },
      {
        accessorKey: "action",
        header: languageContext.dictionary.action,
        size: 1
      },
      {
        accessorKey: "name",
        header: languageContext.dictionary.name,
        size: 1
      },
      {
        accessorKey: "vitrinId",
        header: languageContext.dictionary.shId,
        size: 1,
        Cell: ({cell}) => (
          <span style={{whiteSpace: "pre"}}>
            {cell.getValue()}
          </span>)
      },
      {
        accessorKey: "logInLogOut",
        header: languageContext.dictionary.loginAndLogout,
        size: 1,
        Cell: ({cell}) => (
          <span style={{whiteSpace: "pre"}}>
            {cell.getValue()}
          </span>)
      }
    ],
    [languageContext.language.id],
  );

  const resetFilters = () => {
    setPeriod([null, null]);
    handleLoadServerRoomAccess(true);
  };

  return (
    <Grid container className={classes.grid} spacing={2}>
      <Grid item xs={12} style={{textAlign: "right"}}>
        <Button
          size="large"
          variant="text"
          startIcon={<AddBoxOutlinedIcon/>}
          color="secondary"
          onClick={() => {
            umamiContext.track('server_room_access_permit_request');
            history.push("/serverRoomAccessPermit");
          }}
        >
          {languageContext.dictionary.accessPermissionRequest}
        </Button>
      </Grid>
      <RenderedWithPermission roles={adminTechRight} displayNoRightsLabel>
        <Grid item xs={12}>
          <CustomDateRangePicker
            value={period}
            onChange={date => setPeriod(date)}
            helperText={false}
            size={"small"}
          />
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={2}>
            <Button color="secondary" variant="text" onClick={resetFilters}>
              {languageContext.dictionary.deleteFilters}
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button color="secondary" variant="contained" onClick={() => {
              handleLoadServerRoomAccess();
            }}>
              {languageContext.dictionary.filter}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialReactTable
            columns={columns}
            data={accessList}
            state={{isLoading: loading}}
            localization={languageContext.language.id === "hu" ? MRT_Localization_HU : MRT_Localization_EN}/>
        </Grid>
      </RenderedWithPermission>
    </Grid>
  );

  function handleLoadServerRoomAccess(reset = false) {
    let fromDate = formatDate(period[0]);
    let toDate = formatDate(period[1]);
    if (reset) {
      fromDate = null;
      toDate = null;
    }
    setLoading(true);
    setAccessList([]);
    getDcmaAccessList(auth.userData.access_token, customerContext.selectedCustomer.id, fromDate, toDate)
      .then(res => {
        let serverRoomAccessTableData = res.data.map((item) => {
          return {
            serverRoom: item.serverRoom,
            name: fullNameByLanguage(languageContext.language.id, item.contactLastName, item.contactFirstName),
            action: item.activity,
            vitrinId: item.services.length === 0 ? item.services[0] : item.services.map((item) => {
              return `${item}\n`;
            }),
            entranceDate: dateFormatter(item.requiredTimeStamp, item.requiredEndTimeStamp, "entranceDate"),
            scheduledLogInLogOut: dateFormatter(item.requiredTimeStamp, item.requiredEndTimeStamp, "scheduledLogInLogOut"),
            logInLogOut: dateFormatter(item.entryTimeStamp, item.exitTimeStamp, "logInLogOut")
          };
        });
        setAccessList(serverRoomAccessTableData);
      })
      .finally(() => setLoading(false));
  }
};

export default ServerRoomAccessTab;