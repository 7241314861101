import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DataTable from "../datatable/DataTable";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import {Chip, Stack} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {getUserRightsList} from "../../api/contactResource";
import {CustomerContext} from "../../context/CustomerContext";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import UserEditor from "./UserEditor";
import {abbreviationCorrection, fullNameByLanguage} from "../../utils/commonFunctions";
import {useAuth} from "oidc-react";
import PrivilegeLevelsGrid from "../common/PrivilegeLevelsGrid";
import {UserContext} from "../../context/UserContext";
import {hasAccess} from "../../utils/accessManager";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  btn: {
    width: 80
  },
  gridItem: {
    padding: 5
  },
  circularProgress: {
    margin: theme.spacing(4)
  },
  privilegeLevelGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  link: {
    "user-select": "text !important",
    "&:hover": {
      textDecoration: "underline"
    }
  },
}));

const privilegeLevels = [
  {title: "admin", description: "admin"},
  {title: "financialContact", description: "financialContact"},
  {title: "technicalContact", description: "technicalContact"},
];

const emptyUser = {
  "firstName": "",
  "lastName": "",
  "email": "",
  "position": undefined,
  "role": undefined,
  "rights": [],
  "active": true
};

const UsersAndPermissionsTab = () => {
  const classes = useStyles();
  const auth = useAuth();
  const languageContext = useContext(LanguageContext);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomerContext);
  const {showRequestError} = useRequestErrorMessage();
  const [tableData, setTableData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [adminRole, setAdminRole] = useState(false);


  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      handleTableDataLoading();
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.language.id]);

  useEffect(() => {
    if (customerContext.selectedContact.poid && customerContext.selectedContact?.roles?.length > 0) {
      setAdminRole(customerContext.selectedContact.roles.includes("ADMIN"));
    }
  }, [customerContext.selectedContact?.poid, customerContext.selectedContact?.roles]);

  const clearEditor = () => {
    setEditDialogOpen(false);
    setSelectedUser({});
  };

  const editUser = (user) => {
    setSelectedUser(user);
    setEditDialogOpen(true);
  };

  const onEditCancel = () => {
    clearEditor();
  };

  const onItemEdited = () => {
    setEditDialogOpen(false);
    handleTableDataLoading();
  };

  const columns = [
    {
      name: "name",
      label: "name",
      render: (value, rowData) => (
        <Link
          className={classes.link}
          component="button"
          underline="none"
          onClick={() => editUser(rowData)}
          sx={{color: rowData?.active ? undefined : '#bdbdbd'}}
          disabled={!adminRole}
        >
          {fullNameByLanguage(languageContext.language.id, rowData?.firstName, rowData?.lastName)}
          {!rowData?.active ? ` (${languageContext.dictionary.inactive})` : null}
        </Link>
      )
    },
    {
      name: "email",
      label: "email",
      render: (value, rowData) => <div style={{color: rowData?.active ? undefined : '#bdbdbd'}}>{value}</div>
    },
    {
      name: "rights",
      label: "rights",
      options: {
        filter: false
      },
      render: (value, rowData) =>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {value?.map((right, index) => (
            <Chip
              key={index}
              label={right.description}
              color={rowData?.active ? "secondary" : "default"}
              sx={{color: rowData?.active ? undefined : '#bdbdbd'}}
              variant={right.description === "Admin" ? "filled" : "outlined"}
            />
          ))}
        </Stack>
    },
    {
      name: "",
      label: "actions",
      align: "center",
      options: {
        filter: false
      },
      render: (value, rowData) => adminRole || rowData?.contactId?.toString() === customerContext.selectedContact?.poid ?
        <Tooltip title={userContext?.userInfo?.innerUser ? languageContext.dictionary.disabledImpersonate : languageContext.dictionary.edit}
                 placement="bottom"
                 arrow>
          <span>
            <Button disabled={userContext?.userInfo?.innerUser} size="small" onClick={() => editUser(rowData)}>
              <EditIcon/>
            </Button>
          </span>
        </Tooltip> : null
    }
  ];

  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Grid container className={classes.grid} spacing={6} seleniumid={"usersAndPermissionsTab"}>
        <Grid item container spacing={1} key={"users"} seleniumid={"usersGrid"}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4">
              {languageContext.dictionary.users}
            </Typography>
          </Grid>
          {adminRole ? (
            <Grid item xs={12} sm={6} style={{textAlign: "right"}}>
              <Button size="large"
                      variant="text"
                      startIcon={<AddIcon/>}
                      color="secondary"
                      onClick={() => editUser(emptyUser)}>
                {languageContext.dictionary.newUser}
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Typography>
              {languageContext.dictionary.deleteContactMsg}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              data={tableData}
              columns={columns}
              isLoading={tableDataLoading}
              exportButton={!hasAccess("SALES", auth)}
            />
          </Grid>
        </Grid>
        <PrivilegeLevelsGrid privilegeLevels={privilegeLevels}/>
      </Grid>
      <UserEditor user={selectedUser} open={editDialogOpen} onEditCancel={onEditCancel} onItemEdited={onItemEdited}/>
    </RenderedWithPermission>
  );

  function handleTableDataLoading() {
    setTableDataLoading(true);
    getUserRightsList(auth.userData.access_token, customerContext.selectedCustomer.id)
      .then(res => {
        abbreviationCorrection(res.data, "rights", languageContext);
        setTableData(res.data);
      })
      .catch(error => showRequestError(error, languageContext.dictionary.errorMessages.userDataLoadError))
      .finally(() => setTableDataLoading(false));
  }
};

export default UsersAndPermissionsTab;
