import React, {useContext, useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles} from "@mui/styles";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {CircularProgress, TextField} from "@mui/material";
import CustomDateRangePicker from "../date/CustomDateRangePicker";
import {CustomerContext} from "../../context/CustomerContext";
import Pagination from "@mui/material/Pagination";
import {getErrorTicketList} from "../../api/errorTicketResource";
import ErrorTicket from "./ErrorTicket";
import moment from "moment";
import {useAuth} from "oidc-react";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const ErrorTicketTab = () => {
  const pageSize = 10;
  const [period, setPeriod] = useState([null, null]);
  const langContext = useContext(LanguageContext);
  const [errorTicketList, setErrorTicketList] = useState([]);
  const classes = useStyles();
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  const active = useRef(true);
  const [loading, setLoading] = useState(true);
  const [sourceId, setSourceId] = useState("");
  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const minDate = moment().subtract(2, "year");

  const handleActiveButtonClick = () => {
    active.current = !active.current;
    setPage(1);
    handleLoadErrorTicketList();
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      handleLoadErrorTicketList();
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Grid container className={classes.grid} spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4">
              {active.current ? langContext.dictionary.activeErrorTickets : langContext.dictionary.inactiveErrorTickets}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{textAlign: "right"}}>
            <Button
              size="large"
              variant="text"
              startIcon={!active.current ? <NavigateBeforeIcon/> : null}
              endIcon={active.current ? <NavigateNextIcon/> : null}
              color="secondary"
              onClick={handleActiveButtonClick}
            >
              {active.current ? langContext.dictionary.inactiveErrorTickets : langContext.dictionary.activeErrorTickets}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems="center" seleniumid={"errorTicketFilter"} style={{paddingBottom: 20}}>
          {!active.current ? (
            <Grid item container xs={12} style={{paddingBottom: 10}} spacing={1}>
              <Grid item xs={12} sm={4}>
                <CustomDateRangePicker
                  minDate={minDate}
                  value={period}
                  onChange={date => setPeriod(date)}
                  helperText={false}
                  size={"small"}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField id={"errorTicketId"} value={sourceId} fullWidth label={langContext.dictionary.errorTicketId} size="small" variant="outlined"
                           onChange={e => {
                             setSourceId(e.target.value);
                           }}/>
              </Grid>
              <Grid item xs={12} sm={"auto"} textAlign={"end"}>
                <Button onClick={handleLoadErrorTicketList} color="secondary">
                  {langContext.dictionary.filter}
                </Button>
              </Grid>
            </Grid>
          ) : null}
          {!loading ?
            (errorTicketList?.sum > 0 ? errorTicketList.items.slice(startIndex, endIndex).map((item, index) => (
              <Grid item xs={12} key={index}>
                <ErrorTicket data={item}/>
              </Grid>
            )) : null) : (
              <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
                <CircularProgress sx={{margin: 4}} color="secondary"/>
              </Grid>
            )}
        </Grid>
        <NoDataToDisplay loading={loading} list={errorTicketList.items}/>
        {errorTicketList.sum !== 0 ?
          (<Grid item xs={12} style={{justifyContent: "center", display: 'flex'}}>
            <Pagination count={Math.ceil(errorTicketList.sum / pageSize) || 1} page={page} onChange={handlePaginationChange}
                        color="secondary" size="large"/>
          </Grid>)
          : null}
      </Grid>
    </RenderedWithPermission>
  );

  function handleLoadErrorTicketList() {
    const fromDate = period[0] ? period[0].valueOf() : moment().subtract(1, 'years').valueOf();
    const toDate = period[1] ? period[1].valueOf() : moment().valueOf();
    setLoading(true);
    setErrorTicketList([]);
    let filters = {from: fromDate, to: toDate};
    let query = {limit: page, offset: pageSize};
    let sourceSystemTicketId = sourceId !== "" ? sourceId : null;
    getErrorTicketList(auth.userData.access_token, customerContext.selectedCustomer.id, active.current, null, query, filters, sourceSystemTicketId)
      .then(res => {
        let formattedErrorticketList = res.data;
        formattedErrorticketList.items = formattedErrorticketList.items.map(item => {
          item.description = item.description === "x_Egyéb_x" ? langContext.dictionary.other : item.description;
          return item;
        });
        setErrorTicketList(formattedErrorticketList);
      })
      .finally(() => setLoading(false));
  }
};

export default ErrorTicketTab;