import React, {useContext, useEffect, useState} from "react";
import CookieDialog from "../dialog/CookieDialog";
import {CookieContext} from "./CookieContext";


const CookieWrapper = () => {
  const cookieContext = useContext(CookieContext);
  const [cookieDialogOpen, setCookieDialogOpen] = useState(false);

  useEffect(() => {
    if (!cookieContext.cookieInfo.requiredCookie) {
      setCookieDialogOpen(true);
    }
  }, [cookieContext.cookieInfo.requiredCookie]);

  const closeCookieDialog = (onlyRequired) => {
    const updatedCookie = {...cookieContext.cookieInfo};
    updatedCookie.thirdPartyCookie = !onlyRequired;
    updatedCookie.requiredCookie = true;
    cookieContext.setCookie(updatedCookie);

    setCookieDialogOpen(false);
  };


  return (
    <div>
      <CookieDialog open={cookieDialogOpen} handleClose={closeCookieDialog}/>
    </div>
  );
};

export default CookieWrapper;
