import React, {useContext, useState} from "react";
import {LanguageContext} from "../context/LanguageContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import {Container, Tab, Tabs} from "@mui/material";
import SimpleTabPanel from "../components/common/SimpleTabPanel";
import {makeStyles} from "@mui/styles";
import BalanceAndCurrentBillsTab from "../components/billing/BalanceAndCurrentBillsTab";
import AllBillsTab from "../components/billing/AllBillsTab";

const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}));

const BillingPage = () => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const [actualTab, setActualTab] = useState(0);

  const handleTabChange = (newValue) => {
    setActualTab(newValue);
    // todo set filters to default
  };

  return (
    <Container maxWidth="lg" seleniumid={"billingPage"}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} style={{paddingBottom: 20}}>
          <Typography variant="h3" color="secondary" className={classes.header}>
            {languageContext.dictionary.bills}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={actualTab}
              onChange={(e, newValue) => handleTabChange(newValue)}
              variant="fullWidth"
              scrollButtons="auto"
              indicatorColor="primary"
            >
              <Tab label={languageContext.dictionary.balanceAndCurrentBills} key={"tab_0"} seleniumid={"balanceAndCurrentBillsTab"}/>
              <Tab label={languageContext.dictionary.allBills} key={"tab_1"} seleniumid={"allBillsTab"}/>
            </Tabs>
          </AppBar>
          <SimpleTabPanel value={actualTab} index={0}>
            <BalanceAndCurrentBillsTab/>
          </SimpleTabPanel>
          <SimpleTabPanel value={actualTab} index={1}>
            <AllBillsTab/>
          </SimpleTabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BillingPage;
