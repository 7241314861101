import React, {useContext, useEffect, useState} from "react";
import MyProfileCard from "../myinformation/MyProfileCard";
import MyPermissions from "../mypermissions/MyPermissions";
import {getUserInfo} from "../../api/contactResource";
import {LanguageContext} from "../../context/LanguageContext";
import {CustomerContext} from "../../context/CustomerContext";
import {getCompanyInfo} from "../../api/companyResource";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {useHistory} from "react-router-dom";
import {CircularProgress, Container} from "@mui/material";
import {useAuth} from "oidc-react";
import {abbreviationCorrection} from "../../utils/commonFunctions";

const MyProfileTab = () => {
  const langContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const history = useHistory();
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const [selectedUser, setSelectedUser] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const [companyLoading, setCompanyLoading] = useState(true);
  const [userIsSaved, setUserIsSaved] = useState(false);

  useEffect(() => {
    if (auth.userData && customerContext.selectedContact.poid) {
      setUserIsSaved(false);
      setUserLoading(true);
      getUserInfo(auth.userData.access_token, customerContext.selectedContact.poid)
        .then(res => {
          abbreviationCorrection(res.data, "rights", langContext);
          setSelectedUser(res.data);
          setUserLoading(false);
        })
        .catch(error => {
          showRequestError(error, langContext.dictionary.errorMessages.userDataLoadError);
          history.push("/home");
        });
    }
  }, [auth.userData, userIsSaved, customerContext.selectedContact.poid, langContext.language.id]);

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setCompanyLoading(true);
      getCompanyInfo(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => {
          abbreviationCorrection(res.data, "rights", langContext);
          setCompanyInfo(res.data);
          setCompanyLoading(false);
        })
        .catch(error => {
          showRequestError(error, langContext.dictionary.errorMessages.userDataLoadError);
          history.push("/home");
        });
    }
  }, [auth.userData, customerContext.selectedCustomer.id, langContext.language.id]);

  return !userLoading && !companyLoading ? (
    <>
      <MyProfileCard selectedUser={selectedUser} setUserIsSaved={setUserIsSaved}/>
      <MyPermissions selectedUser={selectedUser} companyInfo={companyInfo}/>
    </>
  ) : (
    <Container sx={{textAlign: "center"}}>
      <CircularProgress sx={{margin: 4}} color="secondary"/>
    </Container>
  );
};
export default MyProfileTab;