import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Backdrop, CircularProgress, Container, TextField} from "@mui/material";
import {LanguageContext} from "../../context/LanguageContext";
import {CustomerContext} from "../../context/CustomerContext";
import Button from "@mui/material/Button";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import ServiceFilterButton from "./ServiceFilterButton";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomSelect from "../formFields/CustomSelect";
import {makeStyles} from "@mui/styles";
import {useAuth} from "oidc-react";
import {generateExcelForService, getServiceAll} from "../../api/serviceResource";
import moment from "moment/moment";
import {filterServicesOrEstablishments, isAdmin, removeDuplicatesFromObjectArray, sortByValueFromObjectArray} from "../../utils/commonFunctions";
import CustomDateRangePicker from "../date/CustomDateRangePicker";
import {UmamiContext} from "../../context/UmamiContext";
import {getOpenErrorTicketList} from "../../api/errorTicketResource";
import {ServicesDataList} from "./ServicesDataList";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const useStyles = makeStyles(() => ({
  inputFieldWrapper: {
    paddingRight: 10,
    paddingLeft: 10
  },
}));

const AllServicesTab = () => {
  let defaultCategories = {
    internet: [],
    dataCenter: [],
    other: [],
    cloud: [],
    office: [],
    security: [],
    communication: []
  };

  const defaultFilters = {
    serviceStatus: "",
    site: "",
    contractId: "",
    serviceId: "",
    validityDate: [null, null],
  };

  const auth = useAuth();
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const [allService, setAllService] = useState([]);
  const [serviceStatusOption, setServiceStatusOption] = useState([]);
  const [categories, setCategories] = useState(defaultCategories);
  const [categoriesItem, setCategoriesItem] = useState([]);
  const [displayableServices, setDisplayableServices] = useState({
    internet: false,
    dataCenter: false,
    other: false,
    cloud: false,
    office: false,
    security: false,
    communication: false
  });
  const [selectedServices, setSelectedServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(defaultFilters);
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [openErrorTicketList, setOpenErrorTicketList] = useState([]);
  const [ticketListLoading, setTicketListLoading] = useState(true);
  const [resetPages, setResetPages] = useState(false);
  const umamiContext = useContext(UmamiContext);
  const ninetiesYear = moment(new Date(1990, 1, 1));

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setLoading(true);
      getServiceAll(auth.userData.id_token, customerContext.selectedCustomer.id)
        .then(res => {
          setAllService(res.data);
          setCategories(filterServicesOrEstablishments(res.data));
        })
        .finally(() => setLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.languageHeader]);

  useEffect(() => {
    setCategories(filterServicesOrEstablishments(selectedServices));
  }, [selectedServices]);

  useEffect(() => {
    setTicketListLoading(true);
    let openTickets = [];
    if (auth.userData && customerContext.selectedCustomer.id) {
      getOpenErrorTicketList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => {
          setOpenErrorTicketList(res.data);
          openTickets.push(...res.data);
        })
        .finally(() => setTicketListLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);


  useEffect(() => {
    if (!ticketListLoading && !loading) {
      setAllService(curr => {
        let updated = [...curr];
        updated.forEach(service => {
          if (service?.serviceIds?.some(id => openErrorTicketList?.includes(id))) {
            service.status = languageContext.dictionary.reportedAsErrorTicket;
          }
        });

        let serviceStatus = updated.map(data => {
          return {
            label: data.status,
            value: data.status,
          };
        });

        let uniqueServices = removeDuplicatesFromObjectArray(serviceStatus, "value");
        uniqueServices = sortByValueFromObjectArray(uniqueServices, "value");
        setServiceStatusOption(uniqueServices);

        return updated;
      });
    }
  }, [ticketListLoading, loading]);

  const handleDisplayableCategories = (field, newValue) => {
    setDisplayableServices(curr => ({...curr, [field]: newValue}));
  };

  const handleExcelExport = () => {
    umamiContext.track('all_services_export');
    setBackdropLoading(true);
    generateExcelForService(auth.userData.access_token, customerContext.selectedCustomer.id)
      .then(res => {
        let a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([res.data]));
        a.download = `${moment().format("YYYYMMDDHHmmss")}.xlsx`;
        a.click();
      })
      .finally(() => setBackdropLoading(false));
  };

  const search = () => {
    const contractNumberFilter = (item) => {
      return filter.contractId === "" || item.contractNumber.includes(filter.contractId);
    };

    const serviceIdFilter = (item) => {
      return filter.serviceId === "" || item.id.includes(filter.serviceId);
    };

    const siteFilter = (item) => {
      return filter.site === "" || (item?.siteList?.some(site => site ? site.includes(filter.site) : null));
    };

    const validityDateFilter = (item) => {

      if (filter.validityDate[0] === null && filter.validityDate[1] === null) {
        return true;
      }
      if (filter.validityDate[0] !== null && filter.validityDate[1] === null) {
        return moment(item.statusChangeDate).valueOf() >= filter.validityDate[0].valueOf();
      }
      if (filter.validityDate[0] !== null && filter.validityDate[1] !== null) {
        return moment(item.statusChangeDate).valueOf() >= filter.validityDate[0].valueOf()
          && moment(item.statusChangeDate).valueOf() <= filter.validityDate[1].valueOf();
      }
    };
    const statusFilter = (item) => {
      return filter.serviceStatus === item.status || filter.serviceStatus === "";
    };

    setResetPages(!resetPages);
    allService.forEach(item => {
      if (statusFilter(item) && contractNumberFilter(item) && serviceIdFilter(item) && validityDateFilter(item) && siteFilter(item)) {
        setSelectedServices(curr => [...curr, item]);
      }
    });
  };

  useEffect(() => {
    let categoriesItems = [];
    Object.values(categories)?.forEach(category => category?.forEach(item => categoriesItems?.push(item)));
    setCategoriesItem(categoriesItems);
  }, [categories]);


  useEffect(() => {
    setSelectedServices([]);
    search();
  }, [filter.serviceStatus, filter.validityDate]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSelectedServices([]);
      search();
    }
  };


  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Grid container sx={{paddingBottom: 3, paddingTop: 3}} spacing={3} seleniumid={"AllServicesTab"}>
        <Grid item xs={12}>
          <Container sx={{textAlign: "right"}}>
            {isAdmin(customerContext) ?
              (<Button
                color="secondary"
                startIcon={<TextSnippetOutlinedIcon/>}
                onClick={handleExcelExport}
                disabled={loading}>
                {languageContext.dictionary.exportToExcel}
              </Button>) : null}
          </Container>
        </Grid>
        <Grid container item xs={12} justifyContent={"start"} rowSpacing={3}>
          {Object.entries(categories).map(([key, list], index) => (
            list.length > 0 ? (
              <Grid item key={`serviceFilterButton_${index}`} style={{marginRight: 8, marginLeft: 8}}>
                <ServiceFilterButton data={key} serviceCount={list.length} handleDisplayableCategories={handleDisplayableCategories}/>
              </Grid>
            ) : null
          ))}
        </Grid>
        <Grid item xs={12}>
          {!loading ? (<Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon color={"secondary"}/>}>
              <Typography color={"secondary"} fontWeight={"bold"}>{languageContext.dictionary.filter}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="row" justifyContent="start" alignItems="center" rowSpacing={2} style={{marginTop: 5, marginBottom: 5}}>
                <Grid item xs={12} md={4} className={classes.inputFieldWrapper}>
                  <CustomSelect
                    disableMinWidth
                    onKeyDown={handleKeyDown}
                    fullWidth
                    size="medium"
                    value={filter.serviceStatus}
                    handleChange={e => setFilter(curr => ({...curr, serviceStatus: e.target.value}))}
                    parameters={{name: "serviceStatusSelect", label: languageContext.dictionary.serviceStatus, options: serviceStatusOption, canBeNull: true}}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={classes.inputFieldWrapper}>
                  <TextField
                    id={`serviceIdText`}
                    value={filter.serviceId}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    label={languageContext.dictionary.serviceId}
                    size="medium"
                    variant="outlined"
                    onChange={e => setFilter(curr => ({...curr, serviceId: e.target.value}))}/>
                </Grid>
                <Grid item xs={12} md={4} className={classes.inputFieldWrapper}>
                  <TextField
                    id={`siteText`}
                    value={filter.site}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    label={languageContext.dictionary.site}
                    size="medium"
                    variant="outlined"
                    onChange={e => setFilter(curr => ({...curr, site: e.target.value}))}/>
                </Grid>
                <Grid item xs={12} md={4} className={classes.inputFieldWrapper}>
                  <TextField
                    id={`contractIdText`}
                    value={filter.contractId}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    label={languageContext.dictionary.contractNumber}
                    size="medium"
                    variant="outlined"
                    onChange={e => setFilter(curr => ({...curr, contractId: e.target.value}))}/>
                </Grid>
                <Grid item xs={12} md={4} className={classes.inputFieldWrapper}>
                  <CustomDateRangePicker
                    value={filter.validityDate}
                    onKeyDown={handleKeyDown}
                    onChange={date => setFilter(curr => ({...curr, validityDate: date}))}
                    helperText={false}
                    size="small"
                    fullWidth
                    startLabel={languageContext.dictionary.validity}
                    endLabel={languageContext.dictionary.validity}
                    minDate={ninetiesYear}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent="space-between">
                  <Grid item xs={"auto"}>
                    <Button color="secondary" variant="text" onClick={() => {
                      setFilter(defaultFilters);
                      setSelectedServices([]);
                    }}>
                      {languageContext.dictionary.deleteFilters}
                    </Button>
                  </Grid>
                  <Grid item xs={"auto"}>
                    <Button color="secondary" variant="contained" onClick={() => {
                      setSelectedServices([]);
                      search();
                    }}>
                      {languageContext.dictionary.filter}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>) : null}
        </Grid>
        {!loading ? Object.entries(categories).map(([key, list], index) => (
          list.length > 0 && (displayableServices[key] || !Object.values(displayableServices).includes(true)) ? (
            <ServicesDataList
              key={`servicesDataList_${index}`}
              title={languageContext.dictionary.serviceCategories[key]}
              dataList={list}
              resetPage={resetPages}
            />
          ) : null
        )) : (
          <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress sx={{margin: 4}} color="secondary"/>
          </Grid>
        )}
        <NoDataToDisplay loading={loading} list={categoriesItem}/>
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={backdropLoading}>
          <CircularProgress color="inherit"/>
        </Backdrop>
      </Grid>
    </RenderedWithPermission>
  );

  async function generateExcel() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = 'Dummy data';
        const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        resolve({data: blob});
      }, 2000);
    });
  }
};

export default AllServicesTab;
