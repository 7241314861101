import React from "react";
import CustomerInformation from "../companyinformation/CustomerInformation";
import InvitechContacts from "../invitechContacts/InvitechContacts";
import InvitechCompanyData from "../common/InvitechCompanyData";

const CompanyDetailsTab = () => {
  return (
    <div>
      <CustomerInformation/>
      <InvitechContacts/>
      <InvitechCompanyData/>
    </div>
  );
};


export default CompanyDetailsTab;
