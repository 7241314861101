import React, {useContext, useEffect, useState} from "react";
import {Box, CircularProgress, Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles} from "@mui/styles";
import Paper from "@mui/material/Paper";
import CircleIcon from "@mui/icons-material/Circle";
import Divider from "@mui/material/Divider";
import {useHistory, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {getErrorTicketDetails} from "../../api/errorTicketResource";
import {CustomerContext} from "../../context/CustomerContext";
import {capitalizeFirstLetter} from "../../utils/commonFunctions";
import ErrorTicketWorkLogs from "./ErrorTicketWorkLogs";
import {formatDateTime} from "../../utils/dateFormat";
import {useAuth} from "oidc-react";
import ErrorTicketButton from "./ErrorTicketButton";

const useStyles = makeStyles(theme => ({
  row: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttonGrid: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  circleActive: {
    color: theme.palette.mainButton.main,
  },
  texActive: {
    color: theme.palette.mainButton.main,
  },
  circleInactive: {
    color: theme.palette.secondary.main,
  },
  textInactive: {
    color: theme.palette.secondary.main,
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    paddingRight: 30
  }
}));
const ErrorTicketDetailsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const langContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  const urlParams = useParams();
  const [ticketDetails, setTicketDetails] = useState();
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (auth.userData && !!customerContext.selectedCustomer.id) {
      setDataLoading(true);
      getErrorTicketDetails(auth.userData.access_token, customerContext.selectedCustomer.id, urlParams.id)
        .then(res => {
          res.data.site = res.data?.site?.replace("Felszerelési cím: ", "");
          setTicketDetails(res.data);
        })
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);


  return (
    <Container maxWidth="lg" style={{marginBottom: 15}}>
      {!dataLoading ? (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" rowSpacing={2} className={classes.buttonGrid}>
            <Grid item>
              <Button size={"large"} color="secondary" startIcon={<ArrowBackIosIcon/>} onClick={() => history.push("/service/errorTickets")}>
                {langContext.dictionary.allErrorTickets}
              </Button>
            </Grid>
            <Grid item>
              <ErrorTicketButton/>
            </Grid>
          </Grid>
          <Paper square elevation={3} style={{padding: 35}}>
            <Grid container direction="row" justifyContent="start" alignItems="center" minHeight={350}>
              <Grid item xs={12} sm={3} className={classes.row}>
                <Typography>
                  {langContext.dictionary.errorTicketId}
                </Typography>
                <Typography color="secondary" fontWeight="bold" fontSize={20}>
                  {ticketDetails?.sourceSystemTicketId}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.row}>
                <Typography>
                  {langContext.dictionary.errorType}
                </Typography>
                <Typography color="secondary" fontWeight="bold" fontSize={20}>
                  {capitalizeFirstLetter(ticketDetails?.description === "x_Egyéb_x" ? langContext.dictionary.other : ticketDetails?.description)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.row}>
                <Box className={classes.statusWrapper}>
                  <CircleIcon style={{float: "left", height: 20}} color={"secondary"}
                              className={ticketDetails?.status === "Folyamatban" ? classes.circleActive : classes.circleInactive}/>
                  <Typography color="secondary" fontWeight="bold"
                              className={ticketDetails?.status === "Folyamatban" ? classes.texActive : classes.textInactive}>{ticketDetails?.status}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.row}>
                <Typography>
                  {langContext.dictionary.reporterName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} className={classes.row}>
                <Typography fontWeight="bold">
                  {ticketDetails?.issuer}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>

              <Grid item xs={12} sm={3} className={classes.row}>
                <Typography>
                  {langContext.dictionary.reportDate}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} className={classes.row}>
                <Typography fontWeight="bold">
                  {formatDateTime(ticketDetails?.dateOfEntry)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.row}>
                <Typography>
                  {langContext.dictionary.reportEndDate}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography fontWeight="bold" className={classes.row}>
                  {formatDateTime(ticketDetails?.dateOfClose)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.row}>
                <Typography>
                  {langContext.dictionary?.relatedSite}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} className={classes.row}>
                <Typography fontWeight="bold">
                  {ticketDetails?.site}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.row}>
                <Typography>
                  {langContext.dictionary.technicalId}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} className={classes.row}>
                <Typography color="secondary" fontWeight="bold">
                  {ticketDetails?.serviceId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12} className={classes.row}>
                <ErrorTicketWorkLogs sourceSystemTicketId={ticketDetails?.sourceSystemTicketId}/>
              </Grid>
            </Grid>
          </Paper>
        </>
      ) : (
        <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
          <CircularProgress sx={{margin: 4}} color="secondary"/>
        </Grid>
      )}
    </Container>
  );
};

export default ErrorTicketDetailsPage;
