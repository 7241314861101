import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {env} from '../../properties';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import GenericMenuItem from "../menu/GenericMenuItem";
import CompanySelectorButton from "./CompanySelectorButton";
import {ListItem} from "@mui/material";
import {useAuth} from "oidc-react";
import {contactHasRight} from "../../utils/commonFunctions";
import {CustomerContext} from "../../context/CustomerContext";

const VerticalMenuList = ({subMenuOpen, handleSubMenuOpen, ...props}) => {
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  return (
    <List>
      {auth.userData ?
        <>
          <ListItem>
            <CompanySelectorButton/>
          </ListItem>
          <Divider/>
        </>
        : null}
      {env.menuItems.map(menuItem =>
        contactHasRight(customerContext.selectedContact.roles, menuItem.access) ?
          <GenericMenuItem menuItem={menuItem}
                           handleSubMenuOpen={handleSubMenuOpen}
                           subMenuOpen={subMenuOpen}
                           orientation={"vertical"}
                           key={menuItem.key || menuItem.url}/> : '')}
    </List>
  );
};

VerticalMenuList.propTypes = {
  handleSubMenuOpen: PropTypes.func.isRequired,
  subMenuOpen: PropTypes.object.isRequired
};

export default VerticalMenuList;
