import React, {useContext, useEffect, useState} from "react";
import {CircularProgress, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import {CustomerContext} from "../../context/CustomerContext";
import {getErrorTicketWorkLogList} from "../../api/workLogResource";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Tooltip from "@mui/material/Tooltip";
import {formatDateTime} from "../../utils/dateFormat";
import {useAuth} from "oidc-react";
import PropTypes from "prop-types";

const WorkLogRow = ({item, index}) => (
  <Grid container item key={index} direction="row" justifyContent="center" alignItems="center" rowSpacing={1} style={{marginBottom: 10}}>
    <Grid item xs={8} sm={6}>
      <Typography fontWeight={"bold"}>
        {item.issuer}
      </Typography>
    </Grid>
    <Grid item xs={4} sm={6}>
      <Typography textAlign={"end"}>
        {formatDateTime(item?.createDate)}
      </Typography>
    </Grid>
    <Grid item xs={12} style={{paddingLeft: 20}}>
      <Typography>
        {item.message}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider/>
    </Grid>
  </Grid>
);

const ErrorTicketWorkLogs = ({sourceSystemTicketId}) => {
  const langContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const auth = useAuth();
  const [workLogList, setWorkLogList] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showList, setShowList] = useState(false);
  const displayableItem = 1;

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id && sourceSystemTicketId) {
      setDataLoading(true);
      getErrorTicketWorkLogList(auth.userData.access_token, customerContext.selectedCustomer.id, sourceSystemTicketId)
        .then(res => setWorkLogList(res.data))
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, sourceSystemTicketId]);

  return workLogList.length !== 0 ? (
    <>
      {!dataLoading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} style={{marginBottom: 20}}>
            <Typography variant={"h5"} fontWeight={"bold"} color={"secondary"}>
              {langContext.dictionary.workLog}
            </Typography>
          </Grid>
          {!showList ? workLogList.slice(0, displayableItem).map((item, index) => (
            <WorkLogRow index={index} item={item}/>
          )) : workLogList.map((item, index) => (
            <WorkLogRow index={index} item={item}/>))}
          {workLogList.length > displayableItem ? (<Grid item xs={12} style={{textAlign: "center", marginTop: 10}}>
            <Tooltip title={showList ? langContext.dictionary.back : langContext.dictionary.another} placement="right">
              <IconButton onClick={() => setShowList(!showList)}>
                {showList ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
              </IconButton>
            </Tooltip>
          </Grid>) : null}
        </Grid>
      ) : <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
        <CircularProgress sx={{margin: 4}} color="secondary"/>
      </Grid>}
    </>
  ) : null;
};

ErrorTicketWorkLogs.propTypes = {
  sourceSystemTicketId: PropTypes.string
};

export default ErrorTicketWorkLogs;