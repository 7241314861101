import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CustomSelect from "../formFields/CustomSelect";
import {universalDmsDynamicFieldSelectApi} from "../../api/dmsResource";
import {useAuth} from "oidc-react";
import {CustomerContext} from "../../context/CustomerContext";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import CustomDatePicker from "../date/CustomDatePicker";
import moment from "moment";
import {env} from "../../properties";
import {handleBase64Formatting, validateFile} from "../../utils/commonFunctions";
import {dateFormat} from "../../utils/commonValues";

const DynamicFormField = ({inputType, label, name, value, setBusinessTemplateAnswers, data, allowedTypes, setDisableFormSubmit}) => {
  const {input, required, endpoint = null, options = []} = inputType;
  const {showRequestError} = useRequestErrorMessage();
  const auth = useAuth();
  const customerContext = useContext(CustomerContext);
  const languageContext = useContext(LanguageContext);
  const defaultSelect = [{label: "", value: ""}];
  const [fileError, setFileError] = useState(null);
  const [selectOptions, setSelectOptions] = useState(defaultSelect);
  const [characterCounter, setCharacterCounter] = useState(0);

  const onChangeHandlerTextSelect = (e, key) => {
    setCharacterCounter(e.target.value.length);
    setBusinessTemplateAnswers(curr => ({...curr, [key]: {value: e.target.value, name: label["hu"]}}));
  };

  const handleDateChange = (date, key) => {
    setBusinessTemplateAnswers(curr => ({...curr, [key]: {value: moment(date).format(dateFormat), name: label["hu"]}}));
  };

  const handleFileChange = async (e, key) => {
      setFileError(null);
      setDisableFormSubmit(false);
      const {attachment, attachment_name, ...businessTemplateAnswersWithoutFiles} = data;
      setBusinessTemplateAnswers({...businessTemplateAnswersWithoutFiles, [key]: ''});
      const fileArray = Object.values(e.target.files).map(item => item);
      const attachmentData = await handleBase64Formatting(fileArray);
      const validationError = validateFile(attachmentData[0], languageContext, allowedTypes);
      if (validationError) {
        setFileError(validationError);
        setDisableFormSubmit(true);
      } else {
        const fileName = attachmentData[0].title;
        const fileData = attachmentData[0].data;
        setBusinessTemplateAnswers({...data, [key]: fileData, [`${key}_name`]: fileName});
      }
    }
  ;

  useEffect(() => {
    if (input === "select") {
      if (options.length > 0) {
        let optionsList = [];
        options.forEach(item => {
          optionsList.push({label: item.key, value: item.value});
        });
        setSelectOptions(optionsList);
      }
    }

    if (input === "apiselect") {
      const testurl = `${env.apiRoot}/${endpoint}`;
      const correctUrl = testurl.replace("{customerId}", customerContext.selectedCustomer.id);
      if (endpoint) {
        universalDmsDynamicFieldSelectApi(auth.userData.access_token, customerContext.selectedCustomer.id, correctUrl)
          .then(res => setSelectOptions(res.data))
          .catch(error => showRequestError(error, languageContext.dictionary.errorMessages.loadingError));
      }
    }
  }, [input]);

  switch (input) {
    case "textarea":
      return (
        <TextField
          required={required}
          value={value}
          fullWidth
          label={label[languageContext.language.id]}
          size="small"
          variant="outlined"
          multiline
          rows={8}
          onChange={e => onChangeHandlerTextSelect(e, name)}
          inputProps={{maxLength: 256}}
          FormHelperTextProps={{sx: {textAlign: "end"}}}
          helperText={`${characterCounter}/256`}
        />
      );
    case "select":
    case "apiselect":
      return (
        <CustomSelect
          disableMinWidth
          fullWidth
          size="small"
          value={value}
          required={required}
          handleChange={e => onChangeHandlerTextSelect(e, name)}
          parameters={{
            name: `${label[languageContext.language.id]}select`,
            label: label[languageContext.language.id],
            options: selectOptions,
            canBeNull: false
          }}
        />
      );
    case "file":
      return (
        <>
          <Button component="label" color="secondary" startIcon={<UploadFileIcon/>}>
            {languageContext.dictionary.uploadFiles}
            <input
              style={{zIndex: -10, height: 1, width: 1}}
              type="file"
              accept="image/*,text/plain,.pdf,.csv,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              required={required}
              onChange={e => handleFileChange(e, name)}
            />
          </Button>
          {fileError === null ? (<ul>
            {data[`${name}_name`] ? (
              <li>
                {data[`${name}_name`]}
              </li>
            ) : null}
          </ul>) : <ul>
            <li style={{color: "red"}}>
              {fileError}
            </li>
          </ul>}
        </>
      );
    case "textfield":
      return (
        <TextField
          required={required}
          value={value}
          fullWidth
          label={label[languageContext.language.id]}
          size="small"
          variant="outlined"
          onChange={e => onChangeHandlerTextSelect(e, name)}
        />
      );
    case "datepicker":
      return (
        <CustomDatePicker
          minDate={moment()}
          clearable
          allowSameDateSelection
          value={value}
          onChange={date => handleDateChange(date, name)}
          label={label[languageContext.language.id]}
          fullWidth
          required={required}
        />
      );
    default:
      return (
        <Typography color="primary">
          {languageContext.dictionary.missingInput}
        </Typography>
      );
  }
};
DynamicFormField.propTypes = {
  inputType: PropTypes.object.isRequired,
  label: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setBusinessTemplateAnswers: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  allowedTypes: PropTypes.array,
  setDisableFormSubmit: PropTypes.func
};
DynamicFormField.defaultProps = {
  allowedTypes: ["csv", "pdf", "png", "jpg", "jpeg", "svg", "doc", "docx", "txt", "webp", "xls", "xlsx", "xml"]
};

export default DynamicFormField;