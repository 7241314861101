import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Chip} from "@mui/material";
import ServicesGrid from "./ServicesGrid";
import Pagination from "@mui/material/Pagination";

export const ServicesDataList = ({title, dataList, resetPage}) => {
  const pageSize = 5;
  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  useEffect(() => {
    setPage(1);
  }, [resetPage]);

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} style={{display: "flex", alignItems: "center", marginTop: 10}}>
        <Typography variant="h5" sx={{marginRight: 1}}>
          {title}
        </Typography>
        <Chip label={dataList.length} color="secondary" size="small"/>
      </Grid>
      <Grid item xs={12} alignItems="start">
        {dataList.slice(startIndex, endIndex).map((data, index) => (
          <ServicesGrid key={index} data={data}/>
        ))}
      </Grid>
      <Grid item xs={12} style={{justifyContent: "center", display: 'flex', alignItems: "end"}}>
        <Pagination
          count={Math.ceil(dataList.length / pageSize)}
          page={page}
          onChange={(e, value) => setPage(value)}
          color="secondary"
        />
      </Grid>
    </Grid>
  );
};