import React, {useContext} from "react";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import {formatDate} from "../../utils/dateFormat";
import CurrencyAvatar from "../common/CurrencyAvatar";
import CurrencyTypography from "../common/CurrencyTypography";

const BillGrid = ({data, colorSwitcher}) => {
  const languageContext = useContext(LanguageContext);

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={2} md={1}>
        <CurrencyAvatar colorSwitcher={colorSwitcher} sx={{borderColor: colorSwitcher, color: colorSwitcher}} currency={data.currency}/>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Typography variant="subtitle1">{languageContext.dictionary.billNumber}</Typography>
        <Typography variant="subtitle1" style={{fontWeight: "bold"}}>{data.invoiceNumber}</Typography>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Typography variant="subtitle1">{languageContext.dictionary.contractualCurrentAccountNumber}</Typography>
        <Typography variant="subtitle1" style={{fontWeight: "bold"}}>{data.giroId}</Typography>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Typography variant="subtitle1">{languageContext.dictionary.billingDate}</Typography>
        <Typography variant="subtitle1" style={{fontWeight: "bold"}}>{formatDate(data.billingDate)}</Typography>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Typography variant="subtitle1">{languageContext.dictionary.paymentDeadline}</Typography>
        <Typography variant="subtitle1" style={{fontWeight: "bold"}}>{formatDate(data.dueDate)}</Typography>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Typography variant="subtitle1">{languageContext.dictionary.billTotal}</Typography>
        <CurrencyTypography amount={data.total} currency={data.currency} variant={"h4"} sx={{fontWeight: "bold"}}/>
      </Grid>
    </Grid>
  );
};

BillGrid.propTypes = {
  data: PropTypes.object,
  colorSwitcher: PropTypes.string
};

export default BillGrid;