import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {CircularProgress, DialogActions, DialogContent, DialogTitle, Paper, TextField, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";
import {UmamiContext} from "../../context/UmamiContext";
import {useAuth} from "oidc-react";
import {useSnackbar} from "notistack";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import IconButton from "@mui/material/IconButton";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomSelect from "../formFields/CustomSelect";
import {getEBill, saveEBill} from "../../api/accountResource";
import {useParams} from "react-router-dom";
import Switch from "@mui/material/Switch";
import {useTheme} from "@mui/styles";
import {UserContext} from "../../context/UserContext";
import Tooltip from "@mui/material/Tooltip";

const OrderDialog = ({open, title, handleClose, fullScreen, setRefreshData, ...props}) => {
  const userContext = useContext(UserContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const langContext = useContext(LanguageContext);
  const auth = useAuth();
  const umamiContext = useContext(UmamiContext);
  const {enqueueSnackbar} = useSnackbar();
  const {showRequestError} = useRequestErrorMessage();
  const [dataLoading, setDataLoading] = useState(true);
  const urlParams = useParams();
  const [eOrderSent, setEOrderSent] = useState(false);
  const [eBillData, setEBillData] = useState({});
  const [noticeStatus, setNoticeStatus] = useState(false);
  const [eBillInputFields, setEBillInputFields] = useState([{value: ''}]);
  const [eNoticeInputFields, setENoticeInputFields] = useState([{value: ''}]);
  const [billType, setBillType] = useState("");

  const selectOptions = [
    {label: langContext.dictionary.eBill, value: "ELECTRO"},
    {label: langContext.dictionary.paperBase, value: "PAPER"},
    {label: langContext.dictionary.remoteAccount, value: "REMOTE"}
  ];

  const removeFields = (index, inputFields, setInputFields) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleFormChange = (index, event, inputFields, setInputFields, inputProperty) => {
    let data = [...inputFields];
    data[index][inputProperty] = event.target.value;
    setInputFields(data);
  };

  const handleDialogClose = (event, reason) => {
    umamiContext.track('e_order_cancel');
    if (props.blocking && reason === "backdropClick") {
      return false;
    }

    if (props.blocking && reason === "escapeKeyDown") {
      return false;
    }

    if (typeof handleClose === "function") {
      handleClose();
    }
  };

  const handleEOrderSend = (e) => {
    e.preventDefault();
    umamiContext.track('e_order_send');
    const eBill = {
      id: eBillData.id,
      billType: billType,
      billEmailAddressList: eBillInputFields,
      eWarningNeeded: noticeStatus,
      warningEmailAddressList: eNoticeInputFields
    };

    saveEBill(auth.userData.access_token, urlParams.id, eBill)
      .then(() => enqueueSnackbar(langContext.dictionary.successEOrderSend, {variant: "success", preventDuplicate: true}))
      .catch(error => showRequestError(error, langContext.dictionary.eOrderSendError))
      .finally(() => {
        setEOrderSent(true);
        handleClose();
        setRefreshData(true);
      });
  };

  useEffect(() => {
    if (auth.userData.access_token && urlParams.id) {
      setEOrderSent(false);
      setDataLoading(true);
      getEBill(auth.userData.access_token, urlParams.id)
        .then(res => {
          setEBillData(res.data);
          if (res.data.warningEmailAddressList?.length > 0) {
            let warningList = [];
            res.data.warningEmailAddressList.forEach(item => warningList.push(item));
            setENoticeInputFields(warningList);
            setNoticeStatus(true);
          }
          if (res.data.billEmailAddressList?.length > 0) {
            let billList = [];
            res.data.billEmailAddressList.forEach(item => billList.push(item));
            setEBillInputFields(billList);
          }
          setBillType(res.data.billType || "PAPER");
        })
        .catch(error => showRequestError(error, langContext.dictionary.errorMessages.loadingError))
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, urlParams.id, eOrderSent]);


  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullScreen={fullScreen}
      maxWidth={"lg"}
      disableEnforceFocus={true}
      fullWidth
    >
      {!dataLoading ?
        (<form onSubmit={handleEOrderSend}>
            <DialogTitle sx={{fontSize: 28}}>
              {title}
              <Divider/>
            </DialogTitle>
            <DialogContent>
              <Grid container sx={{paddingTop: 2}} rowSpacing={mobile ? 4 : 0} direction="row" justifyContent="start" alignItems="start">
                <Grid item container direction="row" xs={12} sm={6} justifyContent={mobile ? "center" : "start"}>
                  <Paper elevation={4} sx={{width: "97%", height: "355px", maxHeight: "355px", overflow: 'auto', padding: 2}}>
                    <Grid item container xs={12} rowSpacing={2}>
                      <Grid item container xs={12}>
                        <Grid item xs={10} sm={5}>
                          <CustomSelect
                            size="small"
                            fullWidth
                            value={billType}
                            handleChange={e => setBillType(e.target.value)}
                            parameters={{name: "billType", label: langContext.dictionary.accountType, options: selectOptions, canBeNull: false}}
                          />
                        </Grid>
                      </Grid>
                      {billType !== "PAPER" ? eBillInputFields.map((input, index) => (
                        <Grid container item xs={12} justifyContent="center">
                          <Grid item xs={10}>
                            <TextField
                              size="small"
                              placeholder={langContext.dictionary.email}
                              fullWidth
                              required
                              value={eBillInputFields[index].value}
                              onChange={event => handleFormChange(index, event, eBillInputFields, setEBillInputFields, "value")}
                            />
                          </Grid>
                          <Grid item xs={"auto"} style={{margin: "auto"}}>
                            <IconButton sx={{padding: 0}}
                                        onClick={() => index === 0 ? setEBillInputFields([...eBillInputFields, {value: ""}]) :
                                          removeFields(index, eBillInputFields, setEBillInputFields)}>
                              {index === 0 ? (<AddBoxRoundedIcon sx={{fontSize: 35}} color={"secondary"}/>) : (
                                <DeleteIcon sx={{fontSize: 30}} color={"error"}/>)}
                            </IconButton>
                          </Grid>
                        </Grid>
                      )) : null}
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item container xs={12} sm={6} justifyContent={mobile ? "center" : "end"}>
                  <Paper elevation={4} sx={{width: "97%", height: "355px", maxHeight: "355px", overflow: 'auto', padding: 2}}>
                    <Grid item container xs={12} rowSpacing={2}>
                      <Grid item xs={"auto"} style={{marginTop: "auto", marginBottom: "auto"}}>
                        <Typography>
                          {langContext.dictionary.eNotice}
                        </Typography>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Switch
                          color="secondary"
                          value={noticeStatus}
                          required={billType === "ELECTRO"}
                          checked={noticeStatus}
                          onClick={() => setNoticeStatus(curr => (!curr))}/>
                      </Grid>
                      {eNoticeInputFields.map((input, index) => (
                        <Grid container item xs={12}>
                          <Grid item xs={10}>
                            <TextField
                              size="small"
                              placeholder={langContext.dictionary.email}
                              fullWidth
                              required
                              disabled={!noticeStatus}
                              value={eNoticeInputFields[index].value}
                              onChange={event => handleFormChange(index, event, eNoticeInputFields, setENoticeInputFields, "value")}
                            />
                          </Grid>
                          <Grid item xs={"auto"} style={{margin: "auto"}}>
                            <IconButton sx={{padding: 0}}
                                        onClick={() => index === 0 ? setENoticeInputFields([...eNoticeInputFields, {value: ""}]) :
                                          removeFields(index, eNoticeInputFields, setENoticeInputFields)}>
                              {index === 0 ? (<AddBoxRoundedIcon sx={{fontSize: 35}} color={"secondary"}/>) : (
                                <DeleteIcon sx={{fontSize: 30}} color={"error"}/>)}
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{marginBottom: 10, marginRight: 10}}>
              <Button color="secondary" onClick={handleDialogClose}>{langContext.dictionary.close}</Button>
              <Tooltip title={userContext?.userInfo?.innerUser ? langContext.dictionary.disabledImpersonate : ""}>
                <span>
                  <Button disabled={userContext?.userInfo?.innerUser}
                          color="secondary"
                          type="submit"
                          variant="contained">{langContext.dictionary.eOrderSend}
                  </Button>
                </span>
              </Tooltip>
            </DialogActions>
          </form>
        ) : (
          <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress color="secondary"/>
          </Grid>)}
    </Dialog>
  );
};

OrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  fullScreen: PropTypes.bool,
  setRefreshData: PropTypes.func,
};

export default OrderDialog;
