import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import UserProvider from "./context/UserContext";
import CustomerProvider from "./context/CustomerContext";
import {BrowserRouter as Router} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import CookieProvider from "./components/cookies/CookieContext";
import LanguageProvider from "./context/LanguageContext";
import {AuthProvider} from "oidc-react";
import UmamiProvider from "./context/UmamiContext";

function getCurrentUrlWithoutSsoParams() {
  let url = new URL(window.location.href);
  url.searchParams.delete("code");
  url.searchParams.delete("scope");
  url.searchParams.delete("state");
  url.searchParams.delete("session_state");
  return url.href;
}

ReactDOM.render(
  <Router>
    <AuthProvider
      authority={window._env_.REACT_APP_OIDC_URL + "/realms/" + window._env_.REACT_APP_OIDC_REALM}
      clientId={window._env_.REACT_APP_CLIENT_ID}
      redirectUri={getCurrentUrlWithoutSsoParams()}
      postLogoutRedirectUri={window.location.origin}
      onBeforeSignIn={() => {
      }}
      onSignIn={() => {
        window.history.replaceState(null, window.name, getCurrentUrlWithoutSsoParams());
      }}
    >
      <SnackbarProvider maxSnack={10} anchorOrigin={{horizontal: "center", vertical: "top"}} preventDuplicate={true}>
        <LanguageProvider>
          <CookieProvider>
            <CustomerProvider>
              <UserProvider>
                <UmamiProvider>
                  <App/>
                </UmamiProvider>
              </UserProvider>
            </CustomerProvider>
          </CookieProvider>
        </LanguageProvider>
      </SnackbarProvider>
    </AuthProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
