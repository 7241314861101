import React from "react";
import AccountBalances from "../accountbalances/AccountBalances";
import AccountBills from "../accountbalances/AccountBills";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinRight} from "../../utils/commonValues";

const BalanceAndCurrentBillsTab = () => {

  return (
    <RenderedWithPermission roles={adminFinRight} displayNoRightsLabel={true}>
      <AccountBalances hasTitle={false}/>
      <AccountBills/>
    </RenderedWithPermission>
  );
};

export default BalanceAndCurrentBillsTab;
