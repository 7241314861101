import React, {useContext} from "react";
import {LanguageContext} from "../context/LanguageContext";
import TabPageWithUrl from "../components/common/TabPageWithUrl";
import {Redirect, useLocation} from "react-router-dom";
import ErrorTicketTab from "../components/errorTicket/ErrorTicketTab";
import ServerRoomPermissionsTab from "../components/serverRoomPermissions/ServerRoomPermissionsTab";
import ServerRoomAccessTab from "../components/serverRoom/ServerRoomAccessTab";

const ServicePage = () => {
  const languageContext = useContext(LanguageContext);
  const location = useLocation();
  const tabs = [
    {pathname: "/service/errorTickets", index: 0, label: "errorTicket", child: ErrorTicketTab},
    {pathname: "/service/serverRoomAccess", index: 1, label: "serverRoomAccess", child: ServerRoomAccessTab},
    {pathname: "/service/serverRoomPermissions", index: 2, label: "serverRoomPermissions", child: ServerRoomPermissionsTab}
  ];

  return (
    <>
      {location.pathname === "/service" ? (
        <Redirect to={tabs[0].pathname}/>
      ) : null}
      <TabPageWithUrl tabs={tabs} title={languageContext.dictionary.service} seleniumid={"ServicePage"}/>
    </>
  );
};

export default ServicePage;