import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {LanguageContext} from "../../context/LanguageContext";
import {getContactPersons, saveCompany} from "../../api/companyResource";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import DataTable from "../datatable/DataTable";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CustomerContext} from "../../context/CustomerContext";
import {UserContext} from "../../context/UserContext";
import {useHistory} from "react-router-dom";
import {useAuth} from "oidc-react";

const CompanySelectorExpandedRow = ({customer, closeDialog}) => {
  const auth = useAuth();
  const customerContext = useContext(CustomerContext);
  const userContext = useContext(UserContext);
  const languageContext = useContext(LanguageContext);
  const {showRequestError} = useRequestErrorMessage();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [columns, setColumns] = useState([]);

  const selectContact = (rowData, customer) => {
    if (!isSelectedContact(customer.id, rowData.email)) {
      customerContext.setCustomer(customer);
      customerContext.setContact({...customer, ...rowData});
      saveCompany(auth.userData.access_token, {
        cmId: customer.id,
        username: userContext.userInfo.email,
        contactEmail: rowData.email,
        contactMobile: rowData.mobile,
        contactFirstName: rowData.firstName,
        contactLastName: rowData.lastName,
        poId: rowData.poid
      })
        .then(() => history.push("/home"))
        .catch(error => showRequestError(error, languageContext.dictionary.companySelectionError));
      closeDialog();
    }
  };

  const isSelectedContact = (cmId, email) => {
    return customerContext.selectedCustomer?.id && cmId === customerContext.selectedCustomer.id &&
      customerContext.selectedContact?.email?.toLowerCase() && email === customerContext.selectedContact.email?.toLowerCase();
  };

  useEffect(() => {
    setLoading(true);
    getContactPersons(auth.userData.access_token, customer.id)
      .then(res => setContactList(res.data))
      .catch(error => showRequestError(error, languageContext.dictionary.contactListLoadError))
      .finally(() => setLoading(false));

    setColumns(getColumns());
  }, [customer]);

  return <tr>
    <td colSpan="100%">
      <Card>
        <CardContent>
          <DataTable
            title={languageContext.dictionary.contactPerson}
            columns={columns}
            data={contactList}
            isLoading={loading}
            sx={{width: "100%", background: "red"}}
            fullwidth
            options={{
              rowsPerPage: 5,
              print: false,
              filter: false,
              viewColumns: false,
              rowsPerPageOptions: []
            }}
          />
        </CardContent>
      </Card>
    </td>
  </tr>;

  function getColumns() {
    return [
      {
        name: "email",
        label: languageContext.dictionary.email
      },
      {
        name: "lastName",
        label: languageContext.dictionary.lastName
      },
      {
        name: "firstName",
        label: languageContext.dictionary.firstName
      },
      {
        name: "mobile",
        label: languageContext.dictionary.mobile
      },
      {
        name: "email",
        label: " ",
        align: "center",
        render: (value, rowData) =>
          <Button
            data-testid="selectContactButton"
            type="submit"
            variant="contained"
            size="small"
            color={isSelectedContact(customer.id, value) ? "mainButton" : "mainButtonReverse"}
            style={{width: 120}}
            onClick={() => selectContact(rowData, customer)}
          >
            {isSelectedContact(customer.id, value) ? languageContext.dictionary.actual : languageContext.dictionary.choose}
          </Button>
      }
    ];
  }
};

CompanySelectorExpandedRow.propTypes = {
  customer: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired
};

export default CompanySelectorExpandedRow;
