import {env} from "../properties";
import axios from "axios";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";

const resource = "/contact";

export const getUserRightsList = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/userRightsByCmId/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

export const getUserInfo = async (token, poId) => {
  let url = `${env.apiRoot}${resource}/userInfo`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}, params: {poId: poId}});
};

export const getUserEditSelectOptions = async (token, queryPoId, verification) => {
  let url = `${env.apiRoot}${resource}/edit/selectorlist/${queryPoId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr, verification: verification}});
};

export const getNewUserTemplate = async (token, customerId) => {
  let url = `${env.apiRoot}${resource}/contactperson/new/${customerId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const saveUser = async (token, userData) => {
  let url = `${env.apiRoot}${resource}/contactperson`;
  const authStr = "Bearer ".concat(token);
  return await axios.post(url, userData, {headers: {Authorization: authStr}});
};

export const getUser = async (token, poId) => {
  let url = `${env.apiRoot}${resource}/contactperson/${poId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};
