import axios from "axios";
import {env} from "../properties";
import moment from "moment";

const resource = "/notification";

export const getNotificationTypeList = async (token) => {
  let url = `${env.apiRoot}${resource}/types`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};

let notificationListController = new AbortController();
export const getNotificationListPagination = async (token, query, cmId, active, type, period) => {
  notificationListController.abort();
  notificationListController = new AbortController();
  let url = `${env.apiRoot}${resource}/notifications/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {
    signal: notificationListController.signal,
    headers: {Authorization: authStr},
    params: {
      offset: query?.page || 0,
      limit: query?.pageSize || 10,
      order: query?.orderBy,
      ordersort: query?.orderDirection,
      active: active,
      type: type === "" ? null : type,
      from: period[0] ? moment(period[0]).valueOf() : null,
      to: period[1] ? moment(period[1]).valueOf() : null
    }
  });
};

export const getNotificationCounter = async (token) => {
  let url = `${env.apiRoot}${resource}/sumActiveUnread`;
  const authStr = "Bearer ".concat(token);
  return await axios.get(url, {headers: {Authorization: authStr}});
};