import {huHU} from "@mui/x-data-grid-pro";
import {CheckCircle, ErrorOutlined, HelpOutlined} from "@mui/icons-material";
import React from "react";

export const compareArraysByProperty = (first, second, property) => {
  first = first || [];
  second = second || [];

  if (first.length !== second.length) {
    return false;
  }
  for (let i = 0; i < first.length; i++) {
    if (first[property] !== second[property]) {
      return false;
    }
  }
  return true;
};

export const localizationTemplate = (strings, ...keys) => {
  return (function (...values) {
    let dict = values[values.length - 1] || {};
    let result = [strings[0]];
    keys.forEach(function (key, i) {
      let value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  });
};

export const capitalizeFirstLetter = (string) => {
  return !!string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
};

export const fileDownloader = (data, filename) => {
  let a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([data]));
  a.download = filename;
  a.click();
};

export const removeDuplicatesFromObjectArray = (array, key) => {
  const uniqueValues = new Set();

  return array.filter(obj => {
    const value = obj[key];
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      return true;
    }
    return false;
  });
};

export const sortByValueFromObjectArray = (array, key) => {
  array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    } else if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });

  return array;
};


export const localeTextForDataGrid = (langId) => {
  switch (langId) {
    case "en":
      return {}; // default
    case "hu":
      return huHU.components.MuiDataGrid.defaultProps.localeText;
    default:
      return huHU.components.MuiDataGrid.defaultProps.localeText;
  }
};

export const fullNameByLanguage = (langId, firstName, lastName) => {
  switch (langId) {
    case "hu":
      return `${lastName || ""} ${firstName || ""}`;
    default:
      return `${firstName || ""} ${lastName || ""}`;
  }
};

export async function handleBase64Formatting(fileList, isPdf = false) {
  return await Promise.all(fileList.map(f => {
    return readAsDataURL(f, isPdf);
  }));
}

export function readAsDataURL(file, isPdf = false) {
  return new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      let base64 = fileReader.result.split("base64,")[1];
      return resolve(!isPdf ?
        {url: URL.createObjectURL(file), title: file.name, data: base64, size: file.size} :
        {title: file.name, data: base64, size: file.size}
      );
    };
    fileReader.readAsDataURL(file);
  });
}

export const validateFile = (file, languageContext, allowedTypeArray = ['jpg', 'png'], maxSize = 20000000) => {
  // files az egy file array és 20 MB a vitrin maximális kapacitása
  const allowedExtensions = allowedTypeArray, sizeLimit = maxSize;
  const {title: fileName, size: fileSize} = file;
  const fileExtension = fileName.split(".").pop(); // file típus kinyerése
  if (!allowedExtensions.includes(fileExtension)) {
    return languageContext.dictionary.fileErrorType + allowedTypeArray?.join(", ");
  } else if (fileSize > sizeLimit) {
    return languageContext.dictionary.fileErrorSize;
  }
  return null;
};

export function truncate(source, size) {
  if (source.length > size) {
    source = source.slice(0, size - 1);
    source.push("...");
  }
  return source.filter(Boolean).join(", ");
}

export const calculateTotalPages = (list, pageSize) => {
  return Math.ceil(list.length / pageSize);
};

export const getStatusIcon = (status) => {
  // Fix adat jön apiból amit így kezelünk
  switch (status) {
    case "Aktív":
      return <CheckCircle color="secondary"/>;
    case "Hibajegyre jelentve":
      return <ErrorOutlined color="warning"/>;
    default:
      return <HelpOutlined color="disabled"/>;
  }
};

export const filterServicesOrEstablishments = (dataList) => {
  let allCategories = {
    internet: [],
    dataCenter: [],
    other: [],
    cloud: [],
    office: [],
    security: [],
    communication: []
  };
  // Égetett adat, mivel ezzel szűrünk az adatokra
  dataList.forEach(data => {
    if (data.category === "Telefon- és kommunikációs szolgáltatások (Voice)") {
      allCategories.communication.push(data);
    }
    if (data.category === "Adat és internet szolgáltatások (Connectivity)") {
      allCategories.internet.push(data);
    }
    if (data.category === "Felhő szolgáltatások (Cloud)") {
      allCategories.cloud.push(data);
    }
    if (data.category === "Egyéb szolgáltatások (Other)") {
      allCategories.other.push(data);
    }
    if (data.category === "IT biztonsági szolgáltatások (Security)") {
      allCategories.security.push(data);
    }
    if (data.category === "Adatközponti szolgáltatások (DC)") {
      allCategories.dataCenter.push(data);
    }
    if (data.category === "Irodai informatikai rendszerek (EUS)") {
      allCategories.office.push(data);
    }
  });
  return allCategories;
};

export const contactHasRight = (yourRight, necessaryRight) => {
  // Belső jogosultásgok ['ADMIN', 'CP_FIN', 'CP_TECH'] elérése customerContext.selectedContact.roles
  // Külső jogosultásgok ['SALES','ALL_CUSTOMER','ADMIN'] elérése auth.userData.profile.realm_access.roles
  if (!necessaryRight || necessaryRight?.length === 0) {
    return true;
  }
  return yourRight?.some(r => necessaryRight?.includes(r));
};

export const canImpersonate = auth => contactHasRight(auth?.userData?.profile?.realm_access?.roles, ['ADMIN', 'SALES', 'ALL_CUSTOMER']);

export const isAdmin = customerContext => contactHasRight(customerContext?.selectedContact?.roles, ['ADMIN']);

export const abbreviationCorrection = (data, prop, language, isUserDialog = false) => {
  if (isUserDialog) {
    data?.forEach(item => {
      item.title = getCorrectedText(item?.title, language);
    });
  } else if (!Array.isArray(data)) {
    data[prop]?.forEach(item => {
      item.description = getCorrectedText(item?.description, language);
    });
  } else {
    data?.forEach(data => {
      data[prop]?.forEach(item => {
        item.description = getCorrectedText(item?.description, language);
      });
    });
  }
};

const getCorrectedText = (text, language) =>
  text?.replace("CSSP", language.dictionary.cssp)?.replace("CP", language.dictionary.cp)?.replace("TP", language.dictionary.tp);

export const validEmail = (email) => {
  return !email || String(email).toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};
