import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {LanguageContext} from "../../context/LanguageContext";
import {CircularProgress} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import BillAccordion from "../billing/BillAccordion";
import {makeStyles} from "@mui/styles";
import {getCollectorList} from "../../api/accountResource";
import {useHistory} from "react-router-dom";
import {useRequestErrorMessage} from "../../utils/useRequestErrorMessage";
import {CustomerContext} from "../../context/CustomerContext";
import CurrencyAvatar from "../common/CurrencyAvatar";
import BillAccordionPaid from "../billing/BillAccordionPaid";
import {useAuth} from "oidc-react";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";

const useStyles = makeStyles(theme => ({
  headerText: {
    fontWeight: "bold",
    fontSize: 28,
    color: theme.palette.secondary.main
  }
}));

const AccountBills = () => {
  const history = useHistory();
  const langContext = useContext(LanguageContext);
  const classes = useStyles();
  const auth = useAuth();
  const {showRequestError} = useRequestErrorMessage();
  const [billData, setBillData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const customerContext = useContext(CustomerContext);

  const handleGoDetailsPage = (data) => {
    history.push(`/billingDetails/${data.collectorId}`);
  };

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setDataLoading(true);
      getCollectorList(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => setBillData(res.data))
        .catch(error => showRequestError(error, langContext.dictionary.errorMessages.userDataLoadError))
        .finally(() => setDataLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id]);

  return (
    <Grid container>
      <Grid item xs={12} style={{marginBottom: 2, marginTop: 5}}>
        <Typography color="primary" variant={"h4"}>{langContext.dictionary.unpaidBills}</Typography>
      </Grid>
      <Grid container item padding={2}>
        {!dataLoading ? billData.map((data, index) =>
          <Paper square elevation={4} sx={{marginBottom: 3}} key={`billPaper_${index}`} style={{width: "100%"}}>
            <Grid container item direction="row" justifyContent="center" alignItems="center" rowSpacing={2} style={{paddingTop: 10}}>
              <Grid item xs={3} sm={2}>
                <CurrencyAvatar currency={data.currency} sx={{margin: "auto"}} filled/>
              </Grid>
              <Grid item xs={6} sm={2} textAlign={{xs: "center", sm: "start"}}>
                <Typography variant="subtitle1">{langContext.dictionary.currency}</Typography>
                <Typography variant="subtitle1" className={classes.headerText}>{data.currency}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} textAlign={{xs: "center", sm: "start"}}>
                <Typography variant="subtitle1">{langContext.dictionary.contractualCurrentAccountNumber}</Typography>
                <Typography variant="subtitle1" className={classes.headerText}>{data.giroId}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} textAlign={{xs: "center", sm: "end"}} paddingRight={{xs: 0, sm: 3}}>
                <Button color="secondary" onClick={() => handleGoDetailsPage(data)} endIcon={<ArrowForwardIosIcon color="secondary"/>}>
                  {langContext.dictionary.accountDetails}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} padding={{xs: 2, sm: 3}}>
              {data.notPayedBillList.length > 0 ? data.notPayedBillList.map((item, index) =>
                <BillAccordion key={`BillAccordion_${index}`} data={item} index={index}/>
              ) : (<BillAccordionPaid/>)}
            </Grid>
          </Paper>
        ) : <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
          <CircularProgress className={classes.circularProgress} color="secondary"/>
        </Grid>}
        <NoDataToDisplay loading={dataLoading} list={billData}/>
      </Grid>
    </Grid>);
};

export default AccountBills;
