import React, {useContext} from "react";
import {Grid} from "@mui/material";
import {LanguageContext} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {formatDate} from "../../utils/dateFormat";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import SectionWithDivider from "../common/SectionWithDivider";
import BillGrid from "./BillGrid";
import BillDocuments from "./BillDocuments";
import CurrencyTypography from "../common/CurrencyTypography";

const useStyles = makeStyles(theme => ({
  duration: {
    fontSize: 33,
    float: "left",
    marginRight: 5
  },
  rightGrid: {
    [theme.breakpoints.up('lg')]: {
      textAlign: "right"
    }
  },
  details: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3)
    },
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  avatar: {
    backgroundColor: "white",
    color: theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: 2,
    width: 60,
    height: 60,
    fontSize: 25
  },
  boldRow: {
    fontWeight: "bold"
  },
}));

const BillAccordion = ({data, index}) => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);

  const colorSwitcher = (data) => {
    if (formatDate(data.dueDate) > formatDate(data.maxPayDate) || data.maxPayDate !== null) {
      return "secondary.main";
    }
    if (formatDate(data.dueDate) < formatDate(new Date()) && data.maxPayDate === null) {
      return "error.main";
    } else {
      return "warning.main";
    }
  };

  return (
    <>
      <Accordion sx={{border: 1, borderColor: colorSwitcher(data), marginTop: 1}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: colorSwitcher(data)}}/>}>
          <BillGrid data={data} key={`billGrid_${index}`} colorSwitcher={colorSwitcher(data)}/>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className={classes.details} direction="row" justifyContent="center" alignItems="center">
            <SectionWithDivider title={languageContext.dictionary.billNumber} value={data.invoiceNumber}/>
            <SectionWithDivider title={languageContext.dictionary.contractualCurrentAccountNumber} value={data.giroId}/>
            <SectionWithDivider title={languageContext.dictionary.billingDate} value={formatDate(data.billingDate)}/>
            <SectionWithDivider title={languageContext.dictionary.paymentDeadline} value={formatDate(data.dueDate)}/>
            <SectionWithDivider title={languageContext.dictionary.fulfillmentDate} value={formatDate(data.fulfillmentDate)}/>
            <SectionWithDivider title={languageContext.dictionary.exhibitionMethod}
                                value={languageContext.dictionary.invoiceOutputTypes[data.invoiceOutputType]}/>
            <SectionWithDivider title={languageContext.dictionary.paymentMethod} value={data.paymentMode}/>
            <SectionWithDivider title={languageContext.dictionary.paymentDate} value={formatDate(data.maxPayDate)}/>
            <SectionWithDivider title={languageContext.dictionary.billTotal}
                                value={<CurrencyTypography amount={data.total} currency={data.currency} sx={{fontWeight: "bold"}}/>}/>
            <SectionWithDivider title={languageContext.dictionary.downloadableDocuments} value={""} lastItem/>
            <BillDocuments billingData={data}/>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

BillAccordion.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number
};

export default BillAccordion;