import React, {useContext} from "react";
import PropTypes from "prop-types";
import {TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {LanguageContext} from "../../../context/LanguageContext";
import RepeaterComboInput from "./RepeaterComboInput";

const ContactDataGrid = ({handleFieldChange, user, selectOptions, selectOptionsLoading, deleteFlag, defaultNewObject, idName}) => {
  const languageContext = useContext(LanguageContext);

  const hasValue = (array) => array?.some(a => a.value && !a.delete);

  return (
    <Grid item xs={12} container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography sx={{fontWeight: "bold"}}>{languageContext.dictionary.contactData}</Typography>
      </Grid>
      <RepeaterComboInput
        handleFieldChange={handleFieldChange}
        dataList={user.phoneNumbers}
        selectOptions={{
          options: selectOptions.phoneTypes,
          label: languageContext.dictionary.phoneNumberType,
          required: !hasValue(user.phoneNumbers)
        }}
        textFieldOptions={{
          label: languageContext.dictionary.phoneNumber,
          required: !hasValue(user.phoneNumbers)
        }}
        selectOptionsLoading={selectOptionsLoading}
        listName={"phoneNumbers"}
        validationType={"phoneNumber"}
        optionLabel={"title"}
        deleteFlag={deleteFlag}
        defaultNewObject={defaultNewObject}
        idName={idName}
      />
      <RepeaterComboInput
        handleFieldChange={handleFieldChange}
        dataList={user.emails}
        selectOptions={{
          options: selectOptions.emailTypes,
          label: languageContext.dictionary.emailAddressType,
          required: !hasValue(user.emails)
        }}
        textFieldOptions={{
          label: languageContext.dictionary.emailAddress,
          required: !hasValue(user.emails)
        }}
        selectOptionsLoading={selectOptionsLoading}
        listName={"emails"}
        validationType={"email"}
        optionLabel={"title"}
        deleteFlag={deleteFlag}
        defaultNewObject={defaultNewObject}
        idName={idName}
      />
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={user.fax?.name}
          variant={"outlined"}
          onChange={e => handleFieldChange("fax", e.target.value)}
          inputProps={{maxLength: 255}}
          value={user.fax?.value || ""}
        />
      </Grid>
    </Grid>
  );
};

ContactDataGrid.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  selectOptions: PropTypes.object.isRequired,
  selectOptionsLoading: PropTypes.bool,
  deleteFlag: PropTypes.string,
  defaultNewObject: PropTypes.object
};

export default ContactDataGrid;
