import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PrivilegeLevelsGrid from "../common/PrivilegeLevelsGrid";
import {CustomerContext} from "../../context/CustomerContext";
import ServerRoomPermissionsFilter from "./ServerRoomPermissionsFilter";
import {useAuth} from "oidc-react";
import UserEditor from "../user/UserEditor";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ServerRoomPermissionsServiceCard from "./ServerRoomPermissionsServiceCard";
import {getColositeColo, getColositeService} from "../../api/colositeResource";
import {isAdmin} from "../../utils/commonFunctions";
import NoDataToDisplay from "../noDataTitle/NoDataToDisplay";
import Tooltip from "@mui/material/Tooltip";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";

const privilegeLevels = [
  {title: "consolePrivilege", description: "consolePrivilege"},
  {title: "hardwareExchangePrivilege", description: "hardwareExchangePrivilege"},
  {title: "hardwareDeliveryPrivilege", description: "hardwareDeliveryPrivilege"}
];

const defaultFilters = {serverRoom: {value: "", label: ""}};

const ServerRoomPermissionsTab = () => {
  const auth = useAuth();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const [services, setServices] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [filterOptions, setFilterOptions] = useState({serverRooms: []});
  const [loading, setLoading] = useState(true);
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(true);
  const [addNewDialog, setAddNewDialog] = useState(false);


  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      handleLoadServerRoomOptions();
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.language.id]);

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      handleLoadServices();
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.language.id]);


  return (
    <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={true}>
      <Grid container sx={{pb: 3, pt: 3}} spacing={2} maxWidth="lg" seleniumid={"serverRoomPermissionsTab"}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">
            {languageContext.dictionary.users}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{textAlign: "right"}}>
          <Tooltip title={!isAdmin(customerContext) ? languageContext.dictionary.noPermission : ""}>
            <span>
              <Button
                disabled={!isAdmin(customerContext)}
                size="large"
                variant="text"
                startIcon={<AddIcon/>}
                color="secondary"
                onClick={() => setAddNewDialog(true)}>
                {languageContext.dictionary.newUser}
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <ServerRoomPermissionsFilter
          filters={filters}
          setFilters={setFilters}
          handleFilter={handleLoadServices}
          defaultFilters={defaultFilters}
          selectOptions={filterOptions}
          loading={filterOptionsLoading}
        />
        {!loading  ? (
          <Grid item container xs={12} spacing={2} sx={{pb: 3}}>
            {services.map((service, index) => (
              <Grid item xs={12} key={service.serviceId}>
                <ServerRoomPermissionsServiceCard
                  service={service}

                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item xs={12} style={{justifyContent: "center", display: 'flex', textAlign: "center"}}>
            <CircularProgress sx={{margin: 4}} color="secondary"/>
          </Grid>
        )}
        <NoDataToDisplay loading={loading} list={services}/>
        <PrivilegeLevelsGrid privilegeLevels={privilegeLevels}/>
        <UserEditor open={addNewDialog} onEditCancel={() => setAddNewDialog(false)} onItemEdited={handleSaveNewUser}/>
      </Grid>
    </RenderedWithPermission>
  );

  async function handleLoadServerRoomOptions() {
    setFilterOptionsLoading(true);

    getColositeColo(auth.userData.access_token, customerContext.selectedCustomer.id)
      .then(res => {
        const coloOptions = res.data.map(option => ({label: option.description, value: option.key}));
        setFilterOptions(o => ({...o, serverRooms: coloOptions}));
      })
      .finally(() => setFilterOptionsLoading(false));
  }

  function handleLoadServices(defaultFilter = false) {
    setLoading(true);
    getColositeService(auth.userData.access_token, customerContext.selectedCustomer.id, !defaultFilter ? filters.serverRoom.value : null)
      .then(res => setServices(res.data.map(service => ({...service, vitrinIdValues: service.vitrinIds?.map(v => v.value)}))))
      .finally(() => setLoading(false));
  }

  function handleSaveNewUser() {
    setAddNewDialog(false);
    handleLoadServerRoomOptions();
  }
};

export default ServerRoomPermissionsTab;