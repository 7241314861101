import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import {makeStyles, useTheme} from "@mui/styles";
import {LanguageContext} from "../../context/LanguageContext";
import {languageOptions} from "../../languages";
import {UserContext} from "../../context/UserContext";
import {Menu, MenuItem, useMediaQuery} from "@mui/material";
import CompanySelectorButton from "./CompanySelectorButton";
import UserMenuMobileDialog from "../dialog/UserMenuMobileDialog";
import {NavLink, useHistory} from "react-router-dom";
import {useAuth} from "oidc-react";
import RenderedWithPermission from "../RenderedWithPermission";
import {adminFinTechRight} from "../../utils/commonValues";
import {InfoOutlined} from "@mui/icons-material";
import SimpleConfirmDialog from "../dialog/SimpleConfirmDialog";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.userBar.background,
    placeContent: "flex-end"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  icon: {
    marginRight: theme.spacing(0.5)
  },
  button: {
    margin: `${theme.spacing(0.5)}`
  },
  username: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "30ch"
  },
  buttonNoLabel: {
    margin: `0`
  },
  buttonGroup: {
    margin: `0 ${theme.spacing(0.5)}`,
    [theme.breakpoints.down('md')]: {display: "none"}
  },
  hide: {
    display: "none"
  },
  badge: {
    '& .MuiBadge-badge': {
      right: -3,
      top: 5
    }
  }
}));

const HorizontalUserBar = props => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [invitationSenderDialogOpen, setInvitationSenderDialogOpen] = useState(false);
  const [mfaConfirmDialogOpen, setMfaConfirmDialogOpen] = useState(false);
  const [userMenuMobileDialogOpen, setUserMenuMobileDialogOpen] = useState(false);
  const renderCompanySelector = useMediaQuery(theme.breakpoints.up('md'));
  const renderUserMenuMobileDialog = useMediaQuery(theme.breakpoints.up('sm'));

  const userMenuOpen = Boolean(anchorEl);

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const languageContext = useContext(LanguageContext);
  const userContext = useContext(UserContext);
  const auth = useAuth();

  const logout = () => {
    auth.userManager.signoutRedirect();
  };

  const changePassword = () => {
    let url = new URL(window._env_.REACT_APP_OIDC_URL + "/realms/" + window._env_.REACT_APP_OIDC_REALM + "/protocol/openid-connect/auth");
    url.searchParams.append("client_id", window._env_.REACT_APP_CLIENT_ID);
    url.searchParams.append("kc_action", "UPDATE_PASSWORD");
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", window.location.href);
    window.open(url, "_self");
  };

  const configureTotp = () => {
    let url = new URL(window._env_.REACT_APP_OIDC_URL + "/realms/" + window._env_.REACT_APP_OIDC_REALM + "/protocol/openid-connect/auth");
    url.searchParams.append("client_id", window._env_.REACT_APP_CLIENT_ID);
    url.searchParams.append("kc_action", "CONFIGURE_TOTP");
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", window.location.href);
    window.open(url, "_self");
  };

  const handleLanguageChange = event => {
    let selectedLanguage;
    const lang = languageContext.language.id;
    if (lang === "hu") {
      selectedLanguage = languageOptions.find(item => item.id === "en");
    } else {
      selectedLanguage = languageOptions.find(item => item.id === "hu");
    }
    languageContext.setLanguage(selectedLanguage);
  };

  const openMfaConfirmDialog = () => {
    setMfaConfirmDialogOpen(true);
  };

  const closeMfaConfirmDialog = () => {
    setMfaConfirmDialogOpen(false);
  };

  const showUserMenuMobileDialog = () => {
    handleUserMenuClose();
    setUserMenuMobileDialogOpen(true);
  };

  const closeUserMenuMobileDialog = () => {
    setUserMenuMobileDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      {auth.userData && renderCompanySelector ? <CompanySelectorButton/> : null}
      <>
        <Button data-testid="userButton"
                id="openMenu"
                className={classes.button}
                onClick={renderUserMenuMobileDialog ? handleUserMenuOpen : showUserMenuMobileDialog}
                variant="text"
                size="small">
          <Icon className={classes.icon}>account_circle</Icon>
          <span className={classes.username}
                title={userContext.userInfo.email || languageContext.dictionary.login}>
            {userContext.userInfo.email || languageContext.dictionary.login}
          </span>
          {userMenuOpen ? (
            <Icon className={classes.icon} sx={{color: theme.palette.mainButton.main}}>expand_less</Icon>
          ) : (
            <Icon className={classes.icon} sx={{color: theme.palette.mainButton.main}}>expand_more</Icon>
          )}
        </Button>
        <Menu
          data-testid="loggedInUserMenu"
          id="basic-menu"
          open={userMenuOpen}
          anchorEl={anchorEl}
          onClose={handleUserMenuClose}
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleUserMenuClose} component={NavLink} to="/companyDetails">
            {languageContext.dictionary.companyDetails}
          </MenuItem>
          <MenuItem onClick={handleUserMenuClose} component={NavLink} to="/profile">
            {languageContext.dictionary.myProfile}
          </MenuItem>
          <RenderedWithPermission roles={adminFinTechRight} displayNoRightsLabel={false}>
            <MenuItem onClick={handleUserMenuClose} component={NavLink} to="/usersAndPermissions">
              {languageContext.dictionary.usersAndPermissions}
            </MenuItem>
          </RenderedWithPermission>
          <MenuItem onClick={changePassword}>{languageContext.dictionary.changePassword}</MenuItem>
          <Tooltip title={languageContext.dictionary.configureMfaExperimentalFeature}>
            <MenuItem onClick={openMfaConfirmDialog}>{languageContext.dictionary.configureMfa}<InfoOutlined fontSize={"small"} color={"disabled"}/></MenuItem>
          </Tooltip>
          <MenuItem onClick={logout}>{languageContext.dictionary.logout}</MenuItem>
        </Menu>
      </>
      <Tooltip title={languageContext.dictionary.changeLanguage}>
        <Button
          className={classes.button}
          color="primary"
          onClick={handleLanguageChange}
          size="small"
        >
          <Icon className={classes.icon}>language</Icon>
          {languageContext.language.id}
        </Button>
      </Tooltip>
      {/*<Tooltip title={languageContext.dictionary.notifications}>
        <Button
          className={classes.button}
          color="primary"
          onClick={() => history.push("/notifications")}
          size="small"
        >
          <Badge badgeContent={userContext.notificationCounter} color="secondary" className={classes.badge}>
            <NotificationsIcon color="action"/>
          </Badge>
        </Button>
      </Tooltip>*/}
      <UserMenuMobileDialog open={userMenuMobileDialogOpen}
                            changePassword={changePassword}
                            logout={logout}
                            handleClose={closeUserMenuMobileDialog}
                            configuretOtp={openMfaConfirmDialog}
                            firstName={userContext.userInfo.firstName}
                            lastName={userContext.userInfo.lastName}/>
      <SimpleConfirmDialog open={mfaConfirmDialogOpen}
                           handleClose={closeMfaConfirmDialog}
                           handleAction={configureTotp}
                           actionLabel={languageContext.dictionary.next}
                           dialogTitle={languageContext.dictionary.configureMfa}
                           dialogContentText={languageContext.dictionary.configureMfaDialog}/>
    </div>
  );
};

HorizontalUserBar.propTypes = {
  content: PropTypes.any,
  changeOrientation: PropTypes.func
};

export default HorizontalUserBar;
