import React, {useContext, useEffect, useMemo, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import {Paper, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomAutocomplete from "../formFields/CustomAutocomplete";
import PropTypes from "prop-types";
import {useAuth} from "oidc-react";
import MaterialReactTable from "material-react-table";
import {MRT_Localization_HU} from "material-react-table/locales/hu";
import {MRT_Localization_EN} from "material-react-table/locales/en";
import {getColositeColo, getColositeVitrinService} from "../../api/colositeResource";
import {CustomerContext} from "../../context/CustomerContext";
import Tooltip from "@mui/material/Tooltip";
import CustomDateRangePicker from "../date/CustomDateRangePicker";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import moment from "moment";

const ServerRoomEnterDetailsForm = ({details, setDetails}) => {
  const auth = useAuth();
  const languageContext = useContext(LanguageContext);
  const customerContext = useContext(CustomerContext);
  const [serverRoomOptions, setServerRoomOptions] = useState([]);
  const [actionOptions, setActionOptions] = useState([]);
  const [affectedServices, setAffectedServices] = useState([]);
  const [actionOptionsLoading, setActionOptionsLoading] = useState(false);
  const [serverRoomOptionsLoading, setServerRoomOptionsLoading] = useState(true);
  const [affectedServicesLoading, setAffectedServicesLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    handleFormChange("affectedServices", affectedServices.filter((s, index) => Object.keys(rowSelection).includes(index.toString())));
  }, [rowSelection]);

  const columns = useMemo(() => [
    {
      accessorKey: 'id',
      header: languageContext.dictionary.technicalId
    },
    {
      accessorKey: 'serviceName',
      header: languageContext.dictionary.serviceId,
      Cell: ({cell}) => <span>{`${cell.getValue()} (${cell.row.original.serviceId})`}</span>,
    },
    {
      accessorKey: 'description',
      header: languageContext.dictionary.serverName
    }
  ], [languageContext.language.id]);

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id) {
      setServerRoomOptionsLoading(true);
      getColositeColo(auth.userData.access_token, customerContext.selectedCustomer.id)
        .then(res => setServerRoomOptions(res.data.map(option => ({label: option.description, value: option.key}))))
        .finally(() => setServerRoomOptionsLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.language.id]);

  useEffect(() => {
    if (auth.userData && customerContext.selectedCustomer.id && details.serverRoom) {
      setActionOptionsLoading(true);
      getActionOptions(auth.userData.access_token, customerContext.selectedCustomer.id, details.serverRoom)
        .then(res => setActionOptions(res.data))
        .finally(() => setActionOptionsLoading(false));

      setAffectedServicesLoading(true);
      getColositeVitrinService(auth.userData.access_token, customerContext.selectedCustomer.id, details.serverRoom)
        .then(res => setAffectedServices(res.data))
        .finally(() => setAffectedServicesLoading(false));
    }
  }, [auth.userData, customerContext.selectedCustomer.id, languageContext.language.id, details.serverRoom]);

  const handleFormChange = (field, newValue) => {
    setDetails(curr => ({...curr, [field]: newValue}));
  };

  return (
    <Paper elevation={4} sx={{width: "100%", p: 3, mt: 2}} seleniumid={"ServerRoomEnterDetailsForm"}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sx={{mb: 1}}>
          <Typography variant={"h5"}>{languageContext.dictionary.enterDetails}</Typography>
        </Grid>
        <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
          <Typography>{languageContext.dictionary.serverRoom}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
          <CustomAutocomplete
            value={details.serverRoom}
            options={serverRoomOptions}
            handleChange={(event, newValue) => handleFormChange("serverRoom", newValue?.value || "")}
            label={languageContext.dictionary.serverRoom}
            loading={serverRoomOptionsLoading}
            required
            size={"small"}
          />
        </Grid>
        <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
          <Typography>{languageContext.dictionary.affectedServices}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
          <MaterialReactTable
            columns={columns}
            data={affectedServices}
            localization={languageContext?.language?.id === 'hu' ? MRT_Localization_HU : MRT_Localization_EN}
            displayColumnDefOptions={{}}  // Workaround: https://github.com/KevinVandy/material-react-table/issues/189
            initialState={{pagination: {pageSize: 5}}}
            enableRowSelection
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{
              rowSelection: rowSelection, //pass our managed row selection state to the table to use
              isLoading: affectedServicesLoading
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
          <Typography>{languageContext.dictionary.action}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
          <CustomAutocomplete
            value={details.action?.value}
            options={actionOptions}
            handleChange={(event, newValue) => handleFormChange("action", newValue || {})}
            label={languageContext.dictionary.action}
            loading={actionOptionsLoading}
            required
            size={"small"}
            disabled={!details.serverRoom}
          />
        </Grid>
        <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
          <Typography>{languageContext.dictionary.licensePlate}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
          <TextField
            value={details.licensePlate || ""}
            fullWidth
            label={languageContext.dictionary.licensePlate}
            size="small"
            onChange={e => handleFormChange("licensePlate", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
          <div style={{display: "flex"}}>
            <Typography sx={{paddingRight: 1}}>{languageContext.dictionary.enterInterval}</Typography>
            <Tooltip title={languageContext.dictionary.enterIntervalTooltip} arrow>
              <HelpOutlineOutlinedIcon fontSize="small"/>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
          <CustomDateRangePicker
            dateTime
            onChange={date => handleFormChange("enterInterval", date)}
            value={details.enterInterval}
            size={"small"}
            fullWidth
            required
            minDate={details.enterInterval[1] ? moment(details.enterInterval[1]).subtract(1, 'w') : undefined}
            maxDate={details.enterInterval[0] ? moment(details.enterInterval[0]).add(1, 'w') : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={5} style={{textAlign: "start"}}>
          <Typography>{languageContext.dictionary.comment}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} style={{textAlign: "end"}}>
          <TextField
            multiline
            value={details.comment || ""}
            fullWidth
            label={languageContext.dictionary.comment}
            size="small"
            onChange={e => handleFormChange("comment", e.target.value)}
            required
          />
        </Grid>
      </Grid>
    </Paper>
  );

// todo only test functions
  async function getActionOptions() {
    return {
      data: [
        {label: languageContext.dictionary.actionOptions.toServer, value: "szerverhez"},
        {label: languageContext.dictionary.actionOptions.visit, value: "látogatás"},
        {label: languageContext.dictionary.actionOptions.maintenance, value: "karbantartás"},
        {label: languageContext.dictionary.actionOptions.bringsOrTakesServer, value: "szervert hoz / visz"},
        {label: languageContext.dictionary.actionOptions.troubleshooting, value: "hibaelhárítás"}
      ]
    };
  }
};

ServerRoomEnterDetailsForm.propTypes = {
  details: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired
};

export default ServerRoomEnterDetailsForm;
