import React, {useContext} from "react";
import {Grid, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {LanguageContext} from "../../context/LanguageContext";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    maxWidth: "370px",
    width: "370px",
    height: "130px",
    maxHeight: "165px"
  }
}));

const buttonData = {
  service: {text: "serviceAdministration", icon: "tune_outlined"},
  bill: {text: "invoicingAdministration", icon: "payments_outlined"},
  requestForOffer: {text: "requestForOffer", icon: "local_mall_outlined"}
};

const AdministrationButtons = ({value, handleChange, disabled}) => {
  const classes = useStyles();
  const languageContext = useContext(LanguageContext);

  return (
    <ToggleButtonGroup
      fullWidth
      disabled={disabled}
      value={value}
      exclusive
      onChange={handleChange}
      style={{display: 'flex', justifyContent: 'center'}}
    >
      {Object.entries(buttonData).map(([key], index) => (
        <ToggleButton key={`ToggleButton${index}`} value={key} color={"secondary"} className={classes.buttonStyle}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Icon>{buttonData[key].icon}</Icon>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"subtitle2"} fontSize={13}>{languageContext.dictionary[buttonData[key].text]}</Typography>
            </Grid>
          </Grid>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

AdministrationButtons.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool
};
export default AdministrationButtons;