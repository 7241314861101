import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import CustomSelect from "../formFields/CustomSelect";
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const defaultFilters = {
  type: "",
  period: [null, null],
  contractNumber: "",
  duration: ""
};

const maxWidth = 200;

const ContractsFilter = ({handleFilterSubmit, optionsLoading, options}) => {
  const [filters, setFilters] = useState(defaultFilters);
  const languageContext = useContext(LanguageContext);

  const handleFilterChange = (newValue, filterName) => {
    setFilters(curr => ({...curr, [filterName]: newValue}));
  };

  const resetFilter = () => {
    setFilters(defaultFilters);
    handleFilterSubmit(defaultFilters);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFilterSubmit(filters);
    }
  };

  useEffect(() => {
    handleFilterSubmit(filters);
  }, [filters.period, filters.duration]);

  return (
    <Grid item xs={12} seleniumid={`contractFilter`} style={{paddingTop: 30}}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary"/>}>
          <Typography variant="h5">{languageContext.dictionary.filters}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} sx={{padding: 2}} alignItems="center">
            <Grid item container xs={12} md={6} alignItems="center" spacing={1}>
              <Grid item xs={12} sm={3} md={4}>
                <Typography>{languageContext.dictionary.contractNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={8}>
                <TextField
                  style={{maxWidth: maxWidth}}
                  label={languageContext.dictionary.contractNumber}
                  onKeyDown={handleKeyDown}
                  name={"contractNumber"}
                  value={filters.contractNumber}
                  onChange={(e) => handleFilterChange(e.target.value, "contractNumber")}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="center" spacing={1}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography>{languageContext.dictionary.period}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <DateRangePicker
                  value={filters.period}
                  onChange={date => handleFilterChange(date, "period")}
                  localeText={{start: languageContext.dictionary.startDate, end: languageContext.dictionary.endDate}}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="center" spacing={1}>
              <Grid item xs={12} sm={3} md={4}>
                <Typography>{languageContext.dictionary.duration}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={8}>
                <CustomSelect
                  value={filters.duration}
                  handleChange={e => handleFilterChange(e.target.value, "duration")}
                  parameters={{name: "duration", label: languageContext.dictionary.duration, options: options.duration, canBeNull: true}}
                  loading={optionsLoading}
                  minWidth={maxWidth}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={12} md={2}>
                <Button color="secondary" variant="text" onClick={resetFilter}>
                  {languageContext.dictionary.deleteFilters}
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button color="secondary" variant="contained" onClick={() => handleFilterSubmit(filters)}>
                  {languageContext.dictionary.filter}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ContractsFilter.propTypes = {
  handleFilterSubmit: PropTypes.func.isRequired,
  optionsLoading: PropTypes.bool,
  options: PropTypes.object.isRequired
};

export default ContractsFilter;