import {env} from "../properties";
import {axiosWithInterceptors} from "../components/common/AxiosInterceptorInitializer";
import axios from "axios";

const resource = "/colosite";

export const getColositeColo = async (token, cmId) => {
  let url = `${env.apiRoot}${resource}/colo/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}});
};

export const getColositeRole = async (token, serviceid) => {
  let url = `${env.apiRoot}${resource}/role`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, params: {serviceid: serviceid}});
};

export const getColositeService = async (token, cmId, site) => {
  let url = `${env.apiRoot}${resource}/service/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, params: {site: site}});
};

export const getColositeUser = async (token, cmId, site) => {
  let url = `${env.apiRoot}${resource}/user/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, params: {site: site}});
};

export const saveColositeService = async (token, cmId, service) => {
  let url = `${env.apiRoot}${resource}/service/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.post(url, service, {headers: {Authorization: authStr}});
};

export const saveColositeUser = async (token, cmId, user) => {
  let url = `${env.apiRoot}${resource}/user/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axios.post(url, user, {headers: {Authorization: authStr}});
};

export const getColositeVitrinService = async (token, cmId, site) => {
  let url = `${env.apiRoot}${resource}/vitrinService/${cmId}`;
  const authStr = "Bearer ".concat(token);
  return await axiosWithInterceptors.get(url, {headers: {Authorization: authStr}, params: {site: site}});
};
