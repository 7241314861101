import React, {useContext} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {LanguageContext} from "../../context/LanguageContext";

const RequestErrorCopyButton = props => {
  const languageContext = useContext(LanguageContext);
  return (
    <Button
      size="small"
      variant="contained"
      style={{color: 'white', backgroundColor: "#4a4f55"}}
      onClick={() => {
        let errorMessage = "";
        if (props.error instanceof Error) {
          if (props.error.response?.data) {
            try {
              errorMessage = JSON.stringify(props.error.response.data);
            } catch (cyclicObjectError) {
              errorMessage = props.error.response.data;
            }
          } else if (props.error.message) {
            errorMessage = props.error.message;
          }
        } else {
          try {
            errorMessage = JSON.stringify(props.error);
          } catch (cyclicObjectError) {
            errorMessage = props.error;
          }
        }
        window.navigator.clipboard.writeText(
          props.errorMessage ?
            props.errorMessage + "\n" + errorMessage :
            errorMessage
        );
        props.closeSnackbar();
      }}
    >
      {languageContext.dictionary.copyErrorMessage}
    </Button>
  );
};

RequestErrorCopyButton.propTypes = {
  error: PropTypes.object,
  errorMessage: PropTypes.string,
  closeSnackbar: PropTypes.func
};

export default RequestErrorCopyButton;
